import { Alert, Box, Button, Input } from "@mui/material";
import { useState } from "react";
import { useUploadActivitiesCSVMutation } from "state/managementApi";

const ActivitesCSVUploader = ({ onClose }) => {
    const [file, setFile] = useState(null);
    const [formErrors, setFormErrors] = useState([]);
    const [formSuccess, setFormSuccess] = useState([]);
    const [uploadCSV] = useUploadActivitiesCSVMutation();
    
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        let errors = [];
        if (!file) {
            errors.push('Please select a CSV file.');
        };
        if (errors.length > 0) {
            setFormErrors(errors);
            return;
        };

        const formData = new FormData();
        formData.append('csvFile', file);
        await uploadCSV(formData).unwrap()
            .then(() => {
                setFormSuccess(['Ayy, CSV uploaded successfully!']);
            })
            .catch((err) => {
                setFormErrors(prev => [...prev, err.message || 'An error occurred while uploading CSV.']);
            });
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2, margin: 2 }}>
            <Input 
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                fullWidth
                variant="outlined"
            />
            <Button variant="contained" color="primary" onClick={handleUpload}>
                Upload CSV
            </Button>
            <Button variant="contained" color="error" onClick={onClose}>
                Close Uploader
            </Button>
            {formErrors.length > 0 && (
                <Box>
                    {formErrors.map((error, index) => (
                        <Alert key={index} severity="error">{error}</Alert>
                    ))}
                </Box>
            )}
            {formSuccess.length > 0 && (
                <Box>
                    {formSuccess.map((success, index) => (
                        <Alert key={index} severity="success">{success}</Alert>
                    ))}
                </Box>
            )}
        </Box>
    )
}

export default ActivitesCSVUploader;