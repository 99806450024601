import { useEffect, useMemo, useState } from "react";
import ktb from '../../assets/ktbwhite.png';
import Grid from '@mui/material/Grid';
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ValueGraph from 'components/tools/ValueGraph';
import { useGetLeaderboardDataQuery } from 'state/api';
import { isWithinBusinessHours } from 'components/helpers/CheckBusinessHours';

// const today = new Date();
// const currentMonth = today.getMonth();
// const currentYear = today.getFullYear();

const WeekStatsTotalSheet = ({ agents, theme, currentDayStats }) => {
    // const [month, setMonth] = useState(currentMonth);
    // const [year, setYear] = useState(currentYear);

    const { data: weeklyStats = [], error: statsError, isLoading: statsLoading, refetch: refetchStats } = useGetLeaderboardDataQuery({ limit: 100 }, { staleTime: 0 });

    const sortedWeeklyStats = useMemo(() => {
        return [...weeklyStats].sort((a, b) => {
            const valueA = a.name || "";
            const valueB = b.name || "";
            return valueA.localeCompare(valueB);
        })
    }, [weeklyStats]).filter((agentStats) => agents.find((agent) => (agent.name === agentStats.name && agent.showStats)));

    const totalCurrentDayStats = currentDayStats.length ? currentDayStats.filter((agentStats) => agents.find((agent) => agent.name === agentStats.name && agent.showStats)).reduce((prev, current) => {
        return {
            dailyNewCalls: prev.dailyNewCalls + current.dailyNewCalls,
            dailyCalls: prev.dailyCalls + current.dailyCalls,
            dailyEmails: prev.dailyEmails + current.dailyEmails,
            dailyListEmails: prev.dailyListEmails + current.dailyListEmails,
            packets: prev.packets + current.packets,
            quotes: prev.quotes + current.quotes,
            loadsBooked: prev.loadsBooked + current.loadsBooked,
            setups: prev.setups + current.setups,
            margin: prev.margin + current.margin
        }
    }) : {};
    // console.log('totalCurrentDayStats')
    // console.log(totalCurrentDayStats)
    
    const totalWeeklyStats = weeklyStats.length ? weeklyStats.filter((agentStats) => agents.find((agent) => (agent.name === agentStats.name && agent.showStats))).reduce((prev, current) => {
        return {
            totalNewCalls: prev.totalNewCalls + current.totalNewCalls,
            totalCalls: prev.totalCalls + current.totalCalls,
            totalEmails: prev.totalEmails + current.totalEmails,
            totalListEmails: prev.totalListEmails + current.totalListEmails,
            totalPackets: prev.totalPackets + current.totalPackets,
            totalQuotes: prev.totalQuotes + current.totalQuotes,
            totalLoadsBooked: prev.totalLoadsBooked + current.totalLoadsBooked,
            totalSetups: prev.totalSetups + current.totalSetups,
            totalMargin: prev.totalMargin + current.totalMargin
        }
    }) : {};
    // console.log('totalWeeklyStats')
    // console.log(totalWeeklyStats)
    
    // TODO: Handle days in week here for cleanliness??
    const totalWeeklyQuota = agents.length ? agents.filter((agent) => agent.showStats).reduce((prev, current) => {
        return {
            newCallGoals: prev.newCallGoals + current.newCallGoals,
            callGoals: prev.callGoals + current.callGoals,
            emailGoals: prev.emailGoals + current.emailGoals,
            dailyListEmailGoals: prev.dailyListEmailGoals + current.dailyListEmailGoals,
            packetGoals: prev.packetGoals + current.packetGoals,
            quoteGoals: prev.quoteGoals + current.quoteGoals,
            loadsBookedGoals: prev.loadsBookedGoals + current.loadsBookedGoals,
            setupGoals: prev.setupGoals + current.setupGoals,
            marginGoals: prev.marginGoals + current.marginGoals
        }
    }) : {};
    
    useEffect(() => {
     const intervalId = setInterval(() => {
        if (isWithinBusinessHours()) {
            refetchStats();
        }
     }, 15 * 60 * 1000); // Refresh every 15 minutes

     return () => clearInterval(intervalId)
    }, [refetchStats]);

    const handleTotalStatAndCurrentDaySum = (agent, stat) => {
        const matchedAgentWeeklyStats = sortedWeeklyStats.find((agentStats) => agentStats._id === agent._id);

        switch (stat) {
            case 'dailyNewCalls':
                return matchedAgentWeeklyStats ? agent.dailyNewCalls + matchedAgentWeeklyStats.totalNewCalls : agent.dailyNewCalls
            case 'dailyCalls':
                return matchedAgentWeeklyStats ? agent.dailyCalls + matchedAgentWeeklyStats.totalCalls : agent.dailyCalls
            case 'dailyEmails':
                return matchedAgentWeeklyStats ? agent.dailyEmails + matchedAgentWeeklyStats.totalEmails : agent.dailyEmails
            case 'dailyListEmails':
                return matchedAgentWeeklyStats ? agent.dailyListEmails + matchedAgentWeeklyStats.totalListEmails : agent.dailyListEmails
            case 'packets':
                return matchedAgentWeeklyStats ? agent.packets + matchedAgentWeeklyStats.totalPackets : agent.packets
            case 'quotes':
                return matchedAgentWeeklyStats ? agent.quotes + matchedAgentWeeklyStats.totalQuotes : agent.quotes
            case 'loadsBooked':
                return matchedAgentWeeklyStats ? agent.loadsBooked + matchedAgentWeeklyStats.totalLoadsBooked : agent.loadsBooked
            case 'setups':
                return matchedAgentWeeklyStats ? agent.setups + matchedAgentWeeklyStats.totalSetups : agent.setups
            case 'margin':
                return matchedAgentWeeklyStats ? agent.margin + matchedAgentWeeklyStats.totalMargin : agent.margin

            default:
                break;
        }

        return 0;
    };

    if (statsLoading) return <CircularProgress />;

    if (statsError || !agents.length) return <p>Error retrieving stats</p>;

    return (
        <Box sx={{ m: '15px 0px' }}>
            {theme.palette.mode === 'dark' ? (
                <Box 
                    component='img'
                    sx={{
                        height: '20%',
                        width: '20%',
                        backgroundColor: theme.palette.primary[900],
                        p: '10px',
                        mr: '15px',
                        mb: '15px',
                    }}
                    alt="KTB Logo"
                    src={ktb}
                />
            ) : (
                <Box 
                    component='img'
                    sx={{
                        height: '20%',
                        width: '20%',
                        backgroundColor: theme.palette.primary[100],
                        p: '10px',
                        mr: '15px',
                        mb: '15px',
                    }}
                    alt="KTB Logo"
                    src={ktb}
                />
            )}
            <Box sx={{ mb: '15px', display: 'flex', justifyContent: 'space-around' }}>
                <Box sx={{ width: '40%' }}>
                    <Grid
                        container
                        direction="row"
                        sx={{
                            border: '1px solid black'
                        }}
                    >
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', m: '15px 0px' }}>
                            <Typography variant='h5' fontWeight="bold">
                                Total New Calls
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', m: '15px 0px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                Total FU Calls
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', mb: '15px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                {((totalWeeklyStats.totalNewCalls || 0) + totalCurrentDayStats.dailyNewCalls) || 0}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', mb: '15px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                {((totalWeeklyStats.totalCalls || 0) + totalCurrentDayStats.dailyCalls) || 0}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid
                        container
                        direction="row"
                        sx={{
                            border: '1px solid black'
                        }}
                    >
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', m: '15px 0px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                Total Packets
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', m: '15px 0px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                Total Quotes
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', mb: '15px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                {((totalWeeklyStats.totalPackets || 0) + totalCurrentDayStats.packets) || 0}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', mb: '15px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                {((totalWeeklyStats.totalQuotes || 0) + totalCurrentDayStats.quotes) || 0}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid
                        container
                        direction="row"
                        sx={{
                            border: '1px solid black'
                        }}
                    >
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', m: '15px 0px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                Total Loads
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', m: '15px 0px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                Total Setups
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', mb: '15px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                {((totalWeeklyStats.totalLoadsBooked || 0) + totalCurrentDayStats.loadsBooked) || 0}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', mb: '15px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                {((totalWeeklyStats.totalSetups || 0) + totalCurrentDayStats.setups) || 0}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid
                        container
                        direction="row"
                        sx={{
                            border: '1px solid black'
                        }}
                    >
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', m: '15px 0px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                Total Margin
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mb: '15px' }}>
                            <Typography variant="h1" fontWeight="bold">
                                ${((totalWeeklyStats.totalMargin || 0) + totalCurrentDayStats.margin) || 0}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ width: '45%' }}>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography>
                            Weekly Company Quota Progress
                        </Typography>
                    </Box>
                    <Grid
                        container
                        direction="column"
                        sx={{
                            border: '1px solid black',
                            p: '10px'
                        }}
                    >
                        <Box sx={{ mb: '25px' }}>
                            <ValueGraph value={(totalWeeklyStats.totalMargin || 0) + totalCurrentDayStats.margin} valueGoal={(totalWeeklyQuota.marginGoals * 5) || 0} label="Margin" />
                        </Box>
                        <Box sx={{ mb: '25px' }}>
                            <ValueGraph value={(((totalWeeklyStats.totalCalls) + (totalWeeklyStats.totalNewCalls)) || 0) + (totalCurrentDayStats.dailyNewCalls + totalCurrentDayStats.dailyCalls)} valueGoal={(totalWeeklyQuota.callGoals + totalWeeklyQuota.newCallGoals) * 5 || 0} label="Calls" />
                        </Box>
                        <Box sx={{ mb: '25px' }}>
                            <ValueGraph value={(((totalWeeklyStats.totalEmails) + (totalWeeklyStats.totalListEmails)) || 0) + (totalCurrentDayStats.dailyEmails + totalCurrentDayStats.dailyListEmails)} valueGoal={(totalWeeklyQuota.emailGoals + totalWeeklyQuota.dailyListEmailGoals) * 5 || 0} label="Emails" />
                        </Box>
                        <Box sx={{ mb: '25px' }}>
                            <ValueGraph value={(totalWeeklyStats.totalPackets || 0) + totalCurrentDayStats.packets} valueGoal={(totalWeeklyQuota.packetGoals * 5) || 0} label="Packets" />
                        </Box>
                        <Box sx={{ mb: '25px' }}>
                            <ValueGraph value={(totalWeeklyStats.totalLoadsBooked || 0) + totalCurrentDayStats.loadsBooked} valueGoal={(totalWeeklyQuota.loadsBookedGoals * 5) || 0} label="Loads Booked" />
                        </Box>
                        <Box>
                            <ValueGraph value={(totalWeeklyStats.totalSetups || 0) + totalCurrentDayStats.setups} valueGoal={(totalWeeklyQuota.setupGoals * 5) || 0} label="Setups" />
                        </Box>
                    </Grid>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                <Box sx={{ width: '78%' }}>
                    <TableContainer component={Paper} sx={{ overflow: 'auto', maxHeight: 570 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell 
                                        sx={{
                                            width: '10%',
                                            position: 'sticky',
                                            left: 0,
                                            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary[900] : theme.palette.grey[100]
                                        }}
                                    />
                                    <TableCell sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary[900] : theme.palette.grey[100], position: 'sticky', top: 0 }}>
                                        Margin
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary[900] : theme.palette.grey[100], position: 'sticky', top: 0 }}>
                                        New Calls
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary[900] : theme.palette.grey[100], position: 'sticky', top: 0 }}>
                                        FU Calls
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary[900] : theme.palette.grey[100], position: 'sticky', top: 0 }}>
                                        Packets
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary[900] : theme.palette.grey[100], position: 'sticky', top: 0 }}>
                                        Quotes
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary[900] : theme.palette.grey[100], position: 'sticky', top: 0 }}>
                                        FU Emails
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary[900] : theme.palette.grey[100], position: 'sticky', top: 0 }}>
                                        List Emails
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary[900] : theme.palette.grey[100], position: 'sticky', top: 0 }}>
                                        Loads Booked
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary[900] : theme.palette.grey[100], position: 'sticky', top: 0 }}>
                                        Setups
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {agents.filter((agent) => agent.showStats).map((agent, index) => (
                                    <TableRow hover key={index} sx={{ width: 700 }}>
                                        <TableCell
                                            padding='checkbox'
                                            size='medium'
                                            sx={{
                                                width: '10%',
                                                position: 'sticky',
                                                left: 0,
                                                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary[900] : theme.palette.grey[100]
                                            }}
                                        >
                                            <Typography sx={{ textDecoration: 'underline' }}>
                                                {agent.name || ''}
                                            </Typography>
                                        </TableCell>
                                        <TableCell padding="none" align="center">
                                            ${handleTotalStatAndCurrentDaySum(agent, "margin")}
                                        </TableCell>
                                        <TableCell padding="none" align="center">
                                            {handleTotalStatAndCurrentDaySum(agent, "dailyNewCalls")}
                                        </TableCell>
                                        <TableCell padding="none" align="center">
                                            {handleTotalStatAndCurrentDaySum(agent, "dailyCalls")}
                                        </TableCell>
                                        <TableCell padding="none" align="center">
                                            {handleTotalStatAndCurrentDaySum(agent, "packets")}
                                        </TableCell>
                                        <TableCell padding="none" align="center">
                                            {handleTotalStatAndCurrentDaySum(agent, "quotes")}
                                        </TableCell>
                                        <TableCell padding="none" align="center">
                                            {handleTotalStatAndCurrentDaySum(agent, "dailyEmails")}
                                        </TableCell>
                                        <TableCell padding="none" align="center">
                                            {handleTotalStatAndCurrentDaySum(agent, "dailyListEmails")}
                                        </TableCell>
                                        <TableCell padding="none" align="center">
                                            {handleTotalStatAndCurrentDaySum(agent, "loadsBooked")}
                                        </TableCell>
                                        <TableCell padding="none" align="center">
                                            {handleTotalStatAndCurrentDaySum(agent, "setups")}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Box>
    )
};

export default WeekStatsTotalSheet;