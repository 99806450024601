import { Delete, Edit, Info } from "@mui/icons-material";
import {
  Box,
  // Button,
  // Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
  // useTheme,
} from "@mui/material";
import UpdateManagerActivityForm from "components/forms/Manager/activities/UpdateManagerActivityForm";
import ConfirmationDialog from "components/helpers/ConfirmDialog";
import { DateTimeFormatter } from "components/helpers/DateTimeHelpers";
import { hoverStyle } from "components/helpers/MediaQueries";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  useDeleteManagerActivityMutation,
  useGetAllManagerActivitiesQuery,
} from "state/managementApi";
import ManagerActivityDetails from "./ManagerActivityDetails";

const ManagerActivityList = () => {
  const userData = useSelector((state) => state.user);
  // const theme = useTheme();

  const [selectedActivity, setSelectedActivity] = useState(null);
  // const [selectedActivities, setSelectedActivities] = useState([]);
  // const [selectedPages, setSelectedPages] = useState([]);

  const [detailsOpen, setDetailsOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);

  // const [sortField, setSortField] = useState("name");
  // const [sortOrder, setSortOrder] = useState("asc");

  const {
    data: activitiesData = [],
    isLoading,
    error,
    // refetch,
  } = useGetAllManagerActivitiesQuery({
    userId: userData._id,
    page: page,
    limit: limit,
    type: type,
    status: status,
    startDate: startDate,
    endDate: endDate,
  });
  const [deleteManagerActivity] =
    useDeleteManagerActivityMutation();

  // Togglers
  const toggleDetailsOpen = () => setDetailsOpen(prev => !prev);
  const toggleUpdateOpen = () => setUpdateOpen((prev) => !prev);
  const toggleDeleteConfirm = () => setDeleteConfirmOpen((prev) => !prev);

  // const toggleActivity = (selectedActivity) => {
  //   const isSelected = selectedActivities.includes(selectedActivity);

  //   if (isSelected) {
  //     setSelectedActivities((prev) =>
  //       prev.filter((activity) => activity !== selectedActivity)
  //     );
  //   } else {
  //     setSelectedActivities([...selectedActivities, selectedActivity]);
  //   }
  // };

  // const toggleAllActivities = () => {
  //   if (selectedPages.includes(page)) {
  //     setSelectedActivities((prev) =>
  //       prev.filter((activity) => !activitiesData.activities.includes(activity))
  //     );
  //     setSelectedPages((prev) =>
  //       prev.filter((selectedPage) => selectedPage !== page)
  //     );
  //   } else {
  //     setSelectedActivities((prev) => [...prev, ...activitiesData.activities]);
  //     setSelectedPages((prev) => [...prev, page]);
  //   }
  // };

  // Handlers
  const handleDeleteConfirm = async () => {
    try {
      await deleteManagerActivity(selectedActivity._id).then(() => {
        setSelectedActivity(null);
        toggleDeleteConfirm();
        // handleRefresh();
      });
    } catch (error) { }
  };

  const handleSort = (category) => {};

  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value));
    setPage(1);
  };

  const handlePageChange = (e, newPage) => {
    e.preventDefault();
    setPage(newPage + 1);
  };

  // const handleRefresh = () => {
  //   refetch();
  // };

  if (isLoading) return;

  if (error) return;

  return (
    <>
      <Box>
        <Toolbar
          sx={{
            pl: 2,
            pr: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {/* <Typography
              variant="subtitle2"
              sx={{
                ml: "10px",
                pt: "6px",
                display: selectedActivities.length ? "block" : "none",
              }}
            >
              {selectedActivities.length} Activities Selected
            </Typography> */}
          </Box>
        </Toolbar>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  count={activitiesData?.count}
                  rowsPerPage={limit}
                  page={page - 1}
                  slotProps={{
                    select: {
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    },
                  }}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  showFirstButton
                  showLastButton
                />
              </TableRow>
              <TableRow>
                {/* <TableCell>
                    <Checkbox 
                        checked={selectedPages.includes(page)}
                        onChange={toggleAllActivities}
                    />
                    Select Page
                </TableCell> */}
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center', }}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      onClick={() => handleSort("name")}
                    >
                      Customer
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      onClick={() => handleSort("type")}
                    >
                      Type
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      onClick={() => handleSort("title")}
                    >
                      Title
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      onClick={() => handleSort("description")}
                    >
                      Description
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      onClick={() => handleSort("status")}
                    >
                      Status
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      onClick={() => handleSort("date")}
                    >
                      Date
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ p: "6px 0px" }} />
                <TableCell sx={{ p: "6px 0px" }} />
                <TableCell sx={{ p: "6px 0px" }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {activitiesData.activities.map((activity) => (
                <TableRow key={activity._id}>
                  {/* <TableCell sx={{ padding: '0 25px', }}>
                        <input 
                            type="checkbox"
                            checked={!!selectedActivities.find((selected) => selected._id === activity._id)}
                            onChange={() => toggleActivity(activity)}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </TableCell> */}
                    {/* TODO: Customer Details on click */}
                  <TableCell>
                    {activity.leadIds && activity.leadIds.length > 1 ? (
                      <span>
                        Multiple
                      </span>
                    ) : activity.leadIds && activity.leadIds.length === 1 ? (
                        <span>
                          {activity.leadIds[0].name}
                        </span>
                    ) : (
                      <span>
                        No Data
                      </span>
                    )}
                  </TableCell>
                  <TableCell>
                    {activity.type
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                  </TableCell>
                  <TableCell>
                    {activity.title}
                  </TableCell>
                  <TableCell>{activity.description}</TableCell>
                  <TableCell>
                    {activity.status
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                  </TableCell>
                  <TableCell>{DateTimeFormatter(activity.date)}</TableCell>
                  <TableCell sx={{ p: '6px' }}>
                    <Info 
                      sx={hoverStyle}
                      onClick={() => {
                        setSelectedActivity(activity);
                        toggleDetailsOpen();
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ p: "6px" }}>
                    <Edit
                      sx={hoverStyle}
                      onClick={() => {
                        setSelectedActivity(activity);
                        toggleUpdateOpen();
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ p: "6px" }}>
                    <Delete
                      sx={hoverStyle}
                      onClick={() => {
                        setSelectedActivity(activity);
                        toggleDeleteConfirm();
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  count={activitiesData?.count}
                  rowsPerPage={limit}
                  page={page - 1}
                  slotProps={{
                    select: {
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    },
                  }}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  showFirstButton
                  showLastButton
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Box>
      {detailsOpen && selectedActivity && (
        <ManagerActivityDetails 
          activityId={selectedActivity._id}
          onClose={toggleDetailsOpen}
        />
      )}
      {updateOpen && selectedActivity && (
        <UpdateManagerActivityForm
          activityData={selectedActivity}
          onClose={() => {
            toggleUpdateOpen();
            setSelectedActivity(null);
          }}
        />
      )}
      {deleteConfirmOpen && (
        <ConfirmationDialog
          open={deleteConfirmOpen}
          onClose={toggleDeleteConfirm}
          onConfirm={handleDeleteConfirm}
          title="Confirm Delete"
          message={`Are you sure you want to delete this activity?`}
        />
      )}
    </>
  );
};

export default ManagerActivityList;
