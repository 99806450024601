import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import NewFeatures from '../developers/NewFeatures';

const FeaturesMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openFeatures, setOpenFeatures] = useState(false);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const openFeaturesOverlay = () => {
    setOpenFeatures(true);
    closeMenu();
  };

  const closeFeaturesOverlay = () => {
    setOpenFeatures(false);
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={openMenu}>
        Features
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu} keepMounted >
        <MenuItem onClick={openFeaturesOverlay} >New/Upcoming Features</MenuItem>
      </Menu>
      {openFeatures && <NewFeatures onClose={closeFeaturesOverlay} />}
    </>
  );
};

export default FeaturesMenu;