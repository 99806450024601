import React from "react";
import { Box } from "@mui/material";
import Header from "components/layout/Header";

const Geography = () => {
  
  return (
    <Box m="1.5rem 2.5rem">
      <Header title="GEOGRAPHY" subtitle="Find where your users are located." />
      Future use scaffolding
    </Box>
  );
};

export default Geography;