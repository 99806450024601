import React, { useState } from "react";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import useTheme from "@mui/material/styles/useTheme";

const RichTooltip = ({ placement = "bottom-start", arrow = true, open, onClose, content, children }) => {
    const theme = useTheme();
    const [arrowRef, setArrowRef] = useState(null);
    const [childNode, setChildNode] = useState(null);

    return (
        <div>
            {React.cloneElement(children, { ...children.props, ref: setChildNode })}
            <Popper
                open={open}
                anchorEl={childNode}
                placement={placement}
                transition
                modifiers={[
                    {
                        name: 'preventOverflow',
                        enabled: true,
                        boundariesElement: 'window',
                    },
                    {
                        name: 'arrow',
                        enabled: true,
                        options: {
                            element: arrowRef,
                        },
                    }
                ]}
                sx={{
                    zIndex: 1000,
                    '&[x-placement*="bottom"] $arrow': {
                        top: 0,
                        left: 0,
                        marginTop: "-0.71em",
                        marginLeft: 4,
                        marginRight: 4,
                        "&::before": {
                            transformOrigin: "0 100%"
                        }
                    },
                    '&[x-placement*="top"] $arrow': {
                        bottom: 0,
                        left: 0,
                        marginBottom: "-0.71em",
                        marginLeft: 4,
                        marginRight: 4,
                        "&::before": {
                            transformOrigin: "100% 0"
                        }
                    },
                    '&[x-placement*="right"] $arrow': {
                        left: 0,
                        marginLeft: "-0.71em",
                        height: "1em",
                        width: "0.71em",
                        marginTop: 4,
                        marginBottom: 4,
                        "&::before": {
                            transformOrigin: "100% 100%"
                        }
                    },
                        '&[x-placement*="left"] $arrow': {
                        right: 0,
                        marginRight: "-0.71em",
                        height: "1em",
                        width: "0.71em",
                        marginTop: 4,
                        marginBottom: 4,
                        "&::before": {
                            transformOrigin: "0 0"
                        }
                    }
                }}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={150}>
                        <Paper sx={{ borderRadius: '50px', }}>
                            <ClickAwayListener onClickAway={onClose}>
                                <Paper sx={{ backgroundColor: theme.palette.background.paper, maxWidth: 1000, borderRadius: '20px', }}>
                                    {arrow && 
                                        <span 
                                            style={{
                                                overflow: 'hidden',
                                                position: 'absolute',
                                                width: '1em',
                                                height: '0.71em', /* = width / sqrt(2) = (length of the hypotenuse) */
                                                boxSizing: 'border-box',
                                                color: "white",
                                                '&::before': {
                                                    content: '""',
                                                    margin: 'auto',
                                                    display: 'block',
                                                    width: '100%',
                                                    height: '100%',
                                                    boxShadow: '5px 10px',
                                                    color: 'currentColor',
                                                    transform: 'rotate(45deg)'
                                                }
                                            }}
                                            ref={setArrowRef}
                                        />
                                    }
                                    <Box sx={{ padding: theme.spacing(2), }}>{content}</Box>
                                </Paper>
                            </ClickAwayListener>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </div>
    );
};

export default RichTooltip;