import { Box, Button, CircularProgress, Typography } from "@mui/material";
import ManageUpdateLeadForm from "components/forms/ManageUpdateLeadForm";
import ConfirmationDialog from "components/helpers/ConfirmDialog";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useManagerDeleteLeadMutation } from "state/managementApi";

const ManageAgentLeadDetails = ({ lead, onDelete, agentName, refetch }) => {
  const [editOpen, setEditOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const [deleteLead] = useManagerDeleteLeadMutation();

  const { enqueueSnackbar } = useSnackbar();

  const handleEditOpen = () => {
    setEditOpen((prev) => !prev);
  };

  const handleDeleteOpen = () => {
    setConfirmDeleteOpen((prev) => !prev);
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteLead(lead._id).then(() => {
        handleDeleteOpen();
        enqueueSnackbar(`${lead.name} deleted successfully`);
        onDelete(lead._id);
      });
    } catch (error) {
      console.error("Error deleting lead:", error);
    }
  };

  /* const handleShowActivities = (leadId) => {}; */

  const handleEnsureHttpPrefix = (url) => {
    // Check if the URL starts with "http://"
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return `http://${url}`;
    }
    return url;
  };

  if (!lead) return <CircularProgress />;

  return (
    <Box
      sx={{
        marginBottom: "2rem",
        border: "1px solid #e0e0e0",
        padding: "1rem",
        borderRadius: "4px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          marginBottom: "1rem",
        }}
      >
        <Box sx={{ display: "flex", marginBottom: "15px" }}>
          <Typography sx={{ flexGrow: 1 }}>
            <strong>Company Name:</strong> {lead.name}
          </Typography>
          <Typography sx={{ flexGrow: 1 }}>
            <strong>Contact Number:</strong> {lead.contactNumber || ""}
          </Typography>
          <Typography sx={{ flexGrow: 1 }}>
            <strong>Website:</strong>
            {lead.website ? (
              <a
                href={handleEnsureHttpPrefix(lead.website)}
                target="_blank"
                style={{
                  color: "inherit",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {lead.website}
              </a>
            ) : (
              ""
            )}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-around" }}>
          <Typography sx={{ whiteSpace: "pre-line" }}>
            <strong>Broker Notes:</strong> {lead.brokerNotes || "None"}
          </Typography>
          <Typography>
            <strong>Recent Activity:</strong>{" "}
            {lead?.recentActivity?.description || "No activity"}
          </Typography>
        </Box>
        {lead.assignedUsers[0] && (
          <Box>
            <Typography>Assigned To:</Typography>
            {!agentName && !lead.assignedUsers.length > 1 ? (
              <Typography>Pool</Typography>
            ) : !agentName && lead.assignedUsers.length > 1 ? (
              lead.assignedUsers.map((user, index) => (
                <Typography key={index}>{user.name}</Typography>
              ))
            ) : !agentName && lead.assignedUsers.length === 1 ? (
              <Typography>{lead.assignedUsers[0].name}</Typography>
            ) : (
              <Typography>{agentName}</Typography>
            )}
          </Box>
        )}
        <Box sx={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
          <Button color="primary" variant="contained" onClick={handleEditOpen}>
            Edit
          </Button>
          <Button color="error" variant="contained" onClick={handleDeleteOpen}>
            Delete
          </Button>
          {/* <Button 
            color="primary" 
            variant="outlined"
            disabled={!lead.activities || lead.activities.length === 0}
            onClick={() => handleShowActivities(lead._id)}
          >
            Show Activity
          </Button> */}
        </Box>
      </Box>
      {confirmDeleteOpen && (
        <ConfirmationDialog
          open={confirmDeleteOpen}
          onClose={handleDeleteOpen}
          onConfirm={handleConfirmDelete}
          title="Confirm Delete"
          message={`Are you sure you want to delete ${lead.name}?`}
        />
      )}
      {editOpen && (
        <ManageUpdateLeadForm
          leadToUpdate={lead}
          // setLeadToUpdate={setLead}
          open={editOpen}
          closeForm={handleEditOpen}
          updateRefetch={refetch}
        />
      )}
    </Box>
  );
};

export default ManageAgentLeadDetails;
