import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const NewFeatures = ({ onClose }) => {
  const theme = useTheme();
  const release = "Beta Version 1.4"
  const currentDate = new Date();
  const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
  const features = {
    "General": [
      "Dispatch board 2.0 implemented, with full rework for stability and performance for local clients.",
      "Customer Success Portal, and Manager Pool lead components updated with several fixes.",
      "CSVUploader streamlined to be more efficient.",
      "Customer conversion data captured for future feedback to agents on effective strategies.",
      "Updated websocket logic to prepare for notifications and internal messaging.",
      "Added mailgun deliverability optimizations.",
      "Add secondary email to all leads.",
      "Add operations Team array.",
      "Add operations team model.",
      "Add operations team GET route. Lets op team see other members",
      "Add operations team POST route. Lets managers add members",
      "Add operations team PATCH route. Lets op team edit color",
      "Add operations team DELETE route. Lets managers remove members",
      "Add operations team GET controller. Handles logic and RBAC for returning all ops team members",
      "Add operations team POST controller. Handles logic and RBAC for managers to add members.",
      "Add operations team PATCH controller. Handles logic and RBAC for updating colors on op board.",
      "Add operations team DELETE controller. Handles logic and RBAC for managers to remove members.",
      "Add Quotes GET route. Agents can see their quotes.",
      "Add Quotes POST route. Agents can create quotes.",
      "Add Quotes PATCH route. Agents can update quotes",
      "Agents can archive quotes",
      "Ops Team/managers can create quotes.",
      "Ops Team/managers can see all quotes.",
      "Ops Team/managers can update all quotes.",
      "Ops Team/managers can archive quotes.",
      "Creating a quote will automatically email operations team.",
      "Completing a quote as Ops Team will automatically email the quote owner.",
      "Added more verbose error logging for squashing pesky errors.",
      "Added daily activities list",
      "Added universal lead search bar for managers",
      "Managers can see agent activities",
      "Added Operations Page",
      "Added Quote Submission Form",
      "Added Quote Owner to Operation Cards",
      "Booking cards color changed",
      "Quote submission now has Canadian provinces",
      "Quote cards now show quote owner",
      "Time since quote created now updates automatically on Ops board",
      "Quotes created with a 'Won' status are sent as 'Booking'",
      "Quotes are able to be cancelled when in the 'Booking' phase",
      "Dispatcher phone number and name are able to be set on Quotes",
      "Customer deletion added",
      "Lead name now on most activity lists",
      "Activity details viewable from Daily Activities",
      "Emailing and adding an event added to customer details",
      "Dispatchers can ping drivers for location",
      "Driver locations can be viewed on dispatch card when ping is responded to",
      "Shipment Date field added to quotes",
      "Added ability to search carriers by MC number",
      "Updated leaderboard",
      "Added statistic tracking for managers",
      "Agents can view past stats and update specific fields",
      "Agents can mark a lead to call tomorrow",
      "Text signature creation",
      "Quotes can now be duplicated",
    ],
    // "Bug Fixes": [
    //   "Quotes no longer subtract 2 cents from truck price when sent back from Operations",
    // ],
    "Development Pipeline": [

      "Full dispatch board rework to optimize and simplify websocket handling to improve performance.",
      "List email activity details should show content of list email.",
      "General search bar for managers to find any lead by partial result.",
      "email deliverables(failed, opened, etc.) will be reported to agents.",
      "Activities from Salesforce sanitized and imported to leads in Portal.",
      "Shift click to select multiple leads.",
      "When you filter by category, it only filters the page you're on, it needs to filter all pages together.",
      "We have to be able to filter these leads by any metric.",
      "Want the top part of the leads list to stay frozen and the scroll only happens for the leads themselves.",
      "Want to have a count where we can see how many we have selected.",
      "Adding a merge field for emails where you can include their first name (like salesforce)",
      "Agent search bar shows any lead despite category",
      "Salesforce style search bar that shows all partial results in all collections.",
      "Email webhook for returned or bad email messages from mailgun",
      "DSLC/DSLE added to lead details.",
      "Prefill email address on partial matches with dropdown from inbox",
      "Filter lead pool by commodity/fields",
      "Add customer list for Kelsie with calls",
      "When lead == assignedCustomer+ remove all duplicate leads",
      "Make error logs visible for superadmin",
      "Quota sheet for whole year",
      "Graphs daily per week agent stats",
      "Leaderboard top 5",
      "Chats can only be 1:1",
      "Public channel for all messages",
      "Implement DNA for list emails/filter from leadsList",
      "Email rich text integration",
      "Automatic phone number format",
      "Phone number extensions",
      "Delete all selected leads button",
      "Anniversary field and reminder",
      "Send leads to pool",
      "List email or email to multiple recipients sends a report to confirm the email transmission, letting agents know how many of them delivered",
      "Request leads",
      "Handle duplicate leads",
      "Reminders for agents to update stats at desired times of day",
      "Add agent card queue",
      "Add backup logging for card",
      "Add ability to categorize Pool leads for managers",
      "Dispatch user separate from the dispatch department that would be able to access their brokers accounts",
      "Display color codes on dispatch archive",
      "View unassigned leads",
      "Save app location/state on refresh",
      "Shift click selecting",
      "Signature editing",
      "Email Templates",
      "Mailgun webhook polishing for email stats and errors",
      "Show agent activities in date range or on date",
      "Managers can compare activity by month for user/users",
      "Managers can compare activity by month for the company (add any date range)",
      "Customer details",
      "View who each customer is assigned to + other lead data (dsla, lastloadmoved, etc)",
      "Nice UI layout for various stats that managers can see over different timelines",
      "If margins are implemented, calculate those, loads per day, etc.",
      "Shuffle people around or drag and drop into teams",
      "Power hour button (Henry: this is awesome)",
      "Have origin/destination populate geolocation through google on form submission, then send to google map api to get markers on google maps",
      "Track google suggested routes with suspected ETA (Awesome)",
      "Remove after shipment at destination",
      "Maybe add shipment form for brokers who don’t use dispatch",
      "Let brokers run MC number if possible to get carrier reports",
      "Notify brokers of any red flags",
      "Integrate API data and filter response on relevant info per Henry",
      "Lead anniversary field/reminder",
      "Email Inbox",
      "Notifications",
      "Messaging",
      "Shipment origin/destination googlemapAPI",
      "Agent metrics for tracking shipments like origin, destination, equipment type, commodity, customerId, margin, etc.",
      "Save page number for specific status when switching between statuses",
      "Figure out where/how to store images on server persistently",
      "Incorporate Redis and move all GET requests to the cached system on the server to increase performance dramatically",
      "Build a DB cloning server that stores data for data analytics",
      "Warehouse should be its own service and run ML models against data samples to calculate various data (shipping estimates by month/location, freight types by location, etc.)",
      "Build out with API with Twilio",
      "Twilio flex -> meeting notes in email",
      "Reset page number on category switch in manageAgentLeadsList bug fix",
      "Check stat reporting on activities for bugs as per agent emails",
      //"Option for manager (customerSuccess, maybe new role?) to see activeCustomer list and make calls to customer, that agents can’t see (had the role for a year, that's Kelsie)",
      "Filter duplicate leads only at activecustomer level by case insensitive name, email, phone, location",
      "Possibly add in duplicate scan for manager discretion",
      "Managers can access people’s call notes",
      "A/Events should have the option to share to other users.",
      /*"Day of the first shipment would be great! Also on each customer page where we have the info if we could add a spot for their work anniversary and have 
      that added as well that would be great. (this should be optional as not everyone knows when their customer started at their company but for those who do 
        I think it would be nice to send them something). If the automation could be like 5-7 days before the date that would be great as well so I have time 
        to send them a card or whatever we decide to do!", */
      "Add loading to CSV uploader.",
      "FE websocket should load messaging when messages opened.",
      "FE websocket should load notifications when logged in.",
      "BE websocket should filter by type and handle each accordingly.",
      "BE websocket should load data for user based on WS connection.",
      "Add contact name to salesforce sanitize",
      "Calendar should be able to have Agents add events for self.",
      "Activities should be able to add events to Calendar",
      "Calendars should send Alerts to users if date = event.date",
      "Users should be able to customize event alerting.",
      "Calendar should be able to have managers add events for any user.",
      "Managers should be able to select all or select specific users to add calendar events for.",
      "Agents should be able to send and receive emails.",
      "Emails should update DailyEmails.",
      "Emails should have option to attach a packet.",
      "Packets should update user Packets",
      "Emails and packets should be tracked by dailyActivities.",
      "Emails should appear in notification WS.",
      "Packet should be editable by managers.",
      "Agents would like to have notifications.",
      "Add tooltip to 'move to pool button' that explains the feature.",
      "Add feature updates to notifications.",
      "Troubleshoot agent lead import not going to proper columns.",
      "Ensure that imported leads are only imported if company name is NOT duplicate.",
      "Pass close dialog down to individual forms for styling changes.",
      "Paginate leads for managers in agentDetails.",
      "Format leads for managers in agentDetails to cut off long website URLS.",
      "Move edit button for leadDetails in manager view.",
      "Activity lead category should update lead status",
      "4. Lead Info Page: Is there a way to automatically have the lead info page updated when you update the lead? I had to refresh the page to get the info updated. ",
      "5. Lead List: Make the cursor a `select` cursor when you hover over leads on the lead list. ",
      "6. Add Activity: When you go to `send email` are you actually sending an email from that window or are you making notes about an email?",
      "7. Add Activity: WHen you are logging a call, make the title a drop down with selections of `first call`, `follow up`, or `other`",
      "8. Add Activity: Need to add some sort of task or event option for reminders. Would like this to trigger a reminder email to their calendar",
      "This prompted another idea of having an internal company calendar where we could schedule meetings and tag other people in them",
      "9. Lead Info Page: Would Like to be able to refresh without it going all the way back to dashboard. That will be an issue when we have 1000 leads on there.",
      "UI remodel.",
      "Make sure we track lead signup dates for customer relations with Kelsie.",
      "When Lead is deleted, make sure assigned Agents no longer reference them.",
      "Any unassigned lead should be sent to pool with status of pool",
      "If no assigned user, delete lead ref from pool",
      "Managers can click a lead from agent leads list and handle edit lead.",
      "Managers can click a lead from agent leads list and delete lead.",
      "Managers can click several leads from agent leads list and delete leads.",
      "When agent is deleted, make sure assigned leads no longer reference them.",
      "Build CSV checker to remove current customers from CSV imports",
      "Build user routes to get daily stats for self.",
      "Build user routes to get monthly stats for self.",
      "Integrate all data flows into leaderboard.",
      "Figure out how to find the assigned user for salesforce leads, for ea migration.",
      "Make sure when leads hit customer they are added to a company list that filters imported CSV companies out(if they are current customer).",
      "Build User controller for importing lead CSV.",
      "Build user routes for importing lead CSV.",
      "Build csv customer company name cache for quick filtering on import.",
      "Work on scrape tools for different sites or tutorials on what to send to helpdesk.",
      "API controller for moving leads from one category(status) to pool for same user by manager.",
      "API routes for moving leads from one category(status) to pool for same user by manager.",
      "Manager can move leads from one category(status) to pool for same user.",
      "Manager can search call/email totals by date.",
      "Determine how long to store user activities in user model and how long to store activities.",
      "Manager can see graph analytics for agent calls/emails",
      "Manager can share leads to agents/salesAgent",
      "Pool feature should be implemented for all users to add general leads.",
      "Sales should refetch on modification.",
      "Integrate calling into portal.",
      "When agent is deleted, make sure assigned sales no longer reference them.",
      "When agent is deleted, make sure activities without a userId Reference function correctly.",
      "User can move lead to lead pool",
      "leads can have multiple users until they become a customer.",
      "Customer lead should be removed from all other usersLists.",
      "Manager can import leads from CSV to agents/salesAgent using JSON",
      "Agent can import leads from CSV to agents/salesAgent using JSON",
      "Sales Agent/Agent should see chart for setups and meetings goals.",
      "Add report generation for management for single or multiple Agents.",
      "Add pagination to leads or scrolling",
      "Check all forms for proper message response codes",
      "Activity log for users.",
      "Messaging elements for internal messaging",
      "Application based email linked to Gmail accounts.",
      "List emails in application.",
      "User notification in application.",
      "Dispatchers can view archived loads",
      "User messaging.",
      "Group messaging.",
      "Location messaging.",
      "Company messaging.",
      "Add logging for Admin to monitor Password changes/Logins and Registrations.",
      "Field monitoring research.",
      "Add Select boxes to leadsList to allow multiple lead actions",
      "CSV upload should not allow non csv files, and should still delete the files on error.",
      "add url shortener function to the admin leads view urls",
      "add the on hover effect so you can see thee full url in the admin leads view url",
    ]
  };

  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <Container
        component="main"
        sx={{
          width: '100%',
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          backgroundColor: theme.palette.primary[900],
          padding: '1px',
          margin: '0 auto',
        }}
      >
        <DialogTitle sx={{ fontWeight: 'bold', fontSize: '1.5em' }}>Features - {currentMonth} {currentDate.getFullYear()}</DialogTitle>
        <DialogContent>
          <Typography variant="body1" sx={{ fontWeight: 'bold', marginY: '10px', fontSize: '20px', textAlign: 'left' }}>
            {release}
          </Typography>
          {Object.entries(features).map(([title, items]) => (
            <React.Fragment key={title}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', marginY: '5px' }}>
                {title}
              </Typography>
              <Box sx={{ pl: 2 }}>
                {items.map((feature, index) => (
                  <Typography variant="body1" key={index}>
                    - {feature}
                  </Typography>
                ))}
              </Box>
            </React.Fragment>
          ))}
        </DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <Button variant="contained" color="primary" onClick={onClose}>
            Close
          </Button>
        </Box>
      </Container>
    </Dialog>
  );
};

export default NewFeatures;