import React, { useState } from 'react';
import {
  Alert,
  Box,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useCreateSaleMutation } from '../../state/salesApi';

const AddSaleForm = ({ leadId, onClose }) => {
  const [formErrors, setFormErrors] = useState([]);
  const [addSaleMutation, { isLoading: adding }] = useCreateSaleMutation();
  const [successMessage, setSuccessMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      description: '',
      date: '',
    },
    validationSchema: Yup.object({
      description: Yup.string().required('Description is required'),
      date: Yup.date().required('Date is required'),
    }),
    onSubmit: async (values, helpers) => {
      let errors = [];
      const saleData = {
        description: values.description,
        date: values.date,
        leadId: leadId, 
      };
      try {
        addSaleMutation(saleData)
          .unwrap()
          .then((response) => {
            formik.resetForm();
            if (response && response.message) {
              setSuccessMessage(response.message);
            } else {
              setSuccessMessage('Operation was not successful.');
            }
            setFormErrors([]);
          })
          .catch((error) => {
            errors.push(error);
            setFormErrors(errors);
            setSuccessMessage('Operation was not successful.');
          });
      } catch (error) {
        errors.push(error);
        setFormErrors(errors);
        setSuccessMessage('Operation was not successful.');
      }
    },
  });


  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const handleAlertClose = () => {
    setSuccessMessage('');
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle sx={{ textAlign: 'center' }}>Add Sale</DialogTitle>
      <DialogContent sx={{ minWidth: '400px', width: '400px' }}>
        <Box component="form" onSubmit={formik.handleSubmit} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            multiline
            rows={4}
            label="Description"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.description && formik.errors.description && (
            <Box sx={{ color: '#ba000d' }}>
              <small>{formik.errors.description}</small>
            </Box>
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            type="date"
            label="Date"
            name="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={formik.values.date}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.date && formik.errors.date && (
            <Box sx={{ color: '#ba000d' }}>
              <small>{formik.errors.date}</small>
            </Box>
          )}
          {formErrors.length ? (
            <Box sx={{ color: '#ba000d' }}>
              <small>Error adding sale.</small>
            </Box>
          ) : null}
          <DialogActions>
            <Button onClick={handleClose} color="error" variant="contained">
              Cancel
            </Button>
            <Button type="submit" color="primary" variant="contained" disabled={adding}>
              Add
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
      {successMessage && (
        <Box sx={{ mt: 2 }}>
          <Alert severity="success" onClose={handleAlertClose}>
            {successMessage}
          </Alert>
        </Box>
      )}
    </Dialog>
  );
};

export default AddSaleForm;