// src/store/webSocketSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isConnected: false,
  data: [],
};

export const webSocketSlice = createSlice({
  name: 'webSocket',
  initialState,
  reducers: {
    setConnected: (state, action) => {
      state.isConnected = action.payload;
    },
    addData: (state, action) => {
      state.data.push(action.payload);
    },
  },
});

export const { setConnected, addData } = webSocketSlice.actions;
export default webSocketSlice.reducer;
