import React, { useState } from 'react';
import { /* Box, */ Button, IconButton, Drawer, List, ListItem, ListItemText, Divider, /* AppBar, Toolbar, Typography, */ useMediaQuery, useTheme, ClickAwayListener, Grid } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import FormsMenu from '../tools/FormsMenu';
import ToolsMenu from '../tools/ToolsMenu';
import ManagerMenu from '../tools/ManagerMenu';
import FeaturesMenu from '../tools/FeaturesMenu';
import { useSelector } from 'react-redux';
import DeveloperMenu from 'components/developers/DeveloperMenu';

const UtilityBar = ({ handleToggleLeads, handleToggleDeveloperBoard, handleToggleErrorLogs }) => {
  const userData = useSelector((state) => state.user);
  const [showLeads, setShowLeads] = useState(false);
  const [showQuotes, setShowQuotes] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleToggleLeadsInUtilityBar = () => {
    setShowLeads(!showLeads);
    handleToggleLeads();
  };

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  if (!userData) return;
  
  return (
    <>
      {isMobile ? (
        <>
          <IconButton edge="start" color="inherit" onClick={handleMobileMenuOpen} aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Drawer anchor="left" open={mobileMenuOpen} onClose={handleMobileMenuClose}>
            <ClickAwayListener onClickAway={handleMobileMenuClose}>
              <List>
                <ListItem onClick={handleToggleLeadsInUtilityBar}>
                  <ListItemText primary={showLeads ? 'Close Leads' : 'Leads'} />
                </ListItem>
                <Divider />
                <FormsMenu />
                <ToolsMenu />
                <FeaturesMenu />
                {userData.role === 'manager' || userData.role === 'admin' || userData.role === 'superadmin' ? (
                  <ManagerMenu />
                ) : null}
                {userData.role === 'superadmin' ? (
                  <DeveloperMenu handleToggleErrorLogs={handleToggleErrorLogs}/>
                ) : null}
              </List>
            </ClickAwayListener>
          </Drawer>
        </>
      ) : null}

      {!isMobile ? (
        <Grid container spacing={2} alignItems="center">
          {userData.role === 'agent' || userData.role === 'manager' || userData.role === 'admin' || userData.role === 'superadmin' ? (
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleToggleLeadsInUtilityBar}>
                {showLeads ? 'Close Leads' : 'Leads'}
              </Button>
            </Grid>
          ) : null}
          <Grid item>
            <FormsMenu />
          </Grid>
          <Grid item>
            <ToolsMenu />
          </Grid>
          <Grid item>
            <FeaturesMenu />
          </Grid>
          {(userData.role === 'manager' || userData.role === 'admin' || userData.role === 'superadmin') && (
            <Grid item>
              <ManagerMenu />
            </Grid>
          )}
          {userData.role === 'superadmin' ? (
            <Grid item>
              <DeveloperMenu handleToggleDeveloperBoard={handleToggleDeveloperBoard} handleToggleErrorLogs={handleToggleErrorLogs}/>
            </Grid>
          ) : null}
        </Grid>
      ) : null}
    </>
  );
};

export default UtilityBar;
