import { Clear, Search, SearchRounded } from "@mui/icons-material";
import { IconButton, InputBase, Paper } from "@mui/material";

const SearchBar = ({ placeholder, searchInput, setSearchInput, handleSearchChange, allLeadsSearch, hasSearched }) => {

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearchChange(searchInput);
        }
    };

    return (
        <>
            {!allLeadsSearch ? (
                <>
                    <Paper
                        component="form"
                        sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400, border: "1px solid grey" }}
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            id="searchbar"
                            value={searchInput}
                            placeholder={placeholder || "Search leads"}
                            onChange={(e) => {
                                setSearchInput(e.target.value);
                                handleSearchChange(e.target.value);
                            }}
                            onKeyDown={handleKeyPress}
                        />
                        <IconButton sx={{ p: "10px" }}>
                            <Search />
                        </IconButton>
                    </Paper>
                </>
            ): (
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Paper
                        component="form"
                        sx={{ p: "2px 4x", display: "flex", alignItems: "center", justifyContent: "center", width: 400, border: "1px solid grey" }}
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <InputBase 
                            sx={{ ml: 1, flex: 1 }}
                            id="searchbar"
                            placeholder={placeholder || "Search leads"}
                            value={searchInput}
                            onChange={(e) => {
                                setSearchInput(e.target.value);
                            }}
                            onKeyDown={handleKeyPress}
                        />
                        {hasSearched ? (
                            <>
                                <IconButton 
                                    type="button" 
                                    sx={{ p: "10px" }}
                                    onClick={(e) => {
                                        handleSearchChange(e);
                                        setSearchInput("");
                                    }}
                                >
                                    <Clear />
                                </IconButton>
                            </>
                        ) : (
                            <>
                                {/* <Button
                                    variant="contained"
                                    onClick={(e) => {
                                        handleSearchChange(e);
                                    }}
                                >
                                    Search
                                </Button> */}
                                <IconButton
                                    type="button"
                                    sx={{ p: "10px" }}
                                    onClick={(e) => {
                                        handleSearchChange(e);
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </>
                        )}
                    </Paper>
                </div>
            )}
        </>
    )
}

export default SearchBar;