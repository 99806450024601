import React/* , { useState } */ from "react";
import {
  // Button,
  Typography,
  Grid,
  // InputBase,
  useTheme,
  Box,
  // Alert,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import ActivityLeadList from "../activities/ActivityLeadList";
// import { useUpdateLeadMutation } from "state/salesApi";
// import { Badge, Email, Phone, Web } from "@mui/icons-material";
import { calculateDSLA } from "components/helpers/CalculateDSLA";
// import { useSnackbar } from "notistack";
import { DateFormatter } from "components/helpers/DateTimeHelpers";

// TODO: Cleanup
const LeadInfo = ({ lead, showActivityList, /* refetch */ }) => {
  const theme = useTheme();
  // const [brokerNotes, setBrokerNotes] = useState(lead.brokerNotes); // State to manage broker notes edit
  // const [alert, setAlert] = useState(null); // State to manage the alert

  // const { enqueueSnackbar } = useSnackbar();

  // const handleAlertClose = () => {
  //   setAlert(null); // Clear the alert
  // };

  // const [updateLeadMutation] = useUpdateLeadMutation();

  // const textFieldStyles = {
  //   borderRadius: "4px",
  //   border: `1px solid ${theme.palette.divider}`,
  //   padding: theme.spacing(1),
  //   backgroundColor: theme.palette.background.paper,
  //   width: "100%",
  //   textAlign: "center",
  // };

  // Handler to translate dates to user friendly format
  // const handleDateConverter = (dateString) => {
  //   // If no value, return empty string
  //   if (!dateString) return "";

  //   // Create a Date object from the given string
  //   const date = new Date(dateString);

  //   // Get the month, day, and year from the Date object
  //   const year = date.getUTCFullYear();
  //   const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Adding 1 due to months being zero-based
  //   const day = date.getUTCDate().toString().padStart(2, "0");

  //   // Form the YYYY-MM-DD format string
  //   const formattedDate = `${month}-${day}-${year}`;

  //   return formattedDate;
  // };

  // const ensureHttpPrefix = (url) => {
  //   // Check if the URL starts with "http://"
  //   if (!url.startsWith("http://") && !url.startsWith("https://")) {
  //     return `http://${url}`;
  //   }
  //   return url;
  // };

  const handleDSLA = (DSLA) => {
    return calculateDSLA(DSLA);
  };

  // const handleBrokerNotesUpdate = async () => {
  //   try {
  //     const values = {
  //       brokerNotes,
  //     };
  //     const response = await updateLeadMutation({
  //       id: lead._id,
  //       leadUpdates: values,
  //     });

  //     refetch();

  //     if (response.data) {
  //       // setAlert({ type: 'success', message: 'Lead updated successfully' });
  //       enqueueSnackbar(`${lead.name} Updated Successfully`);
  //     } else {
  //       setAlert({
  //         type: "error",
  //         message: response.message || "An error occurred",
  //       });
  //     }
  //   } catch (error) {
  //     console.error({ type: "error", message: "An error occurred" });
  //   }
  // };

  return (
    <div sx={{ width: "100%" }}>
      {/* <Typography variant="h4" align="center" sx={{ mt: 1 }}>
        DSLA:{" "}
        {handleDSLA(lead.recentActivity?.date) === 0
          ? "Today"
          : handleDSLA(lead.recentActivity?.date) > 0
          ? handleDSLA(lead.recentActivity?.date)
          : "No Activity Reported"}{" "}
        {lead.recentActivity.date &&
          `-- Last Contacted ${
             `Via ${lead.recentActivity.type
              .replace(/([A-Z])/g, " $1")
              .replace(/^./, function (str) {
                return str.toUpperCase();
              })}`
          }`}
      </Typography>
      <Grid container justifyContent="center" sx={{ mt: 2 }}>
        <Grid item xs={12} sm={6} md={9}>
          <Grid container justifyContent="center" spacing={3} direction="row">
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography
                variant="body1"
                align="center"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Badge />
                {lead.contactName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography
                variant="body1"
                align="center"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Email />
                {lead.contactEmail}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography
                variant="body1"
                align="center"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Phone />
                {lead.contactNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography
                variant="body1"
                align="center"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Web />
                <a
                  href={ensureHttpPrefix(lead.website)}
                  target="_blank"
                  style={{ color: "inherit", textDecoration: "underline" }}
                >
                  {lead.website}
                </a>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1" align="center">
                DSLC:{" "}
                {handleDSLA(lead.DSLC) === 0
                  ? "Today"
                  : handleDSLA(lead.DSLC) > 0
                  ? handleDSLA(lead.DSLC)
                  : "None"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1" align="center">
                DSLE:{" "}
                {handleDSLA(lead.DSLE) === 0
                  ? "Today"
                  : handleDSLA(lead.DSLE) > 0
                  ? handleDSLA(lead.DSLE)
                  : "None"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1" align="center">
                Title: {lead.title}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" sx={{ mt: 1 }} spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1">City: {lead.city}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1">State: {lead.state}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1">Country: {lead.country}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1">Zip Code: {lead.zipCode}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1">AP Name: {lead.APName}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1">
                AP Number: {lead.APNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1">AP Email: {lead.APEmail}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1">
                Credit Limit: {lead.creditLimit}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1">
                Commodity: {lead.commodity}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1">
                Equipment Type: {lead.equipmentType}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1">
                Date Setup: {handleDateConverter(lead.dateSetup, "dateSetup")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1">
                First Load Moved:{" "}
                {handleDateConverter(lead.firstLoadMoved, "firstLoadMoved")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1">
                Last Load Moved:{" "}
                {handleDateConverter(lead.lastLoadMoved, "lastLoadMoved")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1">
                Transfer Date:{" "}
                {handleDateConverter(lead.transferDate, "transferDate")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              {lead.csNotes && (
                <Typography variant="body1">
                  CS Notes: {lead.csNotes}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography variant="body1">
                Next Step: {lead.nextStep}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} rowSpacing={1}>
              <InputBase
                sx={textFieldStyles}
                value={brokerNotes}
                multiline
                rows={3}
                onChange={(e) => setBrokerNotes(e.target.value)}
              />
              <Grid>
                {brokerNotes === lead.brokerNotes ? (
                  <Button
                    color="primary"
                    variant="contained"
                    disabled
                    sx={{ mt: "15px" }}
                  >
                    Save Changes
                  </Button>
                ) : (
                  <Button
                    onClick={handleBrokerNotesUpdate}
                    color="primary"
                    variant="contained"
                    sx={{ mt: "15px" }}
                  >
                    Save Changes
                  </Button>
                )}
              </Grid>
            </Grid>
            <Box textAlign="center">
              {alert && (
                <Alert
                  severity={alert.type}
                  onClose={handleAlertClose}
                  sx={{ mb: 1 }}
                >
                  {alert.message}
                </Alert>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
            <Grid item xs={12} sx={{ mt: 2 }}>
              {showActivityList && <ActivityLeadList lead={lead} />}
            </Grid>
          </Grid>
        </Grid>
      </Grid> */}
      <Grid container direction="row" justifyContent="space-around">
        <Grid item xs={3}>
          <Box>
            <Typography variant="h6" fontWeight="bold" mb="5px">
              Client Information
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableBody>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Contact Name</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      <Typography>
                        {!lead.contactName && !lead.contactLastName
                          ? ""
                          : `${lead.contactName} ${lead.contactLastName}`}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Main Phone</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      <Typography>{lead.contactNumber || ""}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Email</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      <Typography>{lead.contactEmail || ""}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Secondary Email</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      <Typography>{lead?.secondaryEmail || ""}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="h6" fontWeight="bold" mt="30px" mb="5px">
              Business Information
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableBody>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Company Name</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      <Typography>{lead.name || ""}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">
                        Main Business Type
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      <Typography>{lead.commodity || ""}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Website</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      <Typography>{lead.website || ""}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">
                        Business Address
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      <Typography>
                        {lead.city || ""} {lead.state || ""}{" "}
                        {lead.country || ""} {lead.zipCode || ""}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Typography variant="h6" fontWeight="bold" mb="5px">
              Lead Information
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableBody>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Title</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {lead.title || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Next Step</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {lead.nextStep || ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="h6" fontWeight="bold" mt="30px" mb="5px">
              Financial Information
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableBody>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">AP Name</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {lead.APName || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">AP Email</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {lead.APEmail || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">AP Number</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {lead.APNumber || ""}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Credit Limit</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {lead.creditLimit || ""}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box>
            <Typography variant="h6" fontWeight="bold" mb="5px">
              Activity Information
            </Typography>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableBody>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">DSLE</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {handleDSLA(lead.DSLE) === 0
                        ? "Today"
                        : handleDSLA(lead.DSLE) > 0
                        ? handleDSLA(lead.DSLE)
                        : "Not Emailed"}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">DSLC</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {handleDSLA(lead.DSLC) === 0
                        ? "Today"
                        : handleDSLA(lead.DSLC) > 0
                        ? handleDSLA(lead.DSLC)
                        : "Not Called"}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Date Setup</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {DateFormatter(lead.dateSetup || "")}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">
                        First Load Moved
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {DateFormatter(lead.firstLoadMoved || "")}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Last Load Moved</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {DateFormatter(lead.lastLoadMoved || "")}
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ border: "1px solid grey" }}>
                    <TableCell>
                      <Typography fontWeight="bold">Transfer Date</Typography>
                    </TableCell>
                    <TableCell sx={{ border: "1px solid grey" }}>
                      {DateFormatter(lead.transferDate || "")}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Grid container justifyContent="center" sx={{ textAlign: "center" }}>
            <Grid item xs={12} sx={{ mt: 2 }}>
              {showActivityList && <ActivityLeadList lead={lead} />}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <style>
        {`
          .lead-website-link {
            color: ${theme.palette.primary.contrastText};
            text-decoration: none;
          }
    
          .lead-website-link:hover {
            text-decoration: underline;
          }
          `}
      </style>
    </div>
  );
};

export default LeadInfo;
