import React, { useState } from 'react';
import { /* Button, */ Menu, MenuItem } from '@mui/material';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
// import DeveloperBoard from './DeveloperBoard';

const DeveloperMenu = ({ handleToggleDeveloperBoard, handleToggleErrorLogs }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  //const [showDeveloperBoard, setShowDeveloperBoard] = useState(false);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const openDeveloperBoard = () => {
    handleToggleDeveloperBoard();
    closeMenu();
  };

  const openErrorLogs = () => {
    handleToggleErrorLogs();
    closeMenu();
  };

  return (
    <>
      <DeveloperBoardIcon
        color="primary"
        onClick={openMenu}
        sx={{
          fontSize: { xs: '1.5rem', md: '1.5rem' }, 
        }}
      />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu} keepMounted>
        <MenuItem onClick={openDeveloperBoard} >Open Developer Board</MenuItem>
        <MenuItem onClick={openErrorLogs} >Open Error Logs</MenuItem>
      </Menu>
    </>
  );
};

export default DeveloperMenu;
