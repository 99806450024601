import { Close } from "@mui/icons-material";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, MenuItem, Select, Typography } from "@mui/material";
import { hoverStyle } from "components/helpers/MediaQueries";
import { useState } from "react";
import { useGetAgentsQuery } from "state/managementApi";

const AssignPoolLeadMenu = ({ open, onClose, onSubmit, leadName, selectedAgent, setSelectedAgent, leadLength }) => {
    const [selectedAgentName, setSelectedAgentName] = useState('default');

    const { data: agents = [], error: agentsError, isLoading: agentsLoading } = useGetAgentsQuery(null, { staleTime: 0 });

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth='xs'>
            {agentsLoading ? (
                <CircularProgress />
            ) : agentsError ? (
                ''
            ) : (
                <>
                    <DialogTitle>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant="h6">Transfer Lead</Typography>
                            <Close sx={hoverStyle} onClick={onClose} />
                        </div>
                        <Divider sx={{ mb: '15px', }}/>
                    </DialogTitle>
                    <DialogContent sx={{ pb: '0px', }}>
                        <Select
                            labelId="agent-id"
                            id="agent"
                            name="agent"
                            value={selectedAgentName}
                            onChange={(e) => {
                                setSelectedAgentName(e.target.value);
                                setSelectedAgent(agents.find((agent) => agent.name === e.target.value));
                            }}
                        >
                            <MenuItem value='default' sx={{ display: 'none', }}>Select an agent</MenuItem>
                            {agents.map((agent, index) => (
                                <MenuItem key={index} value={agent.name}>{agent.name}</MenuItem>
                            ))}
                        </Select>
                        <Divider sx={{ mt: '15px', }}/>
                    </DialogContent>
                    <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', }}>
                        <Box sx={{ ml: '15px', }}>
                            {selectedAgentName !== 'default' && (
                                <Typography sx={{ flexBasis: '25%', wordWrap: 'break-word', width: '80%', }}><b>Send {leadName ? leadName : `${leadLength > 1 ? `${leadLength} leads` : `1 lead`}`} to {selectedAgentName}?</b></Typography>
                            )}
                        </Box>
                        <Box>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={selectedAgent === null}
                                onClick={onSubmit}
                            >
                                Confirm
                            </Button>
                        </Box>
                    </DialogActions>
                </>
            )}
        </Dialog>
    )
}

export default AssignPoolLeadMenu;