import {
  Box,
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { calculateDSLA } from "components/helpers/CalculateDSLA";
import { useState } from "react";
import { useGetSearchLeadsByFieldQuery } from "state/api";
import LeadDetails from "./LeadDetails";
import { ExpandLess, ExpandMore, Refresh } from "@mui/icons-material";

const LeadSearchList = ({
  searchInput,
  searchCategory,
  handleLeadsUpdate,
  theme,
}) => {
  const [query, setQuery] = useState(searchInput);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [selectedLeads, setSelectedLeads] = useState([]);

  const [sortCategory, setSortCategory] = useState("");
  const [sortDirection, setSortDirection] = useState("");

  const {
    data: leadsData = [],
    error,
    isLoading,
    refetch,
  } = useGetSearchLeadsByFieldQuery({
    field: searchCategory,
    searchQuery: query,
  });

  const [currentPage, setCurrentPage] = useState(1);

  const handleRefresh = () => {
    refetch();
  };

  const handleUpdateLeads = () => {
    handleLeadsUpdate();
  };

  const openLeadDetails = (lead) => {
    setSelectedLead(lead);
  };

  const closeLeadDetails = () => {
    setSelectedLead(null);
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedLeads([]);
    } else {
      const allLeadIds = leadsData.map((lead) => lead._id);
      setSelectedLeads(allLeadIds);
    }
    setSelectAll(!selectAll);
  };

  const handleSelectLead = (leadId) => {
    const isSelected = selectedLeads.includes(leadId);
    if (isSelected) {
      setSelectedLeads(selectedLeads.filter((id) => id !== leadId));
    } else {
      setSelectedLeads([...selectedLeads, leadId]);
    }
  };

  // Function to please the middle mousers
  const handleLeadMiddleClick = (lead, e) => {
    if (e.button === 1) {
      window.open(`/lead/${lead._id}`, "_blank");
    }
  };

  const ensureHttpPrefix = (url) => {
    // Check if the URL starts with "http://"
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return `http://${url}`;
    }
    return url;
  };

  const handleSort = (category) => {
    if (sortCategory === category) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortCategory(category);
      setSortDirection("asc");
      setCurrentPage(1);
    }
  };

  const sortByRecentActivity = (a, b) => {
    const valueA = a.recentActivity?.date || "";
    const valueB = b.recentActivity?.date || "";
    return sortDirection === "asc"
      ? valueA.localeCompare(valueB)
      : valueB.localeCompare(valueA);
  };

  const sortedLeads = [...leadsData]?.sort((a, b) => {
    if (sortCategory === "recentActivity.date") {
      return sortByRecentActivity(a, b);
    } else if (sortCategory) {
      const valueA = a[sortCategory] || "";
      const valueB = b[sortCategory] || "";
      if (typeof valueA === "string" && typeof valueB === "string") {
        return sortDirection === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
      }
    }
    return 0;
  });

  const handleDSLA = (DSLA) => {
    return calculateDSLA(DSLA);
  };

  const shortenURL = (url, maxLength) => {
    if (url && url.length > maxLength) {
      return url.slice(0, maxLength - 3) + "...";
    }

    return url;
  };

  const handlePageChange = (e, newPage) => {
    e.preventDefault();
    setCurrentPage(newPage);
  };

  const itemsPerPage = 100; // Change this to set the number of items per page

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Modify how the data is displayed based on the current page
  const displayedLeads = sortedLeads.slice(startIndex, endIndex);

  if (isLoading)
    return (
      <Container
        component="main"
        maxWidth="xl"
        sx={{ display: "flex", justifyContent: "center", marginTop: "15px" }}
      >
        <CircularProgress />
      </Container>
    );

  return (
    <div style={{ marginTop: "1rem", width: "100%" }}>
      {selectedLead ? (
        <LeadDetails
          lead={selectedLead}
          onClose={closeLeadDetails}
          handleUpdateLeads={handleUpdateLeads}
          refetchLeads={handleRefresh}
        />
      ) : (
        <div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={6}>
              {leadsData?.length === 0 && searchInput !== "" ? (
                <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
                  No leads matching {searchInput}
                </Typography>
              ) : (
                <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
                  Showing {displayedLeads.length} out of {leadsData.length}{" "}
                  total leads.
                </Typography>
              )}
            </Grid>
            <Tooltip title="Refresh" placement="bottom">
              <IconButton
                onClick={handleRefresh}
                color="info"
                size="medium"
                sx={{ ml: 1, boxShadow: "0 5px 5px rgba(0, 0, 0, 0.2" }}
              >
                <Refresh />
              </IconButton>
            </Tooltip>
          </div>
          <Pagination
            style={{ display: "flex", justifyContent: "center" }}
            count={Math.ceil(leadsData?.length / itemsPerPage)}
            page={currentPage}
            onChange={(e, newPage) => {
              handlePageChange(e, newPage);
            }}
          />
          <Box mt={4}>
            <TableContainer
              component={Paper}
              sx={{ overflow: "auto", marginRight: "10px", maxHeight: 570 }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox
                        checked={selectAll}
                        onChange={handleSelectAll}
                      />
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("name")}
                          style={{ cursor: "pointer" }}
                        >
                          Company Name
                        </Typography>
                        {sortCategory !== "name" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("commodity")}
                          style={{ cursor: "pointer" }}
                        >
                          Commodity
                        </Typography>
                        {sortCategory !== "commodity" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("state")}
                          style={{ cursor: "pointer" }}
                        >
                          State
                        </Typography>
                        {sortCategory !== "state" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("contactNumber")}
                          style={{ cursor: "pointer" }}
                        >
                          Phone Number
                        </Typography>
                        {sortCategory !== "contactNumber" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("website")}
                          style={{ cursor: "pointer" }}
                        >
                          Website
                        </Typography>
                        {sortCategory !== "website" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("status")}
                          style={{ cursor: "pointer" }}
                        >
                          Status
                        </Typography>
                        {sortCategory !== "status" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("DSLC")}
                          style={{ cursor: "pointer" }}
                        >
                          DSLC
                        </Typography>
                        {sortCategory !== "DSLC" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("recentActivity.date")}
                          style={{ cursor: "pointer" }}
                        >
                          DSLA
                        </Typography>
                        {sortCategory !== "recentActivity.date" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayedLeads.map((lead) => (
                    <TableRow
                      key={lead._id}
                      sx={{
                          boxShadow:
                            lead.DNA.status && theme.palette.mode === "dark" // DNA Colors
                              ? "inset 0 0 0 1000px #761600"
                              : lead.DNA.status && theme.palette.mode !== "dark"
                              ? "inset 0 0 0 1000px #FF9177"
                              : lead.callTomorrow && theme.palette.mode === "dark" // Call Tomorrow Colors
                              ? "inset 0 0 0 1000px #006517"
                              : lead.callTomorrow && theme.palette.mode !== "dark"
                              ? "inset 0 0 0 1000px #56de75"
                              : "none",
                        }}
                    >
                      <TableCell>
                        <input
                          type="checkbox"
                          checked={selectedLeads.includes(lead._id)}
                          onChange={() => handleSelectLead(lead._id)}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </TableCell>
                      <TableCell
                        onClick={(e) => {
                          openLeadDetails(lead);
                        }}
                      >
                        <span
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                        >
                          <a
                            onMouseDown={(e) => {
                              handleLeadMiddleClick(lead, e);
                            }}
                          >
                            {lead.name}
                          </a>
                        </span>
                      </TableCell>
                      <TableCell>{lead?.commodity}</TableCell>
                      <TableCell>{lead.state}</TableCell>
                      <TableCell>{lead.contactNumber}</TableCell>
                      <TableCell>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {lead.website.length > 30 ? (
                            <Tooltip title={lead.website}>
                              <span style={{ cursor: "pointer" }}>
                                <a
                                  href={ensureHttpPrefix(lead.website)}
                                  target="_blank"
                                  style={{
                                    color: "inherit",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {shortenURL(lead.website, 30)}
                                </a>
                              </span>
                            </Tooltip>
                          ) : (
                            <Tooltip title={lead.website}>
                              <span style={{ cursor: "pointer" }}>
                                <a
                                  href={ensureHttpPrefix(lead.website)}
                                  target="_blank"
                                  style={{
                                    color: "inherit",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {lead.website}
                                </a>
                              </span>
                            </Tooltip>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>
                        {lead.status
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^./, function (str) {
                            return str.toUpperCase();
                          })}
                      </TableCell>
                      <TableCell>
                        {handleDSLA(lead.DSLC) === 0
                          ? "Today"
                          : handleDSLA(lead.DSLC) > 0
                          ? handleDSLA(lead.DSLC)
                          : "New"}
                      </TableCell>
                      <TableCell>
                        {handleDSLA(lead.recentActivity?.date) === 0
                          ? "Today"
                          : handleDSLA(lead.recentActivity?.date) > 0
                          ? handleDSLA(lead.recentActivity?.date)
                          : "New"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}
              count={Math.ceil(leadsData?.length / itemsPerPage)}
              page={currentPage}
              onChange={(e, newPage) => {
                handlePageChange(e, newPage);
              }}
            />
          </Box>
        </div>
      )}
    </div>
  );
};

export default LeadSearchList;
