import { Close } from '@mui/icons-material';
import useTheme from '@mui/material/styles/useTheme';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { hoverStyle } from 'components/helpers/MediaQueries';
import { useFormik } from 'formik';
import mongoose from 'mongoose';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSendEmailDispatchMutation, useUpdateDispatchMutation } from 'state/api';
import { useAddCardToBookedColumnMutation } from 'state/dispatchApi';
import { useUpdateQuoteMutation } from 'state/operationsApi';
import * as yup from 'yup';

const QuoteCarrierSubmissionForm = ({ quote, onClose }) => {
    const theme = useTheme();
    const isConnected = useSelector((state) => state.webSocket.isConnected);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [socketError, setSocketError] = useState(false);
    const [socketErrorMessage, setSocketErrorMessage] = useState("");

    const [updateQuote] = useUpdateQuoteMutation();
    const [updateDispatch] = useUpdateDispatchMutation();
    const [updateNewDispatch] = useAddCardToBookedColumnMutation();
    const [sendEmailDispatch] = useSendEmailDispatchMutation();

    const { enqueueSnackbar } = useSnackbar();

    const initialValues = {
        originCity: quote.origin.city + " " + quote.origin.state,
        destinationCity: quote.destination.city + " " + quote.destination.state,
        customerEmail: '',
        loadOwner: '',
        proNumber: '',
        mcNumber: quote.carrierInfo.mcNumber || '',
        dispatcherName: quote.dispatcherName || '',
        dispatcherPhone: quote.dispatcherPhone || '',
        dispatcherEmail: quote.carrierInfo.email || '',
        driverNames: quote.driverName || '',
        driverNumbers: quote.driverNumber || '', // What value is this?
        // driverNumbers: quote.carrierInfo.phoneNumber,
        specialInstructions: '',
        areTheySetUp: '',
        pickupAppointment: '',
        deliveryAppointment: '',
        pickupTimeZone: '',
        referenceNumber: '',
    }; // Where does the carrier info go?

    const handleCarrierFormSubmission = async (values) => {
        const id = new mongoose.Types.ObjectId().toString();
        const title = values.proNumber + " | " + values.originCity + " - " + values.destinationCity + " (" + values.loadOwner + ")";
        const subtitle = "";
        const notes = "";
        const description = `
      Customer Email: ${values.customerEmail},
        Load Owner: ${values.loadOwner},
        Pro Number: ${values.proNumber},
        Are They Set Up: ${formik.values.areTheySetUp},
        MC Number: ${values.mcNumber},
        Dispatcher Name: ${values.dispatcherName},
        Dispatcher Phone: ${values.dispatcherPhone},
        Dispatcher Email: ${values.dispatcherEmail},
        Driver Names: ${values.driverNames},
        Driver Numbers: ${values.driverNumbers},
        Pickup Appointment: ${formik.values.pickupAppointment},
        Delivery Appointment: ${formik.values.deliveryAppointment},
        Pickup Time Zone: ${formik.values.pickupTimeZone},
        Reference Number: ${formik.values.referenceNumber},
        Instructions for Dispatch: ${values.specialInstructions}`;
        const color = theme.palette.primary[1000];
        const newCard = {
            id,
            title,
            subtitle,
            notes,
            description,
            color,
            archivedBy: id,
        };

        const quoteUpdate = {
            status: "booked"
        };

        try {
            await updateQuote({ id: quote._id, updates: quoteUpdate })
            await updateNewDispatch(newCard);
            await updateDispatch(newCard)
                .then(() => {
                    sendEmailDispatch();
                    setIsSubmitted(true);
                    enqueueSnackbar("Quote Sent To Dispatch");
                    onClose();
                });
        } catch (error) {
            setIsSubmitted(false);
            setSocketError(true);
            setSocketErrorMessage(error);
            console.error('Error updating columns:', error);
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema: yup.object({
            originCity: yup.string().required('Origin city is required'),
            destinationCity: yup.string().required('Destination city is required'),
            customerEmail: yup.string().email('Invalid email address').required('Customer email required'),
            loadOwner: yup.string().required('Load owner is required'),
            proNumber: yup.number().required('Pro number is required'),
            mcNumber: yup.number().required('MC number is required'),
            dispatcherName: yup.string().required('Dispatcher name is required'),
            dispatcherPhone: yup.string().required('Dispatcher phone is required'),
            dispatcherEmail: yup.string().email('Invalied email address').required('Dispatcher email required'),
            driverNames: yup.string().required('Driver name(s) required'),
            driverNumbers: yup.string().required('Driver number(s) required'),
            specialInstructions: yup.string().required('Instructions required'),
            areTheySetUp: yup.string().required('Set up status required'),
            pickupAppointment: yup.string().required('Pickup time required'),
            deliveryAppointment: yup.string().required('Delivery time required'),
            pickupTimeZone: yup.string().required('Pickup time zone required'),
            referenceNumber: yup.string().required('Reference number required')
        }),
        onSubmit: values => {
            handleCarrierFormSubmission(values)
        },
    });

    return (
        <Dialog open onClose={onClose} fullWidth maxWidth="xl">
            <DialogTitle>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant='h6'>Carrier Submission Form</Typography>
                    <IconButton onClick={onClose}>
                        <Close sx={hoverStyle} />
                    </IconButton>
                </div>
                <Divider sx={{ mb: '15px', }} />
            </DialogTitle>
            <DialogContent>
                <Container
                    component="main"
                    maxWidth="xl"
                    padding="40px"
                    sx={{
                        mt: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: theme.palette.primary[900],
                        padding: '40px',
                        margin: '0 auto',
                    }}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    margin='normal'
                                    required
                                    fullWidth
                                    id="originCity"
                                    label="Origin City"
                                    name="originCity"
                                    autoFocus
                                    value={formik.values.originCity}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.originCity && formik.errors.originCity && (
                                    <Alert severity='error'>{formik.errors.originCity}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="destinationCity"
                                    label="Destination City"
                                    name="destinationCity"
                                    // autoFocus
                                    value={formik.values.destinationCity}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.destinationCity && formik.errors.destinationCity && (
                                    <Alert severity="error">{formik.errors.destinationCity}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="customerEmail"
                                    label="Customer Email"
                                    name="customerEmail"
                                    autoComplete="email"
                                    // autoFocus
                                    value={formik.values.customerEmail}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.customerEmail && formik.errors.customerEmail && (
                                    <Alert severity="error">{formik.errors.customerEmail}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="loadOwner"
                                    label="Load Owner"
                                    name="loadOwner"
                                    // autoFocus
                                    value={formik.values.loadOwner}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.loadOwner && formik.errors.loadOwner && (
                                    <Alert severity="error">{formik.errors.loadOwner}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="areTheySetUp"
                                    label="Are They Set Up?"
                                    name="areTheySetUp"
                                    // autoFocus
                                    value={formik.values.areTheySetUp}
                                    onChange={formik.handleChange}
                                    select
                                >
                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                    <MenuItem value="Packet sent">Packet sent</MenuItem>
                                    <MenuItem value="Missing COI">Missing COI</MenuItem>
                                </TextField>
                                {formik.touched.areTheySetUp && formik.errors.areTheySetUp && (
                                    <Alert severity="error">{formik.errors.areTheySetUp}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="proNumber"
                                    label="PRO Number"
                                    name="proNumber"
                                    // autoFocus
                                    value={formik.values.proNumber}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.proNumber && formik.errors.proNumber && (
                                    <Alert severity="error">{formik.errors.proNumber}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="mcNumber"
                                    label="MC Number"
                                    name="mcNumber"
                                    // autoFocus
                                    value={formik.values.mcNumber}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.mcNumber && formik.errors.mcNumber && (
                                    <Alert severity="error">{formik.errors.mcNumber}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="dispatcherName"
                                    label="Dispatcher Name"
                                    name="dispatcherName"
                                    // autoFocus
                                    value={formik.values.dispatcherName}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.dispatcherName && formik.errors.dispatcherName && (
                                    <Alert severity="error">{formik.errors.dispatcherName}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="dispatcherPhone"
                                    label="Dispatcher Phone"
                                    name="dispatcherPhone"
                                    // autoFocus
                                    value={formik.values.dispatcherPhone}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.dispatcherPhone && formik.errors.dispatcherPhone && (
                                    <Alert severity="error">{formik.errors.dispatcherPhone}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="dispatcherEmail"
                                    label="Dispatcher Email"
                                    name="dispatcherEmail"
                                    // autoFocus
                                    value={formik.values.dispatcherEmail}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.dispatcherEmail && formik.errors.dispatcherEmail && (
                                    <Alert severity="error">{formik.errors.dispatcherEmail}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="driverNames"
                                    label="Driver Name(s)"
                                    name="driverNames"
                                    // autoFocus
                                    value={formik.values.driverNames}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.driverNames && formik.errors.driverNames && (
                                    <Alert severity="error">{formik.errors.driverNames}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="driverNumbers"
                                    label="Driver Number(s)"
                                    name="driverNumbers"
                                    // autoFocus
                                    value={formik.values.driverNumbers}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.driverNumbers && formik.errors.driverNumbers && (
                                    <Alert severity="error">{formik.errors.driverNumbers}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="pickupAppointment"
                                    label="Is There A Pickup Appointment? Please Enter Appointment Time"
                                    required
                                    name="pickupAppointment"
                                    // autoFocus
                                    value={formik.values.pickupAppointment}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.pickupAppointment && formik.errors.pickupAppointment && (
                                    <Alert severity="error">{formik.errors.pickupAppointment}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="deliveryAppointment"
                                    label="Is There A Delivery Appointment? Please Enter Appointment Time"
                                    required
                                    name="deliveryAppointment"
                                    // autoFocus
                                    value={formik.values.deliveryAppointment}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.deliveryAppointment && formik.errors.deliveryAppointment && (
                                    <Alert severity="error">{formik.errors.deliveryAppointment}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="pickupTimeZone"
                                    label="Pickup Time Zone"
                                    required
                                    name="pickupTimeZone"
                                    // autoFocus
                                    value={formik.values.pickupTimeZone}
                                    onChange={formik.handleChange}
                                    select
                                >
                                    <MenuItem value="EST">EST</MenuItem>
                                    <MenuItem value="CST">CST</MenuItem>
                                    <MenuItem value="MST">MST</MenuItem>
                                    <MenuItem value="PST">PST</MenuItem>
                                </TextField>
                                {formik.touched.pickupTimeZone && formik.errors.pickupTimeZone && (
                                    <Alert severity="error">{formik.errors.pickupTimeZone}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="referenceNumber"
                                    label="Reference Number"
                                    required
                                    name="referenceNumber"
                                    // autoFocus
                                    value={formik.values.referenceNumber}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.referenceNumber && formik.errors.referenceNumber && (
                                    <Alert severity="error">{formik.errors.referenceNumber}</Alert>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="specialInstructions"
                                    label="Instructions for Dispatch"
                                    name="specialInstructions"
                                    multiline
                                    rows={4}
                                    // autoFocus
                                    value={formik.values.specialInstructions}
                                    onChange={formik.handleChange}
                                />
                                {formik.touched.specialInstructions && formik.errors.specialInstructions && (
                                    <Alert severity="error">{formik.errors.specialInstructions}</Alert>
                                )}
                            </Grid>
                        </Grid>
                        {socketError ?
                            <Typography sx={{ color: '#ba000d', textAlign: 'center', marginTop: '10px', }}>
                                Error submitting: {socketErrorMessage}
                            </Typography>
                            : null}
                    </form>
                </Container>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', }}>
                {isSubmitted && !socketError ?
                    <Box textAlign="center">
                        <Typography sx={{ color: '#00deeb', textAlign: 'center' }}>
                            Submitted - {new Date().toLocaleDateString('en-US')}!
                        </Typography>
                        <Button onClick={onClose} variant="contained" color="error" sx={{ marginTop: 1 }}>
                            Close Form
                        </Button>
                    </Box>
                    :
                    <Box textAlign="center">
                        <Button type="submit" variant="contained" color="primary" onClick={formik.handleSubmit}>
                            Submit
                        </Button>
                        <Button onClick={onClose} variant="contained" color="error" sx={{ marginLeft: 2 }}>
                            Cancel
                        </Button>
                    </Box>}
            </DialogActions>
        </Dialog>
    )
}

export default QuoteCarrierSubmissionForm;