import React from 'react';
import { Button, Paper, useTheme, Grid } from '@mui/material';
import SalesInfo from './SalesInfo';

const SalesDetails = ({ sale, onClose }) => {
  const theme = useTheme();

  const handleDelete = () => {
    // Handle the delete logic, such as updating the sales state in the parent component
    // Example:
    console.log('Delete Sale:', sale);
  };

  const handleUpdate = () => {
    // Handle the update logic, such as updating the sales state in the parent component
    // Example:
    console.log('Update Sale:', sale);
  };

  return (
    <Paper sx={{ backgroundColor: theme.palette.background.paper, padding: '20px' }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Button onClick={onClose} color="primary" variant="contained">
            Back to Sales List
          </Button>
        </Grid>
        <SalesInfo sale={sale} />
        <Grid item xs={12} sm={6} md={4}>
          <Button onClick={handleDelete} color="error" variant="contained" fullWidth>
            Delete Sale
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button onClick={handleUpdate} color="primary" variant="contained" fullWidth>
            Update Sale
          </Button>
        </Grid>
      </Grid>
      
    </Paper>
  );
};

export default SalesDetails;
