import { 
  Box,
  Button,
  Checkbox,
  // CircularProgress,
  IconButton,
  InputBase,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import ComposeEmail from "components/email/ComposeEmail";
import CustomerDetails from "./CustomerDetails";
import { Add, AssignmentInd, Close, Email, Search } from "@mui/icons-material";
import { calculateDSLA } from "components/helpers/CalculateDSLA";
import AddManagerActivityForm from "components/forms/Manager/activities/AddManagerActivityForm";
import AssignLeadForm from "components/forms/Manager/leads/AssignLeadForm";

const { useState, useEffect } = require("react");
const { useManagerGetCustomersQuery, useSearchAllCustomerLeadsQuery } = require("state/managementApi");

const CustomersList = () => {
  const [customerToDetail, setCustomerToDetail] = useState({});
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedCustomerObjects, setSelectedCustomerObjects] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);

  const [addActivityOpen, setAddActivityOpen] = useState(false);
  const [assignLeadOpen, setAssignLeadOpen] = useState(false);
  const [emailFormOpen, setEmailFormOpen] = useState(false);
  const [customerDetailsOpen, setCustomerDetailsOpen] = useState(false);

  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);

  const [searchInput, setSearchInput] = useState("");
  const [searched, setSearched] = useState(false);
  // const [searchResultsObject, setSearchResultsObject] = useState({});
  const [searchSkip, setSearchSkip] = useState(true);

  const {
    data: customers = [],
    // error,
    isLoading,
    refetch: refetchCustomers,
  } = useManagerGetCustomersQuery({ page, limit, sortField, sortOrder });

  const {
    data: searchedCustomers = [],
    // error: searchError,
    isLoading: searchLoading,
    status,
    isSuccess,
    // refetch: refetchSearch,

  } = useSearchAllCustomerLeadsQuery({ page, limit, search: searchInput}, { skip: searchSkip });

  useEffect(() => {
   if (isSuccess && status === "fulfilled") {
    // setSearchResultsObject(searchedCustomers);
    setSearchSkip(true);
   }
  }, [searchedCustomers, isSuccess, status]);


  // Togglers
  const toggleAddActivity = () => setAddActivityOpen((prev) => !prev);
  const toggleAssignLeadOpen = () => setAssignLeadOpen((prev) => !prev);
  const toggleEmailOpen = () => setEmailFormOpen((prev) => !prev);
  const toggleCustomerDetails = (customer) => {
    setCustomerDetailsOpen((prev) => !prev);
    if (customerDetailsOpen) {
      setCustomerToDetail(null);
    } else {
      setCustomerToDetail(customer);
    }
  };

  const toggleCustomer = (selectedCustomerId) => {
    const isSelected = selectedCustomers.includes(selectedCustomerId);

    if (isSelected) {
      setSelectedCustomers((prev) =>
        prev.filter((id) => id !== selectedCustomerId)
      );
      setSelectedCustomerObjects((prev) => 
        prev.filter((customer) => customer._id !== selectedCustomerId)
      );
    } else {
      setSelectedCustomers([...selectedCustomers, selectedCustomerId]);
      setSelectedCustomerObjects(
        [...selectedCustomerObjects, 
          !searched 
            ? 
              customers.data.find((customer) => customer._id === selectedCustomerId) 
            : 
              searchedCustomers.data.find((customer) => customer._id === selectedCustomerId)
        ]
      );
    }
  };

  const toggleAllCustomers = () => {
    // If all customers on current page are selected, deselect all; otherwise, select all
    if (selectedPages.includes(page)) {
      const dataCustomerIds = customers.data.map((customer) => customer._id);
      setSelectedCustomers((prev) =>
        prev.filter((selected) => !dataCustomerIds.includes(selected))
      );
      setSelectedPages((prev) =>
        prev.filter((selectedPage) => selectedPage !== page)
      );
    } else {
      setSelectedCustomers((prev) => [
        ...prev,
        ...customers.data.map((customer) => customer._id),
      ]);
      setSelectedPages((prev) => [...prev, page]);
    }
  };

  // Handlers
  const handleSort = (category) => {
    if (sortField === category) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(category);
      setSortOrder("asc");
      setPage(1);
    }
  };

  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value, 10));
    setPage(1);
  };

  const handlePageChange = (e, newPage) => {
    e.preventDefault();
    setPage(newPage + 1);
  };

  const handleURLShorten = (url, maxLength) => {
    if (url && url.length > maxLength) {
      return url.slice(0, maxLength - 3) + "...";
    }

    return url;
  };

  const handleHttpPrefix = (url) => {
    // Check if URL starts with "http://"
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return `http://${url}`;
    }

    return url;
  };

  const handleSearch = (e) => {
    setPage(1);
    setSearched(true);
    setSearchSkip(false);
    /* if (e.key === 'Enter' && searchInput.trim() !== '') {
      setPage(1);
      setSearched(true);
      setSearchSkip(false);
    }; */
  };

  const handleRefresh = () => {
    refetchCustomers();
  };

  // TODO: This. Lead details dont refetch and update display on edit
  const handleEditRefetch = () => {
    refetchCustomers();
  };

  if (!customers.data) return;

  if (isLoading) return (
    <Skeleton />
  );

  return (
    <Box>
      <Toolbar
        sx={{
          pl: 2,
          pr: 1,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', }}>
          {/* Searchbar */}
          <div style={{ display: 'flex', justifyContent: 'center', }}>
            <Paper
              component="form"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 400, border: '1px solid grey', }}
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <InputBase 
                sx={{ ml: 1, flex: 1 }}
                id="searchbar"
                placeholder="Search Customers"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              {!searched ? (
                <IconButton
                  sx={{ p: '10px' }}
                  onClick={handleSearch}
                >
                  <Search />
                </IconButton>
              ) : (
                <IconButton
                  sx={{ p: '10px' }}
                  onClick={() => {
                    setSearched(false);
                    setSearchInput("");
                  }}
                >
                  <Close />
                </IconButton>
              )}
            </Paper>
          </div>
          <Typography
              variant="subtitle2"
              sx={{
                ml: "10px",
                pt: "6px",
                display: selectedCustomers.length ? "block" : "none",
              }}
            >
              {selectedCustomers.length} Customers Selected
              <IconButton 
                size="small" 
                sx={{ ml: '5px', }}
                onClick={() => {
                  setSelectedCustomers([]);
                  setSelectedCustomerObjects([]);
                  setSelectedPages([]);
                }}
              >
                <Tooltip title="Deselect All" placement="top">
                  <Close sx={{ fontSize: '16px', }}/>
                </Tooltip>
              </IconButton>
            </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box>
            <Button
              variant="contained"
              color="primary"
              disabled={!selectedCustomers.length}
              onClick={toggleAddActivity}
              sx={{
                textTransform: "none",
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
                boxShadow: "none",
                border: "1px solid black",
                borderRight: ".05px solid black",
                p: "6px 8px",
              }}
            >
              <Add sx={{ mr: "3px" }} />
              Activity
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!selectedCustomers.length}
              onClick={toggleAssignLeadOpen}
              sx={{
                textTransform: "none",
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
                borderTopRightRadius: "0px",
                borderBottomRightRadius: "0px",
                boxShadow: "none",
                border: "1px solid black",
                borderLeft: "0px solid black",
                borderRight: ".05px solid black",
                p: "6px 8px",
              }}
            >
              <AssignmentInd sx={{ mr: "3px" }} />
              Assign
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!selectedCustomers.length}
              onClick={toggleEmailOpen}
              sx={{
                textTransform: "none",
                borderTopLeftRadius: "0px",
                borderBottomLeftRadius: "0px",
                boxShadow: "none",
                border: "1px solid black",
                borderLeft: "0px solid black",
                paddingLeft: "8px",
              }}
            >
              <Email sx={{ mr: "6px" }} />
              Email
            </Button>
          </Box>
          {/* <Tooltip title="Filter" placement="top">
                  <IconButton>
                      <Tune />
                  </IconButton>
              </Tooltip> */}
        </Box>
      </Toolbar>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TablePagination 
                rowsPerPageOptions={[5, 10, 25, 50]}
                count={!searched ? customers.pagination.total : searched && searchedCustomers.data ? searchedCustomers.pagination.total : 1}
                rowsPerPage={limit}
                page={page - 1}
                slotProps={{
                  select: {
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  },
                }}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                showFirstButton
                showLastButton
              />
            </TableRow>
            <TableRow>
                <TableCell sx={{ padding: "0 10px", textAlign: "center" }}>
                  <Checkbox
                    checked={selectedPages.includes(page)}
                    onChange={toggleAllCustomers}
                  />
                  Select Page
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      onClick={() => handleSort("name")}
                      style={{ cursor: "pointer" }}
                    >
                      Company Name
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      onClick={() => handleSort("state")}
                      style={{ cursor: "pointer" }}
                    >
                      State
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      onClick={() => handleSort("contactEmail")}
                      style={{ cursor: "pointer" }}
                    >
                      Email
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      onClick={() => handleSort("contactNumber")}
                      style={{ cursor: "pointer" }}
                    >
                      Phone Number
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      onClick={() => handleSort("website")}
                      style={{ cursor: "pointer" }}
                    >
                      Website
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      // onClick={() => handleSort('assignedTo')}
                      // style={{ cursor: 'pointer' }}
                    >
                      Assigned To
                    </Typography>
                  </Box>
                </TableCell>
                {/* <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                            variant="h6"
                            fontWeight="bold"
                            onClick={() => handleSort('recentactivitydescription')}
                            style={{ cursor: 'pointer' }}
                        >
                            Recent Activity
                        </Typography>
                    </Box>
                </TableCell> */}
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      onClick={() => handleSort("DSLA")}
                      style={{ cursor: "pointer" }}
                    >
                      DSLA
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
          </TableHead>
          {!searched ? (
            <TableBody>
              {customers.data.map((customer) => (
                <TableRow key={customer._id}>
                  <TableCell
                      sx={{
                        padding: "0 30px",
                        textAlign: "center",
                        lineHeight: "0",
                      }}
                    >
                      <Checkbox
                        checked={selectedCustomers.includes(customer._id)}
                        onChange={() => toggleCustomer(customer._id)}
                      />
                    </TableCell>
                    <TableCell>
                      <span
                        onClick={() =>
                          toggleCustomerDetails(customer)
                        }
                        style={{ textDecoration: "underline", cursor: "pointer" }}
                      >
                        {customer.name}
                      </span>
                    </TableCell>
                    <TableCell>{customer.state}</TableCell>
                    <TableCell>{customer.contactEmail}</TableCell>
                    <TableCell>{customer.contactNumber}</TableCell>
                    <TableCell>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Tooltip title={customer.website}>
                          <span style={{ cursor: "pointer" }}>
                            <a
                              href={handleHttpPrefix(customer.website)}
                              rel="noreferrer"
                              target="_blank"
                              style={{
                                color: "inherit",
                                textDecoration: "underline",
                              }}
                            >
                              {customer.website.length > 30
                                ? handleURLShorten(customer.website, 30)
                                : customer.website}
                            </a>
                          </span>
                        </Tooltip>
                      </div>
                    </TableCell>
                    <TableCell>
                      {customer.assignedUsers.length
                        ? customer.assignedUsers.length === 1
                          ? customer.assignedUsers[0].name
                          : "Multiple"
                        : customer.status === "pool"
                        ? "Pool"
                        : "Unassigned"}
                    </TableCell>
                    <TableCell>
                      {calculateDSLA(customer.recentActivity?.date) === 0
                        ? "Today"
                        : calculateDSLA(customer.recentActivity?.date) > 0
                        ? calculateDSLA(customer.recentActivity.date)
                        : "New"}
                    </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              {!searchLoading ? (
                searchedCustomers.data.map((customer) => (
                  <TableRow key={customer._id}>
                    <TableCell
                      sx={{
                        p: '0 30px',
                        textAlign: 'center',
                        lineHeight: '0',
                      }}
                    >
                      <Checkbox 
                        checked={selectedCustomers.includes(customer._id)}
                        onChange={() => toggleCustomer(customer._id)}
                      />
                    </TableCell>
                    <TableCell>
                        <span
                          onClick={() =>
                            toggleCustomerDetails(customer)
                          }
                          style={{ textDecoration: "underline", cursor: "pointer" }}
                        >
                          {customer.name}
                        </span>
                      </TableCell>
                      <TableCell>{customer.state}</TableCell>
                      <TableCell>{customer.contactEmail}</TableCell>
                      <TableCell>{customer.contactNumber}</TableCell>
                      <TableCell>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Tooltip title={customer.website}>
                            <span style={{ cursor: "pointer" }}>
                              <a
                                href={handleHttpPrefix(customer.website)}
                                rel="noreferrer"
                                target="_blank"
                                style={{
                                  color: "inherit",
                                  textDecoration: "underline",
                                }}
                              >
                                {customer.website.length > 30
                                  ? handleURLShorten(customer.website, 30)
                                  : customer.website}
                              </a>
                            </span>
                          </Tooltip>
                        </div>
                      </TableCell>
                      <TableCell>
                        {customer.assignedUsers.length
                          ? customer.assignedUsers.length === 1
                            ? customer.assignedUsers[0].name
                            : "Multiple"
                          : customer.status === "pool"
                          ? "Pool"
                          : "Unassigned"}
                      </TableCell>
                      <TableCell>
                        {calculateDSLA(customer.recentActivity?.date) === 0
                          ? "Today"
                          : calculateDSLA(customer.recentActivity?.date) > 0
                          ? calculateDSLA(customer.recentActivity.date)
                          : "New"}
                      </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>
                    <Skeleton variant="rounded"/>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
          <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  // count={customers?.pagination.total}
                  count={!searched ? customers.pagination.total : searched && searchedCustomers.data ? searchedCustomers.pagination.total : 1}
                  rowsPerPage={limit}
                  page={page - 1}
                  slotProps={{
                    select: {
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    },
                  }}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  showFirstButton
                  showLastButton
                />
              </TableRow>
            </TableFooter>
        </Table>
      </TableContainer>
      {emailFormOpen && (
        <ComposeEmail 
          arrayOfEmails={selectedCustomerObjects.map((customer) => customer.contactEmail)}
          handleCloseEmailForm={toggleEmailOpen}
          arrayOfLeadIds={selectedCustomerObjects.map((selected) => selected._id)}
          isManager
        />
      )}
      {customerDetailsOpen && (
        <CustomerDetails 
          customer={!searched ? customers.data.find((customer) => customer._id === customerToDetail._id) : searchedCustomers.data.find((customer) => customer._id === customerToDetail._id)}
          onClose={toggleCustomerDetails}
          refetch={handleRefresh}
        />
      )}
      {addActivityOpen && (
        <AddManagerActivityForm
          customerIds={selectedCustomers}
          onClose={toggleAddActivity}
        />
      )}
      {assignLeadOpen && (
        <AssignLeadForm
          onClose={toggleAssignLeadOpen}
          leadIds={selectedCustomers}
          leads={selectedCustomerObjects}
          refetch={refetchCustomers}
        />
      )}
    </Box>
  )
}

export default CustomersList;