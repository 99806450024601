import React, { useState } from 'react';
import { Button, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper, Typography, useTheme } from '@mui/material';
import { useGetSalesByLeadQuery } from '../../state/salesApi';
import SalesDetails from './SalesDetails';
import AddSaleForm from 'components/forms/AddSaleForm';
import { formatDate } from 'components/helpers/CalculateDSLA';

const SalesList = ({ leadId }) => {
  const [selectedSale, setSelectedSale] = useState(null);
  const [sortCategory, setSortCategory] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [currentPage, setCurrentPage] = useState(1);
  const { data: salesData, isLoading, isError } = useGetSalesByLeadQuery({ leadId, page: currentPage, limit: 5 });
  const [isAddSaleFormOpen, setIsAddSaleFormOpen] = useState(false);
  const theme = useTheme();

  const openSaleDetails = (sale) => {
    setSelectedSale(sale);
  };

  const closeSaleDetails = () => {
    setSelectedSale(null);
  };

  const handleAddSaleFormClose = () => {
    setIsAddSaleFormOpen(false);
  };

  const handleAddSaleFormOpen = () => {
    setIsAddSaleFormOpen(true);
  };

  const handleSort = (category) => {
    if (sortCategory === category) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortCategory(category);
      setSortOrder('asc');
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const sales = salesData?.sales || [];
  const hasNextPage = salesData?.hasNextPage || false;

  const sortedSales = sales && Array.isArray(sales)
    ? [...sales].sort((a, b) => {
        if (sortCategory === 'amount') {
          const valueA = a[sortCategory] || 0;
          const valueB = b[sortCategory] || 0;
          return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
        } else if (sortCategory === 'date') {
          const valueA = new Date(a[sortCategory]) || new Date(0);
          const valueB = new Date(b[sortCategory]) || new Date(0);
          return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
        } else if (sortCategory === 'userName') {
          const valueA = a[sortCategory] || '';
          const valueB = b[sortCategory] || '';
          return sortOrder === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
        } else if (sortCategory === 'status') {
          const valueA = a[sortCategory] || '';
          const valueB = b[sortCategory] || '';
          return sortOrder === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
        }
        return 0;
      })
    : [];

  const handleNextPage = () => {
    if (hasNextPage) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  if (isLoading) {
    return <Typography>Loading...</Typography>;
  }

  if (isError) {
    return <Typography>Error while loading data.</Typography>;
  }

  return (
    <div>
      {selectedSale ? (
        <SalesDetails sale={selectedSale} onClose={closeSaleDetails} />
      ) : (
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddSaleFormOpen}
            sx={{ backgroundColor: theme.palette.background.paper }}
          >
            Add Sale
          </Button>
          {isAddSaleFormOpen && <AddSaleForm leadId={leadId} onClose={handleAddSaleFormClose} />}
          {sales.length === 0 ? (
            <Typography>No sales found.</Typography>
          ) : (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        onClick={() => handleSort('amount')}
                        style={{ cursor: 'pointer' }}
                      >
                        Amount
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        onClick={() => handleSort('date')}
                        style={{ cursor: 'pointer' }}
                      >
                        Date
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        onClick={() => handleSort('userName')}
                        style={{ cursor: 'pointer' }}
                      >
                        Agent
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        onClick={() => handleSort('status')}
                        style={{ cursor: 'pointer' }}
                      >
                        Status
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedSales.map((sale) => (
                    <TableRow key={sale._id} onClick={() => openSaleDetails(sale)}>
                      <TableCell>{sale.description}</TableCell>
                      <TableCell>{formatDate(sale.date)}</TableCell>
                      <TableCell>{sale.userName}</TableCell>
                      <TableCell>{sale.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <div style={{ marginTop: '16px', display: 'flex', justifyContent: 'center' }}>
            {currentPage > 1 && (
              <Button
                variant="contained"
                color="primary" 
                onClick={() => handlePageChange(currentPage - 1)}
                sx={{ backgroundColor: theme.palette.background.paper, marginRight:1 }}
              >
                {'< Previous'}
              </Button>
            )}
            {hasNextPage && (
              <Button 
                variant="contained"
                color="primary"  
                onClick={handleNextPage}
                sx={{ backgroundColor: theme.palette.background.paper }}
              >
                {'Next >'}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SalesList;
