import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";

import { Close } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useManagerUpdateLeadMutation } from "state/managementApi";
import {
  useCreateLeadForSelfSalesMutation,
  useUpdateLeadMutation,
} from "../../state/salesApi"; // Import the correct mutation functions

// Handler to translate dates to form friendly format
const handleDateConverter = (dateString) => {
  // If no value, return empty string
  if (!dateString) return "";

  // Create a Date object from the given string
  const date = new Date(dateString);

  // Get the month, day, and year from the Date object
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Adding 1 due to months being zero-based
  const day = date.getUTCDate().toString().padStart(2, "0");

  // Form the YYYY-MM-DD format string
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

const phoneRegExp =
  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}(?:\s*(?:#|x.?|ext.?|extension)\s*[0-9]+)?$/im;

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  city: yup.string(),
  state: yup.string(),
  country: yup.string(),
  zipCode: yup.string(),
  contactName: yup.string(),
  contactLastName: yup.string(),
  contactNumber: yup.string().matches(phoneRegExp, "Invalid phone number"),
  contactEmail: yup.string().email("Invalid email address"),
  secondaryEmail: yup.string().email("Invalid email address"),
  APName: yup.string(),
  APNumber: yup.string().matches(phoneRegExp, "Invalid phone number"),
  APEmail: yup.string().email("Invalid email address"),
  creditLimit: yup.number(),
  commodity: yup.string(),
  equipmentType: yup.string(),
  dateSetup: yup.date(),
  firstLoadMoved: yup.date(),
  lastLoadMoved: yup.date(),
  transferDate: yup.date(),
  brokerNotes: yup.string(),
  csNotes: yup.string(),
  nextStep: yup.string(),
  title: yup.string(),
  website: yup.string(),
  status: yup.string(),
  assignedUsers: yup.array().of(yup.string()).nullable(),
  DNA: yup.object(),
  callTomorrow: yup.bool(),
});

const AddLeadForm = ({
  mode,
  leadToUpdate,
  open,
  closeForm,
  onCreate,
  isManager = false,
  updateRefetch,
  isPool = false,
  categories,
}) => {
  const user = useSelector((state) => state.user);
  const theme = useTheme();
  const initialValues =
    mode === "create"
      ? {
          name: "",
          city: "",
          state: "",
          country: "",
          zipCode: "",
          contactName: "",
          contactLastName: "",
          contactNumber: "",
          contactEmail: "",
          secondaryEmail: "",
          APName: "",
          APNumber: "",
          APEmail: "",
          creditLimit: "",
          commodity: "",
          equipmentType: "",
          dateSetup: "",
          firstLoadMoved: "",
          lastLoadMoved: "",
          transferDate: "",
          brokerNotes: "",
          csNotes: "",
          nextStep: "",
          title: "",
          website: "",
          status: "new",
          assignedUsers: [],
          callTomorrow: false,
          DNA: {
            notes: "",
            status: false,
          },
          categories: [],
        }
      : {
          _id: leadToUpdate._id,
          name: leadToUpdate.name || "",
          city: leadToUpdate.city || "",
          state: leadToUpdate.state || "",
          country: leadToUpdate.country || "",
          zipCode: leadToUpdate.zipCode || "",
          contactName: leadToUpdate.contactName || "",
          contactLastName: leadToUpdate.contactLastName || "",
          contactNumber: leadToUpdate.contactNumber || "",
          contactEmail: leadToUpdate.contactEmail || "",
          secondaryEmail: leadToUpdate?.secondaryEmail || "",
          APName: leadToUpdate.APName || "",
          APNumber: leadToUpdate.APNumber || "",
          APEmail: leadToUpdate.APEmail || "",
          creditLimit: leadToUpdate.creditLimit || "",
          commodity: leadToUpdate.commodity || "",
          equipmentType: leadToUpdate.equipmentType || "",
          dateSetup: handleDateConverter(leadToUpdate.dateSetup) || "",
          firstLoadMoved:
            handleDateConverter(leadToUpdate.firstLoadMoved) || "",
          lastLoadMoved: handleDateConverter(leadToUpdate.lastLoadMoved) || "",
          transferDate: handleDateConverter(leadToUpdate.transferDate) || "",
          brokerNotes: leadToUpdate.brokerNotes || "",
          csNotes: leadToUpdate.csNotes || "",
          nextStep: leadToUpdate.nextStep || "",
          title: leadToUpdate.title || "",
          website: leadToUpdate.website || "",
          status: leadToUpdate.status || "",
          assignedUsers: leadToUpdate.assignedUsers || [],
          callTomorrow: leadToUpdate.callTomorrow || false,
          DNA: leadToUpdate.DNA || {
            notes: "",
            status: false,
          },
        };
  const [selectedPoolCategories, setSelectedPoolCategories] = useState([]);

  const [createLeadMutation] = useCreateLeadForSelfSalesMutation();
  const [updateLeadMutation] = useUpdateLeadMutation();
  // const [managerUpdateLeadMutation] = useManagerUpdateLeadMutation();
  const [alert, setAlert] = useState(null); // State to manage the alert

  const { enqueueSnackbar } = useSnackbar();

  const handleAlertClose = () => {
    setAlert(null); // Clear the alert
  };

  const handleCallTomorrowChange = () => {
    formik.setFieldValue("callTomorrow", !formik.values.callTomorrow);
  };

  const handleDNAStatusChange = () => {
    formik.setFieldValue("DNA", {
      notes: formik.values.DNA.notes,
      status: !formik.values.DNA.status,
    });
  };

  const handleDNANotesChange = (e) => {
    formik.setFieldValue("DNA", {
      notes: e.target.value,
      status: formik.values.DNA.status,
    });
  };

  const handlePoolCategoryCheckbox = (selectedCategoryId) => {
    const isSelected = selectedPoolCategories.includes(selectedCategoryId);
    if (isSelected) {
      setSelectedPoolCategories(
        selectedPoolCategories.filter(
          (category) => category !== selectedCategoryId
        )
      );
    } else {
      setSelectedPoolCategories([
        ...selectedPoolCategories,
        selectedCategoryId,
      ]);
    }
  };

  const handleUpdateDifferences = async (initialValues, updatedValues) => {
    const differences = {};
    for (let key in updatedValues) {
      if (initialValues[key] !== updatedValues[key]) {
        differences[key] = updatedValues[key];
      }
    }

    return differences;
  };

  const handleSubmit = async (values) => {
    if (mode === "create") {
      try {
        if (isPool) {
          values.categories = selectedPoolCategories;
          values.status = "pool";
        } else {
          values.assignedUsers = [user._id];
        }

        await createLeadMutation(values).then((res) => {
          if (res.error && res.error.data) {
            const { status, message } = res.error.data;
            if (status === 409) {
              setAlert({ type: "error", message: message });
              return;
            } else {
              setAlert({ type: "error", message: "An error occurred" });
              return;
            }
          }
          setAlert({ type: "success", message: "Lead created successfully" });
          enqueueSnackbar(`${res.data.name} Created`);
          onCreate(res.data);
        });
      } catch (error) {
        // Handle error
        console.error("Error creating lead:", error);
      }
    } else if (mode === "update") {
      try {
        const updatedValues = { ...values };
        const differences = await handleUpdateDifferences(
          initialValues,
          updatedValues
        );

        if (!isManager) {
          await updateLeadMutation({
            id: values._id,
            leadUpdates: differences,
          }).then(() => {
            updateRefetch();
            enqueueSnackbar(`${leadToUpdate.name} Updated`);
            closeForm();
          });
        } else {
          await updateLeadMutation({
            id: values._id,
            leadUpdates: differences,
          }).then(() => {
            updateRefetch();
            enqueueSnackbar(`${leadToUpdate.name} Updated`);
            closeForm();
          });
        }
      } catch (error) {
        console.error("Error updating lead:", error.response.data);
        setAlert({ type: "error", message: "An error occurred" });
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <>
      <Dialog open={open} onClose={closeForm} fullWidth maxWidth="xl">
        <DialogTitle sx={{ paddingBottom: "0px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" align="center" gutterBottom>
              {mode === "create" ? "Add Lead" : `Update ${leadToUpdate.name}`}
            </Typography>
            <IconButton onClick={closeForm}>
              <Close />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <Container
            maxWidth="xl"
            sx={{
              backgroundColor: theme.palette.primary[900],
              padding: "40px",
            }}
          >
            <form onSubmit={formik.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Company name *"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="contactName"
                    name="contactName"
                    label="Contact First Name"
                    value={formik.values.contactName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.contactName &&
                      Boolean(formik.errors.contactName)
                    }
                    helperText={
                      formik.touched.contactName && formik.errors.contactName
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="contactLastName"
                    name="contactLastName"
                    label="Contact Last Name"
                    value={formik.values.contactLastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.contactLastName &&
                      Boolean(formik.errors.contactLastName)
                    }
                    helperText={
                      formik.touched.contactLastName &&
                      formik.errors.contactLastName
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="city"
                    name="city"
                    label="City *"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    helperText={formik.touched.city && formik.errors.city}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="state"
                    name="state"
                    label="State *"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    error={formik.touched.state && Boolean(formik.errors.state)}
                    helperText={formik.touched.state && formik.errors.state}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel id="country-label">Country *</InputLabel>
                    <Select
                      labelId="country-label"
                      id="country"
                      name="country"
                      value={formik.values.country}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.country && Boolean(formik.errors.country)
                      }
                    >
                      <MenuItem value="US">US</MenuItem>
                      <MenuItem value="CAN">CAN</MenuItem>
                      <MenuItem value="MEX">MEX</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="zipCode"
                    name="zipCode"
                    label="Zip Code"
                    value={formik.values.zipCode}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.zipCode && Boolean(formik.errors.zipCode)
                    }
                    helperText={formik.touched.zipCode && formik.errors.zipCode}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="website"
                    name="website"
                    label="Website"
                    value={formik.values.website}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.website && Boolean(formik.errors.website)
                    }
                    helperText={formik.touched.website && formik.errors.website}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="contactNumber"
                    name="contactNumber"
                    label="Contact Number"
                    value={formik.values.contactNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.contactNumber &&
                      Boolean(formik.errors.contactNumber)
                    }
                    helperText={
                      formik.touched.contactNumber &&
                      formik.errors.contactNumber
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="contactEmail"
                    name="contactEmail"
                    label="Contact Email"
                    value={formik.values.contactEmail}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.contactEmail &&
                      Boolean(formik.errors.contactEmail)
                    }
                    helperText={
                      formik.touched.contactEmail && formik.errors.contactEmail
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="secondaryEmail"
                    name="secondaryEmail"
                    label="Secondary Email"
                    value={formik.values.secondaryEmail}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.secondaryEmail &&
                      Boolean(formik.errors.secondaryEmail)
                    }
                    helperText={
                      formik.touched.secondaryEmail &&
                      formik.errors.secondaryEmail
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="APName"
                    name="APName"
                    label="AP Name"
                    value={formik.values.APName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.APName && Boolean(formik.errors.APName)
                    }
                    helperText={formik.touched.APName && formik.errors.APName}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="APNumber"
                    name="APNumber"
                    label="AP Number"
                    value={formik.values.APNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.APNumber && Boolean(formik.errors.APNumber)
                    }
                    helperText={
                      formik.touched.APNumber && formik.errors.APNumber
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="APEmail"
                    name="APEmail"
                    label="AP Email"
                    value={formik.values.APEmail}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.APEmail && Boolean(formik.errors.APEmail)
                    }
                    helperText={formik.touched.APEmail && formik.errors.APEmail}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="creditLimit"
                    name="creditLimit"
                    label="Credit Limit(no commas)"
                    value={formik.values.creditLimit}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.creditLimit &&
                      Boolean(formik.errors.creditLimit)
                    }
                    helperText={
                      formik.touched.creditLimit && formik.errors.creditLimit
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="commodity"
                    name="commodity"
                    label="Commodity"
                    value={formik.values.commodity}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.commodity &&
                      Boolean(formik.errors.commodity)
                    }
                    helperText={
                      formik.touched.commodity && formik.errors.commodity
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="equipmentType"
                    name="equipmentType"
                    label="Equipment Type"
                    value={formik.values.equipmentType}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.equipmentType &&
                      Boolean(formik.errors.equipmentType)
                    }
                    helperText={
                      formik.touched.equipmentType &&
                      formik.errors.equipmentType
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="dateSetup"
                    name="dateSetup"
                    label="Date Setup"
                    type="date"
                    value={formik.values.dateSetup}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.dateSetup &&
                      Boolean(formik.errors.dateSetup)
                    }
                    helperText={
                      formik.touched.dateSetup && formik.errors.dateSetup
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="firstLoadMoved"
                    name="firstLoadMoved"
                    label="First Load Moved"
                    type="date"
                    value={formik.values.firstLoadMoved}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.firstLoadMoved &&
                      Boolean(formik.errors.firstLoadMoved)
                    }
                    helperText={
                      formik.touched.firstLoadMoved &&
                      formik.errors.firstLoadMoved
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="lastLoadMoved"
                    name="lastLoadMoved"
                    label="Last Load Moved"
                    type="date"
                    value={formik.values.lastLoadMoved}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.lastLoadMoved &&
                      Boolean(formik.errors.lastLoadMoved)
                    }
                    helperText={
                      formik.touched.lastLoadMoved &&
                      formik.errors.lastLoadMoved
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="transferDate"
                    name="transferDate"
                    label="Transfer Date"
                    type="date"
                    value={formik.values.transferDate}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.transferDate &&
                      Boolean(formik.errors.transferDate)
                    }
                    helperText={
                      formik.touched.transferDate && formik.errors.transferDate
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                {user.role !== "agent" && (
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      id="csNotes"
                      name="csNotes"
                      label="CS Notes"
                      value={formik.values.csNotes}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.csNotes && Boolean(formik.errors.csNotes)
                      }
                      helperText={
                        formik.touched.csNotes && formik.errors.csNotes
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="nextStep"
                    name="nextStep"
                    label="Next Step"
                    value={formik.values.nextStep}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.nextStep && Boolean(formik.errors.nextStep)
                    }
                    helperText={
                      formik.touched.nextStep && formik.errors.nextStep
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    id="title"
                    name="title"
                    label="Title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                  />
                </Grid>
                <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', }}>
                  <FormGroup>
                    <FormControlLabel 
                      control={
                        <Checkbox 
                          checked={formik.values.callTomorrow}
                          onChange={handleCallTomorrowChange}
                        />
                      }
                      label="Call Tomorrow"
                      sx={{ width: '25%' }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.DNA.status}
                          onChange={handleDNAStatusChange}
                        />
                      }
                      label="Do Not Annoy"
                      sx={{ width: "25%" }}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    id="brokerNotes"
                    name="brokerNotes"
                    label="Broker Notes"
                    value={formik.values.brokerNotes}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.brokerNotes &&
                      Boolean(formik.errors.brokerNotes)
                    }
                    helperText={
                      formik.touched.brokerNotes && formik.errors.brokerNotes
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  {formik.values.DNA.status && (
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      id="DNANotes"
                      name="DNANotes"
                      label="DNA Notes"
                      value={formik.values.DNA.notes}
                      onChange={handleDNANotesChange}
                    />
                  )}
                </Grid>
                {isPool && (
                  <Grid item xs={6}>
                    <FormGroup>
                      <InputLabel>Categories</InputLabel>
                      {categories.map((category) => (
                        <FormControlLabel
                          key={category.name}
                          control={
                            <Checkbox
                              checked={
                                !!selectedPoolCategories.includes(category._id)
                              }
                              onChange={() =>
                                handlePoolCategoryCheckbox(category._id)
                              }
                            />
                          }
                          label={category.name}
                        />
                      ))}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedPoolCategories.length === 0}
                            disabled
                          />
                        }
                        label="Default"
                      />
                    </FormGroup>
                  </Grid>
                )}
              </Grid>
            </form>
          </Container>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={formik.handleSubmit}
          >
            {mode === "create" ? "Submit" : "Update"}
          </Button>
          <Button onClick={closeForm} variant="contained" color="error">
            Cancel
          </Button>
        </DialogActions>
        <Box textAlign="center">
          {alert && (
            <Alert
              severity={alert.type}
              onClose={handleAlertClose}
              sx={{ mb: 1 }}
            >
              {alert.message}
            </Alert>
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default AddLeadForm;
