import { useEffect, useMemo, useState } from "react";
import { useGetAllUsersMonthlyStatsByMonthQuery } from "state/managementApi";
import ktb from '../../assets/ktbwhite.png';
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import ValueGraph from "components/tools/ValueGraph";
import CircularProgress from "@mui/material/CircularProgress";

const today = new Date();
const currentMonth = today.getMonth();
const currentYear = today.getFullYear();

const MonthStatsTotalSheet = ({ agents, theme, currentDayStats }) => {
    const [month, setMonth] = useState(currentMonth);
    const [year, setYear] = useState(currentYear);
    const {
        data: monthlyStats = [],
        isLoading: statsLoading,
        isError: statsError,
        refetch: refetchStats
    } = useGetAllUsersMonthlyStatsByMonthQuery({ month: `${currentMonth + 1}-${currentYear}` });

    const sortedMonthlyStats = useMemo(() => {
        return [...monthlyStats].sort((a, b) => {
            const valueA = a.userName || "";
            const valueB = b.userName || "";
            return valueA.localeCompare(valueB);
        })
    }, [monthlyStats]).filter((agentStats) => agents.find((agent) => (agent._id === agentStats.userId && agent.showStats)));

    const weekDaysInCurrentMonth = useMemo(() => {
        // const now = now Date();
        // const year = now.getFullYear();
        // const month = now.getMonth();

        // Get the first and last days of the month
        const firstDay = new Date(year, month, 1);
        const lastDay = new Date(year, month + 1, 0);

        let weekdayCount = 0;

        // Loop through each day of the month
        // TODO: Account for holidays?
        for (let day = firstDay; day <= lastDay; day.setDate(day.getDate() + 1)) {
            const dayOfWeek = day.getDay();
            if (dayOfWeek !== 0 && dayOfWeek !== 6) { // 0 = Sunday, 6 = Saturday
                weekdayCount++;
            }
        };

        return weekdayCount;
    }, [month, year]);

    const totalCurrentDayStats = currentDayStats.length ? currentDayStats.reduce((prev, current) => {
        return {
            dailyNewCalls: prev.dailyNewCalls + current.dailyNewCalls,
            dailyCalls: prev.dailyCalls + current.dailyCalls,
            dailyEmails: prev.dailyEmails + current.dailyEmails,
            dailyListEmails: prev.dailyListEmails + current.dailyListEmails,
            packets: prev.packets + current.packets,
            quotes: prev.quotes + current.quotes,
            loadsBooked: prev.loadsBooked + current.loadsBooked,
            setups: prev.setups + current.setups,
            margin: prev.margin + current.margin
        }
    }) : {};
    // console.log('totalCurrentDayStats')
    // console.log(totalCurrentDayStats)

    const totalMonthlyStats = monthlyStats.length ? monthlyStats.filter((agentStats) => agents.find((agent) => (agent._id === agentStats.userId && agent.showStats))).reduce((prev, current) => {
        return {
            monthlyNewCalls: prev.monthlyNewCalls + current.monthlyNewCalls,
            monthlyCalls: prev.monthlyCalls + current.monthlyCalls,
            monthlyEmails: prev.monthlyEmails + current.monthlyEmails,
            monthlyListEmails: prev.monthlyListEmails + current.monthlyListEmails,
            packets: prev.packets + current.packets,
            quotes: prev.quotes + current.quotes,
            loadsBooked: prev.loadsBooked + current.loadsBooked,
            setups: prev.setups + current.setups,
            margin: prev.margin + current.margin
        };
    }) : {};
    // console.log('totalMonthlyStats')
    // console.log(totalMonthlyStats)

    // TODO: Handle days in month here for cleanliness??
    const totalMonthlyQuota = agents.length ? agents.filter((agent) => agent.showStats).reduce((prev, current) => {
        return {
            newCallGoals: prev.newCallGoals + current.newCallGoals,
            callGoals: prev.callGoals + current.callGoals,
            emailGoals: prev.emailGoals + current.emailGoals,
            dailyListEmailGoals: prev.dailyListEmailGoals + current.dailyListEmailGoals,
            packetGoals: prev.packetGoals + current.packetGoals,
            quoteGoals: prev.quoteGoals + current.quoteGoals,
            loadsBookedGoals: prev.loadsBookedGoals + current.loadsBookedGoals,
            setupGoals: prev.setupGoals + current.setupGoals,
            marginGoals: prev.marginGoals + current.marginGoals
        };
    }) : {};

    useEffect(() => {
     const intervalId = setInterval(() => {
        refetchStats();
     }, 15 * 60 * 1000); // Refresh every 15 minutes

     return () => clearInterval(intervalId);
    }, [refetchStats]);

    const handleTotalStatAndCurrentDaySum = (agent, stat) => {
      const matchedAgentMonthlyStats = sortedMonthlyStats.find((agentStats) => agentStats.userId === agent._id);

      switch (stat) {
        case 'dailyNewCalls':
            return matchedAgentMonthlyStats ? agent.dailyNewCalls + matchedAgentMonthlyStats.monthlyNewCalls : agent.dailyNewCalls
        case 'dailyCalls':
            return matchedAgentMonthlyStats ? agent.dailyCalls + matchedAgentMonthlyStats.monthlyCalls : agent.dailyCalls
        case 'dailyEmails':
            return matchedAgentMonthlyStats ? agent.dailyEmails + matchedAgentMonthlyStats.monthlyEmails : agent.dailyEmails
        case 'dailyListEmails':
            return matchedAgentMonthlyStats ? agent.dailyListEmails + matchedAgentMonthlyStats.monthlyListEmails : agent.dailyListEmails
        case 'packets':
            return matchedAgentMonthlyStats ? agent.packets + matchedAgentMonthlyStats.packets : agent.packets
        case 'quotes':
            return matchedAgentMonthlyStats ? agent.quotes + matchedAgentMonthlyStats.quotes : agent.quotes
        case 'loadsBooked':
            return matchedAgentMonthlyStats ? agent.loadsBooked + matchedAgentMonthlyStats.loadsBooked : agent.loadsBooked
        case 'setups':
            return matchedAgentMonthlyStats ? agent.setups + matchedAgentMonthlyStats.setups : agent.setups
        case 'margin':
            return matchedAgentMonthlyStats ? agent.margin + matchedAgentMonthlyStats.margin : agent.margin

        default:
            break;
    }

    return 0;
    };

    if (statsLoading) return <CircularProgress />

    if (statsError || !agents.length) return <p>Error retrieving stats</p>

    return (
        <Box sx={{ m: '15px 0px' }}>
            {theme.palette.mode === 'dark' ? (
                <Box
                    component='img'
                    sx={{
                        height: '20%',
                        width: '20%',
                        backgroundColor: theme.palette.primary[900],
                        p: '10px',
                        mr: '15px',
                        mb: '15px',
                    }}
                    alt="KTB Logo"
                    src={ktb}
                />
            ) : (
                <Box
                    component="img"
                    sx={{
                        height: '20%',
                        width: '20%',
                        backgroundColor: theme.palette.primary[100],
                        p: '10px',
                        mr: '15px',
                        mb: '15px',
                    }}
                    alt="KTB Logo"
                    src={ktb}
                />
            )}
            <Box sx={{ mb: '15px', display: 'flex', justifyContent: 'space-around' }}>
                <Box sx={{ width: '40%' }}>
                    <Grid
                        container
                        direction="row"
                        sx={{
                            border: '1px solid black'
                        }}
                    >
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', m: '15px 0px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                Total New Calls
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', m: '15px 0px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                Total FU Calls
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', mb: '15px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                {((totalMonthlyStats.monthlyNewCalls || 0) + totalCurrentDayStats.dailyNewCalls) || 0}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', mb: '15px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                {((totalMonthlyStats.monthlyCalls || 0) + totalCurrentDayStats.dailyCalls) || 0}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid
                        container
                        direction="row"
                        sx={{
                            border: '1px solid black'
                        }}
                    >
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', m: '15px 0px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                Total Packets
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', m: '15px 0px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                Total Quotes
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', mb: '15px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                {((totalMonthlyStats.packets || 0) + totalCurrentDayStats.packets) || 0}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', mb: '15px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                {((totalMonthlyStats.quotes || 0) + totalCurrentDayStats.quotes) || 0}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid
                        container
                        direction="row"
                        sx={{
                            border: '1px solid black'
                        }}
                    >
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', m: '15px 0px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                Total Loads
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', m: '15px 0px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                Total Setups
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', mb: '15px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                {((totalMonthlyStats.loadsBooked || 0) + totalCurrentDayStats.loadsBooked) || 0}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'center', mb: '15px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                {((totalMonthlyStats.setups || 0) + totalCurrentDayStats.setups) || 0}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid
                        container
                        direction="row"
                        sx={{
                            border: '1px solid black'
                        }}
                    >
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', m: '15px 0px' }}>
                            <Typography variant="h5" fontWeight="bold">
                                Total Margin
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mb: '15px' }}>
                            <Typography variant="h1" fontWeight="bold">
                                ${((totalMonthlyStats.margin || 0) + totalCurrentDayStats.margin) || 0}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ width: '45%' }}>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography>
                            Monthly Company Quota Progress
                        </Typography>
                    </Box>
                    <Grid
                        container
                        direction="column"
                        sx={{
                            border: '1px solid black',
                            p: '10px'
                        }}
                    >
                        {/* <Box sx={{ mb: '25px' }}>
                            <ValueGraph value={(totalMonthlyStats.margin + totalCurrentDayStats.margin) || 0} valueGoal={(totalMonthlyQuota.marginGoals * weekDaysInCurrentMonth) || 0} label="Margin" />
                        </Box>
                        <Box sx={{ mb: '25px' }}>
                            <ValueGraph value={((totalMonthlyStats.monthlyCalls + totalMonthlyStats.monthlyNewCalls) + (totalCurrentDayStats.dailyCalls + totalCurrentDayStats.dailyNewCalls)) || 0} valueGoal={(totalMonthlyQuota.callGoals + totalMonthlyQuota.newCallGoals) * weekDaysInCurrentMonth || 0} label="Calls" />
                        </Box>
                        <Box sx={{ mb: '25px' }}>
                            <ValueGraph value={((totalMonthlyStats.monthlyEmails + totalMonthlyStats.monthlyListEmails) + (totalCurrentDayStats.dailyEmails + totalCurrentDayStats.dailyListEmails)) || 0} valueGoal={(totalMonthlyQuota.emailGoals + totalMonthlyQuota.dailyListEmailGoals) * weekDaysInCurrentMonth || 0} label="Emails" />
                        </Box>
                        <Box sx={{ mb: '25px' }}>
                            <ValueGraph value={(totalMonthlyStats.packets + totalCurrentDayStats.packets) || 0} valueGoal={(totalMonthlyQuota.packetGoals * weekDaysInCurrentMonth) || 0} label="Packets" />
                        </Box>
                        <Box sx={{ mb: '25px' }}>
                            <ValueGraph value={(totalMonthlyStats.loadsBooked + totalCurrentDayStats.loadsBooked) || 0} valueGoal={(totalMonthlyQuota.loadsBookedGoals * weekDaysInCurrentMonth) || 0} label="Loads Booked" />
                        </Box>
                        <Box>
                            <ValueGraph value={(totalMonthlyStats.setups + totalCurrentDayStats.setups) || 0} valueGoal={(totalMonthlyQuota.setupGoals * weekDaysInCurrentMonth) || 0} label="Setups" />
                        </Box> */}
                        <Box sx={{ mb: '25px' }}>
                            <ValueGraph value={(totalMonthlyStats.margin || 0) + totalCurrentDayStats.margin} valueGoal={(totalMonthlyQuota.marginGoals * weekDaysInCurrentMonth) || 0} label="Margin" />
                        </Box>
                        <Box sx={{ mb: '25px' }}>
                            <ValueGraph value={(((totalMonthlyStats.monthlyCalls) + (totalMonthlyStats.monthlyNewCalls)) || 0) + (totalCurrentDayStats.dailyNewCalls + totalCurrentDayStats.dailyCalls)} valueGoal={(totalMonthlyQuota.callGoals + totalMonthlyQuota.newCallGoals) * weekDaysInCurrentMonth || 0} label="Calls" />
                        </Box>
                        <Box sx={{ mb: '25px' }}>
                            <ValueGraph value={(((totalMonthlyStats.monthlyEmails) + (totalMonthlyStats.monthlyListEmails)) || 0) + (totalCurrentDayStats.dailyEmails + totalCurrentDayStats.dailyListEmails)} valueGoal={(totalMonthlyQuota.emailGoals + totalMonthlyQuota.dailyListEmailGoals) * weekDaysInCurrentMonth || 0} label="Emails" />
                        </Box>
                        <Box sx={{ mb: '25px' }}>
                            <ValueGraph value={(totalMonthlyStats.packets || 0) + totalCurrentDayStats.packets} valueGoal={(totalMonthlyQuota.packetGoals * weekDaysInCurrentMonth) || 0} label="Packets" />
                        </Box>
                        <Box sx={{ mb: '25px' }}>
                            <ValueGraph value={(totalMonthlyStats.loadsBooked || 0) + totalCurrentDayStats.loadsBooked} valueGoal={(totalMonthlyQuota.loadsBookedGoals * weekDaysInCurrentMonth) || 0} label="Loads Booked" />
                        </Box>
                        <Box>
                            <ValueGraph value={(totalMonthlyStats.setups || 0) + totalCurrentDayStats.setups} valueGoal={(totalMonthlyQuota.setupGoals * weekDaysInCurrentMonth) || 0} label="Setups" />
                        </Box>
                    </Grid>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                <Box sx={{ width: '78%' }}>
                    <TableContainer component={Paper} sx={{ overflow: 'auto', maxHeight: 570 }}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        sx={{
                                            width: '10%',
                                            position: 'sticky',
                                            left: 0,
                                            backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary[900] : theme.palette.grey[100]
                                        }}
                                    />
                                    <TableCell sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary[900] : theme.palette.grey[100] }}>
                                        Margin
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary[900] : theme.palette.grey[100] }}>
                                        New Calls
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary[900] : theme.palette.grey[100] }}>
                                        FU Calls
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary[900] : theme.palette.grey[100] }}>
                                        Packets
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary[900] : theme.palette.grey[100] }}>
                                        Quotes
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary[900] : theme.palette.grey[100] }}>
                                        FU Emails
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary[900] : theme.palette.grey[100] }}>
                                        List Emails
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary[900] : theme.palette.grey[100] }}>
                                        Loads Booked
                                    </TableCell>
                                    <TableCell sx={{ backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary[900] : theme.palette.grey[100] }}>
                                        Setups
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {agents.filter((agent) => agent.showStats).map((agent, index) => (
                                    <TableRow hover key={index} sx={{ width: 700 }}>
                                        <TableCell
                                            padding="checkbox"
                                            size="medium"
                                            sx={{
                                                width: '10%',
                                                position: 'sticky',
                                                left: 0,
                                                backgroundColor: theme.palette.mode === 'dark' ? theme.palette.primary[900] : theme.palette.grey[100]
                                            }}
                                        >
                                            <Typography sx={{ textDecoration: 'underline', }}>
                                                {agent.name || ''}
                                            </Typography>
                                        </TableCell>
                                        <TableCell padding="none" align="center">
                                            ${handleTotalStatAndCurrentDaySum(agent, "margin")}
                                        </TableCell>
                                        <TableCell padding="none" align="center">
                                            {handleTotalStatAndCurrentDaySum(agent, "dailyNewCalls")}
                                        </TableCell>
                                        <TableCell padding="none" align="center">
                                            {handleTotalStatAndCurrentDaySum(agent, "dailyCalls")}
                                        </TableCell>
                                        <TableCell padding="none" align="center">
                                            {handleTotalStatAndCurrentDaySum(agent, "packets")}
                                        </TableCell>
                                        <TableCell padding="none" align="center">
                                            {handleTotalStatAndCurrentDaySum(agent, "quotes")}
                                        </TableCell>
                                        <TableCell padding="none" align="center">
                                            {handleTotalStatAndCurrentDaySum(agent, "dailyEmails")}
                                        </TableCell>
                                        <TableCell padding="none" align="center">
                                            {handleTotalStatAndCurrentDaySum(agent, "dailyListEmails")}
                                        </TableCell>
                                        <TableCell padding="none" align="center">
                                            {handleTotalStatAndCurrentDaySum(agent, "loadsBooked")}
                                        </TableCell>
                                        <TableCell padding="none" align="center">
                                            {handleTotalStatAndCurrentDaySum(agent, "setups")}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Box>
        </Box>
    )
};

export default MonthStatsTotalSheet;