import { api } from "./api";

export const clientApi = api.injectEndpoints({
    endpoints: (build) => ({
        //! Quote API Calls
        getCustomerQuotes: build.query({
            query: ({
                customerPortalUserId,
                page,
                limit,
                sortBy,
                sortOrder,
                search,
                startDate,
                endDate
            }) => 
                `/client/customerPortal/quotes?${customerPortalUserId && `customerPortalUserId=${customerPortalUserId}&`}page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}${search && `&search=${search}`}${startDate && `&startDate=${startDate}`}${endDate && `&endDate=${endDate}`}`,
            providesTags: ["Client", "ClientQuotes"],
        }),
        clientGetAllQuotes: build.query({
            query: ({
                customerPortalUserId,
                page,
                limit,
                sortBy,
                sortOrder,
                search,
                startDate,
                endDate
            }) => 
                `/client/customerPortal/allQuotes?${customerPortalUserId && `customerPortalUserId=${customerPortalUserId}&`}page=${page}&limit=${limit}&sortBy=${sortBy}&sortOrder=${sortOrder}${search && `&search=${search}`}${startDate && `&startDate=${startDate}`}${endDate && `&endDate=${endDate}`}`,
            providesTags: ["Client", "ClientQuotes"],
        }),
    }),
});

export const {
    useGetCustomerQuotesQuery,
    useClientGetAllQuotesQuery,
} = clientApi;