import { Close } from "@mui/icons-material";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, /* FormControlLabel, FormGroup, */ Grid, List, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";
// import BatchProcessor from "components/helpers/BatchProcessor";
import { hoverStyle } from "components/helpers/MediaQueries";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useGetAgentsQuery } from "state/managementApi";
import { useAddMemberToOperationsTeamMutation, useRemoveMemberFromOperationsTeamMutation } from "state/operationsApi";
// TODO: FIX ALL
// Transfer list handlers
function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
};
function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
};

const AddOperationsTeamMemberMenu = ({ onClose, currentOpsMembers }) => {
    // List selection states
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [leftIds, setLeftIds] = useState([]);
    const [right, setRight] = useState(currentOpsMembers.sort((a, b) => {
        const valueA = a.name || "";
        const valueB = b.name || "";
        return valueA.localeCompare(valueB);
    }));
    const [rightIds, setRightIds] = useState(currentOpsMembers.map((member) => member._id));
    
    const [currentOpIds, setCurrentOpIds] = useState(currentOpsMembers.map((member) => member._id)); // State to manage and compare changes made

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const { 
        data: agents = [], 
        // error: agentsError,
        // isLoading: agentsLoading, 
        isSuccess 

    } = useGetAgentsQuery();
    const [addToOpps] = useAddMemberToOperationsTeamMutation();
    const [removeFromOpps] = useRemoveMemberFromOperationsTeamMutation();

    const { enqueueSnackbar } = useSnackbar();

    const sortedAgents = [...agents].sort((a, b) => {
        const valueA = a.name || "";
        const valueB = b.name || "";
        return valueA.localeCompare(valueB);
    }).filter((agent) => !right.find((member) => member.name === agent.name));

    useEffect(() => {
        // If data successfully fetched
        if (agents && agents.length && isSuccess) {
            setLeft(sortedAgents);
            setLeftIds(sortedAgents.map((agent) => agent._id));
        }
    }, [agents, isSuccess]);
    
    // Handlers
    const handleToggle = (value) => {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      };

      setChecked(newChecked);
    };

    // Handle sorting sides alphabetically
    const handleSorting = (list) => {
      const sortedList = [...list].sort((a, b) => {
        const valueA = a.name || "";
        const valueB = b.name || "";
        return valueA.localeCompare(valueB);
      });

      return sortedList;
    };

    // Handle adding all agents to ops side
    const handleAllRight = () => {
        // Set Object States
        setRight(right.concat(left));
        setLeft([]);

        // Set Id States
        setRightIds(rightIds.concat(leftIds));
        setLeftIds([]);
    };
    
    // Handle adding agent to assigned side
    const handleCheckedRight = () => {
        const newRight = right.concat(leftChecked);
        const newRightIds = leftChecked.map((left) => left._id);

        // Set Object States
        setRight(handleSorting(newRight));
        setLeft(not(left, leftChecked));

        // Set Id States
        setRightIds(rightIds.concat(newRightIds));
        setLeftIds((prev) => prev.filter((id) => !newRightIds.includes(id)));
        
        setChecked(not(checked, leftChecked));
    };

    // Handle removing category from assigned side
    const handleCheckedLeft = () => {
        const newLeft = left.concat(rightChecked);
        const newLeftIds = rightChecked.map((right) => right._id);
        
        // Set Object States
        setLeft(handleSorting(newLeft));
        setRight(not(right, rightChecked));

        // Set Id States
        setLeftIds(leftIds.concat(newLeftIds));
        setRightIds((prev) => prev.filter((id) => !newLeftIds.includes(id)));
        
        setChecked(not(checked, rightChecked));
    };

    // Handle adding all categories to unassigned side
    const handleAllLeft = () => {
        // Set Object States
        setLeft(left.concat(right));
        setRight([]);

        // Set Id States
        setLeftIds(leftIds.concat(rightIds));
        setRightIds([]);
    };

    // Batch handlers
    const handleBatchAdding = async (agentIds) => {
        try {
            for (let i = 0; i < agentIds.length; i++) {
                await addToOpps(agentIds[i]);
            }
        } catch (error) {
            console.error("Error adding agent to operations team:", error);
        };
    };

    const handleBatchRemoving = async (agentIds) => {
        try {
            for (let i = 0; i < agentIds.length; i++) {
                await removeFromOpps(agentIds[i]);
            }
        } catch (error) {
            console.error("Error removing agent from operations team:", error);
        }
    };

    const handleConfirmation = async () => {

        const newMemberIds = rightIds.filter((id) => !currentOpIds.includes(id));
        const removedMemberIds = leftIds.filter((id) => currentOpIds.includes(id));


        if (newMemberIds.length) {
            await handleBatchAdding(newMemberIds)
                .then(() => {
                    enqueueSnackbar(`${newMemberIds.length} ${newMemberIds.length > 1 ? "agents" : "agent"} added to Operations Team`);
                });
        };

        if (removedMemberIds.length) {
            await handleBatchRemoving(removedMemberIds)
                .then(() => {
                    enqueueSnackbar(`${removedMemberIds.length} ${removedMemberIds.length > 1 ? "agents" : "agent"} removed from Operations Team`);
                });
        };

        onClose();
    };

    const customList = (items) => (
        <Paper sx={{ width: 250, height: 380, overflow: 'auto', }}>
            <List dense component="div" role="list">
                {items.map((agent, index) => (
                    <ListItemButton
                        key={index}
                        role="listitem"
                        disableRipple
                        onClick={() => handleToggle(agent)}
                    >
                        <ListItemIcon>
                            <Checkbox 
                                checked={checked.indexOf(agent) !== -1}
                                tabIndex={-1}
                                disableRipple
                            />
                        </ListItemIcon>
                        <ListItemText primary={agent.name}/>
                    </ListItemButton>
                ))}
            </List>
        </Paper>
    )

    

    if (!agents) return;
    
    return (
        <Dialog open onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography>Add Operations Member</Typography>
                    <Close sx={hoverStyle} onClick={onClose}/>
                </div>
                <Divider sx={{ mb: '15px', }}/>
            </DialogTitle>
            <DialogContent sx={{ pb: '0px', }}>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item>
                        <Typography sx={{ textAlign: 'center', }}>Agents</Typography>
                        {customList(left)}
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" alignItems="center">
                            <Button
                                sx={{ my: 0.5, }}
                                variant="outlined"
                                size="small"
                                onClick={handleAllRight}
                                disabled={left.length === 0}
                            >
                                ≫
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleCheckedRight}
                                disabled={leftChecked.length === 0}
                            >
                                &gt;
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleCheckedLeft}
                                disabled={rightChecked.length === 0}
                            >
                                &lt;
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleAllLeft}
                                disabled={right.length === 0}
                            >
                                ≪
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography sx={{ textAlign: 'center', }}>Operations Team</Typography>
                        {customList(right)}
                    </Grid>
                </Grid>
                <Divider sx={{ mt: '15px', }}/>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', }}>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        // disabled={}
                        onClick={handleConfirmation}
                    >
                        Confirm
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default AddOperationsTeamMemberMenu;