import React, { useState } from "react";
import ShowChart from "@mui/icons-material/ShowChart";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import UtilityBar from "../utilitybar/UtilityBar";
// import PoolLeadsList from "components/pool/PoolLeadsList";
import LeadsTabs from "components/leads/LeadsTabs";
import AgentDailyStats from "components/agents/AgentsDailyStats";
import AgentsList from "../agents/AgentsList";
import DeveloperBoard from "components/developers/DeveloperBoard";
import ManageLeadSearch from "manager/leadSearch/ManageLeadSearch";
import {
  useUpdateLeadCustomersMutation,
  useUpdateLeadStatusesMutation,
} from "state/managementApi";
// import CustomersList from "components/customers/CustomersList";
import UpdateStatsForm from "components/forms/UpdateStatsForm";
import ActivitesCSVUploader from "components/tools/ActivitesCSVUploader";
import PoolCategoriesTree from "components/pool/PoolCategoriesTree";
import DailyActivitiesList from "components/activities/DailyActivitiesList";
import QuoteList from "components/quotes/QuoteList";
import ErrorLogs from "components/developers/ErrorLogs";
import TextLocateForm from "components/dispatch/board/TextLocateForm";
import GetCarrierInfoForm from "components/carriers/forms/GetCarrierInfoForm";
import StatHistory from "components/stats/StatHistory";
import { useSendQuoteToCustomerPortalMutation } from "state/salesApi";

const UserDashboard = ({ userRole, superadminViewRole }) => {
  const [showLeads, setShowLeads] = useState(false);
  const [updateStatsOpen, setUpdateStatsOpen] = useState(false);
  const [dailyActivitiesOpen, setDailyActivitiesOpen] = useState(false);
  const [quotesOpen, setQuotesOpen] = useState(false);
  const [carrierSearchOpen, setCarrierSearchOpen] = useState(false);
  const [statHistoryOpen, setStatHistoryOpen] = useState(false);
  const [showDeveloperBoard, setShowDeveloperBoard] = useState(false);
  const [showErrorLogs, setShowErrorLogs] = useState(false);
  const [displayEmployees, setDisplayEmployees] = useState(false);
  const [displayPoolLeads, setDisplayPoolLeads] = useState(false);
  const [displayCustomers, setDisplayCustomers] = useState(false);
  // const [displayUnassigned, setDisplayUnassigned] = useState(false);
  const [displayLeadSearch, setDisplayLeadSearch] = useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
  const [updateCustomerStatusOpen, setUpdateCustomerStatusOpen] = useState(false);
  const [sendQuoteToCustomerPortalOpen, setSendQuoteToCustomerPortalOpen] = useState(false);
  const [uploadActivitesCSVOpen, setUploadActivitesCSVOpen] = useState(false);

  const [updateLeadStatuses] = useUpdateLeadStatusesMutation();
  const [updateCustomerStatuses] = useUpdateLeadCustomersMutation();
  const [sendQuote] = useSendQuoteToCustomerPortalMutation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [mobileStatsOpen, setMobileStatsOpen] = useState(false);

  // Togglers
  const toggleDailyActivities = () => setDailyActivitiesOpen((prev) => !prev);
  const toggleQuotesList = () => setQuotesOpen(prev => !prev);
  const toggleCarrierSearch = () => setCarrierSearchOpen(prev => !prev);
  const toggleStatHistory = () => setStatHistoryOpen(prev => !prev);

  const handleToggleLeads = () => {
    setShowLeads(!showLeads);
  };

  const handleStatUpdate = () => {
    setUpdateStatsOpen(!updateStatsOpen);
  };

  const handleToggleEmployees = () => {
    // setDisplayEmployees((prev) => !prev)
    if (!displayEmployees) {
      setDisplayEmployees(true);
      setDisplayPoolLeads(false);
      setDisplayCustomers(false);
      setDisplayLeadSearch(false);
    } else {
      setDisplayEmployees(false);
    }
  };

  const handleTogglePoolLeads = () => {
    // setDisplayPoolLeads((prev) => !prev);
    if (!displayPoolLeads) {
      setDisplayEmployees(false);
      setDisplayPoolLeads(true);
      setDisplayCustomers(false);
      setDisplayLeadSearch(false);
    } else {
      setDisplayPoolLeads(false);
    }
  };

  // const handleToggleUnassigned = () => {
  //   setDisplayUnassigned((prev) => !prev);
  // };

  const handleToggleLeadSearch = () => {
    if (!displayLeadSearch) {
      setDisplayEmployees(false);
      setDisplayPoolLeads(false);
      setDisplayCustomers(false);
      setDisplayLeadSearch(true);
    } else {
      setDisplayLeadSearch(false);
    }
  };

  const handleToggleDeveloperBoard = () => {
    setShowDeveloperBoard(!showDeveloperBoard);
  };

  const handleToggleErrorLogs = () => {
    setShowErrorLogs(!showErrorLogs);
  };

  const handleUpdateConfirmation = async () => {
    await updateLeadStatuses();
    setUpdateDialogOpen(false);
  };

  const handleUpdateCustomersConfirmation = async () => {
    await updateCustomerStatuses();
    setUpdateCustomerStatusOpen(false);
  };

  const handleSendQuote = async () => {
    try {
      await sendQuote({ quoteId: "66464bd107436d0295204603", status: "cancelled" })
        .then(() => {
          setSendQuoteToCustomerPortalOpen(false);
        })
    } catch (error) {
      console.error('Error sending quote:', error);
    }
  };

  const handleMobileStatsMenuDisplay = () => {
    setMobileStatsOpen((prev) => !prev);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <UtilityBar
          handleToggleLeads={handleToggleLeads}
          handleToggleDeveloperBoard={handleToggleDeveloperBoard}
          handleToggleErrorLogs={handleToggleErrorLogs}
        />
        {isMobile && (
          <IconButton onClick={handleMobileStatsMenuDisplay}>
            <ShowChart />
          </IconButton>
        )}
      </Grid>
      <Grid item xs={12}>
        {(userRole === "agent" ||
          (superadminViewRole === "agent" && userRole === "superadmin")) && (
            <Box sx={{ width: "100%" }}>
              <AgentDailyStats
                handleToggleLeads={handleToggleLeads}
                isMobile={isMobile}
                mobileStatsOpen={mobileStatsOpen}
              />
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mb: '10px', }}
                    onClick={toggleQuotesList}
                  >
                    My Quotes
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ ml: "15px", mb: "10px" }}
                    onClick={handleStatUpdate}
                  >
                    Update Stats
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ ml: "15px", mb: "10px" }}
                    onClick={toggleDailyActivities}
                  >
                    Daily Activities
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ ml: '15px', mb: '10px', }}
                    onClick={toggleCarrierSearch}
                  >
                    Search Carrier by MC
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ ml: '15px', mb: '10px', }}
                    onClick={toggleStatHistory}
                  >
                    Stats History
                  </Button>
                </Grid>
              </Grid>
              {/* <Box>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mb: '10px', }}
                  onClick={toggleQuotesList}
                >
                  My Quotes
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ ml: "15px", mb: "10px" }}
                  onClick={handleStatUpdate}
                >
                  Update Stats
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ ml: "15px", mb: "10px" }}
                  onClick={toggleDailyActivities}
                >
                  Daily Activities
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ ml: '15px', mb: '10px', }}
                  onClick={toggleCarrierSearch}
                >
                  Search Carrier by MC
                </Button>
              </Box> */}
            </Box>
          )}
        {(userRole === "dispatch" ||
          (superadminViewRole === "dispatch" && userRole === "superadmin")) && (
            <Box sx={{ width: '100%', }}>
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mb: '10px', }}
                  onClick={toggleQuotesList}
                >
                  My Quotes
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ ml: '15px', mb: '10px', }}
                  onClick={toggleCarrierSearch}
                >
                  Search Carrier by MC
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ ml: '15px', mb: '10px', }}
                    onClick={toggleStatHistory}
                  >
                    Stats History
                  </Button>
              </Box>
            </Box>
          )}
        {superadminViewRole === "superadmin" && userRole === "superadmin" && (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>This is the Superadmin dashboard.</Typography>
            <Box>
              <Button
                variant="contained"
                onClick={() => setUpdateDialogOpen(true)}
              >
                Update Lead Statuses
              </Button>
              <Button
                sx={{ ml: "5px" }}
                variant="contained"
                onClick={() => setUpdateCustomerStatusOpen(true)}
              >
                Update Customer Statuses
              </Button>
              <Button
                sx={{ ml: "5px" }}
                variant="contained"
                onClick={() => setUploadActivitesCSVOpen(true)}
              >
                Upload Activities CSV
              </Button>
              <Button
                sx={{ ml: "5px" }}
                variant="contained"
                onClick={() => setSendQuoteToCustomerPortalOpen(true)}
              >
                Send Quote to Customer Portal
              </Button>
            </Box>
          </Box>
        )}
        {userRole === "manager" ||
          userRole === "admin" ||
          (userRole === "superadmin" &&
            superadminViewRole !== "agent" &&
            superadminViewRole !== "dispatch") ? (
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  mt: 1,
                  mr: { xs: 0, sm: 1 },
                  mb: { xs: 1, sm: 0 },
                }}
                onClick={toggleQuotesList}
              >
                My Quotes
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleToggleEmployees}
                sx={{
                  mt: 1,
                  mr: { xs: 0, sm: 1 },
                  marginBottom: { xs: 1, sm: 0 },
                }}
              >
                {displayEmployees ? "Hide Employees" : "Employees"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleTogglePoolLeads}
                sx={{
                  mt: 1,
                  mr: { xs: 0, sm: 1 },
                  marginBottom: { xs: 1, sm: 0 },
                }}
              >
                {displayPoolLeads ? "Hide Pool Leads" : "Pool Leads"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleToggleLeadSearch}
                sx={{
                  mt: 1,
                  mr: { xs: 0, sm: 1 },
                  marginBottom: { xs: 1, sm: 0 },
                }}
              >
                {displayLeadSearch ? "Hide Lead Search" : "Lead Search"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  mt: 1,
                  mr: { xs: 0, sm: 1 },
                  marginBottom: { xs: 1, sm: 0 },
                }}
                onClick={toggleCarrierSearch}
              >
                Search Carrier by MC
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  mt: 1,
                  mr: { xs: 0, sm: 1 },
                  marginBottom: { xs: 1, sm: 0 },
                }}
                onClick={toggleStatHistory}
              >
                Stats History
              </Button>
              {displayEmployees && <AgentsList />}
              {displayPoolLeads && <PoolCategoriesTree />}
              {displayLeadSearch && (
                <Box sx={{ marginTop: "1rem" }}>
                  <ManageLeadSearch />
                </Box>
              )}
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        {showLeads && <LeadsTabs />}
        {updateStatsOpen && <UpdateStatsForm onClose={handleStatUpdate} />}
        {dailyActivitiesOpen && <DailyActivitiesList />}
        {quotesOpen && <QuoteList />}
        {carrierSearchOpen && <GetCarrierInfoForm onClose={toggleCarrierSearch}/>}
        {statHistoryOpen && <StatHistory />}
        {showDeveloperBoard && (
          <DeveloperBoard closeDeveloperBoard={handleToggleDeveloperBoard} />
        )}
        {showErrorLogs && (
          <ErrorLogs closeErrorLogs={handleToggleErrorLogs} />
        )}
      </Grid>
      <Dialog
        open={updateDialogOpen}
        onClose={() => setUpdateDialogOpen(false)}
      >
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to update?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setUpdateDialogOpen(false)}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleUpdateConfirmation()}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={updateCustomerStatusOpen}
        onClose={() => setUpdateCustomerStatusOpen(false)}
      >
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to update?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setUpdateCustomerStatusOpen(false)}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleUpdateCustomersConfirmation()}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={uploadActivitesCSVOpen}
        onClose={() => setUploadActivitesCSVOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <ActivitesCSVUploader
          onClose={() => setUploadActivitesCSVOpen(false)}
        />
      </Dialog>
      <Dialog
        open={sendQuoteToCustomerPortalOpen}
        onClose={() => setSendQuoteToCustomerPortalOpen(false)}
      >
        <DialogTitle>Send Quote</DialogTitle>
        <DialogContent>Send Quote</DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleSendQuote()}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default UserDashboard;
