import { Close } from "@mui/icons-material";
import { Alert, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, List, ListItemButton, ListItemIcon, ListItemText, Paper, Typography } from "@mui/material";
import { hoverStyle } from "components/helpers/MediaQueries";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useAddLeadToCategoryMutation, useGetCategoriesByIdsQuery, useRemoveCategoryFromLeadsMutation } from "state/managementApi";

// Transfer list handlers
function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
};
function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
};

const MovePoolLeadMenu = ({ open, onClose, onSubmit, leadName, leadIds, categories, leadCategories }) => {
    const [checked, setChecked] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);
    const [categoriesToAdd, setCategoriesToAdd] = useState([]);
    const [categoriesToRemove, setCategoriesToRemove] = useState([]);

    const { data: categoriesData = [], error: leadCategoriesError, isLoading: leadCategoriesLoading, refetch } = useGetCategoriesByIdsQuery(leadCategories);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const [addLeadsToCategory] = useAddLeadToCategoryMutation();
    const [removeLeadsFromCategory] = useRemoveCategoryFromLeadsMutation();

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
     if (categoriesData.length) {
        setRight(categoriesData);
        setLeft(categories.filter((category) => !categoriesData.find((categoryData) => categoryData._id === category._id)));
     } else {
        setRight([]);
        setLeft(categories);
     }
    }, [leadCategoriesLoading]);

    useEffect(() => {
        handleCategoryChanges()
    }, [left, right])

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        };

        setChecked(newChecked);
    };

    // Handle adding all categories to assigned side
    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    // Handle adding category to assigned side
    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    // Handle removing category from assigned side
    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    // Handle adding all categories to unassigned side
    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    const customList = (items) => (
        <Paper sx={{ width: 200, height: 230, overflow: 'auto' }}>
            <List dense component="div" role="list">
                {items.map((category, index) => (
                    <ListItemButton
                        key={index}
                        role="listitem"
                        onClick={handleToggle(category)}
                    >
                        <ListItemIcon>
                            <Checkbox 
                                checked={checked.indexOf(category) !== -1}
                                tabIndex={-1}
                                disableRipple
                            />
                        </ListItemIcon>
                        <ListItemText primary={category.name}/>
                    </ListItemButton>
                ))}
            </List>
        </Paper>
    );

    // Handle setting what categories to add and remove
    const handleCategoryChanges = () => {
        setCategoriesToAdd(right.filter((added) => !categoriesData.find((existing) => existing._id === added._id)));
        setCategoriesToRemove(left.filter((removed) => categoriesData.find((existing) => existing._id === removed._id)));
    };

    const handleConfirmation = async () => {
        const addedCategoryIds = categoriesToAdd.map((category) => category._id);
        const removedCategoryIds = categoriesToRemove.map((category) => category._id);

        await handleAddCategories(addedCategoryIds);
        await handleRemoveCategory(removedCategoryIds)
            .then(() => {
                enqueueSnackbar(`${leadName} categories updated`);
                refetch();
                onSubmit();
            });
    };

    const handleAddCategories = async (addedCategoryIds) => {
        if (!addedCategoryIds.length) return
        
        try {
            await addLeadsToCategory({ leadIds, categoryIds: addedCategoryIds });
        } catch (error) {
            console.error("Error adding leads to categories:", error);
        }
    };

    const handleRemoveCategory = async (removedCategoryIds) => {
        if (!removedCategoryIds.length) return
        try {
            await removeLeadsFromCategory({ leadIds, categoryIds: removedCategoryIds });
        } catch (error) {
            console.error("Error removing leads from categories:", error);
        }
    };

    if (leadCategoriesLoading) return (
        <CircularProgress />
    );

    if (leadCategoriesError) return (
        <Alert severity="error">Error Loading Categories</Alert>
    );

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth='sm'>
            <DialogTitle>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h6">Edit Lead Categories</Typography>
                    <Close sx={hoverStyle} onClick={onClose} />
                </div>
                <Divider sx={{ mb: '15px', }}/>
            </DialogTitle>
            <DialogContent sx={{ pb: '0px', }}>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item>
                        <Typography sx={{ textAlign: 'center', }}>Categories</Typography>
                        {customList(left)}
                    </Grid>
                    <Grid item>
                        <Grid container direction="column" alignItems="center">
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleAllRight}
                                disabled={left.length === 0}
                            >
                                ≫
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleCheckedRight}
                                disabled={leftChecked.length === 0}
                            >
                                &gt;
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleCheckedLeft}
                                disabled={rightChecked.length === 0}
                            >
                                &lt;
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant="outlined"
                                size="small"
                                onClick={handleAllLeft}
                                disabled={right.length === 0}
                            >
                                ≪
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Typography sx={{ textAlign: 'center', }}>Assigned Categories</Typography>
                        {customList(right)}
                    </Grid>
                </Grid>
                <Divider sx={{ mt: '15px', }}/>
            </DialogContent>
            {right.length > 10 && (
                <Alert severity="error">Leads Limited to 10 Categories. {right.length} Selected</Alert>
            )}
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between'}}>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={JSON.stringify(right.map((category) => category.name)) === JSON.stringify(categoriesData.map((category) => category.name)) || right.length > 10}
                        onClick={handleConfirmation}
                    >
                        Confirm
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default MovePoolLeadMenu;