import React, { useState } from 'react'
import { Box, Button, CircularProgress, Skeleton, Typography, useTheme } from "@mui/material";
import Header from "components/layout/Header";
import UserDashboard from 'components/dashboards/UserDashboard';
import { useSelector } from 'react-redux';
import { DashboardOutlined } from '@mui/icons-material';

const Dashboard = () => {
  const userData = useSelector((state) => state.user);
  const theme = useTheme();
  const [superadminViewRole, setSuperadminViewRole] = useState('superadmin');
  
  const toggleSuperadminView = () => {
    setSuperadminViewRole((prevRole) => {
      switch (prevRole) {
        case 'agent':
          return 'dispatch';
        case 'dispatch':
          return 'manager';
        case 'manager':
          return 'admin';
        case 'admin':
          return 'superadmin';
        case 'superadmin':
          return 'agent';
        default:
          return 'agent';
      }
    });
  };
  
  if (!userData) return <Skeleton animation="wave" variant='rounded' width='90%' height='70%' sx={{ margin: '1.5rem 2.5rem', padding: 2 }}/>

  return (
    <>
      {!userData.role ? (
          <Skeleton animation="wave" variant='rounded' width='90%' height='70%' sx={{ margin: '1.5rem 2.5rem', padding: 2 }}/>
      ) : (
        <Box>
          <Box m="1.5rem 2.5rem" sx={{ backgroundColor: theme.palette.background.alt, padding: 2, borderRadius: '7px' }}>
            <Box sx={{ display: 'flex', }}>
              <DashboardOutlined sx={{ marginRight: '5px', fontSize: '50px', paddingBottom: '10px' }}/>
              <Header title={`${userData.role.replace(/^[a-z]/, match => match.toUpperCase())} Dashboard`} subtitle={`Logged in as ${userData.name}`}/>
            </Box>
          </Box>
          <Box sx={{ backgroundColor: theme.palette.background.alt, padding: 2, borderRadius: '7px', margin: '0rem 2.5rem' }}>
            <Box sx={{ width: '100%',  }}>
              {userData.role === 'superadmin' && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginBottom: 2 }}>
                  <Button 
                    variant="contained" 
                    sx={{
                      backgroundColor: 'black',
                      color: '#34e718',
                    }} 
                    onClick={toggleSuperadminView}>
                    Toggle Superadmin View
                  </Button>
                  <Typography>{superadminViewRole.toUpperCase()}</Typography>
                </Box>
              )}
              <UserDashboard userRole={userData.role} superadminViewRole={superadminViewRole} />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Dashboard;