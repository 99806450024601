import React from "react";
import { Box } from "@mui/material";
import Header from "components/layout/Header";


const Performance = () => {

  return (
    <Box m="1.5rem 2.5rem">
      <Header
        title="PERFORMANCE PAGE"
        subtitle="Track your Affiliate Sales Performance Here TBD"
      />
      <Box>
        TBD
      </Box>
    </Box>
  );
};

export default Performance;
