import React from 'react'
import Header from 'components/layout/Header';
import { Box } from '@mui/material';

const Transactions = () => {
  
  return (
    <Box m="1.5rem 2.5rem">
      <Header title="TRANSACTIONS" subtitle="TBD" />
      Transaction page TBD
    </Box>
  );
};


export default Transactions