import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { useTheme } from '@mui/material/styles';
import Header from "components/layout/Header";
// import AgentStatsLineGraph from "components/stats/AgentStatsLineGraph";
import { useSelector } from "react-redux";
import { useGetAgentsQuery, /* useGetAllUsersWeekDayStatsByWeekQuery */ } from "state/managementApi";
import { useEffect, useMemo, useState } from "react";
import StatsTable from "manager/stats/StatsTable";
import QuotaSheet from "manager/stats/QuotaSheet";
import MonthStatsTotalSheet from "manager/stats/MonthStatsTotalSheet";
import WeekStatsTotalSheet from "manager/stats/WeekStatsTotalSheet";
import { isWithinBusinessHours } from "components/helpers/CheckBusinessHours";
import { useGetDailyStatDataQuery } from "state/api";
// import { useGetLeaderboardDataQuery } from "state/api";

// const now = new Date();
// const year = now.getFullYear();
// const month = now.getMonth();

const Stats = () => {
    const { data: agents = [], error: agentsError, isLoading: agentsLoading, refetch: agentsRefetch } = useGetAgentsQuery(null, { staleTime: 0 });
    // TODO: Below doesn't account for managers
    const { data: dailyStatData = [], error: dailyStatError, isLoading: dailyStatLoading, refetch: dailyStatRefetch } = useGetDailyStatDataQuery(null, { staleTime: 0 });
    const userData = useSelector((state) => state.user);
    const theme = useTheme();
    const [activeTab, setActiveTab] = useState("statsSheet");
    const [selectedAgent, setSelectedAgent] = useState(/* agents[0] */ null);
    const [displayedAgentName, setDisplayedAgentName] = useState("");

    const sortedAgents = useMemo(() => {
        return [...agents].sort((a, b) => {
            const valueA = a.name || "";
            const valueB = b.name || "";
            return valueA.localeCompare(valueB);
        });
    }, [agents]);

    const sortedDailyStats = useMemo(() => {
        return [...dailyStatData].sort((a, b) => {
            const valueA = a.name || "";
            const valueB = b.name || "";
            return valueA.localeCompare(valueB);
        })
    }, [dailyStatData]);

    // TODO: Refresh times display
    useEffect(() => {
     const intervalId = setInterval(() => {
        if (isWithinBusinessHours()) {
            agentsRefetch();
            dailyStatRefetch();
        }
     }, 15 * 60 * 1000); // Refresh every 15 minutes

     return () => clearInterval(intervalId);
    }, [agentsRefetch, dailyStatRefetch]);


    // Handlers
    // const handleAgentChange = (e) => {
    //     setSelectedAgent(agents.find((agent) => agent.name === e.target.value));
    //     setDisplayedAgentName(agents.find((agent) => agent.name === e.target.value).name);
    // };

    const handleTabChange = (e, newTab) => {
        e.preventDefault();
        setActiveTab(newTab);
    };

    // Styling
    const tabStyles = {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        marginRight: theme.spacing(1),
        borderRadius: '4px',
    };

    if (!userData || dailyStatError || agentsError) return <p>Error retrieving agent statistics</p>

    return (
        <>
            {(agentsLoading || dailyStatLoading) ? (
                <Box m="1.5rem 2.5rem" sx={{ backgroundColor: theme.palette.background.alt, padding: 2 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box m="1.5rem 2.5rem" sx={{ backgroundColor: theme.palette.background.alt, padding: 2 }}>
                    <Header title="Statistics" subtitle="Track Agent Stats & Progress" />
                    <Paper elevation={4} sx={{ padding: theme.spacing(1), pt: '0', pr: '0', borderRadius: '7px', margin: '0rem 2.5rem', }}>
                        <Tabs
                            value={activeTab}
                            onChange={handleTabChange}
                            variant="fullWidth"
                            scrollButtons="auto"
                            sx={{ borderRadius: '4px', mt: '1rem', }}
                            TabIndicatorProps={{ style: { backgroundColor: theme.palette.primary[100], } }}
                        >
                            <Tab label="Stats Sheet" value="statsSheet" style={tabStyles} />
                            <Tab label="Quota Sheet" value="quotaSheet" style={tabStyles} />
                            <Tab label="Stats Total Sheet (Weekly)" value="weeklyStatsTotalSheet" style={tabStyles} />
                            <Tab label="Stats Total Sheet (Monthly)" value="monthlyStatsTotalSheet" style={tabStyles} />
                        </Tabs>
                        {activeTab === "statsSheet" && (
                            <StatsTable agents={sortedAgents} />
                        )}
                        {activeTab === "quotaSheet" && (
                            <QuotaSheet agents={sortedAgents} />
                        )}
                        {activeTab === "weeklyStatsTotalSheet" && (
                            <WeekStatsTotalSheet agents={sortedAgents} theme={theme} currentDayStats={sortedDailyStats}/>
                        )}
                        {activeTab === "monthlyStatsTotalSheet" && (
                            <MonthStatsTotalSheet agents={sortedAgents} theme={theme} currentDayStats={sortedDailyStats}/>
                        )}
                    </Paper>
                    {/* <StatsTable agents={agents} /> */}
                </Box>
            )}
        </>
    )
}

export default Stats;