import { /* Close, */ Info, /* Search */ } from "@mui/icons-material";
import { Box, /* Checkbox, IconButton, InputBase, */ Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Toolbar, Tooltip, Typography } from "@mui/material";
import { DateFormatter } from "components/helpers/DateTimeHelpers";
import { hoverStyle } from "components/helpers/MediaQueries";
import QuoteDetails from "components/quotes/QuoteDetails";
import { useState } from "react";
// import { useGetAllQuotesQuery } from "state/operationsApi";

const OperationsArchivedList = ({ theme, quotes, handlePageChange, handleLimitChange, limit }) => {
    const [quoteToDetail, setQuoteToDetail] = useState(null);
    // const [selectedQuotes, setSelectedQuotes] = useState([]);
    // const [selectedQuoteObjects, setSelectedQuoteObjects] = useState([]);
    // const [selectedPages, setSelectedPages] = useState([]);

    const [quoteDetailsOpen, setQuoteDetailsOpen] = useState(false);
    // const [sortField, setSortField] = useState("name");
    // const [sortOrder, setSortOrder] = useState("asc");
    // const [page, setPage] = useState(1);
    // const [limit, setLimit] = useState(25);
    // const [status, setStatus] = useState(null);
    // const [emailSubject, setEmailSubject] = useState(null);
    // const [date, setDate] = useState(null);
    // const [assignedTo, setAssignedTo] = useState(null);

    // const [searchInput, setSearchInput] = useState("");
    // const [searched, setSearched] = useState(false);
    // const [searchSkip, setSearchSkip] = useState(true);

    const archivedQuotes = quotes.quotes.filter((quote) => quote?.isArchived);

    // Togglers
    const toggleDetailsOpen = () => setQuoteDetailsOpen(prev => !prev);
    /* const toggleQuoteDetails = (quote) => {
        setQuoteDetailsOpen((prev) => !prev);
        if (quoteDetailsOpen) {
            setQuoteToDetail(null);
        } else {
            setQuoteToDetail(quote);
        }
    }; */

    // const toggleQuote = (selectedQuoteId) => {
    //     const isSelected = selectedQuotes.includes(selectedQuoteId);

    //     if (isSelected) {
    //         setSelectedQuotes((prev) =>
    //             prev.filter((id) => id !== selectedQuoteId)
    //         );
    //         setSelectedQuoteObjects((prev) =>
    //             prev.filter((quote) => quote._id !== selectedQuoteId)
    //         );
    //     } else {
    //         setSelectedQuotes([...selectedQuotes, selectedQuoteId]);
    //         // FIX
    //         /* setSelectedQuoteObjects(
    //             [...selectedQuoteObjects,]
    //         ) */
    //     }
    // };


    // Handlers
    const handleSort = () => {
        window.alert("Sorting currently being implemented");
    };

    if (!quotes.quotes) return;

    return (
        <Box sx={{ backgroundColor: theme.palette.background.secondary, padding: 2, }}>
            <Toolbar
                sx={{
                    pl: 2,
                    pr: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', }}>
                    {/* Searchbar */}
                    {/* <div style={{ display: 'flex', justifyContent: 'center', }}>
                        <Paper
                            component="form"
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 400, border: '1px solid grey', }}
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1, }}
                                id="searchbar"
                                placeholder="Search Quotes"
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        // handleSearch();
                                    }
                                }}
                            />
                            {!searched ? (
                                <IconButton
                                    sx={{ p: '10px', }}
                                    // onClick={handleSearch}
                                >
                                    <Search />
                                </IconButton>
                            ) : (
                                <IconButton
                                    sx={{ p: '10px', }}
                                    onClick={() => {
                                        setSearched(false);
                                        setSearchInput("");
                                    }}
                                >
                                    <Close />
                                </IconButton>
                            )}
                        </Paper>
                    </div> */}
                    {/* <Typography
                        variant="subtitle2"
                        sx={{
                            ml: '10px',
                            pt: '6px',
                            display: selectedQuotes.length ? 'block' : 'none',
                        }}
                    >
                        {selectedQuotes.length} Quotes Selected
                        <IconButton
                            size="small"
                            sx={{ ml: '5px', }}
                            onClick={() => {
                                setSelectedQuotes([]);
                                setSelectedQuoteObjects([]);
                                setSelectedPages([]);
                            }}
                        >
                            <Tooltip title="Deselect All" placement="top">
                                <Close sx={{ fontSize: '16px', }} />
                            </Tooltip>
                        </IconButton>
                    </Typography> */}
                </Box>
            </Toolbar>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                count={quotes.total}
                                rowsPerPage={limit}
                                page={parseInt(quotes.currentPage) - 1}
                                slotProps={{
                                    select: {
                                        inputProps: {
                                            "arira-label": "rows per page",
                                        },
                                        native: true,
                                    },
                                }}
                                onPageChange={handlePageChange}
                                onRowsPerPageChange={handleLimitChange}
                                showFirstButton
                                showLastButton
                            />
                        </TableRow>
                        <TableRow>
                            {/* <TableCell sx={{ p: '0 10px', textAlign: 'center', }}>
                                <Checkbox 
                                    checked={selectedPages.includes(page)}
                                    onChange={toggleAllCustomers}
                                />
                                Select Page
                            </TableCell> */}
                            <TableCell>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        onClick={() => handleSort("emailSubject")}
                                        style={{ cursor: "pointer" }}
                                    >
                                        Subject
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        onClick={() => handleSort("origin")}
                                        style={{ cursor: "pointer" }}
                                    >
                                        Origin
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        onClick={() => handleSort("destination")}
                                        style={{ cursor: "pointer" }}
                                    >
                                        Destination
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        onClick={() => handleSort("truckPrice")}
                                        style={{ cursor: "pointer" }}
                                    >
                                        Truck Price
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        onClick={() => handleSort("customerQuote")}
                                        style={{ cursor: "pointer" }}
                                    >
                                        Customer Quote
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Typography
                                        variant="h6"
                                        fontWeight="bold"
                                        onClick={() => handleSort("dateCreated")}
                                        style={{ cursor: "pointer" }}
                                    >
                                        Date Created
                                    </Typography>
                                </Box>
                            </TableCell>
                            <TableCell sx={{ p: '6px 0px', }} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!archivedQuotes && !archivedQuotes.length ? (
                            <TableRow>
                                <TableCell>
                                    <Typography>No Quotes To Display</Typography>
                                </TableCell>
                            </TableRow>
                        ) : (
                            archivedQuotes.map((quote, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <a
                                            href={quote.gmailLink}
                                            rel="noreferrer"
                                            target="_blank"
                                            style={{
                                                textDecoration: 'underline',
                                                color: 'inherit',
                                            }}
                                        >
                                            {quote.emailSubject}
                                        </a>
                                    </TableCell>
                                    <TableCell>
                                        {quote.origin.city || ""}, {quote.origin.state || ""}
                                    </TableCell>
                                    <TableCell>
                                        {quote.destination.city || ""}, {quote.destination.state || ""}
                                    </TableCell>
                                    <TableCell>
                                        {`$${parseFloat(quote.truckPrice).toFixed(2)}` || "None"}
                                    </TableCell>
                                    <TableCell>
                                        {`$${parseFloat(quote.customerQuote).toFixed(2)}` || "None"}
                                    </TableCell>
                                    <TableCell>{DateFormatter(quote.createdAt)}</TableCell>
                                    <TableCell>
                                        <Tooltip title="Details" placement="top">
                                            <Info
                                                sx={hoverStyle}
                                                onClick={() => {
                                                    setQuoteToDetail(quote);
                                                    toggleDetailsOpen();
                                                }}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {quoteDetailsOpen && (
                <QuoteDetails
                    quote={quoteToDetail}
                    onClose={toggleDetailsOpen}
                />
            )}
        </Box>
    )
}

export default OperationsArchivedList;