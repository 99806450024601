import { Box, Paper, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { setupWebSocket } from "components/helpers/WebSocketSetup";
import Header from "components/layout/Header";
import OperationsArchivedList from "components/operations/OperationsArchivedList";
import OperationsBoard from "components/operations/OperationsBoard";
import OperationsCancelledList from "components/operations/OperationsCancelledList";
import OperationsRefusedList from "components/operations/OperationsRefusedList";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetAllOperationsTeamMembersQuery, useGetAllQuotesQuery } from "state/operationsApi";

const Operations = () => {
    const theme = useTheme();
    const isConnected = useSelector((state) => state.webSocket.isConnected);

    const [activeTab, setActiveTab] = useState("opsBoard");

    // Query States
    const [page, setPage] = useState(null);
    const [limit, setLimit] = useState(null);
    const [status, setStatus] = useState(undefined);
    const [emailSubject, setEmailSubject] = useState(undefined);
    const [date, setDate] = useState(undefined);
    const [assignedTo, setAssignedTo] = useState(undefined);
    const [includeArchived, setIncludeArchived] = useState(false);

    const { data: operationsTeamMembers, isLoading: teamMembersLoading, isError: teamMembersError, refetch: refetchTeamMembers } = useGetAllOperationsTeamMembersQuery();
    const { data: quotes, isLoading: quotesLoading, isError: quotesError, refetch: refetchQuotes } = useGetAllQuotesQuery(
        {
            page,
            limit,
            status,
            emailSubject,
            date,
            assignedTo,
            includeArchived
        }
    )

    // Websocket handling
    useEffect(() => {
        const socket = setupWebSocket(
            process.env.REACT_APP_WEBSOCKET_URL,
            "Operations"
        );

        socket.on("newQuote", () => {
            refetchQuotes();
        });

        socket.on("quoteUpdated", () => {
            refetchQuotes();
        });

        return () => socket.disconnect(); // Cleanup on unmount
    }, []);

    // Handlers
    const handleTabChange = (e, newTab) => {
        e.preventDefault();

        switch (newTab) {
            case "opsBoard":
                setPage(null);
                setLimit(null);
                setStatus(undefined);
                setIncludeArchived(false);
                break;

            case "opsArchived":
                setPage(1);
                setLimit(25);
                setStatus("booked");
                setIncludeArchived(true);
                break;
                
            case "opsRefused":
                setPage(1);
                setLimit(25);
                setStatus("refused");
                setIncludeArchived(true);
                break;
                
            case "opsCancelled":
                setPage(1);
                setLimit(25);
                setStatus("cancelled");
                setIncludeArchived(true);
                break;
                    
            default:
                break;
        }
        setDate(undefined);
        setAssignedTo(undefined);
        setActiveTab(newTab);
    };


    const handleLimitChange = (e) => {
        setLimit(parseInt(e.target.value, 10));
        setPage(1);
    };

    const handlePageChange = (e, newPage) => {
        e.preventDefault();
        setPage(newPage + 1);
    };


    // Styling
    const tabStyles = {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        marginRight: theme.spacing(1),
        borderRadius: '4px',
    };

    if (quotesLoading || quotesError) return;

    if (teamMembersLoading || teamMembersError) return;

    return (
        <Box>
            <Box m="1.5rem 2.5rem" sx={{ backgroundColor: theme.palette.background.alt, padding: 2, borderRadius: '7px' }}>
                <Header title="Operations" subtitle="Handle Quotes" />
                <Typography
                    variant="h6"
                    component="h2"
                    gutterBottom
                    sx={{ pb: 1, }}
                >
                    WebSocket Status: {" "}
                    {isConnected ? (
                        <Typography sx={{ color: '#00deeb', }}>Connected</Typography>
                    ) : (
                        <Typography sx={{ color: '#FF0000', }}>Disconnected</Typography>
                    )}
                </Typography>
            </Box>
            <Paper elevation={4} sx={{ padding: theme.spacing(1), pt: '0', borderRadius: '7px', m: '0rem 2.5rem', }}>
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    scrollButtons="auto"
                    sx={{ borderRadius: '4px', }}
                    TabIndicatorProps={{ style: { backgroundColor: theme.palette.primary[100], } }}
                >
                    <Tab label="Operations Board" value="opsBoard" style={tabStyles} />
                    <Tab label="Archived" value="opsArchived" style={tabStyles} />
                    <Tab label="Refused" value="opsRefused" style={tabStyles} />
                    <Tab label="Cancelled" value="opsCancelled" style={tabStyles} />
                </Tabs>
                {activeTab === "opsBoard" ? (
                    <OperationsBoard
                        quotes={quotes.quotes}
                        refetchQuotes={refetchQuotes}
                        operationsTeamMembers={operationsTeamMembers}
                        assignedTo={assignedTo}
                        setAssignedTo={setAssignedTo}
                    />
                ) : activeTab === "opsArchived" ? (
                    <OperationsArchivedList
                        theme={theme}
                        quotes={quotes}
                        handlePageChange={handlePageChange}
                        handleLimitChange={handleLimitChange}
                        limit={limit}
                    />
                ) : activeTab === "opsRefused" ? (
                    <OperationsRefusedList
                        theme={theme}
                        quotes={quotes}
                        handlePageChange={handlePageChange}
                        handleLimitChange={handleLimitChange}
                        limit={limit}
                    />
                ) : activeTab === "opsCancelled" ? (
                    <OperationsCancelledList 
                        theme={theme}
                        quotes={quotes}
                        handlePageChange={handlePageChange}
                        handleLimitChange={handleLimitChange}
                        limit={limit}
                    />
                ) : (
                    ''
                )}
            </Paper>
        </Box>
    )
}

export default Operations;