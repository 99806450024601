import { createSlice } from "@reduxjs/toolkit";
//userId is a test user
const initialState ={
  mode: "dark",
  userId: "test"
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === 'light' ? "dark":"light";
    }
  }
})

export const { setMode } = globalSlice.actions;

export default globalSlice.reducer;