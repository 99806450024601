import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleCallbackMutation } from "state/api";


const GoogleCallback = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (code) {
            fetch(`/auth/google/callback?code=${code}`)
                .then(response => response.json())
                .then(data => {
                    navigate('/dashboard');
                })
                .catch(error => {
                    console.error('Error:', error);
                    navigate('/login');
                });
        }
    }, [navigate]);

    return <div>Loading...</div>;
};



export default GoogleCallback;