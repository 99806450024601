import { Box, Paper, Tab, Tabs, useTheme } from "@mui/material";
import CustomersList from "components/customers/CustomersList";
import Header from "components/layout/Header";
import ManagerActivityList from "manager/activities/ManagerActivityList";
import { useState } from "react";

const CustomerSupport = () => {
    const theme = useTheme();
    const [activeTab, setActiveTab] = useState("customers");
    // const [searched, setSearched] = useState(false);

    // Handlers
    const handleTabChange = (e, newTab) => {
        e.preventDefault();
        setActiveTab(newTab);
    };

    // Styling
    const tabStyles = {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.background.paper,
        marginRight: theme.spacing(1),
        borderRadius: '4px',
    };
    

    /* if (isLoading) return (
        SKELETON
    ) */

    return (
        <Box>
            <Box m="1.5rem 2.5rem" sx={{ backgroundColor: theme.palette.background.alt, padding: 2, borderRadius: '7px', }}>
                <Header title="CUSTOMER SUPPORT" subtitle="Manage and Assist Customers" />
            </Box>
            <Paper elevation={4} sx={{ padding: theme.spacing(1), paddingTop: '0', borderRadius: '7px', margin: '0rem 2.5rem', }}>
                <Tabs
                    value={activeTab}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    scrollButtons="auto"
                    sx={{ borderRadius: '4px', }}
                    TabIndicatorProps={{ style: { backgroundColor: theme.palette.primary[100], } }}
                >
                    <Tab label="Customers" value="customers" style={tabStyles}/>
                    <Tab label="Customer Support Activity" value="activity" style={tabStyles}/>
                </Tabs>
                {activeTab === "customers" && (
                    <CustomersList theme={theme}/>
                )}
                {activeTab === "activity" && (
                    <ManagerActivityList />
                )}
            </Paper>
        </Box>
    )
}

export default CustomerSupport;