import { Box, Divider, Drawer, List, MenuItem, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import EventSettingsMenu from "./EventSettingsMenu";
import SignatureMenu from "./SignatureMenu";
import { CalendarMonth, Edit, Mail } from "@mui/icons-material";
import EmailSettingsMenu from "./EmailSettingsMenu";

const Settings = ({ user, onClose }) => {
    const [settings, setSettings] = useState({});
    const [eventSettingsOpen, setEventSettingsOpen] = useState(false);
    const [emailSettingsOpen, setEmailSettingsOpen] = useState(false);
    const [signatureSettingsOpen, setSignatureSettingsOpen] = useState(false);

    const handleDisplayEventSettings = () => {
        setEventSettingsOpen(prev => !prev);
    };

    const handleDisplayEmailSettings = () => {
        setEmailSettingsOpen(prev => !prev);
    };

    const handleDisplaySignatureSettings = () => {
        setSignatureSettingsOpen(prev => !prev);
    };

    // Handler to update settings
    const handleSaveSettingsToLocalStorage = (key, value) => {
        setSettings(prev => ({
            ...prev,
            [key]: value
        }));
    };

    useEffect(() => {
     const savedSettings = localStorage.getItem('settings');
     if (savedSettings) {
        setSettings(JSON.parse(savedSettings));
     }
    }, []);

    // Save settings to localStorage whenever they change
    useEffect(() => {
     localStorage.setItem('settings', JSON.stringify(settings));
    }, [settings]);

    return (
        <Drawer open onClose={onClose} anchor="right">
            <Box p={2}>
                <List>
                    {/* <MenuItem>Notifications Persist <Switch /></MenuItem>
                    <MenuItem onClick={handleDisplayEventSettings}>
                        <CalendarMonth sx={{ mr: '10px' }}/>
                        Calendar Settings
                    </MenuItem>
                    <Divider /> */}
                    <MenuItem onClick={handleDisplayEmailSettings}>
                        <Mail sx={{ mr: '10px' }}/>
                        Email Settings
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={handleDisplaySignatureSettings}>
                        <Edit sx={{ mr: '10px' }}/>
                        Signature Settings
                    </MenuItem>
                    <Divider />
                </List>
                {eventSettingsOpen && (
                    <EventSettingsMenu userRole={user.role} eventSettingsOpen={eventSettingsOpen} handleCloseEventSettings={handleDisplayEventSettings}/>
                )}
                {emailSettingsOpen && (
                    <EmailSettingsMenu user={user} settings={settings} handleCloseEmailSettings={handleDisplayEmailSettings} saveSettings={handleSaveSettingsToLocalStorage}/>
                )}
                {signatureSettingsOpen && (
                    <SignatureMenu user={user} signatureSettingsOpen={signatureSettingsOpen} handleCloseSignatureSettings={handleDisplaySignatureSettings}/>
                )}
            </Box>
        </Drawer>
    )
}

export default Settings;