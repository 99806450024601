import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { calculateDSLA } from "components/helpers/CalculateDSLA";
import { useState } from "react";
import { useGetSearchLeadsQuery } from "state/api";
import ManageAgentLeadDetails from "../agentManagement/leads/ManageAgentLeadDetails";
import {
  AssignmentInd,
  Close,
  Edit,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import AssignLeadForm from "components/forms/Manager/leads/AssignLeadForm";

const ManageLeadSearchList = ({ searchInput }) => {
  const [query, setQuery] = useState(searchInput);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [leadToDetail, setLeadToDetail] = useState(null);
  const [leadsToAssign, setLeadsToAssign] = useState([]);

  const [leadDetailsOpen, setLeadDetailsOpen] = useState(false);
  const [assignDialogOpen, setAssignDialogOpen] = useState(false);

  const [sortCategory, setSortCategory] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");

  const {
    data: leadsData = [],
    error: leadsError,
    isLoading: leadsLoading,
    refetch,
  } = useGetSearchLeadsQuery(query);

  const [currentPage, setCurrentPage] = useState(1);

  // Togglers
  const toggleAssignDialog = () => {
    setAssignDialogOpen((prev) => !prev);
    if (leadsToAssign) {
      setLeadsToAssign([]);
    } else {
      setLeadsToAssign(selectedLeads);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedLeads([]);
    } else {
      const allLeadIds = leadsData.map((lead) => lead._id);
      setSelectedLeads(allLeadIds);
    }
    setSelectAll(!selectAll);
  };

  const handleSelectLead = (leadId) => {
    const isSelected = selectedLeads.includes(leadId);
    if (isSelected) {
      setSelectedLeads(selectedLeads.filter((id) => id !== leadId));
    } else {
      setSelectedLeads([...selectedLeads, leadId]);
    }
  };

  const handleLeadDetailsDisplay = () => {
    if (!leadDetailsOpen) {
      setLeadToDetail(selectedLeads);
      setLeadDetailsOpen(true);
    } else {
      setLeadToDetail(null);
      setLeadDetailsOpen(false);
    }
  };

  const handleLeadDelete = (deletedLeadId) => {
    handleRefresh();
    setLeadToDetail((prev) =>
      prev.filter((leadId) => leadId !== deletedLeadId)
    );
    setSelectedLeads((prev) =>
      prev.filter((leadId) => leadId !== deletedLeadId)
    );
    if (leadToDetail.length === 1) {
      setLeadDetailsOpen(false);
    }
  };

  const handleSort = (category) => {
    if (sortCategory === category) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortCategory(category);
      setSortDirection("asc");
    }
  };

  const sortByRecentActivity = (a, b) => {
    const valueA = a.recentActivity?.description || "";
    const valueB = b.recentActivity?.description || "";
    return sortDirection === "asc"
      ? valueA.localeCompare(valueB)
      : valueB.localeCompare(valueA);
  };

  const sortByRecentActivityDate = (a, b) => {
    const valueA = a.recentActivity?.date || "";
    const valueB = b.recentActivity?.date || "";
    return sortDirection === "asc"
      ? valueA.localeCompare(valueB)
      : valueB.localeCompare(valueA);
  };

  const sortedLeads = [...leadsData]?.sort((a, b) => {
    if (sortCategory === "recentactivitydescription") {
      return sortByRecentActivity(a, b);
    } else if (sortCategory === "recentActivity.date") {
      return sortByRecentActivityDate(a, b);
    } else if (sortCategory) {
      const valueA = a[sortCategory] || "";
      const valueB = b[sortCategory] || "";
      if (typeof valueA === "string" && typeof valueB === "string") {
        return sortDirection === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      } else {
        return sortDirection === "asc" ? valueA - valueB : valueB - valueA;
      }
    }
    return 0;
  });

  const handleDSLA = (DSLA) => {
    return calculateDSLA(DSLA);
  };

  const shortenURL = (url, maxLength) => {
    if (url && url.length > maxLength) {
      return url.slice(0, maxLength - 3) + "...";
    }

    return url;
  };

  const ensureHttpPrefix = (url) => {
    // Check if the URL starts with "http://"
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return `http://${url}`;
    }
    return url;
  };

  const handlePageChange = (e, newPage) => {
    e.preventDefault();
    setCurrentPage(newPage);
  };

  const handleRefresh = () => {
    refetch();
  };

  const itemsPerPage = 100; // Change this to set the number of items per page

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Modify how the data is displayed based on the current page
  const displayedLeads = sortedLeads.slice(startIndex, endIndex);

  if (leadsLoading)
    return (
      <div
        style={{
          marginTop: "1rem",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </div>
    );

  return (
    <Box>
      <div style={{ marginTop: "1rem", width: "100%" }}>
        {/*         <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            disabled={selectedLeads.length === 0}
            onClick={handleLeadDetailsDisplay}
          >
            Edit Leads
          </Button>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {displayedLeads.length > 0 && (
              <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
                Showing {displayedLeads.length} out of {leadsData.length} total
                leads.
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex", justifyContent: "center" }}>
          <Box>
            <Pagination
              sx={{ mt: 2, transform: "translateX(130%)" }}
              count={Math.ceil(leadsData.length / itemsPerPage)}
              page={currentPage}
              onChange={(e, newPage) => handlePageChange(e, newPage)}
            />
          </Box>
        </Grid> */}
        <Toolbar
          sx={{
            pl: 2,
            pr: 1,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              variant="subtitle1"
              sx={{
                ml: "10px",
                pt: "6px",
                display: selectedLeads.length ? "block" : "none",
              }}
            >
              {selectedLeads?.length > 1
                ? `${selectedLeads.length} Leads Selected`
                : `${selectedLeads.length} Lead Selected`}
            </Typography>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box>
              <Button
                variant="contained"
                color="primary"
                disabled={!selectedLeads.length}
                onClick={handleLeadDetailsDisplay}
                sx={{
                  textTransform: "none",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                  boxShadow: "none",
                  border: "1px solid black",
                  borderRight: ".05px solid black",
                  p: "6px 8px",
                }}
              >
                <Edit sx={{ mr: "5px", pb: "4px" }} />
                Edit
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!selectedLeads.length}
                onClick={toggleAssignDialog}
                sx={{
                  textTransform: "none",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  boxShadow: "none",
                  border: "1px solid black",
                  borderLeft: "0px solid black",
                  p: "6px 8px",
                }}
              >
                <AssignmentInd sx={{ mr: "5px", pb: "4px" }} />
                Assign
              </Button>
            </Box>
          </Box>
        </Toolbar>
        <Box display="flex" justifyContent="center" mt={2}>
          <TableContainer
            component={Paper}
            sx={{ overflow: "auto", marginRight: "10px", maxHeight: 570 }}
          >
            <Table stickyHeader sx={{ paddingRight: "10px" }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAll}
                        />
                      }
                      label="Select All"
                    />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        onClick={() => handleSort("name")}
                        style={{ cursor: "pointer" }}
                      >
                        Company Name
                      </Typography>
                      {sortCategory !== "name" ? (
                        <ExpandMore />
                      ) : (
                        <ExpandLess />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        onClick={() => handleSort("contactNumber")}
                        style={{ cursor: "pointer" }}
                      >
                        Phone Number
                      </Typography>
                      {sortCategory !== "contactNumber" ? (
                        <ExpandMore />
                      ) : (
                        <ExpandLess />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        onClick={() => handleSort("website")}
                        style={{ cursor: "pointer" }}
                      >
                        Website
                      </Typography>
                      {sortCategory !== "website" ? (
                        <ExpandMore />
                      ) : (
                        <ExpandLess />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        // onClick={() => handleSort('assignedTo')}
                        // style={{ cursor: 'pointer' }}
                      >
                        Assigned To
                      </Typography>
                      {/* {sortCategory !== "assignedTo" ? <ExpandMore /> : <ExpandLess />} */}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        onClick={() => handleSort("status")}
                        style={{ cursor: "pointer" }}
                      >
                        Status
                      </Typography>
                      {sortCategory !== "status" ? (
                        <ExpandMore />
                      ) : (
                        <ExpandLess />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        onClick={() => handleSort("title")}
                        style={{ cursor: "pointer" }}
                      >
                        Title
                      </Typography>
                      {sortCategory !== "title" ? (
                        <ExpandMore />
                      ) : (
                        <ExpandLess />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        onClick={() => handleSort("recentActivity.date")}
                        style={{ cursor: "pointer" }}
                      >
                        DSLA
                      </Typography>
                      {sortCategory !== "recentActivity.date" ? (
                        <ExpandMore />
                      ) : (
                        <ExpandLess />
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leadsData.length === 0 && searchInput !== "" ? (
                  <TableRow>
                    <TableCell>
                      <span>No lead names matching "{searchInput}"</span>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                ) : (
                  displayedLeads.map((lead) => (
                    <TableRow key={lead._id}>
                      <TableCell>
                        <Checkbox
                          checked={selectedLeads.includes(lead._id)}
                          onChange={() => handleSelectLead(lead._id)}
                        />
                      </TableCell>
                      <TableCell>
                        <span
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setLeadToDetail([lead._id]);
                            setLeadDetailsOpen(true);
                          }}
                        >
                          {lead.name}
                        </span>
                      </TableCell>
                      <TableCell>{lead.contactNumber}</TableCell>
                      <TableCell>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {lead.website.length > 30 ? (
                            <Tooltip title={lead.website}>
                              <span style={{ cursor: "pointer" }}>
                                <a
                                  href={ensureHttpPrefix(lead.website)}
                                  target="_blank"
                                  style={{
                                    color: "inherit",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {shortenURL(lead.website, 30)}
                                </a>
                              </span>
                            </Tooltip>
                          ) : (
                            <Tooltip title={lead.website}>
                              <span style={{ cursor: "pointer" }}>
                                <a
                                  href={ensureHttpPrefix(lead.website)}
                                  target="_blank"
                                  style={{
                                    color: "inherit",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {lead.website}
                                </a>
                              </span>
                            </Tooltip>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>
                        {lead.assignedUsers.length
                          ? lead.assignedUsers.length === 1
                            ? lead.assignedUsers[0].name
                            : "Multiple"
                          : lead.status === "pool"
                          ? "Pool"
                          : "Unassigned"}
                      </TableCell>
                      <TableCell>
                        {lead.status
                          .replace(/([A-Z])/g, " $1")
                          .replace(/^./, function (str) {
                            return str.toUpperCase();
                          })}
                      </TableCell>
                      <TableCell>
                        {lead.recentActivity?.description || "No activity."}
                      </TableCell>
                      <TableCell>
                        {handleDSLA(lead.recentActivity?.date) === 0
                          ? "Today"
                          : handleDSLA(lead.recentActivity?.date) > 0
                          ? handleDSLA(lead.recentActivity?.date)
                          : "New"}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {assignDialogOpen && (
          <AssignLeadForm
            onClose={toggleAssignDialog}
            leadIds={selectedLeads}
            leads={leadsData.filter((lead) => selectedLeads.includes(lead._id))}
            refetch={refetch}
          />
        )}
        {leadDetailsOpen && (
          <Dialog
            open
            onClose={handleLeadDetailsDisplay}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography>Lead Management Console</Typography>
              <IconButton onClick={handleLeadDetailsDisplay}>
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              {leadToDetail.length > 1 ? (
                leadToDetail.map((leadId, index) => (
                  <ManageAgentLeadDetails
                    key={index}
                    lead={leadsData.find((lead) => lead._id === leadId)}
                    onDelete={handleLeadDelete}
                    refetch={refetch}
                  />
                ))
              ) : (
                <ManageAgentLeadDetails
                  lead={leadsData.find((lead) => lead._id === leadToDetail[0])}
                  onDelete={handleLeadDelete}
                  refetch={handleRefresh}
                />
              )}
            </DialogContent>
          </Dialog>
        )}
      </div>
    </Box>
  );
};

export default ManageLeadSearchList;
