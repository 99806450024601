import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSearchAllLeadsOrActivitiesByTextQuery } from "state/managementApi";
import useDebounce from "components/hooks/useDebounce";
import { Business } from "@mui/icons-material";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import SearchedLeadDetails from "components/leads/SearchedLeadDetails";
import CustomerDetails from "components/customers/CustomerDetails";

const UniversalSearchBar = ({}) => {
    const [value, setValue] = useState(null); // The selected option
    const [input, setInput] = useState(""); // Current search input
    const [options, setOptions] = useState([]); // List of search options

    const [type, setType] = useState('Lead');
    const [sortField, setSortField] = useState('createdAt');
    const [sortOrder, setSortOrder] = useState('asc');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(15);

    
    const debouncedValue = useDebounce(input, 1000);
    const { data: searchResults, isError, isLoading, isUninitialized, isSuccess } = useSearchAllLeadsOrActivitiesByTextQuery({ type, searchText: input, page, limit, sortField, sortOrder }, { skip: debouncedValue == "" || input == "" } );


    useEffect(() => {
     if (searchResults && isSuccess) {
        setOptions(searchResults.data);
     };
    }, [searchResults, isSuccess]);

    // Handlers
    const handleInputChange = (e, value) => {
      setInput(value);
    };

    const handleChange = (e, value) => {
      setValue(value);
    };

    const handleFindingMatchingValues = (option) => {
      for (const key in option) {
        const value = option[key];

        // If the value is an object, recursively search through it
        if (typeof value === 'object' && value !== null) {
            const foundValue = handleFindingMatchingValues(value);
            if (foundValue) return foundValue;
        } else {
            // If the value matches the user input, return it
            if (value === input) {
                return value;
            }
        }
      }
    //   If no matching value, return null
      return null;
    };
    
    return (
        <>
            <Autocomplete 
                id="universal-search-bar"
                sx={{ width: '80%', }}
                // disablePortal
                // loading={isLoading}
                freeSolo
                clearOnEscape
                // openOnFocus
                getOptionLabel={(option) => type === "Lead" ? option.name : ''}
                getOptionKey={(option) => option._id}
                filterOptions={(x) => x}
                options={options}
                // autoComplete
                // includeInputInList
                // filterSelectedOptions
                inputValue={input}
                // value={value}
                noOptionsText="No results found"
                onChange={(e, newValue) => {
                    handleChange(e, newValue);
                }}
                onInputChange={(e, newInput) => {
                    handleInputChange(e, newInput);
                }}
                renderInput={(params) => (
                    <TextField {...params} label="Search Leads" fullWidth/>
                )}
                renderOption={(props, option, { inputValue }) => {
                    const matches = match(option.name, inputValue, { insideWords: true });
                    const parts = parse(option.name, matches);

                    return (
                        <li {...props}>
                            <Grid container alignItems="center">
                                <Grid item sx={{ display: 'flex', width: 44 }}>
                                    <Business />
                                </Grid>
                                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                    {parts.map((part, index) => (
                                        <Box
                                            key={index}
                                            component="span"
                                            sx={{ fontWeight: part.highlight ? 700 : 400, }}
                                        >
                                            {part.text}
                                        </Box>
                                    ))}
                                    <Typography variant="body2" color="text.secondary">
                                        {option.website}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </li>
                    )
                }}
            />
            {value && (
                // <SearchedLeadDetails lead={value} onClose={() => setValue(null)}/>
                <CustomerDetails customer={value} onClose={() => setValue(null)}/>
            )}
        </>
    )
}

export default UniversalSearchBar;