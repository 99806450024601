import { Close } from "@mui/icons-material";
// import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";

const AddAgentStatTrackingForm = ({ agents, onClose, onConfirm }) => {
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [selectedAgentDisplayName, setSelectedAgentDisplayName] = useState("default");
    const untrackedAgents = agents.filter((agent) => !agent.showStats);

    return (
        <Dialog open onClose={onClose} maxWidth='md' fullWidth>
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography>Add Agent to Stat Tracking</Typography>
                <IconButton onClick={onClose}>
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <TextField
                    margin="normal"
                    fullWidth
                    id="untrackedAgents"
                    label="Untracked Agents"
                    name="untrackedAgents"
                    autoFocus
                    select
                    value={selectedAgentDisplayName}
                    onChange={(e) => {
                        setSelectedAgentDisplayName(e.target.value);
                        setSelectedAgent(untrackedAgents.find((agent) => agent.name === e.target.value));
                    }}
                >
                    <MenuItem value="default" sx={{ display: 'none' }}></MenuItem>
                    {untrackedAgents.map((agent, index) => (
                        <MenuItem key={index} value={agent.name}>
                            {agent.name}
                        </MenuItem>
                    ))}
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => onConfirm(selectedAgent)}
                    disabled={!selectedAgent}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default AddAgentStatTrackingForm;