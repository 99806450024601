const { Close } = require("@mui/icons-material");
const { CircularProgress, Typography, Dialog, DialogTitle, IconButton, Divider, DialogContent, DialogActions, Button } = require("@mui/material");
const { useGetManagerActivityByIdQuery } = require("state/managementApi")

const ManagerActivityDetails = ({ activityId, onClose }) => {
    const {
        data: activity,
        error,
        isLoading,
        refetch,
    } = useGetManagerActivityByIdQuery(activityId, {
        enabled: Boolean(activityId),
        refetchOnMountOrArgChange: true,
    });
    // TODO: Implement update activity

    const handleDateTime = (activityDateTime) => {
      const date = new Date(activityDateTime);
      const day = date.toLocaleDateString();
      const time = date.toLocaleTimeString();

      return `${day} at ${time}.`;
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    if (error) {
        console.log(error);
        return <Typography>Error displaying activity</Typography>;
    }

    return (
        <Dialog open onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography>Activity Details</Typography>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </div>
                <Divider sx={{ mb: '15px', }}/>
            </DialogTitle>
            <DialogContent>
                <Typography variant="h4">{activity.title}</Typography>
                <Typography>Date: {handleDateTime(activity.date)}</Typography>
                <Typography>
                    Type:{" "}
                    {activity.type
                        .replace(/([A-Z])/g, " $1")
                        .replace(/^./, function (str) {
                            return str.toUpperCase();
                        })}
                </Typography>
                <Typography>Description: {activity.description}</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="contained" color="error">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ManagerActivityDetails;