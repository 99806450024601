const BatchProcessor = async (asyncFunction, data, sendingObject = null, batchProperty = "") => {
    try {
        const BATCH_SIZE = 500;

        for (let i = 0; i < data.length; i += BATCH_SIZE) {
            const batch = data.slice(i, i + BATCH_SIZE);
            
            if (sendingObject && batchProperty) {
                sendingObject[batchProperty] = batch;

                await asyncFunction(sendingObject);
                // console.log(sendingObject);
            } else {
                await asyncFunction(batch);
                // console.log(batch)
            };
        }
    } catch (error) {
        console.error("Error batch processing:", error);
    };
}

export default BatchProcessor;