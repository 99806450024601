import Edit from "@mui/icons-material/Edit";
import Info from "@mui/icons-material/Info";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import ValueGraph from "components/tools/ValueGraph";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useGetStatsByUserForDayQuery } from "state/salesApi";
import UpdatePastStatsForm from "./UpdatePastStatsForm";

const now = new Date();

const todayString = now.getFullYear() + '-' +
    String(now.getMonth() + 1).padStart(2, '0') + '-' +
    String(now.getDate()).padStart(2, '0');

const StatHistory = () => {
    const theme = useTheme();
    const userData = useSelector((state) => state.user);
    const [selectedDay, setSelectedDay] = useState(todayString);
    const [querySkip, setQuerySkip] = useState(true);
    const [editOpen, setEditOpen] = useState(false);

    /* TODO: Handle if userStats returns null */
    const { data: userStats, isLoading: userStatsLoading, error: userStatsError, refetch: refetchStats } = useGetStatsByUserForDayQuery({ agentId: userData._id, date: selectedDay }, { skip: querySkip });

    const toggleEditOpen = () => setEditOpen(prev => !prev);


    if (userStatsLoading) return <CircularProgress />;

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.grey[900],
                borderRadius: '4px',
                padding: theme.spacing(1),
            }}
        >
            <Paper elevation={4} sx={{ p: 2, }}>
                <Box>
                    <Typography>Date:</Typography>
                    <input 
                        name="statsDate"
                        type="date"
                        max={todayString}
                        value={selectedDay}
                        onChange={(e) => {
                            const newDate = new Date(e.target.value);
                            setSelectedDay(newDate.toISOString().split("T")[0]);
                            setQuerySkip(false);
                        }}
                    />
                </Box>
                <Paper sx={{ mb: 1, p: 1, }}>
                    {/* If stats date hasn't been changed from today */}
                    {selectedDay === todayString ? (
                        <Paper sx={{ mb: 1, p: 1, width: '100%', }}>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2, }}>
                                <Box sx={{ width: '48%' }}>
                                    <Typography>New Calls</Typography>
                                    <ValueGraph value={userData?.dailyNewCalls || 0} valueGoal={userData?.newCallGoals || 0} />
                                </Box>
                                <Box sx={{ width: '48%' }}>
                                    <Typography>Follow Up Calls</Typography>
                                    <ValueGraph value={userData?.dailyCalls || 0} valueGoal={userData?.callGoals || 0} />
                                </Box>
                            </Box>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2, }}>
                                <Box sx={{ width: '48%' }}>
                                    <Typography>Emails</Typography>
                                    <ValueGraph value={userData?.dailyEmails || 0} valueGoal={userData?.emailGoals || 0} />
                                </Box>
                                <Box sx={{ width: '48%' }}>
                                    <Typography>Setups</Typography>
                                    <ValueGraph value={userData?.setups || 0} valueGoal={userData?.setupGoals || 0} />
                                </Box>
                            </Box>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2, }}>
                                <Box sx={{ width: '48%' }}>
                                    <Typography>List Emails</Typography>
                                    <ValueGraph value={userData?.dailyListEmails || 0} valueGoal={userData?.dailyListEmailGoals || 0} />
                                </Box>
                                <Box sx={{ width: '48%' }}>
                                    <Typography>Packets</Typography>
                                    <ValueGraph value={userData?.packets || 0} valueGoal={userData?.packetGoals || 0} />
                                </Box>
                            </Box>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2, }}>
                                <Box sx={{ width: '48%' }}>
                                    <Typography>Loads Booked</Typography>
                                    <ValueGraph value={userData?.loadsBooked || 0} valueGoal={userData?.loadsBookedGoals || 0} />
                                </Box>
                                <Box sx={{ width: '48%' }}>
                                    <Typography>Quotes</Typography>
                                    <ValueGraph value={userData?.quotes || 0} valueGoal={userData?.quoteGoals || 0} />
                                </Box>
                            </Box>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2, }}>
                                <Box sx={{ width: '100%' }}>
                                    <Typography>Margin</Typography>
                                    <ValueGraph value={userData?.margin || 0} valueGoal={userData?.marginGoals || 0} />
                                </Box>
                            </Box>
                        </Paper>
                    ) : (
                        /* If user selects a past day */
                        <Paper sx={{ mb: 1, p: 1, width: '100%', }}>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2, }}>
                                <Box sx={{ width: '48%' }}>
                                    <Typography>New Calls</Typography>
                                    <ValueGraph value={userStats?.dailyNewCalls || 0} valueGoal={userData?.newCallGoals || 0} />
                                </Box>
                                <Box sx={{ width: '48%' }}>
                                    <Typography>Follow Up Calls</Typography>
                                    <ValueGraph value={userStats?.dailyCalls || 0} valueGoal={userData?.callGoals || 0} />
                                </Box>
                            </Box>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2, }}>
                                <Box sx={{ width: '48%' }}>
                                    <Typography>Emails</Typography>
                                    <ValueGraph value={userStats?.dailyEmails || 0} valueGoal={userData?.emailGoals || 0} />
                                </Box>
                                <Box sx={{ width: '48%' }}>
                                    <Typography>Setups</Typography>
                                    <ValueGraph value={userStats?.setups || 0} valueGoal={userData?.setupGoals || 0} />
                                </Box>
                            </Box>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2, }}>
                                <Box sx={{ width: '48%' }}>
                                    <Typography>List Emails</Typography>
                                    <ValueGraph value={userStats?.dailyListEmails || 0} valueGoal={userData?.dailyListEmailGoals || 0} />
                                </Box>
                                <Box sx={{ width: '48%' }}>
                                    <Typography>Packets</Typography>
                                    <ValueGraph value={userStats?.packets || 0} valueGoal={userData?.packetGoals || 0} />
                                </Box>
                            </Box>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2, }}>
                                <Box sx={{ width: '48%' }}>
                                    <Typography>Loads Booked</Typography>
                                    <ValueGraph value={userStats?.loadsBooked || 0} valueGoal={userData?.loadsBookedGoals || 0} />
                                </Box>
                                <Box sx={{ width: '48%' }}>
                                    <Typography>Quotes</Typography>
                                    <ValueGraph value={userStats?.quotes || 0} valueGoal={userData?.quoteGoals || 0} />
                                </Box>
                            </Box>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2, }}>
                                <Box sx={{ width: '100%' }}>
                                    <Typography>Margin</Typography>
                                    <ValueGraph value={userStats?.margin || 0} valueGoal={userData?.marginGoals || 0} />
                                </Box>
                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={toggleEditOpen}
                            >
                                Update
                            </Button>
                        </Paper>
                    )}
                </Paper>
            </Paper>
            {editOpen && <UpdatePastStatsForm stats={userStats} onClose={toggleEditOpen} refetch={refetchStats} />}
        </Box>
    )
}

export default StatHistory;