import { Close } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { hoverStyle } from "components/helpers/MediaQueries";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useCreateManagerActivityMutation } from "state/managementApi";

const getCurrentDateTime = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = `${now.getMonth() + 1}`.padStart(2, "0");
  const day = `${now.getDate()}`.padStart(2, "0");
  const hours = `${now.getHours()}`.padStart(2, "0");
  const minutes = `${now.getMinutes()}`.padStart(2, "0");
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

const AddManagerActivityForm = ({ customerIds, onClose, refetch }) => {
  const [formErrors, setFormErrors] = useState([]);

  const [addManagerActivity, { isLoading: adding }] =
    useCreateManagerActivityMutation();

  const { enqueueSnackbar } = useSnackbar();

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      date: getCurrentDateTime(),
      type: "call",
      status: "complete",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      description: Yup.string().required("Description is required"),
      date: Yup.date().required("Date is required"),
      type: Yup.string().required("Type is required"),
      status: Yup.string().required("Status is required"),
    }),
    onSubmit: async (values) => {
      let errors = [];
      const activityData = {
        leadIds: customerIds,
        title: values.title,
        description: values.description,
        date: new Date(),
        type: values.type,
        status: values.status,
      };

      try {
        await addManagerActivity(activityData)
          .unwrap()
          .then(() => {
            if (refetch) refetch();

            formik.resetForm();
            setFormErrors([]);
            enqueueSnackbar("Activity Added");
            onClose();
          })
          .catch((error) => {
            errors.push(error);
            setFormErrors(errors);
          });
      } catch (error) {
        errors.push(error);
        setFormErrors(errors);
      }
    },
  });

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Add Activity</Typography>
          <Close sx={hoverStyle} onClick={onClose} />
        </div>
        <Divider sx={{ mb: "15px" }} />
      </DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={formik.handleSubmit} noValidate>
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Type</InputLabel>
            <Select
              name="type"
              value={formik.values.type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <MenuItem value="call">Call</MenuItem>
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="inPerson">In Person</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
            {formik.touched.type && formik.errors.type && (
              <Alert severity="error">{formik.errors.type}</Alert>
            )}
          </FormControl>
          {/* {formik.values.type === "call" ? (
            <FormControl fullWidth margin="normal" required>
              <InputLabel>Title</InputLabel>
              <Select
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                <MenuItem value="First Contact">First Contact</MenuItem>
                <MenuItem value="Follow Up">Follow Up</MenuItem>
              </Select>
            </FormControl>
          ) : (
            <TextField
              margin="normal"
              fullWidth
              label="Title"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          )} */}
          <TextField
            margin="normal"
            fullWidth
            label="Title"
            name="title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.title && formik.errors.title && (
            <Alert severity="error">{formik.errors.title}</Alert>
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            multiline
            rows={4}
            label="Description"
            name="description"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.description && formik.errors.description && (
            <Alert severity="error">{formik.errors.description}</Alert>
          )}
          <FormControl fullWidth margin="normal" required>
            <InputLabel>Status</InputLabel>
            <Select
              name="status"
              value={formik.values.status}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <MenuItem value="upcoming">Upcoming</MenuItem>
              <MenuItem value="inProgress">In Progress</MenuItem>
              <MenuItem value="complete">Complete</MenuItem>
            </Select>
            {formik.touched.status && formik.errors.status && (
              <Alert severity="error">{formik.errors.status}</Alert>
            )}
          </FormControl>
          {formErrors.length ? (
            <Alert severity="error">Error adding activity.</Alert>
          ) : null}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          disabled={adding}
          onClick={formik.handleSubmit}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddManagerActivityForm;
