import React, { useState } from "react";
import {
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  TextField,
  Box,
  Paper,
  // Tabs,
  // Tab,
  // AppBar,
  Divider,
  // Toolbar,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import SendIcon from "@mui/icons-material/Send";
import SearchIcon from "@mui/icons-material/Search";
// import EmailIcon from "@mui/icons-material/Email";
// import LabelIcon from "@mui/icons-material/Label";
import Pagination from "@mui/material/Pagination";
import EmailDetails from "./EmailDetails";
import ComposeEmail from "components/email/ComposeEmail";
import { /* Add, */ Dangerous, Delete, Drafts, Edit, Mail, Send, Star } from "@mui/icons-material";

const EmailInbox = ({ handleToggleEmailInbox }) => {
  const [showComposeEmail, setShowComposeEmail] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedLabel, setSelectedLabel] = useState("inbox");
  const [currentPage, setCurrentPage] = useState(1);
  const emailsPerPage = 5; // Adjust as needed

  // Placeholder for email data
  const emails = [
    {
      id: 1,
      sender: "john.doe@gmail.com",
      subject: "Meeting Tomorrow",
      date: "2023-11-17T10:30:00Z",
      content: "<p>This is the content of the email.</p>",
      label: "inbox",
    },
    // Add more email objects
  ];

  // Filter emails based on the selected label
  const filteredEmails = emails.filter((email) =>
    selectedLabel === "inbox" ? true : email.label === selectedLabel
  );

  // Filter emails based on the search keyword
  const searchedEmails = filteredEmails.filter(
    (email) =>
      email.sender.toLowerCase().includes(searchKeyword.toLowerCase()) ||
      email.subject.toLowerCase().includes(searchKeyword.toLowerCase())
  );

  // Paginate the emails
  const totalPages = Math.ceil(searchedEmails.length / emailsPerPage);
  const paginatedEmails = searchedEmails.slice(
    (currentPage - 1) * emailsPerPage,
    currentPage * emailsPerPage
  );

  const handleCloseEmailForm = () => {
    setShowComposeEmail(!showComposeEmail);
  };

  const handleEmailClick = (email) => {
    setSelectedEmail(email);
  };

  const handleSearch = (keyword) => {
    setSearchKeyword(keyword);
    setCurrentPage(1); // Reset to first page when searching
  };

  const handleLabelClick = (label) => {
    setSelectedLabel(label);
    setCurrentPage(1); // Reset to first page when changing labels
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Dialog
      open={true}
      onClose={handleToggleEmailInbox}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Inbox</Typography>
          <IconButton onClick={handleToggleEmailInbox}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex' }}>
          <Paper sx={{ mr: '25px', mb: '25px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', margin: '15px 5px', padding: '0px 10px' }}>
              <Button
                variant="contained"
                onClick={handleCloseEmailForm}
              >
                <Edit sx={{ marginRight: '15px' }} />
                <Typography sx={{ textTransform: 'none', margin: '10px 0px' }}>Compose</Typography>
              </Button>
            </Box>
            {/* <Toolbar /> */}
            <Divider />
            <List>
              <ListItem disablePadding onClick={() => handleLabelClick("inbox")}>
                <ListItemButton>
                  <ListItemIcon>
                    <Mail />
                  </ListItemIcon>
                  <ListItemText primary="Inbox" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding onClick={() => handleLabelClick("starred")}>
                <ListItemButton>
                  <ListItemIcon>
                    <Star />
                  </ListItemIcon>
                  <ListItemText primary="Starred" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding onClick={() => handleLabelClick("sent")}>
                <ListItemButton>
                  <ListItemIcon>
                    <Send />
                  </ListItemIcon>
                  <ListItemText primary="Sent" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding onClick={() => handleLabelClick("drafts")}>
                <ListItemButton>
                  <ListItemIcon>
                    <Drafts />
                  </ListItemIcon>
                  <ListItemText primary="Drafts" />
                </ListItemButton>
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListItem disablePadding onClick={() => handleLabelClick("trash")}>
                <ListItemButton>
                  <ListItemIcon>
                    <Delete />
                  </ListItemIcon>
                  <ListItemText primary="Trash" />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding onClick={() => handleLabelClick("spam")}>
                <ListItemButton>
                  <ListItemIcon>
                    <Dangerous />
                  </ListItemIcon>
                  <ListItemText primary="Spam" />
                </ListItemButton>
              </ListItem>
            </List>
          </Paper>
          <Box>
            <TextField
              fullWidth
              placeholder="Search emails"
              variant="outlined"
              margin="normal"
              onChange={(e) => handleSearch(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
            />
            <List>
              {paginatedEmails.map((email) => (
                <ListItem key={email.id} onClick={() => handleEmailClick(email)}>
                  <ListItemText
                    primary={email.sender}
                    secondary={
                      <React.Fragment>
                        <Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                        >
                          {email.subject}
                        </Typography>
                        {` - ${new Date(email.date).toLocaleString()}`}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              ))}
            </List>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
              style={{ marginTop: "16px" }}
            />
          </Box>
        </Box>
        {/* <Divider style={{ margin: "16px 0" }} /> */}
        {selectedEmail && <EmailDetails email={selectedEmail} />}
        {showComposeEmail && (
          <ComposeEmail
            handleCloseEmailForm={handleCloseEmailForm}
            arrayOfEmails={[]}
            arrayOfLeadIds={[]}
          />
        )}
        {/* <Button
          variant="contained"
          color="primary"
          onClick={handleCloseEmailForm}
          style={{ marginTop: "16px" }}
        >
          Compose Email
        </Button> */}
      </DialogContent>
    </Dialog>
  );
};

export default EmailInbox;
