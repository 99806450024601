import React from "react";
import {
  HomeOutlined,
  PublicOutlined,
  ShoppingCartOutlined,
  Groups2Outlined,
  ReceiptLongOutlined,
  PointOfSaleOutlined,
  TodayOutlined,
  CalendarMonthOutlined,
  PieChartOutlined,
  AdminPanelSettingsOutlined,
  TrendingUpOutlined,
  Inventory,
  BarChart,
  SupportAgent,
  SpaceDashboard,
  CoPresent,
} from "@mui/icons-material";

//define link objects title and icon.
const dashboardLink = { text: "Dashboard", icon: <HomeOutlined /> };
const userNavTitle = { text: "User Navigation", icon: null };
const customerSupportLink = {
  text: "Customer Support",
  icon: <SupportAgent />,
};
const operationsLink = { text: "Operations", icon: <CoPresent /> };
const dispatchLink = { text: "Dispatch", icon: <PublicOutlined /> };
const dispatchBoardLink = {
  text: "Dispatch Board 2.0 (Beta)",
  icon: <SpaceDashboard />,
};
const archiveLink = { text: "Archive", icon: <Inventory /> };
const statsLink = { text: "Stats", icon: <BarChart /> };
const productsLink = { text: "Products", icon: <ShoppingCartOutlined /> };
const customersLink = { text: "Customers", icon: <Groups2Outlined /> };
const transactionsLink = {
  text: "Transactions",
  icon: <ReceiptLongOutlined />,
};
const geographyLink = { text: "Geography", icon: <PublicOutlined /> };
const salesTitle = { text: "Sales", icon: null };
const overviewLink = { text: "Overview", icon: <PointOfSaleOutlined /> };
const dailyLink = { text: "Daily", icon: <TodayOutlined /> };
const monthlyLink = { text: "Monthly", icon: <CalendarMonthOutlined /> };
const breakdownLink = { text: "Breakdown", icon: <PieChartOutlined /> };
const managementLink = { text: "Management", icon: null };
const adminLink = { text: "Admin", icon: <AdminPanelSettingsOutlined /> };
const performanceLink = { text: "Performance", icon: <TrendingUpOutlined /> };

//current roles - "customer", "agent", "dispatch", "manager", "admin", "superadmin" 05/22/23 - links should be in an array
export const AdminItems = [
  dashboardLink,
  userNavTitle,
  statsLink,
  customerSupportLink,
  operationsLink,
  dispatchLink,
  dispatchBoardLink,
  archiveLink,
  productsLink,
  customersLink,
  transactionsLink,
  geographyLink,
  salesTitle,
  overviewLink,
  dailyLink,
  monthlyLink,
  breakdownLink,
  managementLink,
  adminLink,
  performanceLink,
];
export const AgentItems = [
  dashboardLink,
  userNavTitle,
  customersLink,
  geographyLink,
  salesTitle,
  overviewLink,
  dailyLink,
  monthlyLink,
  breakdownLink,
];
export const OperationsItems = [
  dashboardLink,
  userNavTitle,
  customersLink,
  operationsLink,
  geographyLink,
  salesTitle,
  overviewLink,
  dailyLink,
  monthlyLink,
  breakdownLink,
]
export const DispatchItems = [
  dashboardLink,
  userNavTitle,
  dispatchLink,
  dispatchBoardLink,
  archiveLink,
];
export const ManagerItems = [
  dashboardLink,
  userNavTitle,
  statsLink,
  customerSupportLink,
  operationsLink,
  dispatchLink,
  dispatchBoardLink,
];
export const CustomerItems = [dashboardLink, userNavTitle];
