import React, {useState} from 'react';
import { 
  Box, 
  useTheme, 
  Button, 
  TextField,  
  IconButton, 
  Typography, 
  Container,
  Checkbox, 
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from "formik";
import * as Yup from "yup";
import { 
  LightModeOutlined,
  DarkModeOutlined,
} from '@mui/icons-material';
import { setMode } from 'state';
import ktb from '../../assets/ktbwhite.png'
import {useRegisterMutation} from "../../state/api";

function Register() {
  const theme= useTheme();
  const dispatch= useDispatch();
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState([]);
  const [agreedToTerms, setAgreedToTerms] = useState(false); // State to manage user agreement
  const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im
  const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const passwordRegExp = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/
  const [registerMutation] = useRegisterMutation();

  const formik = useFormik({
    initialValues: {
      name:"",
      email: "",
      password: "",
      phoneNumber: "",
      cellNumber: "",
      address: "",
      city: "",
      state: "",
      country: "",
      occupation: "",
      transactions: []
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Enter a valid email")
        .max(40, "Must be 40 characters or less.")
        .required("Enter a valid email")
        .matches(
          emailRegExp,
          "Email is not valid"),
      password: Yup.string()
        .required("A valid password is required")
        .min(8)
        .matches(
          passwordRegExp,
          "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special character"
        ),
      name: Yup.string(),
      phoneNumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
      cellNumber: Yup.string().matches(phoneRegExp, 'Cellphone number is not valid'),
      address: Yup.string(),
      city: Yup.string(),
      state: Yup.string(),
      country: Yup.string(),
      occupation: Yup.string(),
      transactions: Yup.array(),
      //agree
    }),
    onSubmit: async (values, helpers) => {
      let errors = []

      try {
        registerMutation(
          {
            email: values.email,
            password: values.password,
            name: values.name,   
            address: values.address, 
            city: values.city, 
            state: values.state, 
            occupation: values.occupation,
            country: values.country,
            phoneNumber: values.phoneNumber,
            cellNumber: values.cellNumber
          },
        ).then((response) => {
          if (response.data) {
            localStorage.setItem('token', response.data.token)
            navigate('/dashboard')
          }
        })
      } catch (error) {
        errors.push(error);
        setFormErrors(errors);
      }
    }
  })

  return (
    <Container m="1.5rem 2.5rem" component="main" maxWidth="md">
      <Box
        // display: "flex", in sx causes resizing issues
        padding="40px"
        sx={{  
          marginTop: 8,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: theme.palette.primary[900]
        }}
      >
        <Typography component="h1" variant="h5" sx={{display: "flex", alignItems: "center",
          justifyContent: "center",}}>
          REGISTER A NEW ACCOUNT
          <IconButton onClick={() => dispatch(setMode())} >
            {theme.palette.mode === 'dark' ? (
              <DarkModeOutlined sx={{ fontSize: "25px"}} />
            ) : (
              <LightModeOutlined sx={{ fontSize: "25px"}} />
            )}
          </IconButton>
        </Typography>
        {theme.palette.mode === 'dark' ? (
          <Box component="img" //578X164png
            sx={{
              height: "100%",
              width: "100%",
              backgroundColor: theme.palette.primary[900] ,  
              padding:"10px",
            }}
            alt="KTB Logo"
            src={ktb}
          />
          ) : (
          <Box component="img" //578X164png
            sx={{
              height: "100%",
              width: "100%",
              backgroundColor: theme.palette.primary[100],
              padding:"10px",
            }}
            alt="KTB Logo"
            src={ktb}
          />
          )}        
        <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            //error={formik.errors.email}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email ? 
            <Box sx={{color: "#ba000d" }}>
              <small>{formik.errors.email}</small>
            </Box> : null}
          <TextField
            margin="normal"
            required
            fullWidth
            label="Password"
            name="password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.password && formik.errors.password ? 
            <Box sx={{color: "#ba000d" }}>
              <small>{formik.errors.password}</small>
            </Box> 
          : null}
          { formErrors.length
            ? <Box sx={{color: "#ba000d" }}>
                <small>Registration error: </small>
              </Box>
            : null
          }
          <Box display="flex" sx={{ flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center', alignContent: 'center'}}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                margin="normal"
                label="Work phone number"
                name="phoneNumber"
                autoComplete="phoneNumber"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.phoneNumber && formik.errors.phoneNumber ? 
                <Box sx={{color: "#ba000d" }}>
                  <small>{formik.errors.phoneNumber}</small>
                </Box> 
              : null}
              { formErrors.length
                ? <Box sx={{color: "#ba000d" }}>
                    <small>Invalid format</small>
                  </Box>
                : null
              }
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
              margin="normal"
              label="Cell phone number"
              name="cellNumber"
              autoComplete="cellNumber"
              value={formik.values.cellNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              />
              {formik.touched.cellNumber && formik.errors.cellNumber ? 
                <Box sx={{color: "#ba000d" }}>
                  <small>{formik.errors.cellNumber}</small>
                </Box> 
              : null}
              { formErrors.length
                ? <Box sx={{color: "#ba000d" }}>
                    <small>Invalid format</small>
                  </Box>
                : null
              }
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                margin="normal"
                label="Name"
                name="name"
                autoComplete="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                margin="normal"
                label="Address"
                name="address"
                autoComplete="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                margin="normal"
                label="City"
                name="city"
                autoComplete="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                margin="normal"
                label="State"
                name="state"
                autoComplete="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                margin="normal"
                label="Country"
                name="country"
                autoComplete="country"
                value={formik.values.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                margin="normal"
                label="Occupation"
                name="occupation"
                autoComplete="occupation"
                value={formik.values.occupation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </Box>
            {/*
            country: "",
            occupation: ""
            */}
            
          </Box>
          <Box 
            padding="40px"
            display="flex"
            sx={{ 
              marginTop: 1,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: theme.palette.primary[900]
            }}
          >
            <Checkbox onClick={() => setAgreedToTerms(!agreedToTerms)}/>
            <Typography component="h2" variant="h5">
              By signing up, you agree to our <a href="/tos">Terms of Service</a> and <a href="/privacy">Privacy Policy</a>.
            </Typography>
          </Box>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onSubmit={formik.onSubmit}
            disabled={!agreedToTerms}
          >
            Register and sign In
          </Button>
        </Box>
      </Box>
    </Container>
  );
}


export default Register;