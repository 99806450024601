import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { SimpleTreeView, TreeItem } from "@mui/x-tree-view";
import { useState } from "react";
import { useAddLeadToCategoryMutation, useCreateCategoryMutation, useDeleteCategoryMutation, useGetAllCategoriesQuery,/*  useManagerDeleteLeadMutation, */ useMoveLeadsFromPoolToUserMutation, useUpdateCategoryMutation } from "state/managementApi"
import CategoryLeadsTree from "./CategoryLeadsTree";
import { Close, Edit } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { DragDropContext } from "react-beautiful-dnd";
import { hoverStyle } from "components/helpers/MediaQueries";
import ConfirmationDialog from "components/helpers/ConfirmDialog";
import EditPoolLeadsMenu from "./EditPoolLeadsMenu";
import AddLeadForm from "components/forms/AddLeadForm";
import AssignPoolLeadMenu from "./AssignPoolLeadMenu";
import BatchProcessor from "components/helpers/BatchProcessor";

const PoolCategoriesTree = ({}) => {
    const theme = useTheme();

    // Creation form states
    const [newCategoryOpen, setNewCategoryOpen] = useState(false);
    const [newPoolLeadOpen, setNewPoolLeadOpen] = useState(false);
    
    // Selection states
    const [selectedTreeCategory, setSelectedTreeCategory] = useState('');
    const [selectedPoolLead, setSelectedPoolLead] = useState(null);
    const [selectedMultipleLeads, setSelectedMultipleLeads] = useState([]);
    const [editSelectionsOpen, setEditSelectionsOpen ] = useState(false);
    const [assignSelectedOpen, setAssignSelectedOpen] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [selectedPages, setSelectedPages] = useState([]);
    
    // Search states (to be implemented)
    const [searched, setSearched] = useState(false);
    // const [searchInput, setSearchInput] = useState("");
    
    // Category creation states
    const [newCategoryCreationError, setNewCategoryCreationError] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [newCategoryNameError, setNewCategoryNameError] = useState(false);
    const [newCategoryDescription, setNewCategoryDescription] = useState('');
    
    // Category editing states
    const [editCategoryOpen, setEditCategoryOpen] = useState(false);
    const [updateCategoryError, setUpdateCategoryError] = useState('');
    const [categoryToEdit, setCategoryToEdit] = useState(null);
    const [editCategoryName, setEditCategoryName] = useState('');
    const [editCategoryDescription, setEditCategoryDescription] = useState('');
    
    // Category deleting states
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [categoryToDelete, setCategoryToDelete] = useState(null);
    
    const [confirmSelectedDeleteOpen, setConfirmSelectedDeleteOpen] = useState(false);
    
    const { data: categories = [], error, isLoading: categoriesLoading, refetch: refetchCategories } = useGetAllCategoriesQuery();
    const [createCategory] = useCreateCategoryMutation();
    const [updateCategory] = useUpdateCategoryMutation();
    const [deleteCategory] = useDeleteCategoryMutation();
    // const [deleteLeads] = useManagerDeleteLeadMutation();
    const [addLeadToCategory] = useAddLeadToCategoryMutation();
    const [moveLeadsToUser, { isLoading: assignLoading}] = useMoveLeadsFromPoolToUserMutation();

    const { enqueueSnackbar } = useSnackbar();

    // Toggle handlers
    const handleNewLeadToggle = () => setNewPoolLeadOpen(prev => !prev);
    const handleAssignToggle = () => setAssignSelectedOpen(prev => !prev);


    const handleNewCategoryClose = () => {
        setNewCategoryName('');
        setNewCategoryDescription('');
        setNewCategoryNameError(false);
        setNewCategoryOpen(false);
        setNewCategoryCreationError(false);
    };

    const handleCategoryCreation = async () => {
        if (!newCategoryName) {
            setNewCategoryNameError(true);
            return;
        };

        const newCategory = {
            name: newCategoryName,
            description: newCategoryDescription,
        };

        try {
            await createCategory(newCategory)
                .then(() => {
                    enqueueSnackbar(`${newCategoryName} Created`);
                    handleRefresh();
                    handleNewCategoryClose();
                })
        } catch (error) {
            setNewCategoryCreationError(true);
            console.error('Error creating category:', error);
        }
    };

    const handleNewLeadCreation = async (newLead) => {
        handleNewLeadToggle();
        for (let i = 0; i < newLead.categories.length; i++) {
            await addLeadToCategory({ leadIds: [newLead._id], categoryId: newLead.categories[i] })
        };
    };

    const handleEditToggle = (category) => {
        if (!editCategoryOpen) {
            setCategoryToEdit(category);
            setEditCategoryName(category.name);
            setEditCategoryDescription(category.description);
            setEditCategoryOpen(true);
        } else {
            setEditCategoryOpen(false);
            setCategoryToEdit(null);
            setEditCategoryName('');
            setEditCategoryDescription('');
            setUpdateCategoryError("");
        };
    };

    const handleUpdateCategory = async () => {
        try {
            await updateCategory({ categoryId: categoryToEdit._id, name: editCategoryName, description: editCategoryDescription })
                .then (() => {
                    setEditCategoryOpen(false);
                    enqueueSnackbar(`${categoryToEdit.name} Updated`);
                    setCategoryToEdit(null);
                    setEditCategoryName('');
                    setEditCategoryDescription('');
                    handleRefresh();
                })
        } catch (error) {
            console.error("Error:", error);
            setUpdateCategoryError("Error Updating Category")
        }
    };

    const handleAssignLeads = async () => {
        try {
            if (!selectedAgent || selectedMultipleLeads.length === 0) {
                console.log("Invalid data to assign leads.");
                return;
            }

            const selectedLeadIds = selectedMultipleLeads.map((lead) => lead._id);

            // Send in batches
            await BatchProcessor(
                moveLeadsToUser,
                selectedLeadIds,
                {
                    userIdTo: selectedAgent._id,
                },
                'leadIds',
            )
                .then(() => {
                    setAssignSelectedOpen(false);
                    setSelectedAgent(false);
                    setSelectedMultipleLeads([]);
                    setSelectedPages([]);
                });
        } catch (error) {
            console.error("Error moving leads:", error);
        };
    };

    const handleDeleteToggle = (category) => {
        if (!confirmDeleteOpen) {
            setCategoryToDelete(category);
            setConfirmDeleteOpen(true);
        } else {
            setConfirmDeleteOpen(false);
            setCategoryToDelete(null);
        };
    };

    const handleDeleteLeadsToggle = () => {
        if (!confirmSelectedDeleteOpen) {
            setConfirmSelectedDeleteOpen(true);
        } else {
            setConfirmSelectedDeleteOpen(false);
        }
    }

    const handleCategoryDelete = async () => {
        try {
            await deleteCategory(categoryToDelete._id)
                .then (() => {
                    setConfirmDeleteOpen(false);
                    enqueueSnackbar(`${categoryToDelete.name} Deleted`);
                    setCategoryToDelete(null);
                    handleRefresh();
                });
        } catch (error) {
            console.error("Error:", error);
        };
    };

    const handleLeadsDelete = async () => {};
    
    const handleRefresh = () => {
        refetchCategories();
    };
    

    if (categoriesLoading) {
        return (
            <div style={{ marginTop: '1rem', width: '100%' }}>
                <CircularProgress />
            </div>
        )
    };

    if (error) {
        return (
            <div style={{
                marginTop: '1rem',
                width: '100%',
                backgroundColor: theme.palette.mode === 'dark' ? '#212121' : theme.palette.primary.light,
                borderRadius: '4px',
                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
            }}>
                <Typography>Error Loading Categories</Typography>
            </div>
        )
    }

    return (
        <div style={{ 
            marginTop: '1rem',
            width: '100%', 
            backgroundColor: theme.palette.mode === 'dark' ? '#212121' : theme.palette.primary.light,
            borderRadius: '4px',
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
        }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setNewCategoryOpen(true)}
                        sx={{ padding: '5px', margin: '5px', textTransform: 'none', }}
                    >
                        New Category
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNewLeadToggle}
                        sx={{ padding: '5px', margin: '5px', textTransform: 'none', }}
                    >
                        New Pool Lead
                    </Button>
                </Box>
                <Box sx={{ display: 'flex', }}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ padding: '5px', margin: '5px', textTransform: 'none', /* display: selectedMultipleLeads.length ? 'block' : 'none', */ }}
                        disabled={!selectedMultipleLeads.length}
                        onClick={() => setEditSelectionsOpen(true)}
                    >
                        Edit Selections
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ padding: '5px', margin: '5px', textTransform: 'none', }}
                        disabled={!selectedMultipleLeads.length}
                        onClick={handleAssignToggle}
                    >
                        Assign Selections
                    </Button>
                    {/* <Button
                        variant="contained"
                        color="error"
                        sx={{ padding: '5px', margin: '5px', textTransform: 'none', display: selectedMultipleLeads.length ? 'block' : 'none', }}
                        onClick={handleDeleteLeadsToggle}
                    >
                        Delete Selections
                    </Button> */}
                    {/* <Button
                        variant="contained"
                        color="error"
                        sx={{ padding: '5px', margin: '5px', textTransform: 'none', display: selectedTreeCategory && selectedTreeCategory !== "default" ? 'block' : 'none'}}
                    >
                        Delete Category
                    </Button> */}
                </Box>
            </Box>
            <Divider sx={{ mb: '15px', }}/>
            <DragDropContext>
                {!searched ? (
                    <div style={{ display: 'flex', }}>
                        <SimpleTreeView 
                            sx={{ width: '100%', flexGrow: 1, overflowY: 'auto', mb: '30px', }}
                        >
                            {categories.map((category, index) => (
                                <Box key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginBottom: '10px', }}>
                                    <TreeItem 
                                        itemId={category._id} 
                                        label={(
                                            <div>
                                                <Typography>{category.name}</Typography>
                                                <Typography variant="h7" sx={{ opacity: '0.7', }}>{category.description}</Typography>
                                                <Divider />
                                            </div>
                                        )} 
                                        sx={{ width: '95%', }}
                                        onClick={() => setSelectedTreeCategory(category.name)}
                                    >
                                        <CategoryLeadsTree 
                                            categories={categories}
                                            category={category} 
                                            selectedTreeCategory={selectedTreeCategory}
                                            selectedPoolLead={selectedPoolLead}
                                            setSelectedPoolLead={setSelectedPoolLead} 
                                            selectedMultipleLeads={selectedMultipleLeads} 
                                            setSelectedMultipleLeads={setSelectedMultipleLeads}
                                            selectedPages={selectedPages}
                                            setSelectedPages={setSelectedPages}
                                            />
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', }}>
                                            <Tooltip title={`Edit ${category.name}`}>
                                                <Edit sx={hoverStyle} style={{ marginRight: '15px', }} onClick={() => handleEditToggle(category)}/>
                                            </Tooltip>
                                            <Tooltip title={`Delete ${category.name}`}>
                                                <Close sx={hoverStyle} onClick={() => handleDeleteToggle(category)}/>
                                            </Tooltip>
                                        </Box>
                                    </TreeItem>
                                </Box>
                            ))}
                            <TreeItem itemId="default" label="Default" onClick={() => setSelectedTreeCategory('default')}>
                                <CategoryLeadsTree 
                                    category={{ name: 'default' }}
                                    categories={categories}
                                    selectedPoolLead={selectedPoolLead}
                                    setSelectedPoolLead={setSelectedPoolLead} 
                                    selectedMultipleLeads={selectedMultipleLeads} 
                                    setSelectedMultipleLeads={setSelectedMultipleLeads}
                                    selectedPages={selectedPages}
                                    setSelectedPages={setSelectedPages}
                                />
                                <TreeItem itemId="defaultPlaceholder" label="Placeholder" sx={{ display: 'none', }}/>
                            </TreeItem>
                        </SimpleTreeView>
                    </div>
                ) : (
                    ''
                )}
            </DragDropContext>
            {newCategoryOpen && (
                <Dialog open={newCategoryOpen} onClose={handleNewCategoryClose} fullWidth maxWidth="xs">
                    <DialogTitle>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant="h6">New Category</Typography>
                            <IconButton onClick={handleNewCategoryClose}>
                                <Close />
                            </IconButton>
                        </div>
                        <Divider />
                    </DialogTitle>
                    <DialogContent>
                        <TextField 
                            label="Name"
                            fullWidth
                            name="name"
                            value={newCategoryName}
                            onChange={(e) => setNewCategoryName(e.target.value)}
                            sx={{ mt: '10px', }}
                        />
                        {newCategoryNameError && (
                            <Alert severity="error">Name Required</Alert>
                        )}
                        <TextField 
                            label="Description"
                            fullWidth
                            name="description"
                            value={newCategoryDescription}
                            onChange={(e) => setNewCategoryDescription(e.target.value)}
                            sx={{ mt: '15px', }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCategoryCreation}
                        >
                            Add
                        </Button>
                    </DialogActions>
                    {newCategoryCreationError && (
                        <Alert severity="error">Error Creating Category</Alert>
                    )}
                </Dialog>
            )}
            {editCategoryOpen && (
                <Dialog open={editCategoryOpen} onClose={handleEditToggle} fullWidth maxWidth="xs">
                    <DialogTitle>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}
                        >
                            <Typography variant="h6">Edit {categoryToEdit.name}</Typography>
                            <IconButton onClick={handleEditToggle}>
                                <Close />
                            </IconButton>
                        </div>
                        <Divider />
                    </DialogTitle>
                    <DialogContent>
                        <TextField 
                            label="Name"
                            fullWidth
                            name="editName"
                            value={editCategoryName}
                            onChange={(e) => setEditCategoryName(e.target.value)}
                            sx={{ mt: '10px', }}
                        />
                        {editCategoryName === "" && (
                            <Alert severity="error">Name Required</Alert>
                        )}
                        <TextField 
                            label="Description"
                            fullWidth
                            name="editDescription"
                            value={editCategoryDescription}
                            onChange={(e) => setEditCategoryDescription(e.target.value)}
                            sx={{ mt: '15px', }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={editCategoryName === ""}
                            onClick={handleUpdateCategory}
                        >
                            Update
                        </Button>
                    </DialogActions>
                    {updateCategoryError && (
                        <Alert severity="error">Error Updating Category</Alert>
                    )}
                </Dialog>
            )}
            {assignSelectedOpen && (
                <AssignPoolLeadMenu 
                    open={assignSelectedOpen} 
                    onClose={handleAssignToggle} 
                    onSubmit={handleAssignLeads}
                    selectedAgent={selectedAgent}
                    setSelectedAgent={setSelectedAgent}
                    leadLength={selectedMultipleLeads.length}
                />
            )}
            {confirmDeleteOpen && (
                <ConfirmationDialog 
                    open={confirmDeleteOpen}
                    onClose={handleDeleteToggle}
                    onConfirm={handleCategoryDelete}
                    title="Confirm Delete"
                    message={`Are you sure you want to delete ${categoryToDelete.name}?`}
                />
            )}
            {confirmSelectedDeleteOpen && (
                <ConfirmationDialog 
                    open={confirmSelectedDeleteOpen}
                    onClose={handleDeleteLeadsToggle}
                    onConfirm={handleLeadsDelete}
                    title="Confirm Delete"
                    message={`Are you sure you want to delete ${selectedMultipleLeads.length} leads?`}
                />
            )}
            {editSelectionsOpen && (
                <EditPoolLeadsMenu 
                    categories={categories}
                    selectedLeads={selectedMultipleLeads} 
                    setSelectedLeads={setSelectedMultipleLeads} 
                    open={editSelectionsOpen} 
                    onClose={() => setEditSelectionsOpen(false)}
                />
            )}
            {newPoolLeadOpen && (
                <AddLeadForm mode='create' open={newPoolLeadOpen} closeForm={handleNewLeadToggle} onCreate={handleNewLeadCreation} isPool categories={categories}/>
            )}
        </div>
    )
}

export default PoolCategoriesTree