import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  useTheme,
  Tooltip,
  IconButton,
  TextField,
  MenuItem,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Header from 'components/layout/Header';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import mongoose from 'mongoose';

// Import your WebSocket setup function
import { setupWebSocket } from '../../components/helpers/WebSocketSetup';

const DeveloperBoard = ({ closeDeveloperBoard }) => {
  const theme = useTheme();
  const [socket, setSocket] = useState(null);
  const [columns, setColumns] = useState({});
  const [editCardId, setEditCardId] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');
  const [editedType, setEditedType] = useState('Epic');
  const [editedRating, setEditedRating] = useState(5);
  const [editedDescription, setEditedDescription] = useState('');
  const [editedColor, setEditedColor] = useState('');
  const [oldColor, setOldColor] = useState('');
  const [expandedCards, setExpandedCards] = useState({});

  const isConnected = useSelector((state) => state.webSocket.isConnected);
  const defaultCardColor = theme.palette.primary[1000];

  const PREDEFINED_COLORS = [
    { name: 'Default', color: defaultCardColor },
    { name: 'Dan', color: '#990000' },
    { name: 'N/A', color: '#f28500' },
    { name: 'Epic', color: '#680868' },
    { name: 'Story', color: '#3d85c6' },
    { name: 'Task', color: '#37ff77' }, 
    { name: 'Trouble', color: '#ffbf00' }, 
  ];

  useEffect(() => {
    const componentName = 'DeveloperBoard';
    const newSocket = setupWebSocket(process.env.REACT_APP_WEBSOCKET_URL, componentName);

    newSocket.on('update', (updatedColumns) => {
      setColumns(updatedColumns);
    });

    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, []);

  const handleAddCard = () => {
    const id = new mongoose.Types.ObjectId().toString();
    const title = '';
    const type = 'Epic';
    const rating = 5;
    const description = '';
    const color = theme.palette.primary[1000];
    const columnId = 'column-1';
    const column = columns[columnId];
    const newItems = [...column.items, { id, title, type, rating, description, color }];
    const newColumn = { ...column, items: newItems };
    const updatedColumns = { ...columns, [columnId]: newColumn };
    setColumns(updatedColumns);
    socket.emit('update', updatedColumns);
  };

  const handleEditCard = (itemId) => {
    const item = getItemById(itemId);
    setEditCardId(itemId);
    setOldColor(item.color);
    setEditedColor(item.color);
    setEditedTitle(item.title);
    setEditedType(item.type);
    setEditedRating(item.rating);
    setEditedDescription(item.description);
  };

  const handleCardColorChange = (cardId, color) => {
    if (editCardId === cardId) {
      setEditedColor(color.hex);
      const updatedColumns = { ...columns };
      const item = getItemById(cardId);
      item.color = color.hex;
      setColumns(updatedColumns);
    }
  };

  const handleSaveCard = (itemId) => {
    const updatedColumns = { ...columns };
    const item = getItemById(itemId);
    item.title = editedTitle;
    item.type = editedType;
    item.rating = editedRating;
    item.description = editedDescription;
    item.color = editedColor;
    setColumns(updatedColumns);
    setEditCardId(null);
    socket.emit('update', updatedColumns);
  };

  const handleCancelEditCard = (itemId) => {
    const item = getItemById(itemId);
    setEditCardId(null);
    setEditedTitle('');
    setEditedType('Epic');
    setEditedRating(5);
    setEditedDescription('');

    // Reset the color to the old color if it exists
    if (oldColor) {
      item.color = oldColor;
    }
  };

  const handleEditCardChange = (e, field) => {
    const item = getItemById(editCardId);
    switch (field) {
      case 'title':
        setEditedTitle(e.target.value);
        break;
      case 'type':
        setEditedType(e.target.value);
        item.type = e.target.value;
        break;
      case 'rating':
        setEditedRating(e.target.value);
        break;
      case 'description':
        const newValue = e.target.value;
        if (newValue !== undefined) {
          setEditedDescription(newValue);
        }
        break;
      default:
        break;
    }
  };

  const handleDeleteCard = (itemId) => {
    const confirmed = window.confirm('Confirm: delete this card?');
    if (!confirmed) {
      return;
    }
    const updatedColumns = { ...columns };
    for (let column of Object.values(updatedColumns)) {
      const itemIndex = column.items.findIndex((item) => item.id === itemId);
      if (itemIndex > -1) {
        column.items.splice(itemIndex, 1);
        break;
      }
    }
    setColumns(updatedColumns);
    socket.emit('update', updatedColumns);
  };

  const getItemById = (itemId) => {
    return Object.values(columns)
      .flatMap((column) => column.items)
      .find((item) => item.id === itemId);
  };

  const handleToggleCardExpansion = (cardId) => {
    setExpandedCards((prevExpandedCards) => ({
      ...prevExpandedCards,
      [cardId]: !prevExpandedCards[cardId],
    }));
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const item = sourceColumn.items.splice(source.index, 1)[0];
    destColumn.items.splice(destination.index, 0, item);
    setColumns({
      ...columns,
      [source.droppableId]: sourceColumn,
      [destination.droppableId]: destColumn,
    });
    // Send websocket update
    const updatedColumns = { ...columns };
    updatedColumns[source.droppableId] = sourceColumn;
    updatedColumns[destination.droppableId] = destColumn;
    socket.emit('update', updatedColumns);
  };

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          top: theme.spacing(2),
          right: theme.spacing(2),
          cursor: 'pointer',
        }}
        onClick={closeDeveloperBoard}
      >
        <CloseIcon />
      </Box>
      <Header title="Developer Board" subtitle="Manage tasks and assignments" />
      <Typography variant="h6" component="h2" gutterBottom sx={{ paddingBottom: 1 }}>
        WebSocket Status: {isConnected ? (
        <Typography sx={{ color: '#00deeb' }}>Connected</Typography>
        ) : (
        <Typography sx={{ color: '#FF0000' }}>Disconnected</Typography>
        )}
      </Typography>
      <Box display="flex" flexWrap="wrap">
        {PREDEFINED_COLORS.map((colorInfo) => (
          <Box
            key={colorInfo.name}
            display="flex"
            alignItems="center"
            marginRight={2}
            marginBottom={1}
          >
            <div
              style={{
                width: '24px',
                height: '24px',
                backgroundColor: colorInfo.color,
                marginRight: '8px',
                border: '1px solid #000',
              }}
            ></div>
            {colorInfo.name}
          </Box>
        ))}
      </Box>
      <Grid container spacing={1} sx={{ backgroundColor: theme.palette.background.alt, padding: 2 }}>
        <Grid item xs={12} md={2}>
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={12} sm={4} md={4}>
              <Button variant="contained" color="primary" fullWidth onClick={handleAddCard}>
                Add Card
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Grid container spacing={1}>
              {Object.entries(columns).map(([columnId, column]) => (
                <Grid item xs={12} sm={6} md={6} lg={2} key={columnId}>
                  <Paper elevation={3}>
                    <Typography variant="h6" component="h2" align="center" gutterBottom sx={{ backgroundColor: theme.palette.primary[900] }}>
                      {column.name}
                    </Typography>
                    <Droppable droppableId={columnId}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {column.items.map((item, index) => {
                            const isEditing = editCardId === item.id;
                            const isExpanded = expandedCards[item.id];
                            const cardTextColor = item.type === 'Task' ? 'black' : 'white';
                            return (
                              <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <Paper elevation={isEditing ? 10 : 3} style={{ backgroundColor: item.color, color: cardTextColor }}>
                                      <div style={{ padding: '8px' }}>
                                        {isEditing ? (
                                          <div>
                                            <TextField
                                              fullWidth
                                              autoFocus
                                              label="Title"
                                              value={editedTitle}
                                              onChange={(e) => handleEditCardChange(e, 'title')}
                                              variant="standard"
                                            />
                                            <TextField
                                              fullWidth
                                              label="Type"
                                              select
                                              value={editedType}
                                              onChange={(e) => handleEditCardChange(e, 'type')}
                                              variant="standard"
                                            >
                                              <MenuItem value="Epic">Epic</MenuItem>
                                              <MenuItem value="Story">Story</MenuItem>
                                              <MenuItem value="Task">Task</MenuItem>
                                            </TextField>
                                            <TextField
                                              fullWidth
                                              label="Rating"
                                              type="number"
                                              value={editedRating}
                                              onChange={(e) => handleEditCardChange(e, 'rating')}
                                              variant="standard"
                                            />
                                            <TextField
                                              fullWidth
                                              label="Description"
                                              multiline
                                              minRows={3}
                                              value={editedDescription}
                                              onChange={(e) => handleEditCardChange(e, 'description')}
                                              variant="standard"
                                            />
                                                <div>
                                                  {PREDEFINED_COLORS.map((predefinedColor) => {
                                                    if (predefinedColor.name.toLowerCase() !== 'default') {
                                                      return (
                                                        <Tooltip title={predefinedColor.name} key={predefinedColor.name}>
                                                          <Button
                                                            style={{
                                                              backgroundColor: predefinedColor.color,
                                                              margin: '5px',
                                                              color: cardTextColor,
                                                              
                                                            }}
                                                            onClick={() =>
                                                              handleCardColorChange(item.id, { hex: predefinedColor.color })
                                                            }
                                                          >
                                                            {predefinedColor.name}
                                                          </Button>
                                                        </Tooltip>
                                                      );
                                                    }
                                                    return null;
                                                  })}
                                                </div>
                                            <Tooltip title="Save" placement="top">
                                              <IconButton onClick={() => handleSaveCard(item.id)} size="small">
                                                <SaveIcon />
                                              </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Cancel" placement="top">
                                              <IconButton onClick={() => handleCancelEditCard(item.id)} size="small">
                                                <CloseIcon />
                                              </IconButton>
                                            </Tooltip>
                                          </div>
                                        ) : (
                                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Typography variant="h6" component="h3" gutterBottom>
                                              {item.title}
                                            </Typography>
                                            <input
                                              type="checkbox"
                                              checked={expandedCards[item.id] || false}
                                              onChange={() => handleToggleCardExpansion(item.id)}
                                            />
                                          </div>
                                        )}
                                        {isExpanded && !isEditing && (
                                          <div >
                                            <Typography variant="subtitle1" gutterBottom>
                                              Type: {item.type}
                                            </Typography>
                                            <Typography variant="subtitle1" gutterBottom>
                                              Rating: {item.rating}
                                            </Typography>
                                            <Typography variant="body1" gutterBottom>
                                              Description: {item.description}
                                            </Typography>
                                            <Tooltip title="Edit" placement="top">
                                              <IconButton onClick={() => handleEditCard(item.id)} size="small">
                                                <EditIcon />
                                              </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete" placement="top">
                                              <IconButton onClick={() => handleDeleteCard(item.id)} size="small">
                                                <DeleteIcon />
                                              </IconButton>
                                            </Tooltip>
                                          </div>
                                        )}
                                      </div>
                                    </Paper>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </DragDropContext>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeveloperBoard;
