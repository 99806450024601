import {
  ExpandLess,
  ExpandMore,
  Refresh as RefreshIcon,
  Tune,
} from "@mui/icons-material";
import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import ActivityLeadList from "components/activities/ActivityLeadList";
import { calculateDSLA } from "components/helpers/CalculateDSLA";
import { useState } from "react";
import LeadDetails from "./LeadDetails";

const LeadsList = ({
  leads,
  isLoading,
  handleLeadsUpdate,
  totalLeads,
  currentPage,
  setCurrentPage,
  refetch,
  selectedLead,
  setSelectedLead,
  selectedLeads,
  setSelectedLeads,
  selectedLeadObjects,
  setSelectedLeadObjects,
  setSelectedLeadsEmails,
  sortCategory,
  setSortCategory,
  sortOrder,
  setSortOrder,
  theme,
}) => {
  const [highlightedLeads, setHighlightedLeads] = useState([]);
  const [activityDisplay, setActivityDisplay] = useState(false);
  const [selectedActivitiesLead, setSelectedActivitiesLead] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedFilterCategory, setSelectedFilterCategory] = useState("");
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);

  // TODO: Sort by if DNA or not
// case "DSLE":
// case "DSLC":
  const leadPropertiesArray = Object.getOwnPropertyNames(leads[0] || [])
    .filter((property) => {
      switch (property) {
        case "name":
        case "commodity":
        case "state":
        case "contactNumber":
        case "website":
        // case "title":
        case "DSLA":
        case "DSLC":
        case "_id":
        case "__v":
        case "csNotes":
        case "assignedUsers":
        case "recentActivity":
        case "sales":
        case "categories":
        case "DNA":
        case "dateSetup":
        case "firstLoadMoved":
        case "lastLoadMoved":
        case "transferDate":
        case "activities":
        case "isCustomer":
          return;
        default:
          return property;
      }
    })
    .sort();

  const openLeadDetails = (lead) => {
    setSelectedLead(lead);
  };

  const closeLeadDetails = () => {
    setSelectedLead(null);
    handleRefresh();
  };

  const handleRefresh = () => {
    refetch();
  };

  const handleLeadActivityDisplay = (lead) => {
    setSelectedActivitiesLead(lead);
    setActivityDisplay(true);
  };

  const handleCloseLeadActivityDisplay = () => {
    setSelectedActivitiesLead(null);
    setActivityDisplay(false);
  };

  // Sorting handlers
  const handleSort = (category) => {
    if (sortCategory === category) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortCategory(category);
      setSortOrder("asc");
      setCurrentPage(1);
    }
  };

  const handleUpdateLeads = () => {
    handleLeadsUpdate();
  };

  const isAllLeadsSelected = leads.every((lead) =>
    selectedLeads.includes(lead._id)
  );

  const toggleAllLeads = () => {
    // If all leads are selected, deselect all; otherwise, select all
    if (isAllLeadsSelected) {
      setSelectedLeads([]);
      setSelectedLeadObjects([]);
      setSelectedLeadsEmails([]);
    } else {
      const allLeadIds = leads.map((lead) => lead._id);
      setSelectedLeads(allLeadIds);
      setSelectedLeadObjects(leads);
      setSelectedLeadsEmails(leads.map((lead) => lead.contactEmail));
    }
  };

  const handleLeadToggle = (leadId) => {
    const leadIndex = selectedLeads.indexOf(leadId);

    if (leadIndex === -1) {
      // Lead not yet selected, add it
      setSelectedLeads([...selectedLeads, leadId]);
    } else {
      // Lead already selected, remove it
      setSelectedLeads(selectedLeads.filter((id) => id !== leadId));
    }

    // Update selected lead objects
    setSelectedLeadObjects((prev) => {
      const selectedLead = leads.find((lead) => lead._id === leadId);
      return selectedLead ? leadIndex === -1 ? [...prev, selectedLead] : prev.filter((leadObject) => leadObject._id !== selectedLead._id) : prev;
    });

    // Update selected leads' emails
    setSelectedLeadsEmails((prevEmails) => {
      const selectedLead = leads.find((lead) => lead._id === leadId);
      return selectedLead
        ? leadIndex === -1
          ? [...prevEmails, selectedLead.contactEmail]
          : prevEmails.filter((email) => email !== selectedLead.contactEmail)
        : prevEmails;
    });
  };

  function shortenURL(url, maxLength) {
    if (url && url.length > maxLength) {
      return url.slice(0, maxLength - 3) + "...";
    }
    return url;
  }

  const ensureHttpPrefix = (url) => {
    // Check if the URL starts with "http://"
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return `http://${url}`;
    }
    return url;
  };

  // Handler for shift selecting
  const handleShiftSelection = (e, lead) => {
    /* 
      if no buttons and click, set one lead in highlighted
      if button on nonhighlighted lead and highlighted length, add all in between
      if click and drag, add or remove from highlighted
    */
    if (!highlightedLeads.length) {
      setHighlightedLeads([lead]);
    } else {
      if (!highlightedLeads.includes(lead)) {
        setHighlightedLeads((prev) => [...prev, lead]);
      }
    }
  };

  // Function to please the middle mousers
  const handleLeadMiddleClick = (lead, e) => {
    if (e.button === 1) {
      window.open(`/lead/${lead._id}`, "_blank");
    }
  };

  // Handler for filter anchor element
  const handleFilterClick = (e) => {
    if (filterOpen) {
      setFilterAnchorEl(null);
      setFilterOpen(false);
    } else {
      setFilterAnchorEl(e.currentTarget);
      setFilterOpen(true);
    }
  };

  const handleDSLA = (DSLA) => {
    return calculateDSLA(DSLA);
  };

  // Handler for lead categories filter display
  const handleFilterCategoriesDisplay = (property) => {
    switch (property) {
      case "DSLC":
      case "DSLE":
      case "DNA":
        return property;
      case "APEmail":
      case "APName":
      case "APNumber":
        const APType = property.slice(2);
        return `AP ${APType}`;
      default:
        return property
          .replace(/([A-Z])/g, " $1")
          .replace(/^./, function (str) {
            return str.toUpperCase();
          });
    }
  };

  const handlePageChange = (e, newPage) => {
    e.preventDefault();
    setCurrentPage(newPage);
  };

  const itemsPerPage = 100; // Change this to set the number of items per page

  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;

  // Modify how the data is displayed based on the current page
  // const displayedLeads = sortedLeads.slice(startIndex, endIndex);

  if (!leads || leads.length === 0) {
    return <Typography>No leads found.</Typography>;
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <div style={{ width: "100%" }}>
      {selectedLead ? (
        <LeadDetails
          lead={selectedLead}
          onClose={closeLeadDetails}
          handleUpdateLeads={handleUpdateLeads}
          refetchLeads={handleRefresh}
        />
      ) : (
        <div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid item xs={6}>
              <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
                Showing {leads.length} out of {totalLeads} total leads.
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ display: selectedLeads.length ? "block" : "none" }}
              >
                {selectedLeads?.length}{" "}
                {selectedLeads?.length > 1 ? "Leads" : "Lead"} Selected
              </Typography>
              <Typography variant="subtitle2" sx={{ display: selectedLeadObjects.find((lead) => lead.DNA.status === true) ? 'block' : 'none', color: 'red'}}>
                One or more leads marked 'Do Not Disturb'
              </Typography>
            </Grid>
            <Tooltip title="Refresh" placement="bottom">
              <IconButton
                onClick={handleRefresh}
                color="info"
                size="medium"
                sx={{ ml: 1, boxShadow: "0 5px 5px rgba(0, 0, 0, 0.2" }}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
          <Pagination
            style={{ display: "flex", justifyContent: "center" }}
            count={Math.ceil(totalLeads / itemsPerPage)}
            page={currentPage}
            onChange={(e, newPage) => {
              handlePageChange(e, newPage);
            }}
          />
          <Box mt={4}>
            <TableContainer component={Paper} sx={{ maxHeight: 540 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ padding: "0 10px", textAlign: "center" }}>
                      <Checkbox
                        checked={isAllLeadsSelected}
                        onChange={toggleAllLeads}
                      />
                      Select All
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("name")}
                          style={{ cursor: "pointer" }}
                        >
                          Company Name
                        </Typography>
                        {sortCategory !== "name" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("commodity")}
                          style={{ cursor: "pointer" }}
                        >
                          Commodity
                        </Typography>
                        {sortCategory !== "commodity" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("state")}
                          style={{ cursor: "pointer" }}
                        >
                          State
                        </Typography>
                        {sortCategory !== "state" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("contactNumber")}
                          style={{ cursor: "pointer" }}
                        >
                          Phone Number
                        </Typography>
                        {sortCategory !== "contactNumber" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("website")}
                          style={{ cursor: "pointer" }}
                        >
                          Website
                        </Typography>
                        {sortCategory !== "website" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("DSLC")}
                          style={{ cursor: "pointer" }}
                        >
                          DSLC
                        </Typography>
                        {sortCategory !== "DSLC" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("recentActivity.date")}
                          style={{ cursor: "pointer" }}
                        >
                          DSLA
                        </Typography>
                        {sortCategory !== "recentActivity.date" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    {selectedFilterCategory && selectedFilterCategory !== "callTomorrow" && (
                      <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography
                            variant="h6"
                            fontWeight="bold"
                            onClick={() => handleSort(selectedFilterCategory)}
                            style={{ cursor: "pointer" }}
                          >
                            {handleFilterCategoriesDisplay(
                              selectedFilterCategory
                            )}
                          </Typography>
                          {sortCategory !== selectedFilterCategory ? (
                            <ExpandMore />
                          ) : (
                            <ExpandLess />
                          )}
                        </Box>
                      </TableCell>
                    )}
                    <TableCell>
                      <IconButton onClick={handleFilterClick}>
                        <Tune />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leads.length === 0 ? (
                    <TableRow>
                      <TableCell>
                        <span>No leads available</span>
                      </TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  ) : (
                    leads.map((lead) => (
                      <TableRow
                        draggable
                        key={lead._id}
                        sx={{
                          boxShadow:
                            lead.DNA.status && theme.palette.mode === "dark" // DNA Colors
                              ? "inset 0 0 0 1000px #761600"
                              : lead.DNA.status && theme.palette.mode !== "dark"
                              ? "inset 0 0 0 1000px #FF9177"
                              : lead.callTomorrow && theme.palette.mode === "dark" // Call Tomorrow Colors
                              ? "inset 0 0 0 1000px #006517"
                              : lead.callTomorrow && theme.palette.mode !== "dark"
                              ? "inset 0 0 0 1000px #56de75"
                              : highlightedLeads.includes(lead) && // Normal Colors
                                theme.palette.mode === "dark"
                              ? "inset 0 0 0 1000px #414141"
                              : highlightedLeads.includes(lead) &&
                                theme.palette.mode !== "dark"
                              ? "inset 0 0 0 1000px #C0C0C0"
                              : "none",
                        }}
                        // onClick={(e) => handleShiftSelection(e, lead)}
                        onMouseDown={() => setHighlightedLeads([lead])}
                        onDragStart={(e) => {
                          e.dataTransfer.setDragImage(new Image(), 0, 0);
                        }}
                        onDragEnter={(e) => {
                          handleShiftSelection(e, lead);
                        }}
                        onDoubleClick={() => openLeadDetails(lead)}
                      >
                        <TableCell
                          sx={{
                            padding: "0 30px",
                            textAlign: "center",
                            lineHeight: "0",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={selectedLeads.includes(lead._id)}
                            onChange={() => handleLeadToggle(lead._id)}
                            onClick={(e) => e.stopPropagation()}
                          />
                        </TableCell>
                        <TableCell>
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            <a
                              onClick={(e) => {
                                e.stopPropagation();
                                openLeadDetails(lead);
                              }}
                              onMouseDown={(e) => {
                                handleLeadMiddleClick(lead, e);
                              }}
                            >
                              {lead.name}
                            </a>
                          </span>
                        </TableCell>
                        <TableCell>{lead?.commodity}</TableCell>
                        <TableCell>{lead.state}</TableCell>
                        <TableCell>{lead.contactNumber}</TableCell>
                        <TableCell>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            {lead.website.length > 30 ? (
                              <Tooltip title={lead.website}>
                                <span style={{ cursor: "pointer" }}>
                                  <a
                                    href={ensureHttpPrefix(lead.website)}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                      color: "inherit",
                                      textDecoration: "underline",
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                    >
                                    {shortenURL(lead.website, 30)}
                                  </a>
                                </span>
                              </Tooltip>
                            ) : (
                              <Tooltip title={lead.website}>
                                <span style={{ cursor: "pointer" }}>
                                  <a
                                    href={ensureHttpPrefix(lead.website)}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{
                                      color: "inherit",
                                      textDecoration: "underline",
                                    }}
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    {lead.website}
                                  </a>
                                </span>
                              </Tooltip>
                            )}
                          </div>
                        </TableCell>
                        <TableCell>
                          {handleDSLA(lead.DSLC) === 0
                            ? "Today"
                            : handleDSLA(lead.DSLC) > 0
                            ? handleDSLA(lead.DSLC)
                            : "New"}
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Show Activity" placement="top">
                            <span
                              style={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleLeadActivityDisplay(lead);
                              }}
                            >
                              {handleDSLA(lead.recentActivity?.date) === 0
                                ? "Today"
                                : handleDSLA(lead.recentActivity?.date) > 0
                                ? handleDSLA(lead.recentActivity?.date)
                                : "New"}
                            </span>
                          </Tooltip>
                        </TableCell>
                        {/* TODO: Fix DSLC and DSLE */}
                        {selectedFilterCategory && selectedFilterCategory !== "callTomorrow" && (
                          <TableCell>
                            {selectedFilterCategory === "DSLC" ||
                            selectedFilterCategory === "DSLE"
                              ? handleDSLA(lead[selectedFilterCategory]) === 0
                                ? "Today"
                                : handleDSLA(lead[selectedFilterCategory]) > 0
                                ? handleDSLA(lead[selectedFilterCategory])
                                : "New"
                              : lead[selectedFilterCategory]}
                          </TableCell>
                        )}
                        <TableCell />
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}
              count={Math.ceil(totalLeads / itemsPerPage)}
              page={currentPage}
              onChange={(e, newPage) => {
                handlePageChange(e, newPage);
              }}
            />
          </Box>
        </div>
      )}
      <Menu
        anchorEl={filterAnchorEl}
        open={filterOpen}
        onClose={handleFilterClick}
      >
        {leadPropertiesArray.map((property, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              if (property !== "callTomorrow") {
                setSelectedFilterCategory(property);
              } else {
                setSortOrder("desc");
                setSortCategory("callTomorrow");
              }
              handleFilterClick();
            }}
          >
            {handleFilterCategoriesDisplay(property)}
          </MenuItem>
        ))}
      </Menu>
      {activityDisplay && (
        <ActivityLeadList
          lead={selectedActivitiesLead}
          selectedListLead={selectedActivitiesLead}
          onLeadListClose={handleCloseLeadActivityDisplay}
        />
      )}
    </div>
  );
};

export default LeadsList;
