import React, { useState } from 'react';
import { Button, Menu, MenuItem, Dialog, } from '@mui/material';
import AddEmployeeForm from 'components/forms/AddEmployeeForm';
import CSVUploader from '../tools/CSVUploader';
import PacketUpdater from './PacketUpdater';


const ManagerMenu = ({ onAgentAdd, onClose }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showAddEmployeeForm, setShowAddEmployeeForm] = useState(false);
  const [showCSVUploader, setShowCSVUploader] = useState(false);
  const [packetUpdateOpen, setPacketUpdateOpen] = useState(false);
  
  
  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const openAddEmployeeForm = () => {
    setShowAddEmployeeForm(true);
    closeMenu();
  };

  const closeAddEmployeeForm = () => {
    setShowAddEmployeeForm(false);
  };

  const handleAgentAdd = () => {
    
  };

  const handleCloseForm = () => {
    closeAddEmployeeForm();
  };

  const openCSVUploader = () => {
    setShowCSVUploader(true);
    closeMenu();
  };

  const closeCSVUploader = (event, reason) => {
    if (reason !== "backdropClick") {
      setShowCSVUploader(false);
    }
  };

  const handleDisplayPacketUpdateForm = () => {
    setPacketUpdateOpen(prev => !prev);
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={openMenu}>
        Manager Menu
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu} keepMounted>
        <MenuItem onClick={openAddEmployeeForm}>Add Employee</MenuItem>
        <MenuItem onClick={openCSVUploader}>Upload CSV</MenuItem>
        <MenuItem onClick={handleDisplayPacketUpdateForm}>Update Packet</MenuItem>
      </Menu>
      <Dialog open={showAddEmployeeForm} onClose={handleCloseForm} fullWidth maxWidth="md">
        <AddEmployeeForm onAgentAdd={handleAgentAdd} onClose={handleCloseForm} />
      </Dialog>
      <Dialog open={showCSVUploader} onClose={closeCSVUploader} fullWidth maxWidth="md">
        <CSVUploader onClose={closeCSVUploader} />
      </Dialog>
      <Dialog open={packetUpdateOpen} onClose={handleDisplayPacketUpdateForm} fullWidth maxWidth="md">
        <PacketUpdater onClose={handleDisplayPacketUpdateForm}/>
      </Dialog>
    </>
  );
};

export default ManagerMenu;

