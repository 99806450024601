import { io } from 'socket.io-client';

export const setupWebSocket = (url, componentName) => {
  const socket = io(url, {
    withCredentials: true,
    query: { component: componentName },
  });

  return socket;
};
