import { Box, Checkbox, Dialog, DialogTitle, FormControlLabel, FormGroup } from "@mui/material";
import { useState } from "react";

const EventSettingsMenu = ({ userRole, eventSettingsOpen, handleCloseEventSettings }) => {
    const [eventChecked, setEventChecked] = useState([false, false, false]);
    const [anniversaryChecked, setAnniversaryChecked] = useState([false, false, false]);

    const handleReminderChange = (e) => {
        setEventChecked([e.target.checked, e.target.checked, e.target.checked]);
    };

    const handleReminderCheck = (e, index) => {
        const clone = structuredClone(eventChecked);
        clone[index] = e.target.checked;
        setEventChecked(clone);
    };

    const handleAnniversaryChange = (e) => {
        setAnniversaryChecked([e.target.checked, e.target.checked, e.target.checked]);
    };

    const handleAnniversaryCheck = (e, index) => {
        const clone = structuredClone(anniversaryChecked);
        clone[index] = e.target.checked;
        setAnniversaryChecked(clone);
    };

    return (
        <Dialog open={eventSettingsOpen} onClose={handleCloseEventSettings} fullWidth maxWidth="sm">
            <DialogTitle sx={{ textAlign: 'center' }}>Event Reminder Settings</DialogTitle>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-around", padding: "20px 20px" }}>
                <FormGroup>
                    <FormControlLabel
                        label="Email me event reminders"
                        control={
                            <Checkbox 
                                checked={eventChecked[0] && eventChecked[1] && eventChecked[2]}
                                indeterminate={eventChecked[0] !== eventChecked[1] || eventChecked[0] !== eventChecked[2] || eventChecked[1] !== eventChecked[2]}
                                onChange={handleReminderChange}
                            />
                        }
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                        <FormControlLabel
                            label="The morning of"
                            control={<Checkbox checked={eventChecked[0]} onChange={(e) => handleReminderCheck(e, 0)}/>}
                        />
                        <FormControlLabel
                            label="The day before"
                            control={<Checkbox checked={eventChecked[1]} onChange={(e) => handleReminderCheck(e, 1)}/>}
                        />
                        <FormControlLabel
                            label="The week before"
                            control={<Checkbox checked={eventChecked[2]} onChange={(e) => handleReminderCheck(e, 2)}/>}
                        />
                    </Box>
                </FormGroup>
                {(userRole === "superadmin" || userRole === "manager") && (
                    <FormGroup>
                        <FormControlLabel 
                            label="Email me anniversary reminders"
                            control={
                                <Checkbox 
                                    checked={anniversaryChecked[0] && anniversaryChecked[1] && anniversaryChecked[2]}
                                    indeterminate={anniversaryChecked[0] !== anniversaryChecked[1] || anniversaryChecked[0] !== anniversaryChecked[2] || anniversaryChecked[1] !== anniversaryChecked[2]}
                                    onChange={handleAnniversaryChange}
                                />
                            }
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                            <FormControlLabel
                                label="The morning of"
                                control={<Checkbox checked={anniversaryChecked[0]} onChange={(e) => handleAnniversaryCheck(e, 0)}/>}
                            />
                            <FormControlLabel
                                label="The day before"
                                control={<Checkbox checked={anniversaryChecked[1]} onChange={(e) => handleAnniversaryCheck(e, 1)}/>}
                            />
                            <FormControlLabel
                                label="The week before"
                                control={<Checkbox checked={anniversaryChecked[2]} onChange={(e) => handleAnniversaryCheck(e, 2)}/>}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}></Box>
                    </FormGroup>
                )}
            </Box>
        </Dialog>
    )
}

export default EventSettingsMenu;