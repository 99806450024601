// import { Add, ChevronLeft, ChevronRight, ExpandMore } from "@mui/icons-material";
import { Box, Button, /* CircularProgress, Container, */ Dialog, DialogActions, DialogContent, DialogTitle, /* MenuItem, Select, */ Skeleton, Typography } from "@mui/material";
import { useState } from "react";
import { useGetGoogleCalendarEventsQuery } from "state/api";
// import WeekCalendar from "./WeekCalendar";
import AddCalendarEventForm from "components/forms/AddCalendarEventForm";
import { useEffect } from "react";

// TODO: Come back to
const Calendar = ({ user, toggleCalendar }) => {
    const now = new Date();
    const oneWeekFromNow = new Date(now);
    oneWeekFromNow.setDate(now.getDate() + 7);
    const oneMonthFromNow = new Date(now);
    oneMonthFromNow.setDate(oneMonthFromNow.getMonth() + 1);
    const [firstDate, setFirstDate] = useState(new Date(now.getFullYear(), now.getMonth(), 1));
    const [lastDate, setLastDate] = useState(new Date(now.getFullYear(), now.getMonth() + 1, 0));
    const [range, setRange] = useState({
        startDate: new Date(now.getFullYear(), now.getMonth(), 1).toISOString().split("T")[0],
        endDate: new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString().split("T")[0]
    });
    const { 
        data: events, 
        isLoading, 
        // isError,
        refetch
    } = useGetGoogleCalendarEventsQuery(range);
    // const [selectedDisplay, setSelectedDisplay] = useState("week");
    const [addEventOpen, setAddEventOpen] = useState(false);
    const [googleCalendarUrl, setGoogleCalendarUrl] = useState("");    

    // const handleDisplaySelect = (e) => {
    //     setSelectedDisplay(e.target.value);
    // };

    // const handleDateChange = (startDate, endDate) => {
    //     /* refetch({
    //         startDate: startDate.toISOString().split("T")[0],
    //         endDate: endDate.toISOString().split("T")[0],
    //     }); */
    // };

    // const handleMonthChange = (monthOffset) => {
    //     try {
    //         const newStartDate = new Date(firstDate);
    //         newStartDate.setMonth(newStartDate.getMonth() + monthOffset);
            
    //         const newEndDate = new Date(newStartDate.getFullYear(), newStartDate.getMonth() + 1, 0);

    //         setFirstDate(newStartDate);
    //         setLastDate(newEndDate);
    //         setRange({
    //             startDate: newStartDate.toISOString().split("T")[0],
    //             endDate: newEndDate.toISOString().split("T")[0],
    //         });

    //          /* refetch({
    //             startDate: newStartDate,
    //             endDate: newEndDate,
    //         }); */
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    const handleAddEventClose = () => {
        setAddEventOpen(false);
    }

    const handleGoogleCalendarUrlFetch = () => {
        setGoogleCalendarUrl('');
        setTimeout(() => {
            setGoogleCalendarUrl(`https://calendar.google.com/calendar/embed?src=${user.email}&ctz=America%2FChicago`)
        }, 500);
    };

    useEffect(() => {
        setTimeout(() => {
            setGoogleCalendarUrl(`https://calendar.google.com/calendar/embed?src=${user.email}&ctz=America%2FChicago`)
        }, 500);
    }, []);


    return (
        <Dialog
            open={true}
            onClose={toggleCalendar}
            maxWidth="xl" 
            fullWidth 
        >
            <DialogTitle>
                <Box sx={{ textAlign: "center", fontWeight: "bold", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    Calendar
                </Box>
                {/* <Container style={{ display: "flex", alignItems: "center", backgroundColor: "white", justifyContent: "space-between" }}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <iframe src="https://ssl.gstatic.com/calendar/images/dynamiclogo_2020q4/calendar_2_2x.png" style={{ border: "none", width: "80px", height: "80px" }}/>
                        <Typography variant="h3" style={{ color: "black" }}>Calendar</Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <button style={{
                            border: "grey .5px solid",
                            borderRadius: "5px",
                            lineHeight: 2,
                            padding: "5px 10px",
                            fontSize: "1rem",
                            backgroundColor: "white",
                            marginRight: "25px"
                        }}>Today</button>
                        <ChevronLeft 
                            style={{ color: "grey", cursor: "pointer" }}
                            onClick={() => handleMonthChange(-1)}
                        />
                        <ChevronRight 
                            style={{ color: "grey", cursor: "pointer" }}
                            onClick={() => handleMonthChange(1)}
                        />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <Typography variant="h3" style={{ color: "black" }}>{user.name} Calendar</Typography>
                        <Typography variant="h5" style={{ color: "black" }}>{firstDate.toUTCString().slice(8, 16)}</Typography>
                    </Box>
                    <Box style={{ border: ".5px solid black", color: "black", display: "flex", alignItems: "center", borderRadius: "3px" }}>
                        <Select
                            style={{ color: "black" }}
                            value={selectedDisplay}
                            onChange={handleDisplaySelect}
                        >
                            <MenuItem value="day">Day</MenuItem>
                            <MenuItem value="week">Week</MenuItem>
                            <MenuItem value="month">Month</MenuItem>
                        </Select>
                        <ExpandMore />
                    </Box>
                    <Box style={{ 
                        boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.84", 
                        borderRadius: "10px", 
                        display: "flex", 
                        height: "50px", 
                        width: "120px", 
                        alignItems: "center",
                        cursor: "pointer" 
                        }}
                        onClick={() => setAddEventOpen(!addEventOpen)}
                    >
                        <Add style={{ color: "black", width: "50%", height: "100%" }}/>
                        <Typography variant="h5" style={{ color: "black" }}>Add Event</Typography>
                    </Box>
                </Container>
                {selectedDisplay === "week" && (
                    <WeekCalendar />
                )} */}
            </DialogTitle>
            <DialogContent>
                {isLoading || googleCalendarUrl === '' ? (
                    <Skeleton animation="wave" variant="rounded" width="90%" height="30rem" sx={{ margin: '1.5rem 2.5rem', padding: 2 }}/>
                    // <CircularProgress />
                ) : (
                    <Box sx={{ textAlign: "center", fontWeight: "bold", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <iframe src={googleCalendarUrl} style={{border: "0", width: "80rem", height: "40rem", frameBorder: "0", scrolling: "no"}}></iframe>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={handleGoogleCalendarUrlFetch}
                >
                    Refetch
                </Button> */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setAddEventOpen(!addEventOpen)}
                >
                    <Typography variant="h6">Add Event</Typography>
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    onClick={toggleCalendar}
                >
                    Close
                </Button>
            </DialogActions>
            {addEventOpen && (
                <AddCalendarEventForm handleCloseEventForm={handleAddEventClose} googleCalendarRefetch={handleGoogleCalendarUrlFetch}/>
            )}
        </Dialog>
    )
}

export default Calendar;