import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { enqueueSnackbar } from "notistack";
import { useRef, useState } from "react";
import { useAddSignatureMutation } from "state/api";

const SignatureForm = ({ user, onClose, refetch }) => {
    const [signatureType, setSignatureType] = useState(0);
    const [newSignatureName, setNewSignatureName] = useState("");
    const [textPng, setTextPng] = useState(null);
    const [textPngFile, setTextPngFile] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadError, setUploadError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [signature, setSignature] = useState(""); // For text field signature
    const canvasRef = useRef(null);

    const [uploadSignature] = useAddSignatureMutation();

    // Handlers
    const handleInputChange = (e) => {
        setSignature(e.target.value);
    };

    const handleConvertToPng = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext("2d");

        // Set font and calculate the width and height required for the text
        const fontSize = 30;
        const lineHeight = fontSize * 1.2; // line height for spacing between lines
        context.font = `${fontSize}px Arial`;
        const lines = signature.split("\n");

        const canvasWidth = Math.max(...lines.map(line => context.measureText(line).width)) + 40; // Add padding
        const canvasHeight = lines.length * lineHeight + 40; // Add padding for height

        // Set canvas dimensions based on the text size
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        // Fill canvas with white background
        context.fillStyle = "#fff";
        context.fillRect(0, 0, canvas.width, canvas.height);

        // Set text color and style
        context.fillStyle = "#000"; // Black text
        context.font = `${fontSize}px Arial`;

        // Draw each line of text on the canvas
        lines.forEach((line, index) => {
            context.fillText(line, 20, 40 + index * lineHeight); // Draw each line with padding
        });

        // Convert canvas to PNG
        const dataUrl = canvas.toDataURL();

        // Save the PNG data in the state for preview
        setTextPng(dataUrl);

        // Convert to Blob and File
        const blob = handleDataURLToBlob(dataUrl);
        const file = new File([blob], `${newSignatureName}.png` || "signature.png", { type: "image/png" });

        setTextPngFile(file);
    };

    // Helper function to convert base64 data URL to Blob
    const handleDataURLToBlob = (dataUrl) => {
        const arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    };

    const handleAddSignature = (e) => {
        const signature = e.target.files;
        setSelectedFile(signature);
    };

    const handleSignatureUpload = async () => {
        if (!selectedFile && !textPngFile) {
            setUploadError(true);
            setErrorMessage("Nothing to upload");
            return;
        }

        if (newSignatureName === "") {
            setUploadError(true);
            setErrorMessage("Signature must have a name");
        }

        const formData = new FormData();
        formData.append("signatureName", newSignatureName);

        if (signatureType === 0) {
            formData.append("signature", selectedFile[0]);
        } else {
            formData.append("signature", textPngFile);
        }

        try {
            await uploadSignature({ userId: user._id, formData }).unwrap()
                .then(() => {
                    refetch();
                    enqueueSnackbar(`${newSignatureName} uploaded`);
                    onClose();
                });
        } catch (error) {
            console.error("Error:", error);
            setUploadError(true);
            setErrorMessage(error.message);
        }
    };

    return (
        <Box>
            <Box sx={{ margin: 2 }}>
                <Box sx={{ mb: '40px', width: '10%' }}>
                    <Typography>Signature Type</Typography>
                    <Slider
                        shiftStep={1}
                        step={1}
                        min={0}
                        max={1}
                        value={signatureType}
                        onChange={(e) => setSignatureType(e.target.value)}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography
                            variant="body2"
                            onClick={() => setSignatureType(0)}
                            sx={{ cursor: 'pointer' }}
                        >
                            File
                        </Typography>
                        <Typography
                            variant="body2"
                            onClick={() => setSignatureType(1)}
                            sx={{ cursor: 'pointer' }}
                        >
                            Text
                        </Typography>
                    </Box>
                </Box>
                <input
                    type="file"
                    id="signatureInput"
                    style={{ display: 'none' }}
                    onChange={handleAddSignature}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', marginBottom: '20px' }}>
                    <InputLabel htmlFor="signatureName">Signature Name:</InputLabel>
                    <Input
                        name="signatureName"
                        type="text"
                        value={newSignatureName}
                        style={{ marginBottom: 20 }}
                        onChange={(e) => setNewSignatureName(e.target.value)}
                    />
                    {signatureType ? (
                        <>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                                <Box sx={{ width: '50%', }}>
                                    <InputLabel htmlFor="signatureText">Signature Text:</InputLabel>
                                    <TextField
                                        type="text"
                                        value={signature}
                                        onChange={handleInputChange}
                                        placeholder="Enter signature text"
                                        multiline
                                        minRows={4}
                                        style={{ marginBottom: 10, width: '100%' }}
                                    />
                                </Box>
                                <Box sx={{ ml: 5 }}>
                                    <h4>Preview:</h4>
                                    {textPng && (
                                        <div>
                                            <img src={textPng} alt="Text as PNG" style={{ maxWidth: '100%' }} />
                                        </div>
                                    )}
                                </Box>
                            </Box>
                            <Box>
                                <Button
                                    variant="contained"
                                    onClick={handleConvertToPng}
                                    style={{ marginBottom: 10 }}
                                >
                                    Convert text to PNG
                                </Button>
                            </Box>
                            <canvas ref={canvasRef} style={{ display: 'none' }} />
                        </>
                    ) : (
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography>
                                {selectedFile ? selectedFile[0]?.name : "No file selected"}
                            </Typography>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    document.getElementById("signatureInput").click();
                                }}
                            >
                                Add File
                            </Button>
                        </Box>
                    )}

                </Box>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSignatureUpload()}
                        disabled={(signatureType === 1 && !textPngFile)}
                    >
                        Upload
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{ ml: 1 }}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
            <Box
                sx={{
                    marginTop: '5px',
                    width: '100%',
                    maxHeight: 'calc(100vh - 100px',
                    overflow: 'auto',
                    marginBottom: '5px',
                }}
            >
                {uploadError && (
                    <Alert severity="error">
                        {errorMessage}
                    </Alert>
                )}
            </Box>
        </Box>
    )
};

export default SignatureForm