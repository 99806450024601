import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Close from "@mui/icons-material/Close";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Divider from "@mui/material/Divider";
import { DateFormatter } from 'components/helpers/DateTimeHelpers';
import Box from '@mui/material/Box';
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useUpdateUserStatsByDateMutation } from 'state/managementApi';
import { enqueueSnackbar } from 'notistack';

const EditAgentDayStatsForm = ({ stats, onClose }) => {
    const [updateStats] = useUpdateUserStatsByDateMutation();

    const formik = useFormik({
        initialValues: {
            setups: stats.setups || 0,
            loadsBooked: stats.loadsBooked || 0,
            quotes: stats.quotes || 0,
            margin: stats.margin || 0,
            dailyNewCalls: stats.dailyNewCalls || 0,
            dailyCalls: stats.dailyCalls || 0,
            packets: stats.packets || 0,
            dailyEmails: stats.dailyEmails || 0,
            dailyListEmails: stats.dailyListEmails || 0,
        },
        validationSchema: Yup.object({
            setups: Yup.number(),
            loadsBooked: Yup.number(),
            quotes: Yup.number(),
            margin: Yup.number(),
            dailyNewCalls: Yup.number(),
            dailyCalls: Yup.number(),
            packets: Yup.number(),
            dailyEmails: Yup.number(),
            dailyListEmails: Yup.number(),
        }),
        onSubmit: (values) => {
            handleUpdateStats(values);
        },
    });

    const handleUpdateStats = async (updatedStats) => {
        try {
            await updateStats({ statsId: stats._id, stats: updatedStats })
                .then(() => {
                    enqueueSnackbar("Stats Updated");
                    onClose();
                })
        } catch (error) {
            console.error('Error updating stats:', error);
        }
    };

    return (
        <Dialog open onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                    <Typography variant='h6'>Update {/* {DateFormatter(stats.createdAt)} */} Stats for {stats.userName}</Typography>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </div>
                <Divider sx={{ mb: "15px" }} />
            </DialogTitle>
            <DialogContent>
                <Box>
                    {/* TODO: Handle negative values */}
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Margin"
                        name="margin"
                        type="number"
                        value={formik.values.margin}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.margin && formik.errors.margin}
                        helperText={formik.touched.margin && formik.errors.margin}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="New Calls"
                        name="dailyNewCalls"
                        type="number"
                        value={formik.values.dailyNewCalls}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.dailyNewCalls && formik.errors.dailyNewCalls}
                        helperText={formik.touched.dailyNewCalls && formik.errors.dailyNewCalls}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="FU Calls"
                        name="dailyCalls"
                        type="number"
                        value={formik.values.dailyCalls}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.dailyCalls && formik.errors.dailyCalls}
                        helperText={formik.touched.dailyCalls && formik.errors.dailyCalls}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Packets"
                        name="packets"
                        type="number"
                        value={formik.values.packets}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.packets && formik.errors.packets}
                        helperText={formik.touched.packets && formik.errors.packets}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Quotes"
                        name="quotes"
                        type="number"
                        value={formik.values.quotes}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.quotes && formik.errors.quotes}
                        helperText={formik.touched.quotes && formik.errors.quotes}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="FU Emails"
                        name="dailyEmails"
                        type="number"
                        value={formik.values.dailyEmails}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.dailyEmails && formik.errors.dailyEmails}
                        helperText={formik.touched.dailyEmails && formik.errors.dailyEmails}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="List Emails"
                        name="dailyListEmails"
                        type="number"
                        value={formik.values.dailyListEmails}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.dailyListEmails && formik.errors.dailyListEmails}
                        helperText={formik.touched.dailyListEmails && formik.errors.dailyListEmails}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Loads Booked"
                        name="loadsBooked"
                        type="number"
                        value={formik.values.loadsBooked}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.loadsBooked && formik.errors.loadsBooked}
                        helperText={formik.touched.loadsBooked && formik.errors.loadsBooked}
                    />
                    <TextField
                        margin='normal'
                        fullWidth
                        label="Setups"
                        name='setups'
                        type='number'
                        value={formik.values.setups}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.setups && formik.errors.setups}
                        helperText={formik.touched.setups && formik.errors.setups}
                    />
                </Box>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={formik.handleSubmit}
                >
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default EditAgentDayStatsForm;