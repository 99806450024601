import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";
import { useUpdateStatsForUserByDayMutation } from "state/salesApi";
import * as Yup from 'yup';
import TextField from "@mui/material/TextField";
import { enqueueSnackbar } from "notistack";

const UpdatePastStatsForm = ({ stats, onClose, refetch }) => {
    const [updateStats] = useUpdateStatsForUserByDayMutation();

    const formik = useFormik({
        initialValues: {
            setups: stats.setups || 0,
            loadsBooked: stats.loadsBooked || 0,
            quotes: stats.quotes || 0,
            margin: stats.margin || 0,
            dailyNewCalls: stats.dailyNewCalls || 0,
            dailyCalls: stats.dailyCalls || 0,
            packets: stats.packets || 0,
            dailyEmails: stats.dailyEmails || 0,
            dailyListEmails: stats.dailyListEmails || 0,
        },
        validationSchema: Yup.object({
            setups: Yup.number(),
            loadsBooked: Yup.number(),
            quotes: Yup.number(),
            margin: Yup.number(),
            dailyNewCalls: Yup.number(),
            dailyCalls: Yup.number(),
            packets: Yup.number(),
            dailyEmails: Yup.number(),
            dailyListEmails: Yup.number(),
        }),
        onSubmit: (values) => {
            handleUpdateStats(values);
        }
    });

    const handleUpdateStats = async (updatedStats) => {
        try {
            await updateStats({ statsId: stats._id, stats: updatedStats })
                .then(() => {
                    enqueueSnackbar("Stats Updated");
                    refetch();
                    onClose();
                })
        } catch (error) {
            console.error('Error updating stats:', error);
        }
    };

    return (
        <Dialog open onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                    <Typography variant="h6">Update Previous Stats</Typography>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </div>
                <Divider />
            </DialogTitle>
            <DialogContent>
                <Box>
                    {/* TODO: Handle negative values */}
                    <TextField
                        margin='normal'
                        fullWidth
                        label="Setups"
                        name='setups'
                        type='number'
                        value={formik.values.setups}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.setups && formik.errors.setups}
                        helperText={formik.touched.setups && formik.errors.setups}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Loads Booked"
                        name="loadsBooked"
                        type="number"
                        value={formik.values.loadsBooked}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.loadsBooked && formik.errors.loadsBooked}
                        helperText={formik.touched.loadsBooked && formik.errors.loadsBooked}
                    />
                    <TextField
                        margin="normal"
                        fullWidth
                        label="Quotes"
                        name="quotes"
                        type="number"
                        value={formik.values.quotes}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.quotes && formik.errors.quotes}
                        helperText={formik.touched.quotes && formik.errors.quotes}
                    />
                    <TextField 
                        margin="normal"
                        fullWidth
                        label="Margin"
                        name="margin"
                        type="number"
                        value={formik.values.margin}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.margin && formik.errors.margin}
                        helperText={formik.touched.margin && formik.errors.margin}
                    />
                </Box>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={formik.handleSubmit}
                >
                    Update
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default UpdatePastStatsForm;