import React, {useState} from 'react';
import { 
  Box, 
  useTheme, 
  IconButton, 
  Grid, 
  Typography, 
  Container 
} from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from "formik";
import * as Yup from "yup";
import {useGetGoogleAuthUrlQuery, useLoginMutation} from "../../state/api";
import { 
  LightModeOutlined,
  DarkModeOutlined,
} from '@mui/icons-material';
import { setMode } from 'state';
import ktb from '../../assets/ktbwhite.png';
import GoogleButton from 'components/google/GoogleButton';

function Login() {
  const theme= useTheme()
  const dispatch= useDispatch()
  const navigate = useNavigate();
  const [loginMutation] = useLoginMutation();

  const { data: googleAuthUrl, isLoading } = useGetGoogleAuthUrlQuery();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Enter a valid email")
        .max(40, "Must be 40 characters or less.")
        .required("A valid email is Required"),
      password: Yup.string()
        .required("A valid password is required")
    }),
    onSubmit: async (values, helpers) => {
      let errors = []
      try {
        await loginMutation(
          {
            email: values.email,
            password: values.password,
          },
        ).then((response) => {          
          if (response.data) {
            localStorage.setItem('token', response.data.token)
            navigate('/dashboard')
          }
          if(response.error){
            errors.push(response.error);
          }
        })
      } catch (error) {
        console.error(error)
      }
    }
  })

  if (isLoading) return <div>Loading...</div>;

  return (
    <Container m="1.5rem 2.5rem" component="main" maxWidth="md"
    >
      <Box
        //display={isNonMobile ? "flex": "block"} width="100%" height="100%" display: "flex", in sx causes resizing issues
        padding="40px"
        sx={{  
          marginTop: 8,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: theme.palette.primary[900]
        }}
      >
        <Typography component="h1" variant="h5" sx={{display: "flex",alignItems: "center",
          justifyContent: "center",}}>
          LOGIN
          <IconButton onClick={() => dispatch(setMode())} >
            {theme.palette.mode === 'dark' ? (
              <DarkModeOutlined sx={{ fontSize: "25px"}} />
            ) : (
              <LightModeOutlined sx={{ fontSize: "25px"}} />
            )}
          </IconButton>
        </Typography>
        {theme.palette.mode === 'dark' ? (
          <Box component="img" //578X164png
            sx={{
              height: "100%",
              width: "100%",
              backgroundColor: theme.palette.primary[900] ,  
              padding:"10px",
            }}
            alt="KTB Logo"
            src={ktb}
          />
          ) : (
          <Box component="img" //578X164png
            sx={{
              height: "100%",
              width: "100%",
              backgroundColor: theme.palette.primary[100],
              padding:"10px",
            }}
            alt="KTB Logo"
            src={ktb}
          />
          )}        
        <Box component="form" onSubmit={formik.handleSubmit} noValidate sx={{ mt: 1 }}>
          <Grid container style={{ display: "flex", justifyContent: "center" }}>
            <GoogleButton accountMade={true} googleAuthUrl={googleAuthUrl}/>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
}


export default Login;