export const states = [
    {
        code: "AK",
        label: "Alaska"
    },
    {
        code: "AL",
        label: "Alabama"
    },
    {
        code: "AR",
        label: "Arkansas"
    },
    {
        code: "AZ",
        label: "Arizona"
    },
    {
        code: "CA",
        label: "California"
    },
    {
        code: "CO",
        label: "Colorado"
    },
    {
        code: "CT",
        label: "Connecticut"
    },
    {
        code: "DC",
        label: "District of Columbia"
    },
    {
        code: "DE",
        label: "Delaware"
    },
    {
        code: "FL",
        label: "Florida"
    },
    {
        code: "GA",
        label: "Georgia"
    },
    {
        code: "HI",
        label: "Hawaii"
    },
    {
        code: "IA",
        label: "Iowa"
    },
    {
        code: "ID",
        label: "Idaho"
    },
    {
        code: "IL",
        label: "Illinois"
    },
    {
        code: "IN",
        label: "Indiana"
    },
    {
        code: "KS",
        label: "Kansas"
    },
    {
        code: "KY",
        label: "Kentucky"
    },
    {
        code: "LA",
        label: "Louisiana"
    },
    {
        code: "MA",
        label: "Massachusetts"
    },
    {
        code: "MD",
        label: "Maryland"
    },
    {
        code: "ME",
        label: "Maine"
    },
    {
        code: "MI",
        label: "Michigan"
    },
    {
        code: "MN",
        label: "Minnesota"
    },
    {
        code: "MO",
        label: "Missouri"
    },
    {
        code: "MS",
        label: "Mississippi"
    },
    {
        code: "MT",
        label: "Montana"
    },
    {
        code: "NC",
        label: "North Carolina"
    },
    {
        code: "ND",
        label: "North Dakota"
    },
    {
        code: "NE",
        label: "Nebraska"
    },
    {
        code: "NH",
        label: "New Hampshire"
    },
    {
        code: "NJ",
        label: "New Jersey"
    },
    {
        code: "NM",
        label: "New Mexico"
    },
    {
        code: "NV",
        label: "Nevada"
    },
    {
        code: "NY",
        label: "New York"
    },
    {
        code: "OH",
        label: "Ohio"
    },
    {
        code: "OK",
        label: "Oklahoma"
    },
    {
        code: "OR",
        label: "Oregon"
    },
    {
        code: "PA",
        label: "Pennsylvania"
    },
    {
        code: "RI",
        label: "Rhode Island"
    },
    {
        code: "SC",
        label: "South Carolina"
    },
    {
        code: "SD",
        label: "South Dakota"
    },
    {
        code: "TN",
        label: "Tennessee"
    },
    {
        code: "TX",
        label: "Texas"
    },
    {
        code: "UT",
        label: "Utah"
    },
    {
        code: "VA",
        label: "Virginia"
    },
    {
        code: "VT",
        label: "Vermont"
    },
    {
        code: "WA",
        label: "Washington"
    },
    {
        code: "Wisconsin",
        label: "WI"
    },
    {
        code: "WV",
        label: "West Virginia"
    },
    {
        code: "WY",
        label: "Wyoming"
    },
    {
        code: "ON",
        label: "Ontario"
    },
    {
        code: "QC",
        label: "Quebec"
    },
    {
        code: "NS",
        label: "Nova Scotia"
    },
    {
        code: "NB",
        label: "New Brunswick"
    },
    {
        code: "MB",
        label: "Manitoba"
    },
    {
        code: "BC",
        label: "British Columbia"
    },
    {
        code: "PE",
        label: "Prince Edward Island"
    },
    {
        code: "SK",
        label: "Saskatchewan"
    },
    {
        code: "AB",
        label: "Alberta"
    },
    {
        code: "NL",
        label: "Newfoundland"
    }
];