const handleUpdateDifferences = async (initialValues, updatedValues) => {
    const differences = {};
    for (let key in updatedValues) {
      if (initialValues[key] !== updatedValues[key]) {
        differences[key] = updatedValues[key];
      };
    };

    return differences;
  };

export default handleUpdateDifferences;