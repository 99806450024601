import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, Divider, IconButton, MenuItem, Select, Typography } from "@mui/material";
import { useState } from "react";

const EmailSettingsMenu = ({ user, settings, handleCloseEmailSettings, saveSettings }) => {
    const [altEmails, setAltEmails] = useState(user.alternateEmails || []);
    // const [changesMade, setChangesMade] = useState(false);


    const handleUpdateSettings = (key, value) => {
        saveSettings(key, value);
    };

    return (
        <Dialog open onClose={handleCloseEmailSettings} fullWidth maxWidth="sm">
            <DialogTitle sx={{ paddingBottom: '0px' }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Typography variant="h6">Email Settings</Typography>
                    <IconButton onClick={handleCloseEmailSettings}>
                        <Close />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <Divider sx={{ marginBottom: '25px' }}/>
                <div>
                    <Typography><b>Primary Email</b></Typography>
                    <Select
                        value={settings.preferredEmail || 1}
                        onChange={(e) => handleUpdateSettings('preferredEmail', e.target.value)}
                    >
                        <MenuItem value={1}>{user.email || 'No email given'}</MenuItem>
                        {altEmails.map((alt, index) => (
                            <MenuItem key={index} value={index + 2}>{alt}</MenuItem>
                        ))}
                    </Select>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default EmailSettingsMenu;