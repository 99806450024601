import Edit from "@mui/icons-material/Edit";
import Info from "@mui/icons-material/Info";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import UpdateActivityForm from "components/forms/UpdateActivityForm";
import { DateTimeFormatter } from "components/helpers/DateTimeHelpers";
import { hoverStyle } from "components/helpers/MediaQueries";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useGetActivitiesByUserForDayQuery } from "state/salesApi";
import ActivityDetails from "./ActivityDetails";

const now = new Date();

const DailyActivitiesList = () => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  // const [day, setDay] = useState(now.toISOString()); // Handle change to activities from yesterday functionality
  const day = now.toISOString();

  const [selectedActivity, setSelectedActivity] = useState(null);
  // const [selectedActivities, setSelectedActivities] = useState([]);
  // const [selectedPages, setSelectedPages] = useState([]);

  const [detailsOpen, setDetailsOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);

  // const [activityType, setActivityType] = useState("");
  // const [activityStatus, setActivityStatus] = useState("");
  // const [page, setPage] = useState(1);
  // const [limit, setLimit] = useState(25);

  const {
    data: dailyActivities,
    isLoading,
    error,
    refetch,
  } = useGetActivitiesByUserForDayQuery(day);

  // Togglers
  const toggleDetailsOpen = () => setDetailsOpen(prev => !prev);
  const toggleUpdateOpen = () => setUpdateOpen((prev) => !prev);

  /* const toggleActivity = (selectedActivity) => {
    const isSelected = selectedActivities.includes(selectedActivity);

    if (isSelected) {
      setSelectedActivities((prev) =>
        prev.filter((activity) => activity !== selectedActivity)
      );
    } else {
      setSelectedActivities([...selectedActivities, selectedActivity]);
    }
  }; */

  /* const toggleAllActivities = () => {
    if (selectedPages.includes(page)) {
      setSelectedActivities((prev) =>
        prev.filter(
          (activity) => !dailyActivities.activities.includes(activity)
        )
      );
      setSelectedPages((prev) =>
        prev.filter((selectedPage) => selectedPage !== page)
      );
    } else {
      setSelectedActivities((prev) => [...prev, ...dailyActivities.activities]);
      setSelectedPages((prev) => [...prev, page]);
    }
  }; */

  // Handlers
  // const handleSort = (category) => {};

  const handleUpdate = () => {
    toggleUpdateOpen();
    enqueueSnackbar("Activity Updated");
    handleRefresh();
  };

  const handleRefresh = () => {
    refetch();
  };

  if (isLoading) return <CircularProgress />
  if (error && error?.data?.message !== 'No activities found for this date') {
    return <p>Error displaying activities</p>
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.grey[900],
        borderRadius: "4px",
        padding: theme.spacing(1),
      }}
    >
      <Paper elevation={4} sx={{ p: 2, mt: 2 }}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                {/* <TablePagination rowsPerPageOptions={[5, 10, 25, 50]} /> */}
              </TableRow>
              <TableRow>
                <TableCell>
                  <Box sx={{ display: 'flex', alignItems: 'center', }}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      // onClick={() => handleSort("name")}
                    >
                      Lead
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      // onClick={() => handleSort("type")}
                    >
                      Type
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      // onClick={() => handleSort("title")}
                    >
                      Title
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      // onClick={() => handleSort("description")}
                    >
                      Description
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      // onClick={() => handleSort("status")}
                    >
                      Status
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      // onClick={() => handleSort("dateCreated")}
                    >
                      Date
                    </Typography>
                  </Box>
                </TableCell>
                {/* <TableCell sx={{ p: "6px 0px" }} /> */}
                <TableCell sx={{ p: "6px 0px" }} />
                <TableCell sx={{ p: "6px 0px" }} />
                <TableCell sx={{ p: "6px 0px" }} />
              </TableRow>
            </TableHead>
            <TableBody>
              {!dailyActivities ? (
                <TableRow>
                  <TableCell>
                    <Typography>No Activities Yet</Typography>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                </TableRow>
              ) : (
                dailyActivities.activities.map((activity, index) => (
                  <TableRow key={index}>
                    {/* <TableCell sx={{ padding: "0 25px" }}>
                      <input
                        type="checkbox"
                        checked={
                          !!selectedActivities.find(
                            (selected) => selected._id === activity._id
                          )
                        }
                        onChange={() => toggleActivity(activity)}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </TableCell> */}
                    <TableCell>
                      {activity.leadIds && activity.leadIds.length > 1 ? (
                        <span>
                          Multiple
                        </span>
                      ) : activity.leadIds && activity.leadIds.length === 1 ? (
                        <span>
                          {activity.leadIds[0].name}
                        </span>
                      ) : (
                        <span>
                          No Data
                        </span>
                      )}
                    </TableCell>
                    <TableCell>
                      {activity.type
                        .replace(/([A-Z])/g, " $1")
                        .replace(/^./, function (str) {
                          return str.toUpperCase();
                        })}
                    </TableCell>
                    <TableCell>
                      {activity.title}
                    </TableCell>
                    <TableCell>{activity.description}</TableCell>
                    <TableCell>
                      {activity.status
                        .replace(/([A-Z])/g, " $1")
                        .replace(/^./, function (str) {
                          return str.toUpperCase();
                        })}
                    </TableCell>
                    <TableCell>{DateTimeFormatter(activity.date)}</TableCell>
                    <TableCell>
                      <Info 
                        sx={hoverStyle}
                        onClick={() => {
                          setSelectedActivity(activity);
                          toggleDetailsOpen();
                        }}
                      />
                    </TableCell>
                    <TableCell sx={{ p: "6px 0px" }}>
                      <Edit
                        sx={hoverStyle}
                        onClick={() => {
                          setSelectedActivity(activity);
                          toggleUpdateOpen();
                        }}
                      />
                    </TableCell>
                    <TableCell />
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {detailsOpen && (
        <ActivityDetails 
          activityId={selectedActivity._id}
          handleDetailsClose={toggleDetailsOpen}
        />
      )}
      {updateOpen && (
        <UpdateActivityForm
          activityData={selectedActivity}
          onClose={toggleUpdateOpen}
          onSubmit={handleUpdate}
        />
      )}
    </Box>
  );
};

export default DailyActivitiesList;
