import { api } from "./api";

export const dispatchApi = api.injectEndpoints({
  endpoints: (build) => ({
    getColumn: build.query({
      query: (columnId) => `/general/column/${columnId}`,
    }),
    updateColumn: build.mutation({
      query: ({ updatedColumn }) => ({
        url: `/general/column/update`,
        method: "POST",
        body: {
          sourceColumnId: updatedColumn.sourceColumnId,
          destinationColumnId: updatedColumn.destinationColumnId,
          cardId: updatedColumn.cardId,
          newPosition: updatedColumn.newPosition,
        },
      }),
    }),
    addCardToBookedColumn: build.mutation({
      query: (newCard) => ({
        url: `/general/booked/add-card`,
        method: "POST",
        body: {
          title: newCard.title,
          subtitle: newCard.subtitle,
          notes: newCard.notes,
          description: newCard.description,
          color: newCard.color,
        },
      }),
    }),
    removeCardFromColumn: build.mutation({
      query: ({ columnId, cardId }) => ({
        url: `/general/column/${columnId}/remove-card/${cardId}`,
        method: "DELETE",
      }),
    }),
    deleteCard: build.mutation({
      query: (cardId) => ({
        url: `/general/card/${cardId}`,
        method: "DELETE",
      }),
    }),
    updateCard: build.mutation({
      query: ({ cardId, cardUpdates }) => ({
        url: `/general/card/${cardId}`,
        method: "PUT",
        body: cardUpdates,
      }),
    }),
    archiveCard: build.mutation({
      query: (cardId) => ({
        url: `/general/card/${cardId}/archive`,
        method: "PUT",
      }),
    }),
  }),
});

export const {
  useGetColumnQuery,
  useUpdateColumnMutation,
  useAddCardToBookedColumnMutation,
  useRemoveCardFromColumnMutation,
  useDeleteCardMutation,
  useUpdateCardMutation,
  useArchiveCardMutation,
} = dispatchApi;
