import { Alert, Box, Button, Dialog, DialogTitle, Input, InputLabel, MenuItem, Stack, Typography } from "@mui/material";
import SignatureForm from "components/forms/SignatureForm";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useAddSignatureMutation, useDeleteSignatureMutation, useGetSignaturesQuery } from "state/api";

const SignatureMenu = ({ user, signatureSettingsOpen, handleCloseSignatureSettings }) => {
    const { data: signatures, error, isLoading, refetch } = useGetSignaturesQuery(user._id);
    const [newSignatureFormOpen, setNewSignatureFormOpen] = useState(false);
    const [newSignatureName, setNewSignatureName] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadSignature] = useAddSignatureMutation(); 
    const [uploadError, setUploadError] = useState(false);
    const [deleteSignature] = useDeleteSignatureMutation();
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [deleteError, setDeleteError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const { enqueueSnackbar } = useSnackbar();


    const handleAddSignature = (e) => {
        const signature = e.target.files;
        setSelectedFile(signature)
    }

    const handleNewSignatureClose = () => {
        setNewSignatureFormOpen(false);
        setNewSignatureName("");
        setSelectedFile(null);
      };

    const handleSignatureUpload = async () => {
        if (!selectedFile) {
            setUploadError(true)
            setErrorMessage("No file selected");
            return;
        }

        const formData = new FormData();
        formData.append("signatureName", newSignatureName);
        formData.append("signature", selectedFile[0]);

        try {
            await uploadSignature({ userId: user._id, formData}).unwrap()
                .then(() => {
                    setNewSignatureFormOpen(false);
                    refetch();
                    enqueueSnackbar(`${newSignatureName} uploaded`);
                });
            setUploadError(false);
            setErrorMessage("");
        } catch (error) {
            console.error("Error:", error);
        }
    }

    const handleSignatureDelete = async (signatureName) => {
        await deleteSignature({ userId: user._id, signatureName: signatureName })
            .then(() => {
                // console.log("Console 2",signatureName)
                enqueueSnackbar("Signature deleted");
                refetch();
            })
            .catch((error) => {
                console.error('Error:', error);
                setDeleteError(true);
            });
    }

    if (isLoading) return <div>Loading...</div>;
    if (error || !signatures) return <div>Error loading signatures.</div>;

    return (
        <>
            <Dialog open={signatureSettingsOpen} onClose={handleCloseSignatureSettings} fullWidth maxWidth="lg">
                <DialogTitle sx={{ textAlign: 'center' }}>Signature Settings</DialogTitle>
                <Stack spacing={2}>
                    {signatures.map((signature, index) => (
                        <MenuItem key={index} style={{ display: "flex", justifyContent: "space-around" }}>
                            <img src={signature.data} alt={signature.name} style={{ width: "40%", }} />
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => handleSignatureDelete(signature.name)}
                            >
                                Delete
                            </Button>
                        </MenuItem>
                    ))}
                </Stack>
                {/* <input
                    type="file"
                    id="signatureInput"
                    style={{ display: "none" }}
                    onChange={handleAddSignature}
                /> */}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setNewSignatureFormOpen(!newSignatureFormOpen)}
                    sx={{ marginTop: "20px" }}
                >
                    Add Signature
                </Button>
                {/* {newSignatureFormOpen && (
                    <Box sx={{ margin: 2 }}>
                        <Box style={{ display: "flex", flexDirection: "column", marginBottom: "20px" }}>
                            <InputLabel htmlFor="signatureName">Signature Name:</InputLabel>
                            <Input
                                name="signatureName"
                                type="text"
                                value={newSignatureName}
                                style={{ marginBottom: 20 }}
                                onChange={(e) => setNewSignatureName(e.target.value)}
                            />
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <Typography>
                                    {selectedFile ? selectedFile[0]?.name : "No file selected"}
                                </Typography>
                                <Button
                                    variant="contained"
                                    onClick={() => {
                                        document.getElementById("signatureInput").click();
                                    }}
                                >
                                    Add File
                                </Button>
                            </Box>
                        </Box>
                        <Box>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleSignatureUpload()}
                            >
                                Upload
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                sx={{ ml: 1 }}
                                onClick={handleNewSignatureClose}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                )} */}
                {newSignatureFormOpen && (
                    <SignatureForm user={user} onClose={() => setNewSignatureFormOpen(false)} refetch={refetch}/>
                )}
                <Box
                    sx={{
                        marginTop: '5px',
                        width: "100%",
                        maxHeight: 'calc(100vh - 100px',
                        overflow: 'auto',
                        marginBottom: '5px'
                    }}
                >
                    {uploadError && (
                        <Alert severity="error">
                            {errorMessage}
                        </Alert>
                    )}
                    {deleteError && (
                        <Alert severity="error">
                            Error deleting signature.
                        </Alert>
                    )}
                </Box>
            </Dialog>
        </>
    )
}

export default SignatureMenu;