import {
  Add,
  ArrowDropDown,
  ArrowDropUp,
  Attachment,
  // TextFormat,
} from "@mui/icons-material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { UpdateSettings } from "components/helpers/UpdateSettings";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetSignaturesQuery, useSendEmailMutation } from "state/api";
import { useCreateManagerActivityMutation } from "state/managementApi";
import { useCreateActivityForLeadsMutation } from "state/salesApi";
import * as Yup from "yup";

function ComposeEmail({
  handleCloseEmailForm,
  arrayOfEmails,
  arrayOfLeadIds,
  /* setEmailSent, */ 
  isManager = false,
  DNAIds,
}) {
  const user = useSelector((state) => state.user);
  const [userSettings, setUserSettings] = useState(
    JSON.parse(localStorage.getItem("settings")) || null
  );
  // TODO: DOUBLE CHECK THIS
  const [senderAddress, setSenderAddress] = useState(
    user.alternateEmails.length && (userSettings && userSettings.preferredEmail)
      ? userSettings?.preferredEmail
      : 1
  );
  const [attachments, setAttachments] = useState([]);
  // const [deliveryDate, setDeliveryDate] = useState("");
  const {
    data: signatures,
    // error,
    // isLoading,
  } = useGetSignaturesQuery(user._id);
  const [sendEmail] = useSendEmailMutation();
  const [emailResponseMessage, setEmailResponseMessage] = useState([]);
  const [formErrors, setFormErrors] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  // Set 'Add Recipient' dialog open if composing new email and not emailing a lead
  const [openAddEmailDialog, setOpenAddEmailDialog] = useState(
    arrayOfEmails.length === 0
  );
  const [newEmailAddress, setNewEmailAddress] = useState("");
  const [sendOptionsOpen, setSendOptionsOpen] = useState(false);
  // const [deliveryDatetimeOpen, setDeliveryDatetimeOpen] = useState(false);
  const [addEmailErrors, setAddEmailErrors] = useState([]);

  const [leadIds, setLeadIds] = useState(arrayOfLeadIds);
  // const leadIds = arrayOfLeadIds;
  const [addEmailActivityMutation] = useCreateActivityForLeadsMutation();
  const [addManagerActivity, /* { isLoading: adding } */] =
    useCreateManagerActivityMutation();

  // Validation schema for manually adding emails
  const addEmailSchema = Yup.object().shape({
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
        "Invalid email"
      )
      .required("Email is required"),
  });

  const handleAddEmailSubmit = async () => {
    try {
      await addEmailSchema.validate({
        email: newEmailAddress,
      });

      const formattedNewEmail =
        formik.values.to.trim().length > 0
          ? `, ${newEmailAddress}`
          : newEmailAddress;

      formik.setFieldValue("to", `${formik.values.to} ${formattedNewEmail}`);
      formik.setFieldValue("numLeads", formik.values.numLeads++);

      setNewEmailAddress("");
      setAddEmailErrors([]);
      setOpenAddEmailDialog(false);
    } catch (err) {
      setAddEmailErrors(err.errors);
    }
  };

  const formik = useFormik({
    initialValues: {
      userId: user._id,
      to: arrayOfEmails.join(", "),
      emailSubject: "",
      emailText: "",
      deliveryTime: null,
      signatureName: "",
      packetIncluded: false,
      numLeads: arrayOfEmails.length,
      senderEmail: user.email,
    },
    validationSchema: Yup.object({
      to: Yup.string().required("Recipient email is required"),
      emailSubject: Yup.string().required("Email subject is required"),
      emailText: Yup.string().required("Email message is required"),
      deliveryTime: Yup.date()
        .nullable()
        .test(
          "is-future-time",
          "Delivery time must be at least 15 minutes in the future",
          function (value) {
            if (value) {
              const currentTime = new Date();
              return (
                value > currentTime &&
                value > currentTime.getTime() + 15 * 60 * 1000
              );
            }
            return true;
          }
        ),
      packetIncluded: Yup.boolean(),
      numLeads: Yup.number(),
      senderEmail: Yup.string(),
    }),

    onSubmit: async (values, helpers) => {
      let errors = [];
      const formData = new FormData();

      let activityData = {
        isListEmail: false,
        type: "",
      };

      if (DNAIds && DNAIds.length) {
        const filteredLeadIds = leadIds.filter((id) => !DNAIds.includes(id));
        activityData.leadIds = filteredLeadIds;
      } else {
        activityData.leadIds = leadIds;
      }

      formData.append("userId", values.userId);
      formData.append("to", values.to);
      formData.append("subject", values.emailSubject);
      formData.append("text", values.emailText);

      if (values.deliveryTime) {
        const deliveryDateTime = new Date(values.deliveryTime);

        if (!isNaN(deliveryDateTime)) {
          formData.append("deliveryTime", deliveryDateTime.toISOString());
        }
      }

      formData.append("signatureName", values.signatureName);

      for (const attachment of attachments) {
        formData.append("attachments", attachment);
      }

      formData.append("packetIncluded", values.packetIncluded);
      formData.append("numLeads", values.numLeads);

      // Logic for handling sender address
      if (senderAddress === 1) {
        formData.append("senderEmail", values.senderEmail);
      } else {
        formData.append(
          "senderEmail",
          user.alternateEmails[userSettings.preferredEmail - 2]
        );
      }

      try {
        await sendEmail(formData).unwrap();
        setEmailResponseMessage([
          { type: "success", message: "Email sent successfully!" },
        ]);
        if (!isManager) {
          activityData.text = values.emailText;

          if (leadIds.length > 1) {
            activityData.title = "List Email Sent";
            activityData.type = "listEmail";

            await addEmailActivityMutation(activityData);
          } else if (leadIds.length === 1) {
            activityData.title = "Email Sent";
            activityData.type = "email";

            await addEmailActivityMutation(activityData);
          }
        } else {
          activityData.description = values.emailText;

          if (leadIds.length > 1) {
            activityData.title = "List Email Sent";
            activityData.type = "listEmail";
            activityData.date = new Date();

            await addManagerActivity(activityData);
          } else if (leadIds.length === 1) {
            activityData.title = "Email Sent";
            activityData.type = "email";
            activityData.date = new Date();

            await addManagerActivity(activityData);
          }
        }
        formik.resetForm();
        setFormErrors([]);
        setAttachments([]);
        handleCloseEmailForm();
        enqueueSnackbar("Email sent");
      } catch (error) {
        console.error("Email send error:", error);
        errors.push(error);
        setFormErrors(errors);
        setEmailResponseMessage([
          {
            type: "error",
            message: error.message || "An Email error has occurred.",
          },
        ]);
      }
    },
  });

  const handleEmailSendDateClick = () => {
    // const minDateTime = new Date();
    // console.log("min", minDateTime.toISOString().split("T")[0]);
  };

  const handleAttachmentChange = (e) => {
    const files = e.target.files;
    setAttachments([...attachments, ...files]);
  };

  const handleAddEmailClick = () => {
    setOpenAddEmailDialog(true);
  };

  const handleAddEmailDialogClose = () => {
    setOpenAddEmailDialog(false);
  };

  const handlePreferredEmailSelect = () => {
    const updatedSettings = UpdateSettings("preferredEmail", senderAddress);
    setUserSettings(updatedSettings);
    setSenderAddress(updatedSettings["preferredEmail"]);
  };

  const handleSendOptionsDisplay = () => {
    setSendOptionsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (!user.alternateEmails.length) {
      handlePreferredEmailSelect();
    }
  }, []);

  if (!signatures) return;

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleCloseEmailForm}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6">New Message</Typography>
            <IconButton onClick={handleCloseEmailForm}>
              <CloseIcon />
            </IconButton>
          </div>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <div>
            <Typography sx={{ fontSize: "12px" }}>
              Sending from:{" "}
              {!userSettings ||
              !userSettings.preferredEmail ||
              userSettings.preferredEmail === 1 ||
              !user.alternateEmails.length
                ? `${user.email}`
                : `${user.alternateEmails[userSettings.preferredEmail - 2]}`}
            </Typography>
            <TextField
              label="To"
              variant="standard"
              fullWidth
              name="to"
              value={formik.values.to}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              margin="normal"
              required
              inputProps={{
                readOnly: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleAddEmailClick}>
                      <Add />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.touched.to && formik.errors.to && (
              <Alert severity="error">{formik.errors.to}</Alert>
            )}
            {/* <Button
              variant="contained"
              color="primary"
              onClick={handleAddEmailClick}
              style={{ marginTop: "10px" }}
            >
              <AddIcon />
              Add Email
            </Button> */}
            <TextField
              label="Subject"
              variant="standard"
              fullWidth
              name="emailSubject"
              value={formik.values.emailSubject}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.touched.emailSubject && formik.errors.emailSubject && (
              <Alert severity="error">{formik.errors.emailSubject}</Alert>
            )}
            <TextField
              label="Message"
              variant="outlined"
              fullWidth
              multiline
              rows={15}
              name="emailText"
              value={formik.values.emailText}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              margin="normal"
              required
              InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.touched.emailText && formik.errors.emailText && (
              <Alert severity="error">{formik.errors.emailText}</Alert>
            )}
            <TextField
              label="Delivery Time"
              variant="outlined"
              fullWidth
              name="deliveryTime"
              value={formik.values.deliveryTime || ""}
              type="datetime-local"
              onBlur={formik.handleBlur}
              margin="normal"
              onClick={handleEmailSendDateClick}
              onChange={(e) =>
                formik.setFieldValue("deliveryTime", e.target.value)
              }
              InputProps={{
                min: new Date().toISOString().split("T")[0], // Restrict to future dates
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {formik.touched.deliveryTime && formik.errors.deliveryTime && (
              <Alert severity="error">{formik.errors.deliveryTime}</Alert>
            )}
            <InputLabel id="signature">Signature</InputLabel>
            <Box>
              <Select
                labelId="signature"
                label="Signature"
                name="signatureName"
                value={formik.values.signatureName || ""}
                style={{ width: "100%", height: "100%" }}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              >
                {signatures.map((signature, index) => (
                  <MenuItem key={index} value={signature.name}>
                    <img src={signature.data} alt={signature.name} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
            <input
              type="file"
              id="attachmentInput"
              multiple
              style={{ display: "none" }}
              onChange={handleAttachmentChange}
            />
            {attachments.length > 0 && (
              <List>
                {attachments.map((attachment) => (
                  <ListItem key={attachment.name}>
                    <DeleteIcon
                      variant="contained"
                      onClick={() =>
                        setAttachments(
                          attachments.filter((a) => a !== attachment)
                        )
                      }
                    />
                    <ListItemIcon>
                      <AttachFileIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={attachment.name}
                      secondary={`${attachment.size} bytes`}
                    />
                  </ListItem>
                ))}
              </List>
            )}
            {/* <Button
              variant="contained"
              color="primary"
              onClick={() => {
                document.getElementById("attachmentInput").click();
              }}
            >
              Attach Files
            </Button> */}
            {/* <FormControlLabel
              control={
                <Checkbox
                  name="packetIncluded"
                  value={formik.values.packetIncluded}
                  onChange={formik.handleChange}
                />
              }
              label="Include packet"
              style={{
                marginLeft: "10px"
              }}
            /> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "10px",
              }}
            >
              <Box sx={{ display: "flex" }}>
                <form onSubmit={formik.handleSubmit}>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Tooltip title="Send" placement="top">
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                          borderRadius: "15px",
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",
                        }}
                      >
                        Send
                      </Button>
                    </Tooltip>
                    <Tooltip title="Send options" placement="top">
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          borderRadius: "15px",
                          borderTopLeftRadius: "0px",
                          borderBottomLeftRadius: "0px",
                          padding: "5px",
                          minWidth: "0",
                        }}
                        onClick={handleSendOptionsDisplay}
                      >
                        {!sendOptionsOpen ? <ArrowDropDown /> : <ArrowDropUp />}
                      </Button>
                    </Tooltip>
                  </Box>
                </form>
                {/* <Popover
                  open={sendOptionsOpen}
                  onClose={handleSendOptionsDisplay}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  Test
                </Popover> */}
                <Box sx={{ display: "flex", marginLeft: "15px" }}>
                  {/* <Tooltip title="Formatting" placement="top">
                    <IconButton sx={{ borderRadius: '5px', padding: '2px' }}>
                      <TextFormat sx={{ fontSize: '25px' }} />
                    </IconButton>
                  </Tooltip> */}
                  <Tooltip title="Attach Files" placement="top">
                    <IconButton
                      sx={{
                        rotate: "-90deg",
                        borderRadius: "5px",
                        padding: "2px",
                      }}
                      onClick={() => {
                        document.getElementById("attachmentInput").click();
                      }}
                    >
                      <Attachment />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <FormControlLabel
                control={
                  <Checkbox
                    name="packetIncluded"
                    value={formik.values.packetIncluded}
                    onChange={formik.handleChange}
                  />
                }
                label="Include packet"
                style={{
                  marginLeft: "10px",
                }}
              />
            </Box>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={openAddEmailDialog}>
        <DialogContent style={{ position: "relative" }}>
          <Box p={2}>
            <CloseIcon
              style={{
                position: "absolute",
                right: "5px",
                top: "5px",
                cursor: "pointer",
              }}
              onClick={handleAddEmailDialogClose}
            />
            {/* {addEmailErrors.length > 0 &&
              addEmailErrors.map((error, i) => (
                <Alert key={i} severity="error" style={{ marginTop: "8px" }}>
                  {error}
                </Alert>
              ))} */}
            <Typography>Send to:</Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <TextField
                value={newEmailAddress}
                onChange={(e) => setNewEmailAddress(e.target.value)}
                style={{ marginTop: "8px", marginRight: "8px" }}
              />
              {/* <Button
                variant="contained"
                color="primary"
                onClick={handleAddEmailSubmit}
                style={{ marginTop: "8px" }}
              >
                Add Email
              </Button> */}
            </Box>
            {(!userSettings ||
              !userSettings.preferredEmail) /* || userSettings.preferredEmail !== 1 */ &&
              user.alternateEmails.length > 0 && (
                <Box
                  mt="35px"
                  sx={{ display: "flex", flexDirection: "column" }}
                >
                  <Typography>Send from:</Typography>
                  <FormControl>
                    <Select
                      value={senderAddress}
                      onChange={(e) => {
                        setSenderAddress(e.target.value);
                      }}
                    >
                      <MenuItem value={1}>{user.email}</MenuItem>
                      {user.alternateEmails.map((alt, index) => (
                        <MenuItem key={index} value={index + 2}>
                          {alt}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      *Selecting an option will save it as your preferred email
                      in settings
                    </FormHelperText>
                  </FormControl>
                </Box>
              )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleAddEmailSubmit();
              handlePreferredEmailSelect();
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <div style={{ position: "relative" }}>
        {emailResponseMessage.length > 0 &&
          emailResponseMessage.map((message, index) =>
            message.type === "error" ? (
              <Alert key={index} severity={message.type}>
                {message.message}
              </Alert>
            ) : null
          )}
      </div>
    </div>
  );
}
export default ComposeEmail;
