import { History, Info } from "@mui/icons-material";
import {
  Box,
  // IconButton,
  // Checkbox,
  // FormControlLabel,
  // Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import {
  DateTimeFormatter,
} from "components/helpers/DateTimeHelpers";
import { hoverStyle } from "components/helpers/MediaQueries";
// import { hoverStyle } from "components/helpers/MediaQueries";
import { useState } from "react";
import { useGetActivitiesByUserAndDateRangeQuery } from "state/managementApi";

const now = new Date();
const oneWeekAgo = new Date(now);
oneWeekAgo.setDate(now.getDate() - 7);

const todayString = now.toISOString().split("T")[0]; // For the max start date input format

const ManageAgentActivitesList = ({ agentId, /* agentName, allAgents */ }) => {
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);

  const [updateOpen, setUpdateOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const [startDate, setStartDate] = useState(oneWeekAgo.toISOString().split("T")[0]);
  const [endDate, setEndDate] = useState(now.toISOString().split("T")[0]);
  const [activityType, setActivityType] = useState("");
  const [activityStatus, setActivityStatus] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);

  const {
    data: activities,
    error: activitiesError,
    isLoading: activitiesLoading,
    // refetch: refetchActivities,
  } = useGetActivitiesByUserAndDateRangeQuery({
    userId: agentId,
    startDate: `${startDate}T00:00:00Z`,
    endDate: `${endDate}T23:59:59Z`,
    type: activityType,
    status: activityStatus,
    page,
    limit,
  });

  // Togglers
  const toggleUpdateOpen = () => setUpdateOpen((prev) => !prev);
  const toggleDeleteConfirm = () => setDeleteConfirmOpen((prev) => !prev);

  const toggleActivity = (selectedActivity) => {
    const isSelected = selectedActivities.includes(selectedActivity);

    if (isSelected) {
      setSelectedActivities((prev) =>
        prev.filter((activity) => activity !== selectedActivity)
      );
    } else {
      setSelectedActivities([...selectedActivities, selectedActivity]);
    }
  };

  const toggleAllActivities = () => {
    if (selectedPages.includes(page)) {
      setSelectedActivities((prev) =>
        prev.filter((activity) => !activities.activities.includes(activity))
      );
      setSelectedPages((prev) =>
        prev.filter((selectedPage) => selectedPage !== page)
      );
    } else {
      setSelectedActivities((prev) => [...prev, ...activities.activities]);
      setSelectedPages((prev) => [...prev, page]);
    }
  };

  // Handlers
  const handleDeleteConfirm = async () => {};

  const handleSort = (category) => {};

  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value, 10));
    setPage(1);
  };

  const handlePageChange = (e, newPage) => {
    e.preventDefault();
    setPage(newPage + 1);
  };

  if (!activities) return;

  if (activitiesLoading) return;

  if (activitiesError) return;

  return (
    <div style={{ marginTop: "1rem", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
          marginBottom: "1rem",
          flexDirection: "column",
        }}
      >
        {/* <Grid
            container
            spacing={2}
            sx={{ display: 'flex', alignItems: 'center', marginBottom: '15px', }}
        >
        Showing leads out of length
        </Grid> */}
      </Box>
      {/* TODO: Fix bug where date range changes and current page no longer possible */}
      <Box sx={{ display: 'flex', gap: '10px', }}>
        <Typography>Start Date:</Typography>
        <input 
          name="startDate"
          type="date"
          max={todayString}
          value={startDate}
          onChange={(e) => {
            const newDate = new Date(e.target.value);
            setStartDate(newDate.toISOString().split("T")[0])
          }}
        />
        <Typography>End Date:</Typography>
        <input 
          name="endDate"
          type="date"
          max={todayString}
          value={endDate}
          onChange={(e) => {
            const newDate = new Date(e.target.value);
            setEndDate(newDate.toISOString().split("T")[0])
          }}
        />
      </Box>
      <TableContainer
        component={Paper}
        sx={{ overflow: "auto", marginRight: "10px", maxHeight: 570 }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                count={activities?.totalActivities}
                rowsPerPage={limit}
                page={parseInt(activities?.currentPage) - 1}
                slotProps={{
                  select: {
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  },
                }}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                showFirstButton
                showLastButton
              />
            </TableRow>
            <TableRow>
              {/* <TableCell>
                <FormControlLabel 
                  control={
                    <Checkbox 
                      checked={selectAllChecked}
                      onChange={handleSelectAll}
                    />
                  }
                  label="Select All"
                />
              </TableCell> */}
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center', }}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    onClick={() => handleSort("name")}
                  >
                    Lead
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    onClick={() => handleSort("type")}
                  >
                    Type
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    onClick={() => handleSort("title")}
                  >
                    Title
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    onClick={() => handleSort("title")}
                  >
                    Description
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    onClick={() => handleSort("title")}
                  >
                    Status
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    // onClick={() => handleSort("title")}
                  >
                    Date
                  </Typography>
                </Box>
              </TableCell>
              {/* TODO: All three below */}
              {/* <TableCell /> */}
              {/* <TableCell sx={{ p: "6px 0px" }} /> */}
              {/* <TableCell sx={{ p: "6px 0px" }} /> */}
              {/* <TableCell sx={{ p: "6px 0px" }} /> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {activities.activities.map((activity, index) => (
              <TableRow key={index}>
                {/* <TableCell sx={{ padding: '0 25px', }}>
                        <input 
                            type="checkbox"
                            checked={!!selectedActivities.find((selected) => selected._id === activity._id)}
                            onChange={() => toggleActivity(activity)}
                            onClick={(e) => e.stopPropagation()}
                        />
                    </TableCell> */}
                {/* TODO: Activity details */}
                <TableCell>
                  {activity.leadIds && activity.leadIds.length > 1 ? (
                    <span
                      onClick={() => window.alert("Lead Details View Being Implemented")}
                      // style={{ textDecoration: 'underline', cursor: 'pointer', }}
                    >
                      Multiple
                    </span>
                  ) : activity.leadIds && activity.leadIds.length === 1 ? (
                    <span
                      onClick={() => window.alert("Lead Details View Being Implemented")}
                      // style={{ textDecoration: 'underline', cursor: 'pointer', }}
                    >
                      {activity.leadIds[0].name}
                    </span>
                  ) : (
                    <span
                      onClick={() => window.alert("Lead Details View Being Implemented")}
                      // style={{ textDecoration: 'underline', cursor: 'pointer', }}
                    >
                      No Data
                    </span>
                  )}
                </TableCell>
                <TableCell>
                  {activity.type
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, function (str) {
                      return str.toUpperCase();
                    })}
                </TableCell>
                <TableCell>
                  {activity.title}
                </TableCell>
                <TableCell>{activity.description}</TableCell>
                <TableCell>
                  {activity.status
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, function (str) {
                      return str.toUpperCase();
                    })}
                </TableCell>
                <TableCell>{DateTimeFormatter(activity.date)}</TableCell>
                {/* TODO: All three below */}
                {/* <TableCell>
                  <Info 
                    sx={hoverStyle}
                    onClick={() => {
                      window.alert("Details View Being Implemented");
                      // setActivityToDetail(activity);
                      // toggleActivityDetailsOpen();
                    }}
                  />
                </TableCell> */}
                {/* <TableCell sx={{ p: "6px 0px" }}>
                  <Edit
                    sx={hoverStyle}
                    onClick={() => {
                      setSelectedActivity(activity);
                      toggleUpdateOpen();
                    }}
                  />
                </TableCell>
                <TableCell sx={{ p: "6px 0px" }}>
                  <Delete
                    sx={hoverStyle}
                    onClick={() => {
                      setSelectedActivity(activity);
                      toggleDeleteConfirm();
                    }}
                  />
                </TableCell> 
                <TableCell sx={{ p: '6px 0px' }}>                
                  <History 
                    sx={hoverStyle}
                    onClick={() => {
                      setSelectedActivity(activity);
                      toggleHistoryOpen();
                    }}
                  />
                </TableCell>
                */}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                count={activities?.totalActivities}
                rowsPerPage={limit}
                page={parseInt(activities?.currentPage) - 1}
                slotProps={{
                  select: {
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  },
                }}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                showFirstButton
                showLastButton
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ManageAgentActivitesList;
