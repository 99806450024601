
export const hoverStyle = () => ({
    transition: 'transform 0.3s',
    '&:hover': {
        transform: 'scale(1.05)',
        cursor: 'pointer',
    },
});

export const highlightStyle = () => ({
    transition: 'transform 0.3s, background-color 0.2s',
    borderRadius: '5px',
    '&:hover': {
        backgroundColor: '#4F4E4E',
        cursor: 'pointer'
    }
})