

export const isWithinBusinessHours = () => {
  const now = new Date();
  const day = now.getDay(); // 0 (Sunday) - 6 (Saturday)
  const hours = now.getHours(); // 0 - 23
  const minutes = now.getMinutes(); // 0 - 59

  // Check if the current time is between 7:30 AM and 4:30 PM from Monday to Friday
  return (
    day >= 1 && day <= 5 &&
    ((hours === 7 && minutes >= 30) || (hours > 7 && hours < 16) || (hours === 16 && minutes <= 30))
  );
};