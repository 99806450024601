import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, MenuItem, Select, TextField, Typography } from "@mui/material";
import { hoverStyle } from "components/helpers/MediaQueries";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { SketchPicker } from "react-color";
import { useEditOperationsTeamMemberColorMutation } from "state/operationsApi";

// TODO: Agents only edit own colors
const EditColorsMenu = ({ opsMembers, onClose }) => {
    const [selectedAgentName, setSelectedAgentName] = useState("default");
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [newColor, setNewColor] = useState("");

    const [editColor] = useEditOperationsTeamMemberColorMutation();

    const { enqueueSnackbar } = useSnackbar();

    const sortedAgents = [...opsMembers].sort((a, b) => {
        const valueA = a.name || "";
        const valueB = b.name || "";
        return valueA.localeCompare(valueB);
    });

    const handleSubmit = async () => {
        try {
            await editColor({ id: selectedAgent.user._id, color: newColor.hex })
            .then(() => {
                enqueueSnackbar(`${selectedAgentName} color updated`);
                onClose();
            })
        } catch (error) {
            console.error("Error updating color:", error);
        }
    };

    return (
        <Dialog open onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography>Edit Operation Member Colors</Typography>
                    <Close sx={hoverStyle} onClick={onClose}/>
                </div>
                <Divider sx={{ pb: '0px', }}/>
            </DialogTitle>
            <DialogContent sx={{ pb: '0px', }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', }}>
                    <Box sx={{ width: '50%', }}>
                        <Select
                            labelId="agent-id"
                            id="agent"
                            name="agent"
                            value={selectedAgentName}
                            onChange={(e) => {
                                setSelectedAgentName(e.target.value);
                                setSelectedAgent(opsMembers.find((agent) => agent.name === e.target.value));
                                setNewColor(opsMembers.find((agent) => agent.name === e.target.value).color);
                            }}
                        >
                            <MenuItem value="default" sx={{ display: 'none', }}>Select an Operations Member</MenuItem>
                            {sortedAgents.map((agent, index) => (
                                <MenuItem key={index} value={agent.name}>{agent.name}</MenuItem>
                            ))}
                        </Select>
                    </Box>
                    {selectedAgent && (
                        <>
                            {/* <TextField 
                                margin="normal"
                                id="newColor"
                                label="Color (#000000)"
                                name="color"
                                autoFocus
                                value={newColor}
                                onChange={(e) => setNewColor(e.target.value)}
                            /> */}
                        </>
                    )}
                    <SketchPicker 
                        color={newColor}
                        onChange={(color) => {
                            setNewColor(color);
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', }}>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={selectedAgentName === "default" || newColor === selectedAgent.color}
                        onClick={handleSubmit}
                    >
                        Confirm
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    )
}

export default EditColorsMenu;