import React, { useState } from 'react';
import { Alert, Button, Chip, Input, FormControl, InputLabel, Select, MenuItem, Box } from '@mui/material';
import { useGetAgentsQuery, useGetAllCategoriesQuery, useUploadCSVMutation, } from 'state/managementApi';

const CSVUploader = ({ onClose }) => {
  const [file, setFile] = useState(null);
  const [format, setFormat] = useState('');
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const { data: agents = [], isLoading } = useGetAgentsQuery(null, { staleTime: 0 });
  const { data: categories = [], isLoading: categoriesLoading } = useGetAllCategoriesQuery();
  const [formErrors, setFormErrors] = useState([]);
  const [uploadCSV] = useUploadCSVMutation(); //{ isLoading: isUploading, error: uploadError }
  const [formSuccess, setFormSuccess] = useState([]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFormatChange = (e) => {
    setFormat(e.target.value);
  };

  const handleAgentChange = (e) => {
    setSelectedAgents(e.target.value);
  };

  const handleUpload = async () => {
    let errors = [];
    if (!file) {
      errors.push('Please select a CSV file.');
    };
    if (!format) {
      errors.push('Please select format for the upload.');
    };
    if (errors.length > 0) {
      setFormErrors(errors);
      return;
    };

    const formData = new FormData();
    formData.append('csvFile', file);
    formData.append('assignedUsers', JSON.stringify(selectedAgents));
    formData.append('format', format);
    formData.append('categoryId', selectedCategory);

    try {
      await uploadCSV(formData).unwrap();
      setFormSuccess(['CSV uploaded successfully!']);
    } catch (error) {
      setFormErrors(prevErrors => [...prevErrors, error.data?.error || 'An error occurred while uploading the CSV.']);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2, margin: 2 }}>
      <Input
        type="file"
        accept=".csv"
        onChange={handleFileChange}
        fullWidth
        variant="outlined"
      />
      <FormControl fullWidth variant="outlined">
        <InputLabel id="agent-label">Select Agents</InputLabel>
        <Select
          labelId="agent-label"
          multiple
          value={selectedAgents}
          onChange={handleAgentChange}
          label="Select Agents"
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={agents.find(agent => agent._id === value).name} />
              ))}
            </Box>
          )}
        >
          {isLoading ? (
            <MenuItem>Loading agents...</MenuItem>
          ) : (
            agents && agents.map((agent) => (
              <MenuItem key={agent._id} value={agent._id}>
                {agent.name}
              </MenuItem>
            ))
          )}
        </Select>
      </FormControl>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="format-label">Select a format</InputLabel>
        <Select
          labelId="format-label"
          value={format}
          onChange={handleFormatChange}
          label="Select a format"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="python">Python</MenuItem>
          <MenuItem value="salesforce">Salesforce</MenuItem>
          <MenuItem value="zoominfo">Zoominfo</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth variant="outlined">
        <InputLabel id="category-label">Select a Category</InputLabel>
        <Select
          labelId="category-label"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          label="Select a Category"
        >
          {categoriesLoading ? <MenuItem>Loading categories...</MenuItem> : categories.map(category => <MenuItem key={category._id} value={category._id}>{category.name}</MenuItem>)}
        </Select>
      </FormControl>
      <Button variant="contained" color="primary" onClick={handleUpload}>
        Upload CSV
      </Button>
      <Button variant="contained" color="error" onClick={onClose}>
        Close Uploader
      </Button>
      {formErrors.length ? (
        <Box>
          {formErrors.map((error, index) => (
            <Alert key={index} severity="error">{error}</Alert>
          ))}
        </Box>
      ) : null}
      {formSuccess.length ? (
        <Box>
          {formSuccess.map((success, index) => (
            <Alert key={index} severity="success">{success}</Alert>
          ))}
        </Box>
      ) : null}
    </Box>
  );
};

export default CSVUploader;
