import { Close } from "@mui/icons-material";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Calculator from "components/tools/Calculator";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useUpdateUserMutation } from "state/salesApi";
import { useDispatch } from "react-redux";
import { setUser } from "store/userSlice";

const UpdateStatsForm = ({ onClose }) => {
    const userData = useSelector((state) => state.user);
    const [calculatorOpen, setCalculatorOpen] = useState(true);
    const [maxWidth, setMaxWidth] = useState('lg');
    const dispatch = useDispatch();

    const [updateStats] = useUpdateUserMutation();

    const { enqueueSnackbar } = useSnackbar();

    /* const handleDisplayCalculator = () => {
        setCalculatorOpen(!calculatorOpen);
        setMaxWidth((prev) => prev === 'md' ? 'lg' : 'md')
    } */

    const formik = useFormik({
        initialValues: {
            // meetings: userData.meetings || 0,
            setups: userData.setups || 0,
            loadsBooked: userData.loadsBooked || 0,
            quotes: userData.quotes || 0,
            margin: userData.margin || 0,
        },
        validationSchema: Yup.object({
            // meetings: Yup.number(),
            setups: Yup.number(),
            loadsBooked: Yup.number(),
            quotes: Yup.number(),
            margin: Yup.number(),
        }),
        onSubmit: (values) => {
            handleUpdateStats(values);
        },
        enableReinitialize: true,
    });

    const handleUpdateStats = (updatedStats) => {
        updateStats({ id: userData._id, data: updatedStats })
            .unwrap()
            .then((data) => {
                dispatch(setUser(data));
                enqueueSnackbar("Stats Updated");
                onClose();
            })
    };

    // TODO Handle new stats stuff

    return (
        <>
            <Dialog open onClose={onClose} maxWidth={maxWidth} fullWidth>
                <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography>Update Stats</Typography>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: `${calculatorOpen ? 'space-between' : 'center'}` }}>
                    <Box>
                        {/* <TextField
                            margin="normal"
                            fullWidth
                            label="Meetings"
                            name="meetings"
                            type="number"
                            value={formik.values.meetings}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.meetings && formik.errors.meetings}
                            helperText={formik.touched.meetings && formik.errors.meetings}
                        /> */}
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Setups"
                            name="setups"
                            type="number"
                            value={formik.values.setups}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.setups && formik.errors.setups}
                            helperText={formik.touched.setups && formik.errors.setups}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Loads Booked"
                            name="loadsBooked"
                            type="number"
                            value={formik.values.loadsBooked}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.loadsBooked && formik.errors.loadsBooked}
                            helperText={formik.touched.loadsBooked && formik.errors.loadsBooked}
                        />
                        <TextField
                            margin="normal"
                            fullWidth
                            label="Quotes"
                            name="quotes"
                            type="number"
                            value={formik.values.quotes}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.quotes && formik.errors.quotes}
                            helperText={formik.touched.quotes && formik.errors.quotes}
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <TextField
                                margin="normal"
                                fullWidth
                                label="Margin"
                                name="margin"
                                type="number"
                                value={formik.values.margin}
                                sx={{ paddingRight: '20px' }}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.margin && formik.errors.margin}
                                helperText={formik.touched.margin && formik.errors.margin}
                            />
                        </Box>
                    </Box>
                    {calculatorOpen && (
                        <>
                            <Calculator isMargin={true} />
                        </>
                    )}
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={formik.handleSubmit}
                    >
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default UpdateStatsForm;