import { Close } from "@mui/icons-material";
import { /* Box, */ Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Link, Tooltip, Typography, useTheme } from "@mui/material";
// import { useGetQuoteByIdQuery } from "state/operationsApi";

const QuoteDetails = ({ quote, onClose }) => {
    const theme = useTheme();

    // const { data: quoteData, isLoading, isError, refetch } = useGetQuoteByIdQuery(quote._id);

    // if (!quoteData) return;

    return (
        <Dialog open onClose={onClose} fullWidth maxWidth="lg">
            <DialogTitle>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography>Quote Details</Typography>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </div>
                <Divider sx={{ mb: '15px', }}/>
            </DialogTitle>
            <DialogContent>
                <Container
                    component="main"
                    maxWidth="xl"
                    padding="40px"
                    sx={{
                        mt: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        backgroundColor: theme.palette.primary[900],
                        p: '20px',
                        m: '0 auto',
                    }}
                >
                    <Typography variant="h4" sx={{ mb: '35px', }}>
                        <Tooltip title="Open Quote Email" placement="top">
                            <a
                                href={quote.gmailLink}
                                target="_blank"
                                style={{
                                    textDecoration: 'underline',
                                    color: 'inherit',
                                }}
                            >
                                {quote.emailSubject}
                            </a>
                        </Tooltip>
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <Grid container item spacing={2}>
                                    <Grid item xs={1}>
                                        <Typography>
                                            <b>Origin</b>:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>{(quote.origin.city + ", ") || ""}{quote.origin.state || ""}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>
                                            <b>Destination</b>:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography>{(quote.destination.city + ", ") || ""}{quote.destination.state || ""}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1"><b>Status</b>: {quote.status.replace(/^\w/, (c) => c.toUpperCase())}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1"><b>Quote Status</b>: {quote.quoteStatus.replace(/^\w/, (c) => c.toUpperCase())}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1"><b>Shipment Date</b>: {quote.shipmentDate}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="center" item spacing={2}>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Typography variant="body1"><b>Truck Price</b>: {`$${parseFloat(quote.truckPrice).toFixed(2)}` || "No Info"}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Typography variant="body1"><b>Customer Quote</b>: {`$${parseFloat(quote.customerQuote).toFixed(2)}` || "No Info"}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Typography variant="body1"><b>Margin</b>: {`$${parseFloat(quote.margin).toFixed(2)}` || "No Info"}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent="center" sx={{ mt: 2 }}>
                                <Grid container justifyContent="center" spacing={2}>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Typography variant="body1"><b>Equipment Type</b>: {quote.equipType || ""}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Typography variant="body1"><b>Load Type</b>: {quote.loadType || ""}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Typography variant="body1"><b>Driver Name</b>: {quote.driverName || ""}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Typography variant="body1"><b>Driver Phone Number</b>: {quote.driverPhone || ""}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Typography variant="body1"><b>Dispatcher Name</b>: {quote.dispatcherName || ""}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Typography variant="body1"><b>Dispatcher Phone Number</b>: {quote.dispatcherPhone || ""}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Typography variant="body1"><b>Dispatcher Email</b>: {quote.carrierInfo.email || ""}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Typography variant="body1"><b>Truck In Hand</b>: {quote.truckInHand ? "Yes" : "No"}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Typography variant="body1"><b>Pickup and Delivery ETA</b>: {quote.pickupAndDeliveryETA || ""}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Typography variant="body1"><b>MC Number</b>: {quote.carrierInfo.mcNumber || ""}</Typography>
                                    </Grid>
                                    {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Typography variant="body1"><b>Carrier Phone Number</b>: {quote.carrierInfo.phoneNumber || ""}</Typography>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1"><b>Notes</b>: {quote.notes || ""}</Typography>
                        </Grid>
                    </Grid>
                </Container>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', }}>

                    <Button
                        variant="contained"
                        color="error"
                        onClick={onClose}
                    >
                        Close
                    </Button>
            </DialogActions>
        </Dialog>
    )
}

export default QuoteDetails;