import { Close, ExpandLess, ExpandMore, Search } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { calculateDSLA } from "components/helpers/CalculateDSLA";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import {
  useGetAllLeadsForUserQuery,
  useMoveLeadsBetweenUsersMutation,
  useMoveLeadsToPoolMutation,
  useSearchAgentsLeadsQuery,
  useUpdateLeadStatusForMultipleLeadsAndUserMutation,
  useUpdateLeadStatusForUserMutation,
} from "../../../state/managementApi";
import ManageAgentLeadDetails from "./ManageAgentLeadDetails";
import BatchProcessor from "components/helpers/BatchProcessor";
import ActivityLeadList from "components/activities/ActivityLeadList";

const ManageAgentLeadsList = ({ agentId, agentName, allAgents }) => {
  const [selectedStatus, setSelectedStatus] = useState("new");
  const [selectedDestinationStatus, setSelectedDestinationStatus] =
    useState("pool");
  const [displayedDestinationStatus, setdisplayedDestinationStatus] =
    useState("Pool");
  const [selectedLead, setSelectedLead] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState("");
  const [displayedAgentName, setDisplayedAgentName] = useState("");
  // Multiple leads state vs single leads state.
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [sortCategory, setSortCategory] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [sendDialogOpen, setSendDialogOpen] = useState(false);
  // Lead details
  const [leadToDetail, setLeadToDetail] = useState(null);
  const [leadDetailsOpen, setLeadDetailsOpen] = useState(false);
  // Activities
  const [selectedActivitiesLead, setSelectedActivitiesLead] = useState(null);
  const [leadActivitesOpen, setLeadActivitesOpen] = useState(false);

  const [limit, setLimit] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  // Search states
  const [searched, setSearched] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchSkip, setSearchSkip] = useState(true);
  
  const {
    data: leadsData = [],
    error: leadsError,
    isLoading: leadsLoading,
    refetch: refetchLeadsData,
  } = useGetAllLeadsForUserQuery({ userId: agentId, status: selectedStatus, page: currentPage, limit: limit, sortField: sortCategory, sortOrder: sortOrder });

  const {
    data: searchData = [],
    error: searchError,
    isLoading: searchLoading,
    refetch: refetchSearch,
  } = useSearchAgentsLeadsQuery({ userId: agentId, searchQuery, page: currentPage, limit, sortField: sortCategory, sortOrder }, { skip: searchSkip}); 
  
  const [moveLeadsBetweenUsers, { isLoading: moveLeadsLoading }] = useMoveLeadsBetweenUsersMutation();
  const [updateLeadStatusForUser] = useUpdateLeadStatusForUserMutation();
  const [updateLeadStatusForMultipleLeadsAndUser] = useUpdateLeadStatusForMultipleLeadsAndUserMutation();
  const [moveLeadsToPool, { isLoading: poolLeadsLoading }] = useMoveLeadsToPoolMutation();
  
  const { enqueueSnackbar } = useSnackbar();

  const selectedAgentObject = allAgents.find(
    (agent) => agent._id === selectedAgent
  );

  const openLeadDetails = () => {
    setLeadToDetail(selectedLeads);
    setLeadDetailsOpen(true);
  };

  const closeLeadDetailsDialog = () => {
    setSelectedLead([]);
    setLeadDetailsOpen(false);
  };

  useEffect(() => {
    refetchLeadsData();
  }, [selectedStatus, refetchLeadsData]);

  const handleLeadDelete = (deletedLeadId) => {
    handleRefresh();
    setLeadToDetail((prev) =>
      prev.filter((leadId) => leadId !== deletedLeadId)
    );
    setSelectedLeads((prev) =>
      prev.filter((leadId) => leadId !== deletedLeadId)
    );
    if (leadToDetail.length === 1) {
      setLeadDetailsOpen(false);
    }
  };

  const handleSearchLeadDelete = (deletedLeadId) => {
    setLeadToDetail((prev) =>
      prev.filter((leadId) => leadId !== deletedLeadId)
    );
    setSelectedLeads((prev) =>
      prev.filter((leadId) => leadId !== deletedLeadId)
    );
    if (leadToDetail.length === 1) {
      setLeadDetailsOpen(false);
    }
  }

  const handleSort = (category) => {
    if (sortCategory === category) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortCategory(category);
      setSortOrder("asc");
      setCurrentPage(1);
    }
  };

  const sortedAgents = [...allAgents].sort((a, b) => {
    const valueA = a.name || "";
    const valueB = b.name || "";
    return valueA.localeCompare(valueB);
  });

  const handleDSLA = (DSLA) => {
    return calculateDSLA(DSLA);
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
    setSelectedLeads([]);
    setSelectAllChecked(false);
    setSearchInput("");
    // setSearchResults([]);
    setSearched(false);
    setCurrentPage(1);
  };

  const handleDestinationStatusChange = (event) => {
    setSelectedDestinationStatus(event.target.value);
    const modifiedString = event.target.value.replace(
      /(?<=[a-z])(?=[A-Z])/g,
      " "
    );
    const capitalizedString = modifiedString.replace(/\b\w/g, (firstLetter) =>
      firstLetter.toUpperCase()
    );
    setdisplayedDestinationStatus(capitalizedString);
  };

  const handleSelectLead = (leadId) => {
    const isSelected = selectedLeads.includes(leadId);
    if (isSelected) {
      setSelectedLeads(selectedLeads.filter((id) => id !== leadId));
    } else {
      setSelectedLeads([...selectedLeads, leadId]);
    }
  };

  const handleSelectAll = () => {
    if (selectAllChecked) {
      setSelectedLeads([]);
    } else {
      const allLeadIds = leadsData?.leads.map((lead) => lead._id);
      setSelectedLeads(allLeadIds);
    }
    setSelectAllChecked(!selectAllChecked);
  };

  const handleStatusChangeClick = () => {
    setDialogOpen(true);
  };

  const handleLeadActivityDisplay = (lead) => {
    if (leadActivitesOpen) {
      setSelectedActivitiesLead(null);
      setLeadActivitesOpen(false);
    } else {
      setSelectedActivitiesLead(lead);
      setLeadActivitesOpen(true);
    }
  };

  // Batch handler
  const handleBatchSending = async (leadIds) => {
    await BatchProcessor(
      moveLeadsBetweenUsers,
      leadIds,
      {
        userIdFrom: agentId,
        userIdTo: selectedAgent,
      },
      "leadIds"
    );
  };

  const handleStatusChangeConfirm = async () => {
    setDialogOpen(false);
    try {
      if (selectedDestinationStatus === "pool") {
        await BatchProcessor(moveLeadsToPool, selectedLeads).then(() => {
          enqueueSnackbar(
            selectedLeads.length > 1
              ? `${selectedLeads.length} leads moved to Pool`
              : "Lead moved to Pool"
          );
        });
      } else {
        if (selectedLeads.length > 1) {
          await updateLeadStatusForMultipleLeadsAndUser({
            leadUpdates: selectedLeads.map((leadId) => ({
              leadId,
              status: selectedDestinationStatus,
              isCustomer: leadsData?.leads.find((lead) => lead._id === leadId)
                .isCustomer,
            })),
          });
          enqueueSnackbar(
            `${selectedLeads.length} leads moved to ${displayedDestinationStatus}`
          );
        } else {
          await updateLeadStatusForUser({
            leadId: selectedLeads[0],
            userId: agentId,
            status: selectedDestinationStatus,
          });
          enqueueSnackbar(`Lead moved to ${displayedDestinationStatus}`);
        }
      }
      setSelectedLeads([]);
      setSelectAllChecked(false);
      await refetchLeadsData();
    } catch (error) {
      console.error("Error updating lead statuses:", error);
    }
  };

  const handleStatusChangeCancel = () => {
    setDialogOpen(false);
  };

  const handleAgentChange = (event) => {
    setSelectedAgent(event.target.value);
    setDisplayedAgentName(
      allAgents.find((agent) => agent._id === event.target.value).name
    );
  };

  const handleSendToClick = () => {
    setSendDialogOpen(true);
  };

  const handleSendToConfirm = async () => {
    setSendDialogOpen(false);
    try {
      if (
        !agentId ||
        !selectedAgent ||
        selectedAgent === "" ||
        selectedLeads.length === 0
      ) {
        console.log("Invalid data to move leads.");
        setDialogOpen(false);
        return;
      }

      await handleBatchSending(selectedLeads).then(() => {
        enqueueSnackbar(
          `${selectedLeads.length} moved to ${selectedAgentObject.name}`
        );
        setSelectedLeads([]);
        refetchLeadsData();
      });
    } catch (error) {
      console.error("Error moving leads:", error);
    }
  };

  const handleSendToCancel = () => {
    setSendDialogOpen(false);
  };

  const handleLimitChange = (e) => {
    setLimit(parseInt(e.target.value, 10));
    setCurrentPage(1);
  };
  
  const handlePageChange = (e, newPage) => {
    e.preventDefault();
    setCurrentPage(newPage + 1);
  };

  function shortenURL(url, maxLength) {
    if (url && url.length > maxLength) {
      return url.slice(0, maxLength - 3) + "...";
    }
    return url;
  }

  const ensureHttpPrefix = (url) => {
    // Check if the URL starts with "http://"
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      return `http://${url}`;
    }
    return url;
  };

  const handleRefresh = () => {
    refetchLeadsData();
  };

  // Handle displaying leads based on search
  const handleSearch = () => {
    setSelectedLeads([]);
    setCurrentPage(1);
    setSortCategory("name");
    setSortOrder("asc");
  };

  if (!leadsData.leads || !leadsData.totalCount) return;

  return (
    <div style={{ marginTop: "1rem", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
          marginBottom: "1rem",
          flexDirection: "column",
        }}
      >
        {moveLeadsLoading ||
          (poolLeadsLoading && <Typography>Loading...</Typography>)}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>{agentName}'s</Typography>
          <Select
            value={selectedStatus}
            onChange={handleStatusChange}
            sx={{ margin: "0px 15px" }}
          >
            <MenuItem value="new">New</MenuItem>
            <MenuItem value="warm">Warm</MenuItem>
            <MenuItem value="hot">Hot</MenuItem>
            <MenuItem value="activeCustomer"> Active Customer</MenuItem>
            <MenuItem value="pendingCustomer">Pending Customer</MenuItem>
            <MenuItem value="assignedCustomer">Assigned Customer</MenuItem>
          </Select>
          <Typography>leads</Typography>
        </Box>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Box>
            <Button
              variant="contained"
              disabled={selectedLeads.length === 0}
              onClick={openLeadDetails}
            >
              Edit Selected Leads
            </Button>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleStatusChangeClick}
              disabled={selectedLeads.length === 0}
            >
              Change Status of Selected to
            </Button>
            <Select
              value={selectedDestinationStatus}
              onChange={handleDestinationStatusChange}
              sx={{ marginLeft: "15px" }}
            >
              <MenuItem
                value="new"
                disabled={selectedDestinationStatus === "new"}
              >
                New Leads
              </MenuItem>
              <MenuItem
                value="warm"
                disabled={selectedDestinationStatus === "warm"}
              >
                Warm Leads
              </MenuItem>
              <MenuItem
                value="hot"
                disabled={selectedDestinationStatus === "hot"}
              >
                Hot Leads
              </MenuItem>
              <MenuItem
                value="assignedCustomer"
                disabled={selectedDestinationStatus === "assignedCustomer"}
              >
                Assigned Customer
              </MenuItem>
              <MenuItem
                value="pendingCustomer"
                disabled={selectedDestinationStatus === "pendingCustomer"}
              >
                Pending Customer
              </MenuItem>
              <MenuItem
                value="activeCustomer"
                disabled={selectedDestinationStatus === "activeCustomer"}
              >
                Active Customer
              </MenuItem>
              <MenuItem value="pool">Pool</MenuItem>
            </Select>
          </Box>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendToClick}
              disabled={selectedLeads.length === 0 || !selectedAgent}
            >
              Send Selected To
            </Button>
            <Select
              value={selectedAgent}
              onChange={handleAgentChange}
              sx={{ marginLeft: "15px" }}
            >
              {sortedAgents.map((agent) => (
                <MenuItem key={agent._id} value={agent._id}>
                  {agent.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {/* <Box>
            <SearchBar
              searchInput={searchInput}
              setSearchInput={setSearchInput}
              handleSearchChange={handleSearch}
              allLeadsSearch={true}
              hasSearched={searched}
            />
          </Box> */}
          <Box sx={{ display: 'flex', alignItems: 'center', }}>
            {/* Searchbar */}
            <div style={{ display: 'flex', justifyContent: 'center', }}>
              <Paper
                component="form"
                sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: 400, border: '1px solid grey', }}
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <InputBase 
                  sx={{ ml: 1, flex: 1 }}
                  id="searchbar"
                  placeholder="Search Leads"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setSearchSkip(false);
                      setSearchQuery(searchInput);
                      handleSearch();
                      setSearched(true);
                    }
                  }}
                />
                {!searched && searchSkip ? (
                  <IconButton
                    sx={{ p: '10px', }}
                    onClick={() => {
                      setSearchSkip(false);
                      setSearchQuery(searchInput);
                      handleSearch();
                      setSearched(true);
                    }}
                  >
                    <Search />
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{ p: '10px', }}
                    onClick={() => {
                      setSearchSkip(true);
                      handleSearch();
                      setSearchInput("");
                      setSearched(false);
                    }}
                  >
                    <Close />
                  </IconButton>
                )}
              </Paper>
            </div>
          </Box>
        </div>
      </Box>
      {!searched ? (
        <>
          <Grid
            container
            spacing={2}
            sx={{ display: "flex", alignItems: "center", marginBottom: "15px" }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                marginBottom: "25px",
                paddingLeft: "30px",
              }}
            >
              <Grid
                item
                xs={6}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ display: selectedLeads.length ? "block" : "none" }}
                >
                  {selectedLeads?.length}{" "}
                  {selectedLeads?.length > 1 ? "Leads" : "Leads"} Selected
                </Typography>
              </Grid>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
            </div>
          </Grid>
          <TableContainer
            component={Paper}
            sx={{ overflow: "auto", marginRight: "10px", maxHeight: 570 }}
          >
            <Table stickyHeader sx={{ paddingRight: "10px" }}>
              <TableHead>
                <TableRow>
                  <TablePagination 
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    count={leadsData.totalCount}
                    rowsPerPage={limit}
                    page={currentPage - 1}
                    slotProps={{
                      select: {
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }
                    }}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    showFirstButton
                    showLastButton
                  />
                </TableRow>
                <TableRow>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectAllChecked}
                          onChange={handleSelectAll}
                        />
                      }
                      label="Select All"
                    />
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        onClick={() => handleSort("name")}
                        style={{ cursor: "pointer" }}
                      >
                        Company Name
                      </Typography>
                      {sortCategory !== "name" ? (
                        <ExpandMore />
                      ) : (
                        <ExpandLess />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        onClick={() => handleSort("contactNumber")}
                        style={{ cursor: "pointer" }}
                      >
                        Phone Number
                      </Typography>
                      {sortCategory !== "contactNumber" ? (
                        <ExpandMore />
                      ) : (
                        <ExpandLess />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        onClick={() => handleSort("website")}
                        style={{ cursor: "pointer" }}
                      >
                        Website
                      </Typography>
                      {sortCategory !== "website" ? (
                        <ExpandMore />
                      ) : (
                        <ExpandLess />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        onClick={() => handleSort("title")}
                        style={{ cursor: "pointer" }}
                      >
                        Title
                      </Typography>
                      {sortCategory !== "title" ? (
                        <ExpandMore />
                      ) : (
                        <ExpandLess />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        onClick={() => handleSort("recentactivitydescription")}
                        style={{ cursor: "pointer" }}
                      >
                        Recent Activity
                      </Typography>
                      {sortCategory !== "recentactivitydescription" ? (
                        <ExpandMore />
                      ) : (
                        <ExpandLess />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        onClick={() => handleSort("recentActivity.date")}
                        style={{ cursor: "pointer" }}
                      >
                        DSLA
                      </Typography>
                      {sortCategory !== "recentActivity.date" ? (
                        <ExpandMore />
                      ) : (
                        <ExpandLess />
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!leadsData.leads || leadsData?.leads.length === 0 ? (
                  <TableRow>
                    <TableCell>
                      <Typography>No leads found for {agentName}</Typography>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                  </TableRow>
                ) : (
                  leadsData?.leads.map((lead) => (
                    <TableRow key={lead._id}>
                      <TableCell>
                        <Checkbox
                          checked={selectedLeads.includes(lead._id)}
                          onChange={() => handleSelectLead(lead._id)}
                        />
                      </TableCell>
                      <TableCell>
                        <span
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setLeadToDetail([lead._id]);
                            setLeadDetailsOpen(true);
                          }}
                        >
                          {lead.name}
                        </span>
                      </TableCell>
                      <TableCell>{lead.contactNumber}</TableCell>
                      <TableCell>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {lead.website.length > 30 ? (
                            <Tooltip title={lead.website}>
                              <span style={{ cursor: "pointer" }}>
                                <a
                                  href={ensureHttpPrefix(lead.website)}
                                  target="_blank"
                                  style={{
                                    color: "inherit",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {shortenURL(lead.website, 30)}
                                </a>
                              </span>
                            </Tooltip>
                          ) : (
                            <Tooltip title={lead.website}>
                              <span style={{ cursor: "pointer" }}>
                                <a
                                  href={ensureHttpPrefix(lead.website)}
                                  target="_blank"
                                  style={{
                                    color: "inherit",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {lead.website}
                                </a>
                              </span>
                            </Tooltip>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>{lead.title || ""}</TableCell>
                      <TableCell>
                        {lead.recentActivity?.description || "No activity."}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Show Activity" placement="top">
                          <span
                            style={{
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleLeadActivityDisplay(lead);
                            }}
                          >
                            {handleDSLA(lead.recentActivity?.date) === 0
                            ? "Today"
                            : handleDSLA(lead.recentActivity?.date) > 0
                            ? handleDSLA(lead.recentActivity?.date)
                            : "New"}
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination 
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    count={leadsData.totalCount}
                    rowsPerPage={limit}
                    page={currentPage - 1}
                    slotProps={{
                      select: {
                        inputProps: {
                          "aria-label": "rows per page",
                        },
                        native: true,
                      }
                    }}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    showFirstButton
                    showLastButton
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </>
      ) : (
        <>
          <Grid
            container
            spacing={2}
            sx={{ display: 'flex', alignItems: 'center', mb: '15px', }}
          >
            <div
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '25px',
                paddingLeft: '30px',
              }}
            >
              <Grid
                item
                xs={6}
                sx={{ display: 'flex', flexDirection: 'column', }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{ display: selectedLeads.length ? "block" : "none" }}
                >
                  {selectedLeads?.length}{" "}
                  {selectedLeads?.length > 1 ? "Leads" : "Lead"} Selected
                </Typography>
              </Grid>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
              }}
            />
          </Grid>
          {searchLoading ? (
            <CircularProgress />
          ) : (
            <TableContainer
              component={Paper}
              sx={{ overflow: 'auto', mr: '10px', maxHeight: 570 }}
            >
              <Table stickyHeader sx={{ pr: '10px' }}>
                <TableHead>
                  <TableRow>
                    <TablePagination 
                      rowsPerPageOptions={[5, 10, 25, 50]}
                      count={searchData.totalCount}
                      rowsPerPage={limit}
                      page={currentPage - 1}
                      slotProps={{
                        select: {
                          inputProps: {
                            "aria-label": "rows per page",
                          },
                          native: true,
                        }
                      }}
                      onPageChange={handlePageChange}
                      onRowsPerPageChange={handleLimitChange}
                      showFirstButton
                      showLastButton
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectAllChecked}
                            onChange={handleSelectAll}
                          />
                        }
                        label="Select All"
                      />
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("name")}
                          style={{ cursor: "pointer" }}
                        >
                          Company Name
                        </Typography>
                        {sortCategory !== "name" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("contactNumber")}
                          style={{ cursor: "pointer" }}
                        >
                          Phone Number
                        </Typography>
                        {sortCategory !== "contactNumber" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("website")}
                          style={{ cursor: "pointer" }}
                        >
                          Website
                        </Typography>
                        {sortCategory !== "website" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("title")}
                          style={{ cursor: "pointer" }}
                        >
                          Title
                        </Typography>
                        {sortCategory !== "title" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("recentactivitydescription")}
                          style={{ cursor: "pointer" }}
                        >
                          Recent Activity
                        </Typography>
                        {sortCategory !== "recentactivitydescription" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="h6"
                          fontWeight="bold"
                          onClick={() => handleSort("recentActivity.date")}
                          style={{ cursor: "pointer" }}
                        >
                          DSLA
                        </Typography>
                        {sortCategory !== "recentActivity.date" ? (
                          <ExpandMore />
                        ) : (
                          <ExpandLess />
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!searchData.leads || searchData?.leads.length === 0 ? (
                    <TableRow>
                      <TableCell>
                        <Typography>No leads found for {agentName}</Typography>
                      </TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  ) : (
                    searchData?.leads.map((lead) => (
                      <TableRow key={lead._id}>
                        <TableCell>
                          <Checkbox
                            checked={selectedLeads.includes(lead._id)}
                            onChange={() => handleSelectLead(lead._id)}
                          />
                        </TableCell>
                        <TableCell>
                          <span
                            style={{
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setLeadToDetail([lead._id]);
                              setLeadDetailsOpen(true);
                            }}
                          >
                            {lead.name}
                          </span>
                        </TableCell>
                        <TableCell>{lead.contactNumber}</TableCell>
                        <TableCell>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            {lead.website.length > 30 ? (
                              <Tooltip title={lead.website}>
                                <span style={{ cursor: "pointer" }}>
                                  <a
                                    href={ensureHttpPrefix(lead.website)}
                                    target="_blank"
                                    style={{
                                      color: "inherit",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    {shortenURL(lead.website, 30)}
                                  </a>
                                </span>
                              </Tooltip>
                            ) : (
                              <Tooltip title={lead.website}>
                                <span style={{ cursor: "pointer" }}>
                                  <a
                                    href={ensureHttpPrefix(lead.website)}
                                    target="_blank"
                                    style={{
                                      color: "inherit",
                                      textDecoration: "underline",
                                    }}
                                  >
                                    {lead.website}
                                  </a>
                                </span>
                              </Tooltip>
                            )}
                          </div>
                        </TableCell>
                        <TableCell>{lead.title || ""}</TableCell>
                        <TableCell>
                          {lead.recentActivity?.description || "No activity."}
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Show Activity" placement="top">
                            <span
                              style={{
                                textDecoration: 'underline',
                                cursor: 'pointer',
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleLeadActivityDisplay(lead);
                              }}
                            >
                              {handleDSLA(lead.recentActivity?.date) === 0
                              ? "Today"
                              : handleDSLA(lead.recentActivity?.date) > 0
                              ? handleDSLA(lead.recentActivity?.date)
                              : "New"}
                            </span>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination 
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        count={searchData.totalCount}
                        rowsPerPage={limit}
                        page={currentPage - 1}
                        slotProps={{
                          select: {
                            inputProps: {
                              "aria-label": "rows per page",
                            },
                            native: true,
                          }
                        }}
                        onPageChange={handlePageChange}
                        onRowsPerPageChange={handleLimitChange}
                        showFirstButton
                        showLastButton
                      />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </>
      )}
      <Dialog open={sendDialogOpen} onClose={handleSendToCancel}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to send {selectedLeads.length} leads to "
            {selectedAgentObject?.name || ""}"?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSendToCancel} color="error">
            Cancel
          </Button>
          <Button
            onClick={handleSendToConfirm}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={dialogOpen} onClose={handleStatusChangeCancel}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to move {selectedLeads.length} leads to "
            {displayedDestinationStatus}"?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleStatusChangeCancel}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button
            onClick={handleStatusChangeConfirm}
            variant="contained"
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      {leadDetailsOpen && (
        <Dialog open={leadDetailsOpen} onClose={closeLeadDetailsDialog}>
          <DialogContent>
            {leadToDetail.length > 1 ? (
              leadToDetail.map((leadId, index) => (
                <ManageAgentLeadDetails
                  key={index}
                  lead={
                    !searched
                      ? leadsData?.leads.find((lead) => lead._id === leadId)
                      : searchData?.leads.find((lead) => lead._id === leadId)
                  }
                  agentName={agentName}
                  onDelete={searched ? handleSearchLeadDelete : handleLeadDelete}
                  refetch={searched ? refetchSearch : refetchLeadsData}
                />
              ))
            ) : (
              <ManageAgentLeadDetails
                lead={
                  !searched 
                    ? leadsData?.leads.find((lead) => lead._id === leadToDetail[0])
                    : searchData?.leads.find((lead) => lead._id === leadToDetail[0])
                }
                agentName={agentName}
                onDelete={searched ? handleSearchLeadDelete : handleLeadDelete}
                refetch={searched ? refetchSearch : refetchLeadsData}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={closeLeadDetailsDialog}
              variant="outlined"
              color="error"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {leadActivitesOpen && (
        <ActivityLeadList 
          lead={selectedActivitiesLead}
          selectedListLead={selectedActivitiesLead}
          onLeadListClose={handleLeadActivityDisplay}
        />
      )}
    </div>
  );
};

export default ManageAgentLeadsList;
