import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { themeSettings } from "theme";
import Dashboard from "scenes/dashboard";
import Layout from "scenes/layout";
import Products from "scenes/products";
import Transactions from "./scenes/transactions";
import Geography from "./scenes/geography";
import Overview from "./scenes/overview";
import Monthly from "scenes/monthly";
import Performance from "scenes/performance";
import Dispatch from "scenes/dispatch";
import Login from "scenes/login";
import Register from "scenes/register";
import { useWebSocket } from "./components/helpers/WebSocketHook";
import TOS from "scenes/tos";
import PP from "scenes/pp";
import GoogleCallback from "components/google/GoogleCallback";
import Archive from "scenes/archive";
import { SnackbarProvider } from "notistack";
import LeadDetailsMM from "components/leads/LeadDetailsMM";
import Stats from "scenes/stats";
import CustomerSupport from "scenes/customerService";
import DispatchBoard from "scenes/dispatch/2.0";
import Operations from "scenes/operations";

function App() {
  //setup material UI
  const mode = useSelector((state) => state.global.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  // useWebSocket("Dashboard"); // Connect to WebSocket for Dashboard
  useWebSocket("Dispatch"); // Connect to WebSocket for Dispatch

  //ThemeProvider and CssBaseline = materialUI setup
  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <SnackbarProvider maxSnack={3}>
            <CssBaseline />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route path="/tos" element={<TOS />} />
              <Route path="/privacy" element={<PP />} />
              <Route
                path="/auth/google/callback"
                element={<GoogleCallback />}
              />
              <Route element={<Layout />}>
                <Route path="/" element={<Navigate to="/login" replace />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/lead/:leadId" element={<LeadDetailsMM />} />
                <Route path="/customer support" element={<CustomerSupport />} />
                <Route path="/operations" element={<Operations />} />
                <Route path="/dispatch" element={<Dispatch />} />
                <Route path="/dispatch board 2.0 (beta)" element={<DispatchBoard />} />
                <Route path="/archive" element={<Archive />} />
                <Route path="/stats" element={<Stats />} />
                <Route path="/products" element={<Products />} />
                <Route path="/transactions" element={<Transactions />} />
                <Route path="/geography" element={<Geography />} />
                <Route path="/overview" element={<Overview />} />
                <Route path="/monthly" element={<Monthly />} />
                <Route path="/performance" element={<Performance />} />
              </Route>
            </Routes>
          </SnackbarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
