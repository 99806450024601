import { Add, Business, Close, Edit } from "@mui/icons-material";
import { Box, Button, Divider, Grid, Tooltip, Typography, useTheme } from "@mui/material";
import { hoverStyle } from "components/helpers/MediaQueries";
import { useState } from "react";
import { useDeletePoolLeadMutation, useMoveLeadsFromPoolToUserMutation } from "state/managementApi";
import AssignPoolLeadMenu from "./AssignPoolLeadMenu";
import { useSnackbar } from "notistack";
import MovePoolLeadMenu from "./MovePoolLeadMenu";
import ConfirmationDialog from "components/helpers/ConfirmDialog";
import EditPoolLead from "./forms/EditPoolLead";
import AssignPoolCategoryMenu from "./menus/AssignPoolCategoryMenu";

const PoolLeadDetails = ({ lead, onClose, onAssign, onMove, onEdit, onDelete, categories }) => {
    const theme = useTheme();
    const [assignSelectOpen, setAssignSelectOpen] = useState(false);
    const [selectedAgent, setSelectedAgent] = useState(null);
    const [editLeadOpen, setEditLeadOpen] = useState(false);
    const [moveCategoryOpen, setMoveCategoryOpen] = useState(false);
    const [assignCategoryOpen, setAssignCategoryOpen] = useState(false);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

    const [moveLeadsFromPoolToUser] = useMoveLeadsFromPoolToUserMutation();
    const [deletePoolLead] = useDeletePoolLeadMutation();

    const { enqueueSnackbar } = useSnackbar();

    const toggleEditOpen = () => setEditLeadOpen(prev => !prev);
    const toggleAssignCategoryOpen = () => setAssignCategoryOpen(prev => !prev);

    const handleConfirmCategoryChange = () => {
        setMoveCategoryOpen(false);
        setAssignCategoryOpen(false);
        if (onMove) {
            onMove();
        }; 
    };

    const handleAssignLead = async () => {
        try {
            await moveLeadsFromPoolToUser({ userIdTo: selectedAgent._id, leadIds: [lead._id] })
            .then(() => {
                enqueueSnackbar(`${lead.name} sent to ${selectedAgent.name}`);
                onAssign(lead._id);
            });
        } catch (error) {
            console.error('Error assigning lead:', error);
        }
    };

    const handleConfirmDelete = async () => {
        try {
            await deletePoolLead(lead._id)
                .then(() => {
                    enqueueSnackbar(`${lead.name} deleted`);
                    onDelete(lead._id);
                })
        } catch (error) {
            console.error('Error deleting lead:', error)
        }
    };

    const shortenURL = (url, maxLength) => {
        if (url && url.length > maxLength) {
            return url.slice(0, maxLength - 3) + "...";
        };
        return url;
    };

    const ensureHttpPrefix = (url) => {
        // Check if the URL starts with "http://"
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          return `http://${url}`;
        }
        return url;
      };

    return (
        <Box sx={{ margin: '15px 0px', }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: '15px', mr: '15px', }}>
                <Tooltip title="Close" placement="top">
                    <Close sx={hoverStyle} onClick={onClose}/>
                </Tooltip>
            </Box>
            <Box sx={{ backgroundColor: theme.palette.background.secondary, borderRadius: '8px', marginBottom: '20px', }}>
                <Box
                    sx={{
                        backgroundColor: theme.palette.background.paper,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        borderTopLeftRadius: '8px',
                        borderTopRightRadius:'8px',
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{
                            backgroundColor: theme.palette.background.alt,
                            margin: '15px 10px',
                            height: '2.9rem',
                            width: '2.9rem',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '10px',
                        }}>
                            <Business sx={{ fontSize: '35px', }}/>
                        </Box>
                        <Typography>
                            {lead.name}
                        </Typography>
                    </div>
                    <div>
                        <Box>
                            <Button
                                color="primary"
                                variant="contained"
                                sx={{
                                    textTransform: 'none',
                                    borderTopRightRadius: '0px',
                                    borderBottomRightRadius: '0px',
                                    boxShadow: 'none',
                                    border: '1px solid black',
                                    borderRight: '0.5px solid black',
                                }}
                                onClick={toggleEditOpen}
                            >
                                <Edit sx={{ marginRight: '5px', fontSize: '15px', }}/>
                                Edit
                            </Button>
                            {lead?.categories.length ? (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    sx={{
                                        textTransform: 'none',
                                        borderRadius: '0px',
                                        boxShadow: 'none',
                                        border: '1px solid black',
                                        borderLeft: '0px solid black',
                                        borderRight: '0.5px solid black',
                                        wordWrap: 'break-word',
                                    }}
                                    onClick={() => setMoveCategoryOpen(true)}
                                >
                                    <Edit sx={{ marginRight: '5px', fontSize: '15px', }}/>
                                    Category
                                </Button>
                            ) : (
                                <Button
                                    color="primary"
                                    variant="contained"
                                    sx={{
                                        textTransform: 'none',
                                        borderRadius: '0px',
                                        boxShadow: 'none',
                                        border: '1px solid black',
                                        borderLeft: '0px solid black',
                                        borderRight: '0.5px solid black',
                                        wordWrap: 'break-word',
                                    }}
                                    onClick={toggleAssignCategoryOpen}
                                >
                                    <Add sx={{ marginRight: '5px', fontSize: '15px', }}/>
                                    Category
                                </Button>
                            )}
                            <Button
                                color="primary"
                                variant="contained"
                                sx={{
                                    textTransform: 'none',
                                    borderRadius: '0px',
                                    boxShadow: 'none',
                                    border: '1px solid black',
                                    borderLeft: '0px solid black',
                                    borderRight: '.05px solid black',
                                }}
                                onClick={() => setAssignSelectOpen(true)}
                            >
                                <Add sx={{ marginRight: '5px', fontSize: '15px', }}/>
                                Assign
                            </Button>
                            <Button
                                color="error"
                                variant="contained"
                                sx={{
                                    textTransform: 'none',
                                    borderTopLeftRadius: '0px',
                                    borderBottomLeftRadius: '0px',
                                    boxShadow: 'none',
                                    border: '1px solid black',
                                    borderLeft: '0px solid black',
                                    borderRight: '.05px solid black',
                                    marginRight: '10px',
                                }}
                                onClick={() => setDeleteConfirmOpen(true)}
                            >
                                Delete
                            </Button>
                        </Box>
                    </div>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', flexDirection: 'row', marginTop: '10px', }}>
                    <Box sx={{ display: 'flex', marginBottom: '10px', justifyContent: 'space-evenly', width: '100%', }}>
                        {/* <Box sx={{ flexBasis: '25%', wordWrap: 'break-word', }}>
                            {!multiple ? (
                                <>
                                    <Typography sx={{ fontFamily: theme.typography.h6.fontFamily, fontSize: theme.typography.h7.fontSize, marginLeft: '5px', marginRight: '70px' }}>
                                        State
                                    </Typography>
                                    <Divider sx={{ width: '50px' }} />
                                    <Typography sx={{ fontFamily: theme.typography.h6.fontFamily, fontSize: theme.typography.h6.fontSize, marginLeft: '5px', }}>
                                        {lead.state || "No Info"}
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <Typography sx={{ fontFamily: theme.typography.h6.fontFamily, fontSize: theme.typography.h7.fontSize, marginLeft: '5px', marginRight: '70px' }}>
                                        Folder
                                    </Typography>
                                    <Divider sx={{ width: '50px' }} />
                                    <Typography sx={{ fontFamily: theme.typography.h6.fontFamily, fontSize: theme.typography.h6.fontSize, marginLeft: '5px', }}>
                                        {categories.find((category) => category._id === lead.categories[0])?.name || "Default"}
                                    </Typography>
                                </>
                            )}
                        </Box> */}
                        <Box sx={{ flexBasis: '25%', wordWrap: 'break-word' }}>
                            <Typography sx={{ fontFamily: theme.typography.h6.fontFamily, fontSize: theme.typography.h7.fontSize, marginRight: '70px' }}>
                                Commodity
                            </Typography>
                            <Divider sx={{ width: '50px' }} />
                            <Typography sx={{ fontFamily: theme.typography.h6.fontFamily, fontSize: theme.typography.h6.fontSize }}>
                                {lead.commodity || "No Info"}
                            </Typography>
                        </Box>
                        <Box sx={{ flexBasis: '25%', wordWrap: 'break-word' }}>
                            <Typography sx={{ fontFamily: theme.typography.h6.fontFamily, fontSize: theme.typography.h7.fontSize, marginRight: '70px' }}>
                                Phone
                            </Typography>
                            <Divider sx={{ width: '50px' }} />
                            <Typography sx={{ fontFamily: theme.typography.h6.fontFamily, fontSize: theme.typography.h6.fontSize }}>
                                {lead.contactNumber || "No Info"}
                            </Typography>
                        </Box>
                        <Box sx={{ flexBasis: '25%', wordWrap: 'break-word' }}>
                            <Typography sx={{ fontFamily: theme.typography.h6.fontFamily, fontSize: theme.typography.h7.fontSize, marginRight: '70px' }}>
                                Website
                            </Typography>
                            <Divider sx={{ width: '50px' }} />
                            {lead.website && lead.website.length > 30 ? (
                                <Tooltip title={lead.website}>
                                    <span style={{ cursor: "pointer" }}>
                                        <a
                                            href={ensureHttpPrefix(lead.website)}
                                            target="_blank"
                                            style={{ color: "inherit", textDecoration: "underline" }}
                                        >
                                            {shortenURL(lead.website, 30)}
                                        </a>
                                    </span>
                                </Tooltip>
                            ) : lead.website && lead.website.length < 30 ? (
                                <Tooltip title={lead.website}>
                                    <span style={{ cursor: "pointer" }}>
                                        <a
                                            href={ensureHttpPrefix(lead.website)}
                                            target="_blank"
                                            style={{ color: "inherit", textDecoration: "underline" }}
                                        >
                                            {lead.website}
                                        </a>
                                    </span>
                                </Tooltip>
                            ) : (
                                <Typography sx={{ fontFamily: theme.typography.h6.fontFamily, fontSize: theme.typography.h6.fontSize }}>
                                    No Info
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <div sx={{ width: '100%', }}>
                <Grid container justifyContent="center" sx={{ mt: 2}}>
                    <Grid item xs={12} sm={6} md={9}>
                        <Grid container justifyContent="center" spacing={2} sx={{ mt: 1, }}>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Typography variant="body1">City: {lead.city}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Typography variant="body1">State: {lead.state}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Typography variant="body1">Country: {lead.country}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Typography variant="body1">Zip Code: {lead.zipCode}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Typography variant="body1">AP Name: {lead.APName}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Typography variant="body1">AP Number: {lead.APNumber}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Typography variant="body1">AP Email: {lead.APEmail}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Typography variant="body1">Credit Limit: {lead.creditLimit}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Typography variant="body1">Equipment Type: {lead.equipmentType}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            {editLeadOpen && (
                <EditPoolLead 
                    leadToUpdate={lead}
                    closeForm={toggleEditOpen}
                    categories={categories}
                    onSubmit={onEdit}
                />
            )}
            {assignSelectOpen && (
                <AssignPoolLeadMenu 
                    open={assignSelectOpen} 
                    onClose={() => setAssignSelectOpen(false)} 
                    onSubmit={handleAssignLead} 
                    leadName={lead.name}
                    selectedAgent={selectedAgent}
                    setSelectedAgent={setSelectedAgent}
                />
            )}
            {assignCategoryOpen && (
                <AssignPoolCategoryMenu 
                    onClose={toggleAssignCategoryOpen}
                    onSubmit={handleConfirmCategoryChange}
                    leadName={lead.name}
                    leadIds={[lead._id]}
                    categories={categories}
                />
            )}
            {moveCategoryOpen && (
                <MovePoolLeadMenu 
                    open={moveCategoryOpen}
                    onClose={() => setMoveCategoryOpen(false)}
                    onSubmit={handleConfirmCategoryChange}
                    leadName={lead.name}
                    leadIds={[lead._id]}
                    categories={categories}
                    leadCategories={lead.categories}
                />
            )}
            <ConfirmationDialog 
                open={deleteConfirmOpen}
                onClose={() => setDeleteConfirmOpen(false)}
                onConfirm={handleConfirmDelete}
                title="Confirm Delete"
                message={`Are you sure you want to delete ${lead.name}?`}
            />
        </Box>
    )
}

export default PoolLeadDetails;