import React, { useState } from 'react';
import { Button, Menu, MenuItem, Dialog, DialogContent, DialogActions } from '@mui/material';
import CarrierSubmissionForm from 'components/forms/CarrierSubmissionForm.js';
import AddLeadForm from '../forms/AddLeadForm';
import { useNavigate } from 'react-router-dom';
import QuoteSubmissionForm from 'components/forms/QuoteSubmissionForm';

const FormsMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedForm, setSelectedForm] = useState(null);
  const [showForm, setShowForm] = useState(false);

  const navigate = useNavigate();

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const openForm = (form) => {
    setSelectedForm(form);
    setShowForm(true);
    closeMenu();
  };

  const closeForm = () => {
    setShowForm(false);
  };

  const handleLeadCreate = (newLead) => {
    setShowForm(false);
    navigate(`/lead/${newLead._id}`);
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={openMenu}>
        Forms
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu} keepMounted>
        <MenuItem onClick={() => openForm({ name: 'Quote Submission Form', component: <QuoteSubmissionForm closeForm={closeForm} /> })}>
          Quote Submission Form
        </MenuItem>
        <MenuItem onClick={() => openForm({ name: 'Carrier Submission Form', component: <CarrierSubmissionForm closeForm={closeForm} /> })}>
          Carrier Submission Form
        </MenuItem>
        <MenuItem onClick={() => openForm({ name: 'Add Lead Form', component: <AddLeadForm mode={'create'} leadToUpdate={null} open={showForm} closeForm={closeForm} onCreate={handleLeadCreate} /> })}>
          Add Lead Form
        </MenuItem>
        <MenuItem onClick={() => openForm({ name: 'Form 3', component: <div>Form 3 Placeholder</div> })}>
          Form 3(placeholder)
        </MenuItem>
      </Menu>
      {selectedForm && showForm && selectedForm.name === "Carrier Submission Form" ? (
        <CarrierSubmissionForm open={showForm} closeForm={closeForm} />
      ) : selectedForm && showForm && selectedForm.name === "Quote Submission Form" ? (
        <QuoteSubmissionForm open={showForm} closeForm={closeForm} />
      ) : selectedForm && showForm && selectedForm.name === "Add Lead Form" ? (
        <AddLeadForm mode={"create"} leadToUpdate={null} open={showForm} closeForm={closeForm} onCreate={handleLeadCreate} />
      ) : selectedForm && selectedForm.name === "Form 3" ? (
        <Dialog open={showForm}>
          <DialogContent>
            <div>Form 3 Placeholder</div>
            <DialogActions>
              <Button onClick={closeForm} variant="contained" color="error">
                Close
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      ) : (
        ""
      )}
      {/* <Dialog open={showForm} onClose={closeForm} fullWidth maxWidth="xl">
        <DialogContent>{selectedForm ? selectedForm.component : null}</DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
        </DialogActions>
      </Dialog> */}
    </>
  );
};

export default FormsMenu;

