import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  TextField,
  Box,
  Button,
  Container,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  useTheme,
  Alert,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useManagerUpdateLeadMutation } from "state/managementApi";
import { useSnackbar } from "notistack";
import { Close } from "@mui/icons-material";

const phoneRegExp =
  /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}(?:\s*(?:#|x.?|ext.?|extension)\s*[0-9]+)?$/im;

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  city: yup.string(),
  state: yup.string(),
  country: yup.string(),
  zipCode: yup.string(),
  contactName: yup.string(),
  contactLastName: yup.string(),
  contactNumber: yup.string().matches(phoneRegExp, "Invalid phone number"),
  contactEmail: yup.string().email("Invalid email address"),
  secondaryEmail: yup.string().email("Invalid email address"),
  APName: yup.string(),
  APNumber: yup.string().matches(phoneRegExp, "Invalid phone number"),
  APEmail: yup.string().email("Invalid email address"),
  creditLimit: yup.number(),
  commodity: yup.string(),
  equipmentType: yup.string(),
  dateSetup: yup.date(),
  firstLoadMoved: yup.date(),
  lastLoadMoved: yup.date(),
  transferDate: yup.date(),
  brokerNotes: yup.string(),
  csNotes: yup.string(),
  nextStep: yup.string(),
  title: yup.string(),
  website: yup.string(),
  status: yup.string(),
  assignedUsers: yup.array().of(yup.object()).nullable(), //THIS
});

const ManageUpdateLeadForm = ({
  leadToUpdate,
  open,
  closeForm,
  updateRefetch,
  isPool = false,
  categories,
}) => {
  const theme = useTheme();
  const initialValues = !isPool
    ? {
        _id: leadToUpdate._id,
        name: leadToUpdate.name || "",
        city: leadToUpdate.city || "",
        state: leadToUpdate.state || "",
        country: leadToUpdate.country || "",
        zipCode: leadToUpdate.zipCode || "",
        contactName: leadToUpdate.contactName || "",
        contactLastName: leadToUpdate.contactLastName || "",
        contactNumber: leadToUpdate.contactNumber || "",
        contactEmail: leadToUpdate.contactEmail || "",
        secondaryEmail: leadToUpdate?.secondaryEmail || "",
        APName: leadToUpdate.APName || "",
        APNumber: leadToUpdate.APNumber || "",
        APEmail: leadToUpdate.APEmail || "",
        creditLimit: leadToUpdate.creditLimit || "",
        commodity: leadToUpdate.commodity || "",
        equipmentType: leadToUpdate.equipmentType || "",
        dateSetup: leadToUpdate.dateSetup || "",
        firstLoadMoved: leadToUpdate.firstLoadMoved || "",
        lastLoadMoved: leadToUpdate.lastLoadMoved || "",
        transferDate: leadToUpdate.transferDate || "",
        brokerNotes: leadToUpdate.brokerNotes || "",
        csNotes: leadToUpdate.csNotes || "",
        nextStep: leadToUpdate.nextStep || "",
        title: leadToUpdate.title || "",
        website: leadToUpdate.website || "",
        status: leadToUpdate.status || "new",
        // assignedUsers: leadToUpdate.assignedUsers || [],
      }
    : {
        _id: leadToUpdate._id,
        name: leadToUpdate.name || "",
        contactNumber: leadToUpdate.contactNumber || "",
        commodity: leadToUpdate.commodity || "",
        website: leadToUpdate.website || "",
        city: leadToUpdate.city || "",
        state: leadToUpdate.state || "",
        country: leadToUpdate.country || "",
        zipCode: leadToUpdate.zipCode || "",
        status: leadToUpdate.status || "pool",
        APName: leadToUpdate.APName || "",
        APNumber: leadToUpdate.APNumber || "",
        APEmail: leadToUpdate.APEmail || "",
        creditLimit: leadToUpdate.creditLimit || "",
        equipmentType: leadToUpdate.equipmentType || "",
        categories: leadToUpdate.categories || [],
      };
  const [selectedPoolCategories, setSelectedPoolCategories] = useState(
    leadToUpdate.categories
  );

  const [managerUpdateLead] = useManagerUpdateLeadMutation();
  const [alert, setAlert] = useState(null); // State to manage the alert

  const { enqueueSnackbar } = useSnackbar();

  const handleAlertClose = () => {
    setAlert(null); // Clear the alert
  };

  const handlePoolCategoryCheckbox = (selectedCategoryId) => {
    const isSelected = selectedPoolCategories.includes(selectedCategoryId);
    if (isSelected) {
      setSelectedPoolCategories(
        selectedPoolCategories.filter(
          (category) => category !== selectedCategoryId
        )
      );
    } else {
      setSelectedPoolCategories([
        ...selectedPoolCategories,
        selectedCategoryId,
      ]);
    }
  };

  const handleUpdateDifferences = async (initialValues, updatedValues) => {
    const differences = {};
    for (const key in updatedValues) {
      if (initialValues[key] !== updatedValues[key]) {
        differences[key] = updatedValues[key];
      }
    }

    return differences;
  };

  const handleSubmit = async (values) => {
    if (isPool) {
      try {
        values.categories = selectedPoolCategories;

        await managerUpdateLead({ id: values._id, data: values }).then(() => {
          updateRefetch();
          enqueueSnackbar(`${values.name} Updated`);
          closeForm();
        });
      } catch (error) {
        console.error("Error updating lead:", error);
        setAlert({ type: "error", message: "An error occurred" });
      }
    } else {
      try {
        const updatedValues = { ...values };
        const differences = await handleUpdateDifferences(
          initialValues,
          updatedValues
        );

        await managerUpdateLead({
          id: values._id,
          data: values,
          leadUpdates: differences,
        }).then(() => {
          updateRefetch();
          enqueueSnackbar(`${values.name} Updated`);
          closeForm();
        });
      } catch (error) {
        console.error("Error updating lead:", error);
        setAlert({ type: "error", message: "An error occurred" });
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  return (
    <Dialog open={open} onClose={closeForm} fullWidth maxWidth="xl">
      <DialogTitle sx={{ paddingBottom: "0px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" align="center" gutterBottom>
            Update {leadToUpdate.name}
          </Typography>
          <IconButton onClick={closeForm}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <Container
          maxWidth="xl"
          sx={{
            backgroundColor: theme.palette.primary[900],
            padding: "40px",
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Company name *"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="contactName"
                  name="contactName"
                  label="Contact First Name"
                  value={formik.values.contactName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.contactName &&
                    Boolean(formik.errors.contactName)
                  }
                  helperText={
                    formik.touched.contactName && formik.errors.contactName
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="contactLastName"
                  name="contactLastName"
                  label="Contact Last Name"
                  value={formik.values.contactLastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.contactLastName &&
                    Boolean(formik.errors.contactLastName)
                  }
                  helperText={
                    formik.touched.contactLastName &&
                    formik.errors.contactLastName
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="city"
                  name="city"
                  label="City *"
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  error={formik.touched.city && Boolean(formik.errors.city)}
                  helperText={formik.touched.city && formik.errors.city}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="state"
                  name="state"
                  label="State *"
                  value={formik.values.state}
                  onChange={formik.handleChange}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="country-label">Country *</InputLabel>
                  <Select
                    labelId="country-label"
                    id="country"
                    name="country"
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.country && Boolean(formik.errors.country)
                    }
                  >
                    <MenuItem value="US">US</MenuItem>
                    <MenuItem value="CAN">CAN</MenuItem>
                    <MenuItem value="MEX">MEX</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="zipCode"
                  name="zipCode"
                  label="Zip Code"
                  value={formik.values.zipCode}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.zipCode && Boolean(formik.errors.zipCode)
                  }
                  helperText={formik.touched.zipCode && formik.errors.zipCode}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="website"
                  name="website"
                  label="Website"
                  value={formik.values.website}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.website && Boolean(formik.errors.website)
                  }
                  helperText={formik.touched.website && formik.errors.website}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="contactNumber"
                  name="contactNumber"
                  label="Contact Number"
                  value={formik.values.contactNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.contactNumber &&
                    Boolean(formik.errors.contactNumber)
                  }
                  helperText={
                    formik.touched.contactNumber && formik.errors.contactNumber
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="contactEmail"
                  name="contactEmail"
                  label="Contact Email"
                  value={formik.values.contactEmail}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.contactEmail &&
                    Boolean(formik.errors.contactEmail)
                  }
                  helperText={
                    formik.touched.contactEmail && formik.errors.contactEmail
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="secondaryEmail"
                  name="secondaryEmail"
                  label="Secondary Email"
                  value={formik.values.secondaryEmail}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.secondaryEmail &&
                    Boolean(formik.errors.secondaryEmail)
                  }
                  helperText={
                    formik.touched.secondaryEmail &&
                    formik.errors.secondaryEmail
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="APName"
                  name="APName"
                  label="AP Name"
                  value={formik.values.APName}
                  onChange={formik.handleChange}
                  error={formik.touched.APName && Boolean(formik.errors.APName)}
                  helperText={formik.touched.APName && formik.errors.APName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="APNumber"
                  name="APNumber"
                  label="AP Number"
                  value={formik.values.APNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.APNumber && Boolean(formik.errors.APNumber)
                  }
                  helperText={formik.touched.APNumber && formik.errors.APNumber}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="APEmail"
                  name="APEmail"
                  label="AP Email"
                  value={formik.values.APEmail}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.APEmail && Boolean(formik.errors.APEmail)
                  }
                  helperText={formik.touched.APEmail && formik.errors.APEmail}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="creditLimit"
                  name="creditLimit"
                  label="Credit Limit(no commas)"
                  value={formik.values.creditLimit}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.creditLimit &&
                    Boolean(formik.errors.creditLimit)
                  }
                  helperText={
                    formik.touched.creditLimit && formik.errors.creditLimit
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="commodity"
                  name="commodity"
                  label="Commodity"
                  value={formik.values.commodity}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.commodity && Boolean(formik.errors.commodity)
                  }
                  helperText={
                    formik.touched.commodity && formik.errors.commodity
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="equipmentType"
                  name="equipmentType"
                  label="Equipment Type"
                  value={formik.values.equipmentType}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.equipmentType &&
                    Boolean(formik.errors.equipmentType)
                  }
                  helperText={
                    formik.touched.equipmentType && formik.errors.equipmentType
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="dateSetup"
                  name="dateSetup"
                  label="Date Setup"
                  type="date"
                  value={formik.values.dateSetup}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.dateSetup && Boolean(formik.errors.dateSetup)
                  }
                  helperText={
                    formik.touched.dateSetup && formik.errors.dateSetup
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="firstLoadMoved"
                  name="firstLoadMoved"
                  label="First Load Moved"
                  type="date"
                  value={formik.values.firstLoadMoved}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstLoadMoved &&
                    Boolean(formik.errors.firstLoadMoved)
                  }
                  helperText={
                    formik.touched.firstLoadMoved &&
                    formik.errors.firstLoadMoved
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="lastLoadMoved"
                  name="lastLoadMoved"
                  label="Last Load Moved"
                  type="date"
                  value={formik.values.lastLoadMoved}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastLoadMoved &&
                    Boolean(formik.errors.lastLoadMoved)
                  }
                  helperText={
                    formik.touched.lastLoadMoved && formik.errors.lastLoadMoved
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="transferDate"
                  name="transferDate"
                  label="Transfer Date"
                  type="date"
                  value={formik.values.transferDate}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.transferDate &&
                    Boolean(formik.errors.transferDate)
                  }
                  helperText={
                    formik.touched.transferDate && formik.errors.transferDate
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="csNotes"
                  name="csNotes"
                  label="CS Notes"
                  value={formik.values.csNotes}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.csNotes && Boolean(formik.errors.csNotes)
                  }
                  helperText={formik.touched.csNotes && formik.errors.csNotes}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="nextStep"
                  name="nextStep"
                  label="Next Step"
                  value={formik.values.nextStep}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.nextStep && Boolean(formik.errors.nextStep)
                  }
                  helperText={formik.touched.nextStep && formik.errors.nextStep}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="title"
                  name="title"
                  label="Title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  id="brokerNotes"
                  name="brokerNotes"
                  label="Broker Notes"
                  value={formik.values.brokerNotes}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.brokerNotes &&
                    Boolean(formik.errors.brokerNotes)
                  }
                  helperText={
                    formik.touched.brokerNotes && formik.errors.brokerNotes
                  }
                  style={{ marginBottom: "16px" }}
                />
              </Grid>
              {isPool && (
                <Grid item xs={6}>
                  <FormGroup>
                    <InputLabel>Categories</InputLabel>
                    {categories.map((category) => (
                      <FormControlLabel
                        key={category.name}
                        control={
                          <Checkbox
                            checked={
                              !!selectedPoolCategories.includes(category._id)
                            }
                            onChange={() =>
                              handlePoolCategoryCheckbox(category._id)
                            }
                          />
                        }
                        label={category.name}
                      />
                    ))}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedPoolCategories.length === 0}
                          disabled
                        />
                      }
                      label="Default"
                    />
                  </FormGroup>
                </Grid>
              )}
            </Grid>
          </form>
        </Container>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={formik.handleSubmit}
        >
          Update
        </Button>
        <Button variant="outlined" color="error" onClick={closeForm}>
          Close
        </Button>
      </DialogActions>
      <Box textAlign="center">
        {alert && (
          <Alert
            severity={alert.type}
            onClose={handleAlertClose}
            sx={{ mb: 1 }}
          >
            {alert.message}
          </Alert>
        )}
      </Box>
    </Dialog>
  );
};

export default ManageUpdateLeadForm;
