import { api } from "./api";

// ADD TAGS

export const salesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getEmployeesList: build.query({
      query: () => `/sales/agents`,
    }),
    updateUser: build.mutation({
      query: ({ id, data }) => ({
        url: `/sales/users/${id}/update`,
        method: "PATCH",
        body: data,
      }),
    }),
    getAllSales: build.query({
      query: () => "/sales/sales",
    }),
    createSale: build.mutation({
      query: (data) => ({
        url: "/sales/sale",
        method: "POST",
        body: data,
      }),
    }),
    getSaleById: build.query({
      query: (id) => `/sales/sales/${id}`,
    }),
    updateSale: build.mutation({
      query: ({ id, data }) => ({
        url: `/sales/sales/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    deleteSale: build.mutation({
      query: (id) => ({
        url: `/sales/sales/${id}`,
        method: "DELETE",
      }),
    }),
    getAllLeads: build.query({
      query: () => "/sales/leads",
    }),
    createLeadForSelfSales: build.mutation({
      query: (data) => ({
        url: "/sales/leads",
        method: "POST",
        body: data,
      }),
    }),
    getLeadById: build.query({
      query: (id) => `/sales/leads/${id}`,
    }),
    getLeadsByStatus: build.query({
      query: ([status, page = 1, sort, order /*, commodity*/]) => {
        const url = `/sales/leads/status/${status}?page=${page}&limit=100&sort=${sort}&order=${order}`;
        return url;
      },
    }),
    updateLead: build.mutation({
      query: ({ id, leadUpdates }) => ({
        url: `/sales/leads/${id}`,
        method: "PATCH",
        body: leadUpdates,
      }),
    }),
    transferLeads: build.mutation({
      query: (data) => ({
        url: `/sales/leads/transfer`,
        method: "POST",
        body: data,
      }),
    }),
    deleteLead: build.mutation({
      query: (id) => ({
        url: `/sales/leads/${id}`,
        method: "DELETE",
      }),
    }),
    getSalesByLead: build.query({
      query: ({ leadId, page = 1, limit = 5 }) =>
        `/sales/lead/${leadId}?page=${page}&limit=${limit}`,
    }),
    createActivity: build.mutation({
      query: (data) => ({
        url: "/sales/activities",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["User"],
    }),
    createActivityForLeads: build.mutation({
      query: (data) => ({
        url: "/sales/activities/emails",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["User"],
    }),
    getAllActivities: build.query({
      query: () => "/sales/activities",
    }),
    getActivityById: build.query({
      query: (id) => `/sales/activities/${id}`,
    }),
    getActivitiesByUserForDay: build.query({
      query: (date) => `/sales/activities/date?date=${date}`,
    }),
    getActivitiesByLeadId: build.query({
      query: (leadId) => `/sales/activities/lead/${leadId}`,
      providesTags: ["Activity"],
    }),
    updateActivity: build.mutation({
      query: ({ id, data }) => ({
        url: `/sales/activities/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Activity"],
    }),
    deleteActivity: build.mutation({
      query: (id) => ({
        url: `/sales/activities/${id}`,
        method: "DELETE",
      }),
    }),
    getStatsByUserForDay: build.query({
      query: ({ agentId, date }) =>
        `/sales/stats/${agentId}/${date}`,
    }),
    updateStatsForUserByDay: build.mutation({
      query: ({ statsId, stats }) => ({
        url: `/sales/stats/${statsId}`,
        method: "PATCH",
        body: stats,
      })
    }),
    //! Customer Portal routes
    sendQuoteToCustomerPortal: build.mutation({
      query: ({ quoteId, status }) => ({
        url: `/sales/send-quote-to-customer-portal`,
        method: "POST",
        body: {
          quoteId,
          status,
        }
      })
    }),
  }),
});

export const {
  useGetEmployeesListQuery,
  useUpdateUserMutation,
  useGetAllSalesQuery,
  useCreateSaleMutation,
  useGetSaleByIdQuery,
  useUpdateSaleMutation,
  useDeleteSaleMutation,
  useGetAllLeadsQuery,
  useCreateLeadForSelfSalesMutation,
  useGetLeadByIdQuery,
  useGetLeadsByStatusQuery,
  useUpdateLeadMutation,
  useTransferLeadsMutation,
  useDeleteLeadMutation,
  useGetSalesByLeadQuery,
  useCreateActivityMutation,
  useCreateActivityForLeadsMutation,
  useGetAllActivitiesQuery,
  useGetActivityByIdQuery,
  useGetActivitiesByUserForDayQuery,
  useGetActivitiesByLeadIdQuery,
  useUpdateActivityMutation,
  useDeleteActivityMutation,
  useGetStatsByUserForDayQuery,
  useUpdateStatsForUserByDayMutation,
  useSendQuoteToCustomerPortalMutation,
} = salesApi;
