import { api } from "./api";

export const operationsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAllOperationsTeamMembers: build.query({
            query: () => `/operations/members`,
            providesTags: ["OperationsTeam"],
        }),
        editOperationsTeamMemberColor: build.mutation({
            query: ({ id, color }) => ({
                url: `/operations/edit/${id}`,
                method: 'PATCH',
                body: {
                    color
                }
            }),
            invalidatesTags: ["OperationsTeam"],
        }),
        addMemberToOperationsTeam: build.mutation({
            query: (userIdToAdd) => ({
                url: `/operations/add`,
                method: 'POST',
                body: {
                    userIdToAdd
                },
            }),
            invalidatesTags: ["OperationsTeam"],
        }),
        removeMemberFromOperationsTeam: build.mutation({
            query: (userIdToRemove) => ({
                url: `/operations/remove`,
                method: 'DELETE',
                body: {
                    userIdToRemove
                }
            }),
            invalidatesTags: ["OperationsTeam", "Quotes"],
        }),
        createQuote: build.mutation({
            query: (quote) => ({
                url: `/operations/quotes`,
                method: 'POST',
                body: quote
            }),
            invalidatesTags: ["Quotes"]
        }),
        getAllQuotes: build.query({
            query: ({ page, limit, status, emailSubject, date, assignedTo, includeArchived }) => {
                const params = new URLSearchParams();

                if (page) params.append('page', page);
                if (limit) params.append('limit', limit);
                if (status) params.append('status', status);
                if (emailSubject) params.append('emailSubject', emailSubject);
                if (date) params.append('date', date);
                if (assignedTo) params.append('assignedTo', assignedTo);
                if (includeArchived) params.append('includeArchived', 'true');

                const queryString = params.toString();
                return `/operations/quotes${queryString ? `?${queryString}` : ''}`;
            },
            providesTags: ["Quotes"]
        }),
        getQuoteById: build.query({
            query: (id) => `/operations/quotes/${id}`,
        }),
        updateQuote: build.mutation({
            query: ({ id, updates }) => ({
                url: `/operations/quotes/${id}`,
                method: 'PATCH',
                body: updates,
            }),
            invalidatesTags: ["Quotes"]
        }),
        deleteQuote: build.mutation({
            query: (id) => ({
                url: `/operations/quotes/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ["Quotes"]
        }),
    })
});

export const {
    useGetAllOperationsTeamMembersQuery,
    useEditOperationsTeamMemberColorMutation,
    useAddMemberToOperationsTeamMutation,
    useRemoveMemberFromOperationsTeamMutation,
    useCreateQuoteMutation,
    useGetAllQuotesQuery,
    useGetQuoteByIdQuery,
    useUpdateQuoteMutation,
    useDeleteQuoteMutation,
} = api;