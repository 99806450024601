import React from 'react'
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme
} from "@mui/material";
import {
  ChevronLeft,
  ChevronRightOutlined,
} from "@mui/icons-material";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import FlexBetween from './FlexBetween';
import { AdminItems, AgentItems, ManagerItems, DispatchItems, CustomerItems, OperationsItems,} from './SidebarItems';

let navItems =[]

const Sidebar = ({
  user,
  drawerWidth,
  isSidebarOpen,
  setIsSidebarOpen,
  isNonMobile
}) => {
  const { pathname } = useLocation();
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();

  //this will set active to correct URL as it changes. 
  useEffect(()=> {
    setActive(pathname.substring(1));
  }, [pathname])

  if (user.role === 'superadmin' || user.role === 'admin') {
    navItems = AdminItems;
  } else if (user.role === 'agent' && user.isOnOperations) {
    navItems = OperationsItems;
  } else if (user.role === 'agent') {
    navItems = AgentItems;
  } else if (user.role === 'customer') {
    navItems = CustomerItems;
  } else if (user.role === 'manager') {
    navItems = ManagerItems;
  } else if (user.role === 'dispatch') {
    navItems = DispatchItems;
  } else {
    // Default fallback if the user role doesn't match any specific condition
    navItems = [{ text: "User not found."}]
  }

  return (
    <Box component="nav">
      {isSidebarOpen && (
        <Drawer 
          open={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          variant="persistent"
          anchor="left"
          sx={{
            width: drawerWidth,
            "& .MuiDrawer-paper": {
              color: theme.palette.secondary[200],
              backgroundColor: theme.palette.background.alt,
              boxSizing: "border-box",
              borderWidth: isNonMobile ? 0 : "2px",
              width: drawerWidth
            }
          }}
        >
          <Box width="100%">
            <Box m="1.5rem 2rem 2rem 3rem">
              <FlexBetween color={theme.palette.secondary.main}>
                <Box display="flex" alignItems="center" gap=".5rem">
                  <Typography variant="h4" fontWeight="bold">
                    KTB GLOBAL
                  </Typography>
                </Box>
                {!isNonMobile && (
                  <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                    <ChevronLeft />
                  </IconButton>
                )}
              </FlexBetween>
            </Box>
            {/*List NAV ITEMS */}
            <List>
              {navItems.map(({ text, icon }) => {
                if (!icon){
                  return (
                    <Typography key={text} sx={{m: "2.25rem 0 1rem 3rem"}}>
                      {text}
                    </Typography>
                  )
                }
                const lcText = text.toLowerCase();

                return (
                  <ListItem key={text} disablePadding>
                    <ListItemButton 
                      onClick={() => { 
                        navigate(`/${lcText}`); 
                        setActive(lcText);
                      }}
                      sx={{
                        backgroundColor: 
                          active === lcText 
                            ? theme.palette.secondary[300]
                            : "transparent",
                        color: 
                          active === lcText 
                            ? theme.palette.primary[600]
                            : theme.palette.secondary[100], 
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          m: "2rem",
                          color: 
                            active === lcText 
                              ? theme.palette.primary[600]
                              : theme.palette.secondary[200],
                        }}
                      >
                        {icon}
                      </ListItemIcon>
                      <ListItemText primary={text} />
                      {active === lcText && (
                        <ChevronRightOutlined sx={{ ml: "auto" }} />
                      )}
                    </ListItemButton>
                  </ListItem>
                )
              })}
            </List>
          </Box>
          <Box position="absolute" bottom="2rem">
          <Divider />
          </Box>
        </Drawer>
      )}
    </Box>
  )
}

export default Sidebar;