import React from 'react';
import { Box } from '@mui/material';

const ValueGraph = ({ value, valueGoal, label }) => {
  const calculatePercentage = () => {
    if (valueGoal === 0) return 0;
    return (value / valueGoal) * 100;
  };

  const getBackgroundColor = () => {
    const percentage = calculatePercentage();

    if (percentage <= 15) {
      return '#DC143C'; // Crimson
    } else if (percentage <= 40) {
      return '#FFA500'; // Orange
    } else if (percentage <= 60) {
      return '#008080'; // Teal
    } else if (percentage <= 80) {
      return '#008000'; // Green
    } else {
      return '#32CD32'; // Lime Green
    }
  };

  const isGlowing = calculatePercentage() === 100;

  return (
    <Box
      sx={{
        width: '100%',
        height: '10px',
        backgroundColor: '#f0f0f0',
        borderRadius: '5px',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          width: `${calculatePercentage()}%`,
          height: '100%',
          backgroundColor: getBackgroundColor(),
          borderRadius: '5px',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {isGlowing && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: '-100%',
              width: '200%',
              height: '100%',
              background: `linear-gradient(90deg, ${getBackgroundColor()} 0%, #69fe0f 50%, ${getBackgroundColor()} 100%)`,
              animation: 'glowing-animation 4s linear infinite',
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: '#000000',
          fontWeight: 'bold',
        }}
      >
        {`${value}/${valueGoal}`}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          right: '8px',
          transform: 'translateY(-50%)',
          color: '#000000',
          padding: '1px',
        }}
      >
        <b>{label}</b>
      </Box>
      <style>
        {`
        @keyframes glowing-animation {
          0% {
            transform: translateX(0%);
          }
          100% {
            transform: translateX(100%);
          }
        }
      `}
      </style>
    </Box>
  );
};

export default ValueGraph;






