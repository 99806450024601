import React, { useState, useEffect } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import Navbar from '../../components/layout/Navbar';
import Sidebar from '../../components/layout/Sidebar';
import { useGetUserByJWTQuery } from 'state/api';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/userSlice';
import Footer from 'components/layout/Footer';

const Layout = () => {
  const isNonMobile = useMediaQuery('(min-width:600px)');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, isError } = useGetUserByJWTQuery(undefined, {
    skip: false,
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  
  useEffect(() => {
    if (data) {
      dispatch(setUser(data));
    } else if (isError) {
      navigate('/login', { replace: true });
    }
  }, [data, isError, dispatch, navigate]);

  return (
    <Box display={isNonMobile ? 'flex' : 'block'} width="100%" height="100%">
      <Sidebar
        user={data || {}}
        isNonMobile={isNonMobile}
        drawerWidth="250px"
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <Box flexGrow={1}>
        <Navbar
          user={data || {}}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '90.3%', justifyContent: 'space-between'}}>
          <Outlet />
          <Footer 
            user={data || {}}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;