import { Button } from "@mui/material";
import googleLogo from "../../assets/googleLogo.png";
import { useEffect, useState } from "react";

const GoogleButton = ({accountMade, googleAuthUrl}) => {
    const [googleUrl, setGoogleUrl] = useState("");

    const customBtnStyle = {
        display: "inline-block",
        background: "white",
        color: "#444",
        width: "230px",
        borderRadius: "5px",
        border: "thin solid #888",
        boxShadow: "1px 1px 1px grey",
        whiteSpace: "nowrap",
        cursor: "pointer"
    };

    const iconStyle = {
        display: "inline-block",
        verticalAlign: "middle",
        width: "42px",
        height: "42px"
    };

    const btnTextStyle = {
        display: "inline-block",
        verticalAlign: "middle",
        paddingLeft: "12px",
        fontSize: "14px",
        fontWeight: "bold"
    }


    useEffect(() => {
    // Fetch the Google Auth URL from backend
    const fetchGoogleUrl = async () => {
        const response = await googleAuthUrl;
        setGoogleUrl(response.url);
    };

    fetchGoogleUrl();
    }, );

    return (
        <Button id="googleButton" style={customBtnStyle} onClick={() => window.location.href = googleUrl}>
            <img src={googleLogo} style={iconStyle} alt="google"></img >
            <span style={btnTextStyle}>
                {accountMade ? (
                    "Sign in with Google"
                ):(
                    "Sign up with Google"
                )}
            </span>
        </Button>
    )
};

export default GoogleButton;