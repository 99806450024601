import React, { useState } from 'react';
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl
} from '@mui/material';
import { useDeleteLeadMutation, useUpdateLeadMutation } from '../../state/salesApi';
import AddLeadForm from '../forms/AddLeadForm';
import ConfirmationDialog from '../helpers/ConfirmDialog';
import { useSnackbar } from 'notistack';

const LeadActions = ({ lead, onDelete, /* onMoveToPool, */ refetch }) => {
  const [deleteLead] = useDeleteLeadMutation();
  const [updateLeadMutation] = useUpdateLeadMutation();
  const [updateFormOpen, setUpdateFormOpen] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmMoveToOpen, setConfirmMoveToOpen] = useState(false);
  const [moveTo, setMoveTo] = useState(
    lead.status
  );

  const { enqueueSnackbar } = useSnackbar();

  const handleConfirmDelete = async () => {
    try {
      await deleteLead(lead._id);
      setConfirmDeleteOpen(false);
      onDelete();
    } catch (error) {
      console.error('Error deleting lead:', error);
    }
  };

  const handleMoveTo = (e) => {
    setConfirmMoveToOpen(true);
    setMoveTo(e.target.value);
  }

  const handleConfirmMoveTo = async () => {
    try {
      const values = {
        status: moveTo
      };
      await updateLeadMutation({ id: lead._id, leadUpdates: values })
        .then(() => {
          enqueueSnackbar(`${lead.name} moved to ${moveTo.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase() })}`);
          refetch();
        })
        .then(() => {
          setConfirmMoveToOpen(false);
        })
    } catch (error) {
      console.error({ type: 'error', message: 'An error occurred' });
    }
  };

  const handleUpdateFormClose = () => {
    setUpdateFormOpen(false);
  };

  const handleCancel = () => {
    setConfirmDeleteOpen(false);
    setConfirmMoveToOpen(false);
    setMoveTo(lead.status);
  };

  return (
    <Grid container spacing={2} sx={{ marginTop: 0, display: "flex", alignItems: "center" }}>
      <Grid item xs={12} sm={4}>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl fullWidth>
          <InputLabel variant="filled" id="move-to-select" style={{ color: "inherit" }}>Status:</InputLabel>
          <Select value={moveTo} labelId="move-to-select" onChange={handleMoveTo} style={{ paddingTop: "5px" }}>
            <MenuItem value={"new"}>New</MenuItem>
            <MenuItem value={"warm"}>Warm</MenuItem>
            <MenuItem value={"hot"}>Hot</MenuItem>
            <MenuItem value={"activeCustomer"}>Active Customer</MenuItem>
            <MenuItem value={"pendingCustomer"}>Pending Customer</MenuItem>
            <MenuItem value={"assignedCustomer"}>Assigned Customer</MenuItem>
            {/* <MenuItem value={"pool"}>Pool</MenuItem> */}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
      </Grid>
      <ConfirmationDialog
        open={confirmDeleteOpen}
        onClose={handleCancel}
        onConfirm={handleConfirmDelete}
        title="Confirm Delete"
        message={`Are you sure you want to delete ${lead.name}?`}
      />
      <ConfirmationDialog
        open={confirmMoveToOpen}
        onClose={handleCancel}
        onConfirm={handleConfirmMoveTo}
        title="Confirm Move"
        message={`Are you sure you want to move ${lead.name} to ${moveTo.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase() })}?`}
      />
      <AddLeadForm
        mode="update"
        leadToUpdate={lead}
        open={updateFormOpen}
        closeForm={handleUpdateFormClose}
        updateRefetch={refetch}
      />
    </Grid>
  );
};

export default LeadActions;
