// import { useState } from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { useMemo } from "react";
// import IconButton from "@mui/material/IconButton";
// import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
// import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";


const Header = () => {
    const cellStyles = {
        borderLeft: '0.5px solid gray',
        borderRight: '0.5px solid gray',
    };

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell rowSpan={2} /> */}
                <TableCell rowSpan={2} align="center">Broker</TableCell>
                <TableCell colSpan={2} align="center" style={cellStyles}>Margin</TableCell>
                <TableCell colSpan={2} align="center">New Calls</TableCell>
                <TableCell colSpan={2} align="center" style={cellStyles}>FU Calls</TableCell>
                <TableCell colSpan={2} align="center">Packets</TableCell>
                <TableCell colSpan={2} align="center" style={cellStyles}>FU Emails</TableCell>
                <TableCell colSpan={2} align="center">List Emails</TableCell>
                <TableCell colSpan={2} align="center" style={cellStyles}>Loads</TableCell>
                <TableCell colSpan={2} align="center">Setups</TableCell>
                {/* <TableCell colSpan={2} align="center">Weekly Totals</TableCell> */}
            </TableRow>
            <TableRow>
                <TableCell style={cellStyles}>Actual</TableCell>
                <TableCell>Quota</TableCell>
                <TableCell style={cellStyles}>Actual</TableCell>
                <TableCell>Quota</TableCell>
                <TableCell style={cellStyles}>Actual</TableCell>
                <TableCell>Quota</TableCell>
                <TableCell style={cellStyles}>Actual</TableCell>
                <TableCell>Quota</TableCell>
                <TableCell style={cellStyles}>Actual</TableCell>
                <TableCell>Quota</TableCell>
                <TableCell style={cellStyles}>Actual</TableCell>
                <TableCell>Quota</TableCell>
                <TableCell style={cellStyles}>Actual</TableCell>
                <TableCell>Quota</TableCell>
                <TableCell style={cellStyles}>Actual</TableCell>
                <TableCell>Quota</TableCell>
                {/* <TableCell style={cellStyles}>Actual</TableCell> */}
                {/* <TableCell>Quota</TableCell> */}
            </TableRow>
        </TableHead>
    )
};

const Row = ({ agent }) => {
    // const [isOpen, setIsOpen] = useState();

    const oddCellStyles = {
        borderLeft: '0.5px solid gray',
        borderRight: '0.5px solid gray',
        borderBottom: '0.5px solid gray',
    };

    const evenCellStyles = {
        borderBottom: '0.5px solid gray',
    };

    return (
        <>
            <TableRow
                sx={{
                    transition: 'background-color 0.1s',
                    '&:hover': {
                        backgroundColor: '#4F4E4E'
                    }
                }}
            >
                {/* <TableCell>
                    <IconButton
                        aria-label="expand table"
                        size="small"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </TableCell> */}
                <TableCell /* component="th" scope="row" */ style={evenCellStyles}>
                    {agent.name}
                </TableCell>
                <TableCell
                    align="right"
                    style={oddCellStyles}
                    sx={{
                        backgroundColor: agent.margin >= agent.marginGoals ? '#58BB49' : '#D32D2D'
                    }}
                >
                    ${agent.margin}
                </TableCell>
                <TableCell align="right" style={evenCellStyles}>
                    ${agent.marginGoals}
                </TableCell>
                <TableCell
                    align="right"
                    style={oddCellStyles}
                    sx={{
                        backgroundColor: agent.dailyNewCalls >= agent.newCallGoals ? '#58BB49' : '#D32D2D'
                    }}
                >
                    {agent.dailyNewCalls}
                </TableCell>
                <TableCell align="right" style={evenCellStyles}>
                    {agent.newCallGoals}
                </TableCell>
                <TableCell
                    align="right"
                    style={oddCellStyles}
                    sx={{
                        backgroundColor: agent.dailyCalls >= agent.callGoals ? '#58BB49' : '#D32D2D'
                    }}
                >
                    {agent.dailyCalls}
                </TableCell>
                <TableCell align="right" style={evenCellStyles}>
                    {agent.callGoals}
                </TableCell>
                <TableCell
                    align="right"
                    style={oddCellStyles}
                    sx={{
                        backgroundColor: agent.packets >= agent.packetGoals ? '#58BB49' : '#D32D2D'
                    }}
                >
                    {agent.packets}
                </TableCell>
                <TableCell align="right" style={evenCellStyles}>
                    {agent.packetGoals}
                </TableCell>
                <TableCell
                    align="right"
                    style={oddCellStyles}
                    sx={{
                        backgroundColor: agent.dailyEmails >= agent.emailGoals ? '#58BB49' : '#D32D2D'
                    }}
                >
                    {agent.dailyEmails}
                </TableCell>
                <TableCell align="right" style={evenCellStyles}>
                    {agent.emailGoals}
                </TableCell>
                <TableCell
                    align="right"
                    style={oddCellStyles}
                    sx={{
                        backgroundColor: agent.dailyListEmails >= agent.dailyListEmailGoals ? '#58BB49' : '#D32D2D'
                    }}
                >
                    {agent.dailyListEmails}
                </TableCell>
                <TableCell align="right" style={evenCellStyles}>
                    {agent.dailyListEmailGoals}
                </TableCell>
                <TableCell
                    align="right"
                    style={oddCellStyles}
                    sx={{
                        backgroundColor: agent.loadsBooked >= agent.loadsBookedGoals ? '#58BB49' : '#D32D2D'
                    }}
                >
                    {agent.loadsBooked}
                </TableCell>
                <TableCell align="right" style={evenCellStyles}>
                    {agent.loadsBookedGoals}
                </TableCell>
                <TableCell
                    align="right"
                    style={oddCellStyles}
                    sx={{
                        backgroundColor: agent.setups >= agent.setupGoals ? '#58BB49' : '#D32D2D'
                    }}
                >
                    {agent.setups}
                </TableCell>
                <TableCell align="right" style={evenCellStyles}>
                    {agent.setupGoals}
                </TableCell>
            </TableRow>
        </>
    )
};

// TODO: Double check week quota logic and leaderboard pulling
const QuotaSheet = ({ agents }) => {
    const filteredStats = useMemo(() => {
        return [...agents].sort((a, b) => {
            const valueA = a.userName || "";
            const valueB = b.userName || "";
            return valueA.localeCompare(valueB);
        }).filter((agent) => agent?.showStats !== false);
    }, [agents]);
    
    return (
        <TableContainer component={Paper} sx={{ mt: '1rem', overflow: 'auto', maxHeight: 570 }}>
            <Table aria-label="quota table" size="small" stickyHeader>
                <Header />
                <TableBody>
                    {filteredStats.map((agent, index) => (
                        <Row key={index} agent={agent} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
};

export default QuotaSheet;