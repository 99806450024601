import { Close } from "@mui/icons-material";
import { Alert, Box, Button, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Input, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useUploadPacketUploadMutation } from "state/managementApi";

const PacketUpdater = ({ onClose }) => {
    const [packetFile, setPacketFile] = useState(null);
    const [formErrors, setFormErrors] = useState([]);
    const [uploadPacket] = useUploadPacketUploadMutation();

    const { enqueueSnackbar } = useSnackbar();

    const handleFileChange = (e) => {
        setPacketFile(e.target.files[0]);
    };

    const handleUpload = async () => {
        let errors = [];
        if (!packetFile) {
            errors.push('Please select a PDF file.');
        };
        if (errors.length > 0) {
            setFormErrors(errors);
            return;
        };

        const formData = new FormData();
        formData.append('file', packetFile);
        uploadPacket(formData).unwrap()
            .then(() => {
                enqueueSnackbar('Packet Uploaded Successfully');
                onClose();
            }).catch(error => {
                setFormErrors(prev => [...prev, error.message || 'An error occurred while updating packet']);
            });
    };

    return (
        <>
            <DialogTitle>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h6">Update Packet</Typography>
                    <IconButton onClick={onClose}>
                        <Close />
                    </IconButton>
                </div>
                <Divider />
            </DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, padding: 2, margin: 2 }}>
                    <Input 
                        type="file"
                        accept=".pdf"
                        onChange={handleFileChange}
                        fullWidth
                    />
                </Box>
                {formErrors.length > 0 && (
                    <Box>
                        {formErrors.map((error, index) => (
                            <Alert key={index} severity="error">{error}</Alert>
                        ))}
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={handleUpload}>
                    Upload New Packet
                </Button>
                <Button variant="outlined" color="error" onClick={onClose}>
                    Cancel
                </Button>
            </DialogActions>
        </>
    )
}

export default PacketUpdater;