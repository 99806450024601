import React from 'react'
import { Box, Card } from "@mui/material";
import Header from "../../components/layout/Header"


//build product component for mapping, could be it's own component
const Product = () => {
 
  return (
    <Card >
      Example Product Card
    </Card>
  )
}

const Products = () => {

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="PRODUCTS" subtitle="See all products"/>
      Mapping of products
      <Product />   
    </Box>
  )
}

export default Products