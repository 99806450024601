import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";

const MessageInbox = ({ toggleMessages }) => {


    return (
        <Dialog
        open
        onClose={toggleMessages}
        fullWidth
        maxWidth="md"
        >
            <DialogTitle>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography variant="h6">Messages</Typography>
                    <IconButton onClick={toggleMessages}>
                        <Close />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <Typography variant="h3">To be implemented</Typography>
            </DialogContent>
        </Dialog>
    )
}

export default MessageInbox;