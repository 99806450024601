import { Cancel, Close, Delete, Edit, Refresh, Save, /* Tune */ } from "@mui/icons-material";
import { Box, Button, Card, CardContent, /* CardHeader, */ Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, /* Divider, */ FormControlLabel, Grid, IconButton, /* ListItem, ListItemText, Menu, MenuItem, MenuList, */ Pagination, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { hoverStyle } from "components/helpers/MediaQueries";
import Header from "components/layout/Header";
import { useSnackbar } from "notistack";
import mongoose from 'mongoose';
import { useState } from "react";
import { useArchiveDispatchCardMutation, useDeleteArchivedDispatchMutation, useGetArchivedDispatchQuery, useUpdateArchivedDispatchMutation } from "state/api";
// import ArchiveFilterMenu from "components/dispatch/archive/ArchiveFilterMenu";

const Archive = () => {
    const theme = useTheme();
    const [filterOpen, setFilterOpen] = useState(false);
    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const [filterDialogOpen, setFilterDialogOpen] = useState(false);
    const [activeFilters, setActiveFilters] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [isEditingColor, setIsEditingColor] = useState(false);
    const [editCardId, setEditCardId] = useState(null);
    const [editedTitle, setEditedTitle] = useState('');
    const [editedSubtitle, setEditedSubtitle] = useState('');
    const [editedNotes, setEditedNotes] = useState('');
    const [editedDescription, setEditedDescription] = useState('');
    const [editedColor, setEditedColor] = useState('');
    const [oldColor, setOldColor] = useState('');
    const [expandedCards, setExpandedCards] = useState({});
    
    // Query states
    const [currentPage, setCurrentPage] = useState(1);
    const [sortField, setSortField] = useState('createdAt');
    const [sortOrder, setSortOrder] = useState('desc');
    // const [startDate, setStartDate] = useState(null);
    // const [endDate, setEndDate] = useState(null);
    // const [archivedBy, setArchivedBy] = useState(null);
    
    const { data: archivedCards, isLoading, isError, refetch } = useGetArchivedDispatchQuery({ 
        page: currentPage,
        limit: 50, // Change to handle limit
        sortBy: sortField,
        order: sortOrder,
    });
    const [addArchivedCard] = useArchiveDispatchCardMutation();
    const [updateCard] = useUpdateArchivedDispatchMutation();
    const [deleteCard] = useDeleteArchivedDispatchMutation();

    const { enqueueSnackbar } = useSnackbar();

    const defaultCardColor = theme.palette.primary[1000];

    const PREDEFINED_COLORS = [
        { name: 'Default', color: defaultCardColor },
        { name: 'Sergio Rosa', color: '#d961a2' },
        { name: 'Tori Siegal', color: '#8e7cc3' },
        { name: 'Morgan Hodges', color: '#0e9602' },
        { name: 'Dispatch 1', color: '#6d9eeb' },
        { name: 'Dispatch 2', color: '#76a5af' },
        { name: 'Other', color: '#a0522d' },        // Orange/Brown
        { name: 'Trouble', color: '#ff0000' },        // Red
      ];

    const handleFilterDialogOpen = () => setFilterDialogOpen(true);
    const handleFilterDialogClose = () => setFilterDialogOpen(false);

    const handleToggleFilter = (e) => {
        if (filterOpen) {
            setFilterOpen(false);
            setFilterAnchorEl(null);
        } else {
            setFilterOpen(true);
            setFilterAnchorEl(e.currentTarget);
        }
    };

    const handleFilterChange = (color) => {
        if (activeFilters.includes(color)) {
            setActiveFilters(prev => prev.filter(c => c!== color));
        } else {
            setActiveFilters(prev => [...prev, color]);
        }
    };

    const handleToggleCardExpansion = (cardId) => {
        setExpandedCards((prev) => ({
            ...prev,
            [cardId]: !prev[cardId],
        }));
    };

    const handleEditCard = (cardId) => {
        const card = archivedCards.cards.find((item) => item._id === cardId);
        setEditCardId(cardId);
        setOldColor(card.color);
        setEditedColor(card.color);
        setEditedTitle(card.title);
        setEditedSubtitle(card.subtitle);
        setEditedNotes(card.notes);
        setEditedDescription(card.description);
        setIsEditingColor(true);
    };

    const handleEditCardChange = (e, field) => {
        switch (field) {
            case 'title':
                setEditedTitle(e.target.value);
                break;
            case 'subtitle':
                setEditedSubtitle(e.target.value);
                break;
            case 'notes':
                setEditedNotes(e.target.value);
                break;
            case 'description':
                const newValue = e.target.value;
                if (newValue !== undefined) {
                    setEditedDescription(newValue);
                }
                break;
            default:
                break;
        }
    };

    const handleCardColorChange = (cardId, color) => {
        if (isEditingColor) {
            // const card = archivedCards.cards.find((item) => item._id === cardId);
            // card.color = color.hex;
            setEditedColor(color.hex);
        }
    };

    const handleSaveCard = async (cardId) => {
        try {
            await updateCard({ id: cardId, data: {
                title: editedTitle,
                subtitle: editedSubtitle,
                notes: editedNotes,
                description: editedDescription,
                color: editedColor,
            }})
                .then(() => {
                    handleCancelEditCard();
                    enqueueSnackbar("Archived card updated");
                    handleRefresh();
                });
        } catch (error) {
            console.error(error);
        }
    };

    const handleCancelEditCard = () => {
        setEditCardId(null);
        setEditedTitle('');
        setEditedSubtitle('');
        setEditedNotes('');
        setEditedDescription('');
    };

    const handleAddCard = async () => {
        try {
            await addArchivedCard({
                cardId: new mongoose.Types.ObjectId().toString(),
                title: '',
                subtitle: '',
                notes: '',
                description: '',
                color: theme.palette.primary[1000]
            })
                .then(() => {
                    enqueueSnackbar("New card added");
                    handleRefresh();
                });
        } catch (error) {
            console.error(error);
        }
    };

    const handleDeleteCard = async (cardId) => {
        const confirmed = window.confirm('Confirm: delete this card?');
        if (!confirmed) {
            return;
        }
        await deleteCard(cardId)
            .then(() => {
                handleRefresh();
                enqueueSnackbar("Dispatch card deleted");
            })
    };

    const handleClearFilters = () => {
        setActiveFilters([]);
    };

    const handlePageChange = (e, newPage) => {
        e.preventDefault();
        setCurrentPage(newPage);
    };

    const handleRefresh = () => {
        refetch();
    };

    if (isLoading) return <CircularProgress />

    return (
        <Box p="1.5rem 2.5rem" sx={{ width: '100%' }}>
            <Header title="DISPATCH ARCHIVE" subtitle="Manage and view archived cards" />
            <Grid container spacing={1} sx={{ backgroundColor: theme.palette.background.alt, padding: 2, margin: "10px 0px" }}>
                <Grid item xs={12} md={2}>
                    <Grid container spacing={8}>
                        <Box sx={{ display: "flex", marginLeft: "60px", marginTop: "60px", alignItems: "center", }}>
                            <Box sx={{ display: 'flex', }}>
                                <Grid item xs={6} sm={4} md={4}>
                                    <Button variant="contained" fullWidth onClick={handleAddCard}>
                                        Add Card
                                    </Button>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} sx={{ marginLeft: "20px" }}>
                                    <Button variant="contained" fullWidth onClick={handleFilterDialogOpen}>
                                        Filter Colors
                                    </Button>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} sx={{ marginLeft: "20px" }}>
                                    <Button variant="contained" fullWidth onClick={handleClearFilters}>
                                        Clear Filters
                                    </Button>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} sx={{ marginLeft: "20px" }}>
                                    <IconButton onClick={handleRefresh} color="info" size="medium" sx={{ ml: 1, boxShadow: '0 5px 5px rgba(0, 0, 0, 0.2)' }}>
                                        <Refresh />
                                    </IconButton>
                                </Grid>
                            </Box>
                            {/* <Box sx={{ ml: '100px', }}>
                                <Button
                                    variant="contained"
                                    onClick={handleToggleFilter}
                                >
                                    Filter
                                    <Tune sx={{ ml: '10px', }}/>
                                </Button>
                            </Box> */}
                        </Box>
                    </Grid>
                    {/* {filterOpen && (
                        <Menu
                            anchorEl={filterAnchorEl}
                            open={filterOpen}
                            onClose={handleToggleFilter}
                        >
                            <ArchiveFilterMenu predefinedColors={PREDEFINED_COLORS}/>
                        </Menu>
                    )} */}
                    {filterDialogOpen && (
                        <Dialog open={filterDialogOpen} onClose={handleFilterDialogClose}>
                            <DialogTitle>Filter by Color</DialogTitle>
                            <DialogContent>
                                {PREDEFINED_COLORS.map((color) => (
                                    <FormControlLabel
                                        key={color.name}
                                        control={
                                            <Checkbox 
                                                checked={activeFilters.includes(color.color)}
                                                onChange={() => handleFilterChange(color.color)}
                                                name={color.name}
                                                color="primary"
                                            />
                                        }
                                        label={color.name}
                                    />
                                ))}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleFilterDialogClose} variant="contained" color="error">
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </Grid>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
                        <Pagination 
                            sx={{ display: 'flex', justifyContent: 'center', }}
                            count={archivedCards?.totalPages || 1}
                            page={currentPage}
                            onChange={(e, newPage) => {
                                handlePageChange(e, newPage);
                            }}
                        />
                    </div>
                <Grid item xs={12} md={12}>
                    <Grid container sx={{ display: "flex" , flexFlow: "row wrap", }}>
                        {archivedCards?.cards.filter(item => activeFilters.length === 0 || activeFilters.includes(item.color)).map((card, index) => {
                            const isEditing = editCardId === card._id
                            const isExpanded = expandedCards[card._id]
                            const isHovered = !isExpanded ? hoverStyle : {};
                            const color = editCardId === card._id ? editedColor : card.color;

                            return (
                                <Box key={index} sx={{ minWidth: 275, margin: "10px" }}>
                                    <Card 
                                        elevation={isEditing ? 10 : 3} 
                                        sx={isHovered}
                                        style={{ backgroundColor: color, minHeight: 75, }}
                                        onClick={() => {
                                            if (!isExpanded) {
                                                handleToggleCardExpansion(card._id);
                                            };
                                        }}
                                    >
                                        {isExpanded && (
                                            <Typography sx={{ display: "flex", justifyContent: "flex-end", margin: "5px 5px 0px 0px" }}>
                                                <Close 
                                                    sx={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        handleToggleCardExpansion(card._id);
                                                        if (isEditing) {
                                                            handleCancelEditCard(card._id);
                                                        }
                                                    }}
                                                />
                                            </Typography>
                                        )}
                                        <CardContent>
                                            {isEditing ? (
                                                <div>
                                                    <TextField 
                                                        fullWidth
                                                        autoFocus
                                                        label="Title"
                                                        value={editedTitle}
                                                        onChange={(e) => handleEditCardChange(e, 'title')}
                                                        variant="standard"
                                                        multiline
                                                    />
                                                    <TextField 
                                                        fullWidth
                                                        label="Subtitle"
                                                        value={editedSubtitle}
                                                        onChange={(e) => handleEditCardChange(e, 'subtitle')}
                                                        variant="standard"
                                                    />
                                                    <TextField 
                                                        fullWidth
                                                        label="Notes"
                                                        value={editedNotes}
                                                        onChange={(e) => handleEditCardChange(e, 'notes')}
                                                        variant="standard"
                                                        multiline
                                                    />
                                                    <TextField 
                                                        fullWidth
                                                        label="Description"
                                                        variant="outlined"
                                                        value={editedDescription.replace(/,\s+/g, ', ')}
                                                        multiline
                                                        onChange={(e) => {
                                                            const newValue = e.target.value;
                                                            setEditedDescription(newValue);
                                                        }}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            style: {
                                                                whiteSpace: 'pre-wrap',
                                                                overflowWrap: 'break-word',
                                                                wordBreak: 'break-all'
                                                            }
                                                        }}
                                                    />

                                                    <div>
                                                        {PREDEFINED_COLORS.map((predefinedColor) => {
                                                            if (predefinedColor.name.toLowerCase() !== 'default') {
                                                                return (
                                                                    <Tooltip title={predefinedColor.name} key={predefinedColor.name}>
                                                                        <Button
                                                                            style={{ backgroundColor: predefinedColor.color, margin: "5px", color: "white" }}
                                                                            onClick={() => handleCardColorChange(card._id, { hex: predefinedColor.color })}
                                                                        >
                                                                            {predefinedColor.name}
                                                                        </Button>
                                                                    </Tooltip>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </div>
                                                    <Tooltip title="Save" placement="top">
                                                        <IconButton onClick={() => handleSaveCard(card._id)} size="small">
                                                            <Save />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Cancel" placement="top">
                                                        <IconButton onClick={() => handleCancelEditCard(card._id)} size="small">
                                                            <Cancel />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            ) : (
                                                <div 
                                                    style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                                                    /* onClick={() => {
                                                        if (!isExpanded) {
                                                            handleToggleCardExpansion(card._id);
                                                        }
                                                    }} */
                                                >
                                                    <Box>
                                                        <Typography variant="h6" component="h3" gutterBottom>
                                                            {card.title}
                                                        </Typography>
                                                        <Typography variant="subtitle1" gutterBottom>
                                                            {card.subtitle}
                                                        </Typography>
                                                    </Box>
                                                </div>
                                            )}
                                            {isExpanded && !isEditing && (
                                                <div>
                                                    {/* Expanded content */}
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        {card.notes}
                                                    </Typography>
                                                    <Typography variant="body1" gutterBottom>
                                                        {card.description.split(',').map((pair, index) => {
                                                            const [key, ...valueParts] = pair.split(':');
                                                            const value = valueParts.join(':').trim();
                                                            return (
                                                                <span key={index}>
                                                                    {card.description.includes(key) ? (
                                                                        <span style={{ fontWeight: 'bold' }}>{key}</span>
                                                                    ) : (
                                                                        <span>{key}</span>
                                                                    )}
                                                                    {value && `: ${value}`}
                                                                    {index < card.description.split(',').length - 1 ? ',' : ''}
                                                                </span>
                                                            );
                                                        })}
                                                    </Typography>
                                                    <Tooltip title="Edit" placement="top">
                                                        <IconButton onClick={() => handleEditCard(card._id)} size="small">
                                                            <Edit />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete" placement="top">
                                                        <IconButton onClick={() => handleDeleteCard(card._id)} size="small">
                                                            <Delete />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            )}
                                        </CardContent>
                                    </Card>
                                </Box>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Archive;