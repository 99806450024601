import React, { useState } from 'react';
import { Box, Button, CircularProgress, Grid, IconButton, Paper, useTheme } from '@mui/material';
import LeadInfo from './LeadInfo';
import SalesList from '../sales/SalesList';
import LeadActions from './LeadActions';
import AddActivityForm from 'components/forms/AddActivityForm';
import ComposeEmail from 'components/email/ComposeEmail';
import { Add, Email, Refresh as RefreshIcon } from '@mui/icons-material';
// import ActivityLeadList from 'components/activities/ActivityLeadList';
import { useDeleteLeadMutation, /* useGetEmployeesListQuery, */ useGetLeadByIdQuery, /* useTransferLeadsMutation, useUpdateLeadMutation */ } from 'state/salesApi';
import AddCalendarEventForm from 'components/forms/AddCalendarEventForm';
import { useNavigate, useParams } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

// TODO: Return to someday maybe
const LeadDetailsMM = ({ }) => {
  const { leadId } = useParams();
  // const user = useSelector((state) => state.user);
  const theme = useTheme();
  // const [updateFormOpen, setUpdateFormOpen] = useState(false);
  const [isAddActivityOpen, setIsAddActivityOpen] = useState(false);
  const [isEmailLeadOpen, setIsEmailLeadOpen] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [isAddEventOpen, setIsAddEventOpen] = useState(false);
  // const [sentToOpen, setSentToOpen] = useState(false);
  const [showActivityList, setShowActivityList] = useState(false);
  // const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [isSalesListOpen, setIsSalesListOpen] = useState(false);

  const { 
    data: leadData, 
    // isError, 
    isLoading, 
    refetch 
  } = useGetLeadByIdQuery(leadId);
  // const { 
  //   data: employeesData = [], 
  //   employeesError, 
  //   agentsLoading, refetchEmployees 
  // } = useGetEmployeesListQuery();
  // const [transferLead] = useTransferLeadsMutation();
  // const [selectedAgent, setSelectedAgent] = useState('default');
  // const [transferError, setTransferError] = useState(false);
  // const [transferErrorMessage, setTransferErrorMessage] = useState('');
  // const [updateLead] = useUpdateLeadMutation();
  const [deleteLead] = useDeleteLeadMutation();

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  // const handleEditToggle = () => setUpdateFormOpen(prev => !prev);
  // const handleAddActivityToggle = () => setIsAddActivityOpen(prev => !prev);
  // const handleAddEventToggle = () => setIsAddEventOpen(prev => !prev);
  // const handleDeleteToggle = () => setConfirmDeleteOpen(prev => !prev);

  const handleClose = () => {
    // window.close();
    navigate("/dashboard");
  };

  const handleMoveToPool = (updatedLead) => {
    alert("Under Development")
  };

  /* const handleAgentChange = async () => {
    try {
      await transferLead({
        targetEmployeeEmail: selectedAgent,
        leadIds: [leadData._id]
      })
        .then((res) => {
          if (res.error) {
            setTransferError(true);
            setTransferErrorMessage(res.error.data.message);
          } else {
            enqueueSnackbar(`${leadData.name} sent to ${selectedAgent}`);
            handleClose();
          }
        })
    } catch (error) {
      console.error("Error moving lead:", error);
      setTransferError(true);
      setTransferErrorMessage(error.data.message);
    }
  }; */

  /* const handleDNA = async () => {
    try {
      const updatedValues = {
        notes: leadData.DNA.notes,
        status: !leadData.DNA.status
      };
  
      await updateLead({ id: leadData._id, leadUpdates: { DNA: updatedValues } })
        .then(() => {
          handleRefresh();
        });
        
    } catch (error) {
      
    }
  }; */

  const handleDelete = async () => {
    try {
      await deleteLead(leadData._id);
      handleClose();
      enqueueSnackbar(`${leadData.name} Deleted`);
    } catch (error) {
      console.error('Error Deleting Lead:', error);
    }
  };

  const toggleSalesList = () => {
    setIsSalesListOpen(!isSalesListOpen);
  };

  const handleAddEventOpen = () => {
    setIsAddEventOpen(true);
  };

  const handleAddEventClose = () => {
    setIsAddEventOpen(false);
  };

  const handleAddActivity = () => {
    setIsAddActivityOpen(true);
  };

  const handleAddActivityClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setIsAddActivityOpen(false);
    }
  };

  const handleEmailLead = () => {
    setIsEmailLeadOpen(true);
  };

  const handleEmailLeadClose = () => {
    setIsEmailLeadOpen(false);
  };

  const handleToggleActivityList = () => {
    setShowActivityList(prev => !prev);
  };

  const handleRefresh = () => {
    refetch();
  };

  if (isLoading) return <CircularProgress />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}></Grid>
      <Paper sx={{ backgroundColor: theme.palette.background.paper, padding: '10px' }}>
        <Grid style={{ display: "flex", justifyContent: "space-between" }}>
          <Grid container spacing={1} justifyContent="left">
            <Grid item>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Button onClick={handleClose} color="primary" variant="contained">
                  Back to Leads List
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{ mt: 1 }}
                  onClick={handleAddEventOpen}
                >
                  Add Event
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Button onClick={handleAddActivity} color="primary" variant="contained">
                <Add style={{ marginRight: "5px" }} />
                Add Activity
              </Button>
            </Grid>
            <Grid item>
              {leadData.contactEmail ? (
                <Button onClick={handleEmailLead} color="primary" variant="contained">
                  <Email style={{ marginRight: "10px" }} />
                  Email Lead
                </Button>
              ) : (
                <Button color="primary" variant="contained" disabled>
                  No email found
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid justifyContent="center">
            <IconButton onClick={handleRefresh} color="info" size="medium" sx={{ ml: 1, boxShadow: "0 5px 5px rgba(0, 0, 0, 0.2)" }}>
              <RefreshIcon />
            </IconButton>
          </Grid>
          <Grid container spacing={1} justifyContent="right">
            <Button
              variant="contained"
              color="primary"
              style={{ alignSelf: "flex-end" }}
              onClick={handleToggleActivityList}
            >
              {showActivityList ? "Hide All Activity" : "Show All Activity"}
            </Button>
          </Grid>
        </Grid>
        <LeadInfo lead={leadData} showActivityList={showActivityList} refetch={refetch} />
        <LeadActions lead={leadData} onMoveToPool={handleMoveToPool} onDelete={handleDelete} refetch={refetch} />
        <Button onClick={toggleSalesList} color="primary" variant="contained" sx={{ marginTop: 1, marginBottom: 1 }}>
          {isSalesListOpen ? 'Close Sales List' : 'Open Sales List'}
        </Button>
        {isSalesListOpen && <SalesList leadId={leadData._id} />}
        {isAddEventOpen && <AddCalendarEventForm handleCloseEventForm={handleAddEventClose} />}
        {isAddActivityOpen && (
          <AddActivityForm leadId={leadData._id} onClose={handleAddActivityClose} refetch={refetch} />
        )}
        {isEmailLeadOpen && (
          <ComposeEmail
            handleCloseEmailForm={handleEmailLeadClose}
            arrayOfEmails={[leadData.contactEmail]}
            arrayOfLeadIds={[leadData._id]}
            setEmailSent={setEmailSent}
          />
        )}
      </Paper>
    </Grid>
  );
};

export default LeadDetailsMM;
