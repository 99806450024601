import React, { useState, useEffect } from "react";
import {
  useGetAgentByIdQuery,
  useUpdateAgentMutation,
  useDeleteAgentMutation,
  // useResetAgentPasswordMutation,
  useDeleteAllLeadsForUserMutation,
} from "../../state/managementApi";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
  useTheme,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Paper,
  Grid,
} from "@mui/material";
import { useSelector } from "react-redux";
import EditAgentForm from "../forms/EditAgentForm";
import ValueGraph from "components/tools/ValueGraph";
import {
  BadgeOutlined,
  Email,
  Home,
  LocationCity,
  Phone,
  Refresh as RefreshIcon,
  Smartphone,
} from "@mui/icons-material";
import CalendarDialog from "components/dialog/CalendarDialog";
import AgentMonthlyStats from "./AgentMonthlyStats";
import SetAgentGoalsForm from "components/forms/SetAgentGoalsForm";
import ManageAgentLeadsList from "manager/agentManagement/leads/ManageAgentLeadsList";
import AgentSignatureForm from "../forms/AgentSignatureForm";
import { useSnackbar } from "notistack";
import ManageAgentActivitesList from "manager/agentManagement/activities/ManageAgentActivitiesList";
// import { useAddMemberToOperationsTeamMutation } from "state/operationsApi";

const AgentDetails = ({ agentId, /* onAgentUpdate, */ allAgents }) => {
  const userData = useSelector((state) => state.user);
  const {
    data: agentData,
    error,
    isLoading,
    refetch,
  } = useGetAgentByIdQuery(agentId);
  const theme = useTheme();
  const [statsRange, setStatsRange] = useState("Daily");
  const [editMode, setEditMode] = useState(false);
  const [updateAgent, { isLoading: updating }] = useUpdateAgentMutation();
  const [editSuccess, setEditSuccess] = useState(false);
  const [editError, setEditError] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [deleteAgent, /* { isLoading: deleting } */] = useDeleteAgentMutation();
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  // const [addToOperations] = useAddMemberToOperationsTeamMutation();
  const [monthYear, setMonthYear] = useState(null);
  const [calendarDialogOpen, setCalendarDialogOpen] = useState(false);
  const [agentGoalsOpen, setAgentGoalsOpen] = useState(false);
  const [showAgentLeads, setShowAgentLeads] = useState(false);
  const [showAgentActivities, setShowAgentActivities] = useState(false);
  // const [resetPassword, { isLoading: resettingPassword }] =
  //   useResetAgentPasswordMutation();
  // const [resetPasswordResponse, setResetPasswordResponse] = useState(null);
  const [nukeDialogOpen, setNukeDialogOpen] = useState(false);
  const [nukeMessage, setNukeMessage] = useState(null);
  const [showSignatureForm, setShowSignatureForm] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [deleteAllLeads, { isLoading: deletingLeads, isSuccess, isError }] =
    useDeleteAllLeadsForUserMutation({}); //! NUKE ROUTE

  const today = new Date();

  useEffect(() => {
    if (isSuccess) {
      setNukeMessage("Leads deleted successfully!");
      setTimeout(() => {
        setNukeMessage("");
      }, 5000);
    } else if (isError) {
      setNukeMessage("Error deleting leads.");
    }
  }, [isSuccess, isError]);

  const handleEditAgent = () => {
    setEditMode(true);
  };

  const toggleSignatureForm = () => {
    setShowSignatureForm((prevState) => !prevState);
  };

  const toggleAgentActivities = () => setShowAgentActivities((prev) => !prev);

  const handleSaveAgent = (updatedAgentData, isOperationMember) => {
    /* if (isOperationMember) {
      addToOperations({ userIdToAdd: agentData._id })
        .catch((error) => {
          console.error("Error adding agent to operations:", error);
        });
    }; */

    updateAgent({ agentId: agentData._id, data: updatedAgentData })
      .unwrap()
      .then(() => {
        setEditMode(false);
        // setEditSuccess(true);
        enqueueSnackbar(`${agentData.name} updated`);
        refetch();
      })
      .catch((error) => {
        console.error("Error:", error);
        setEditError(true);
      });
  };

  const handleCancelEdit = () => {
    setEditMode(false);
  };

  const handleDeleteAgent = () => {
    setConfirmDialogOpen(true);
  };

  const handleConfirmDelete = () => {
    deleteAgent(agentId)
      .unwrap()
      .then(() => {
        setConfirmDialogOpen(false);
        setDeleteSuccess(true);
        refetch();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleCancelDelete = () => {
    setConfirmDialogOpen(false);
  };

  const handleRefresh = () => {
    refetch();
  };

  const handleSetAgentGoals = () => {
    setAgentGoalsOpen(true);
  };

  const sendAgentMessage = () => {
    alert("TBD");
  };

  const handleOpenCalendar = () => {
    setCalendarDialogOpen(true);
  };

  const handleSaveAgentGoals = () => {
    setAgentGoalsOpen(false);
  };

  const handleSelectMonthYear = (selectedMonthYear) => {
    setMonthYear(selectedMonthYear);
    setCalendarDialogOpen(false);
  };

  const handleAgentStatsClose = () => {
    setMonthYear(null);
  };

  const handleManageAgentLeads = () => {
    setShowAgentLeads((prevShowAgentLeads) => !prevShowAgentLeads);
  };

  const handleNukeClick = () => {
    setNukeDialogOpen(true);
  };

  const handleNukeConfirmation = async () => {
    await deleteAllLeads(agentId);
    setNukeDialogOpen(false);
  };

  return (
    <Container
      component="main"
      maxWidth="xl"
      sx={{
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: theme.palette.primary[900],
        width: "95%",
        overflow: "hidden",
        marginTop: 1,
        "@media (max-width: 600px)": {
          minWidth: "100px",
          width: "100%",
          padding: "5px",
          overflowX: "auto",
          margin: 0,
        },
      }}
    >
      {isLoading && <CircularProgress />}
      {error && <Typography>Error grabbing agent</Typography>}
      {agentData && (
        <div>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton
              onClick={handleRefresh}
              color="info"
              size="medium"
              sx={{ ml: 1, boxShadow: "0 5px 5px rgba(0, 0, 0, 0.2)" }}
            >
              <RefreshIcon />
            </IconButton>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-around" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography>
                <b>
                  <u>{agentData.name}</u>
                </b>
              </Typography>
              <Typography sx={{ display: "flex", alignItems: "center" }}>
                <b>
                  <Email style={{ marginRight: "15px" }} />
                </b>{" "}
                {agentData?.email || "None"}
              </Typography>
              <Typography sx={{ display: "flex", alignItems: "center" }}>
                <b>
                  <Phone style={{ marginRight: "15px" }} />
                </b>{" "}
                {agentData?.phoneNumber || "None"}
              </Typography>
              <Typography sx={{ display: "flex", alignItems: "center" }}>
                <b>
                  <Smartphone style={{ marginRight: "15px" }} />
                </b>{" "}
                {agentData?.cellNumber || "None"}
              </Typography>
              <Typography sx={{ display: "flex", alignItems: "center" }}>
                <b>
                  <BadgeOutlined style={{ marginRight: "15px" }} />
                </b>{" "}
                {agentData?.role.replace(/\b\w/g, (match) =>
                  match.toUpperCase()
                ) || "None"}
              </Typography>
              <Typography sx={{ display: "flex", alignItems: "center" }}>
                <b>
                  <Home style={{ marginRight: "15px" }} />
                </b>{" "}
                {agentData?.address || "None"}
              </Typography>
              <Typography sx={{ display: "flex", alignItems: "center" }}>
                <b>
                  <LocationCity style={{ marginRight: "15px" }} />
                </b>{" "}
                {agentData?.city?.replace(/\b\w/g, (match) =>
                  match.toUpperCase()
                ) || "None"}{" "}
                {agentData?.state || ""}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                width: "60%",
              }}
            >
              <Typography variant="h5">
                <b>
                  <u>
                    {agentData.name}'s Statistics For {today.toDateString()}
                  </u>
                </b>
              </Typography>
              {statsRange === "Daily" ? (
                <>
                  <Paper sx={{ marginBottom: 1, p: 1, width: "100%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ width: "48%" }}>
                        <ValueGraph
                          value={agentData.dailyCalls}
                          valueGoal={agentData.callGoals}
                          label="Follow Up Calls"
                        />
                      </Box>
                      <Box sx={{ width: "48%" }}>
                        <ValueGraph
                          value={agentData.dailyNewCalls}
                          valueGoal={agentData.newCallGoals}
                          label="New Calls"
                        />
                      </Box>
                    </Box>
                  </Paper>
                  <Paper sx={{ marginBottom: 1, p: 1, width: "100%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ width: "48%" }}>
                        <ValueGraph
                          value={agentData.dailyEmails}
                          valueGoal={agentData.emailGoals}
                          label="Emails"
                        />
                      </Box>
                      <Box sx={{ width: "48%" }}>
                        <ValueGraph
                          value={agentData.setups}
                          valueGoal={agentData.setupGoals}
                          label="Setups"
                        />
                      </Box>
                    </Box>
                  </Paper>
                  <Paper sx={{ marginBottom: 1, p: 1, width: "100%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ width: "48%" }}>
                        {/* <ValueGraph
                          value={agentData.meetings}
                          valueGoal={agentData.meetingGoals}
                          label="Meetings"
                        /> */}
                        <ValueGraph 
                          value={agentData.dailyListEmails}
                          valueGoal={agentData.dailyListEmailGoals}
                          label="List Emails"
                        />
                      </Box>
                      <Box sx={{ width: "48%" }}>
                        {/* <GoallessValueGraph
                          value={agentData.packets}
                          label="Packets"
                        /> */}
                        <ValueGraph 
                          value={agentData.packets}
                          valueGoal={agentData.packetGoals}
                          label="Packets"
                        />
                      </Box>
                    </Box>
                  </Paper>
                  <Paper sx={{ marginBottom: 1, p: 1, width: "100%" }}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box sx={{ width: "48%" }}>
                        {/* <GoallessValueGraph
                          value={agentData.loadsBooked}
                          label="Loads Booked"
                        /> */}
                        <ValueGraph 
                          value={agentData.loadsBooked}
                          valueGoal={agentData.loadsBookedGoals}
                          label="Loads Booked"
                        />
                      </Box>
                      <Box sx={{ width: "48%" }}>
                        {/* <GoallessValueGraph
                          value={agentData.quotes}
                          label="Quotes"
                        /> */}
                        <ValueGraph 
                          value={agentData.quotes}
                          valueGoal={agentData.quoteGoals || 0}
                          label="Quotes"
                        />
                      </Box>
                    </Box>
                  </Paper>
                  <Paper sx={{ marginBottom: 1, p: 1, width: "100%" }}>
                    <Box sx={{ width: "100%" }}>
                      {/* <GoallessValueGraph
                        value={agentData.margin}
                        label="Margin"
                      /> */}
                      <ValueGraph 
                        value={agentData.margin}
                        valueGoal={agentData.marginGoals || 0}
                        label="Margin"
                      />
                    </Box>
                  </Paper>
                </>
              ) : (
                ""
              )}
            </Box>
          </Box>
          <Grid container spacing={2} justifyContent="center" marginY={1}>
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSetAgentGoals}
              >
                Set Agent Goals
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleManageAgentLeads}
              >
                {showAgentLeads ? "Hide Agent Leads" : "Show Agent Leads"}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={toggleSignatureForm}
              >
                Manage Agent Signatures
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleOpenCalendar}
              >
                Monthly stats
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={sendAgentMessage}
              >
                Send Agent Message
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={toggleAgentActivities}
              >
                {showAgentActivities ? "Hide Agent Activities" : "Show Agent Activities"}
              </Button>
            </Grid>
            {deletingLeads ? (
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <CircularProgress />
                </Grid>
              </Grid>
            ) : (
              userData.role === "superadmin" && (
                <>
                  <Grid item xs={12} sm={6} md={3}>
                    <Button
                      variant="contained"
                      color="error"
                      fullWidth
                      sx={{ backgroundColor: "orange" }}
                      onClick={() => {
                        // deleteAllLeads(agentId);
                        handleNukeClick();
                      }}
                    >
                      NUKE LEADS
                    </Button>
                  </Grid>
                </>
              )
            )}
          </Grid>
          {!editMode && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: { xs: 1, sm: 2 },
              }}
              gap={2}
              marginBottom={1}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleEditAgent}
              >
                Edit Agent
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleDeleteAgent}
              >
                Delete Agent
              </Button>
            </Box>
          )}
          {monthYear && (
            <AgentMonthlyStats
              agentId={agentId}
              monthYear={monthYear}
              onClose={handleAgentStatsClose}
            />
          )}
          {editMode && (
            <EditAgentForm
              agentData={agentData}
              onSaveAgent={handleSaveAgent}
              onCancelEdit={handleCancelEdit}
              updating={updating}
              allAgents={allAgents}
              sx={{ overflow: "auto" }}
            />
          )}
          {showSignatureForm && (
            <AgentSignatureForm
              signatures={agentData.signatures}
              agentId={agentId}
              onClose={toggleSignatureForm}
            />
          )}
          {showAgentLeads && (
            <Box
              sx={{
                marginTop: "5px",
                width: "100%",
                maxHeight: "calc(100vh - 100px)",
                overflow: "auto",
                marginBottom: "5px",
              }}
            >
              <ManageAgentLeadsList
                agentId={agentId}
                agentName={agentData.name}
                allAgents={allAgents}
              />
            </Box>
          )}
          {showAgentActivities && (
            <Box
              sx={{
                marginTop: "5px",
                width: "100%",
                maxHeight: "calc(100vh - 100px)",
                overflow: "auto",
                marginBottom: "5px",
              }}
            >
              <ManageAgentActivitesList
                agentId={agentId}
                agentName={agentData.name}
                allAgents={allAgents}
              />
            </Box>
          )}
          <Dialog open={confirmDialogOpen} onClose={handleCancelDelete}>
            <DialogTitle>Delete Agent</DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to delete this agent?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCancelDelete}
                color="primary"
                variant="contained"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirmDelete}
                color="error"
                variant="contained"
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={nukeDialogOpen}
            onClose={() => setNukeDialogOpen(false)}
          >
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
              <Typography>
                Are you sure you want to delete all {agentData.name}'s leads?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setNukeDialogOpen(false)}
                variant="contained"
                color="error"
              >
                Cancel
              </Button>
              <Button
                onClick={() => handleNukeConfirmation()}
                variant="contained"
                color="primary"
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
          <Box
            sx={{
              marginTop: "5px",
              width: "100%",
              maxHeight: "calc(100vh - 100px)",
              overflow: "auto",
              marginBottom: "5px",
            }}
          >
            {editSuccess && (
              <Alert severity="success">Agent edited successfully</Alert>
            )}
            {editError && <Alert severity="error">Error updating agent</Alert>}
            {deleteSuccess && (
              <Alert severity="success">Agent deleted successfully</Alert>
            )}
            {/* {resetPasswordResponse && (
              <Alert severity="success">
                {resetPasswordResponse.message}
                <br />
                New Password: {resetPasswordResponse.newPassword}
              </Alert>
            )} */}
            {nukeMessage && <Alert severity="success">{nukeMessage}</Alert>}
          </Box>
          {calendarDialogOpen && (
            <CalendarDialog
              onSelectMonthYear={handleSelectMonthYear}
              onClose={() => setCalendarDialogOpen(false)}
            />
          )}
          {agentGoalsOpen && (
            <SetAgentGoalsForm
              agentData={agentData}
              refetch={refetch}
              onSaveAgentGoals={handleSaveAgentGoals}
              onCancel={() => setAgentGoalsOpen(false)}
            />
          )}
        </div>
      )}
    </Container>
  );
};

export default AgentDetails;
