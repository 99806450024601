import React from "react";
import { Box } from "@mui/material";
import Header from "components/layout/Header";

const Monthly = () => {
  
  return (
    <Box m="1.5rem 2.5rem">
      <Header title="TBD" subtitle="Use TBD" />
      <Box height="75vh">
        TBD
      </Box>
    </Box>
  );
};

export default Monthly;