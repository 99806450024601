// Handler to translate dates to form friendly format
export const DateFormatter = (dateString) => {
  // If no value, return empty string
  if (!dateString || dateString === "") return "";

  // Create a Date object from the given string
  const date = new Date(dateString);

  // Get the month, day, and year from the Date object
  const year = date.getUTCFullYear();
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // Adding 1 due to months being zero-based
  const day = date.getUTCDate().toString().padStart(2, "0");

  // Form the MM-DD-YYYY format string
  const formattedDate = `${month}-${day}-${year}`;

  return formattedDate;
};

export const TimeFormatter = (timeString) => {
    // If no value, return empty string
    if (!timeString) return "";

    // Create a Date object from the given string
    const date = new Date(timeString);
    const localeTime = date.toLocaleTimeString();

    // Mess w/ time
    const [timePart, period] = localeTime.split(' '); // Split the time string into hours, minutes, and period (AM/PM)
    const [hours, minutes] = timePart.split(":"); // Split the time part into hours and minutes
    const hours12 = (parseInt(hours, 10) % 12) || 12; // Convert hours to 12-hour format and handle midnight (0 hours)
    const formattedTime = `${hours12}:${minutes}${period.toLowerCase()}`;

    return formattedTime;
};

// Handler to translate dateTimes to form friendly format
export const DateTimeFormatter = (dateTimeString) => {
  // If no value, return empty string
  if (!dateTimeString) return "";

  // Create a Date object from the given string
  const date = new Date(dateTimeString);
  const localeTime = date.toLocaleTimeString();

  // Get the month, day, and year from the Date object
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 due to months being zero-based
  const day = date.getUTCDate().toString().padStart(2, "0");

  // Mess w/ time
  const [timePart, period] = localeTime.split(' '); // Split the time string into hours, minutes, and period (AM/PM)
  const [hours, minutes] = timePart.split(":"); // Split the time part into hours and minutes
  const hours12 = (parseInt(hours, 10) % 12) || 12; // Convert hours to 12-hour format and handle midnight (0 hours)
  const formattedTime = `${hours12}:${minutes}${period.toLowerCase()}`;

  // Form the MM-DD-YYYY format string
  const formattedDateTime = `${formattedTime} ${month}/${day}/${year}`;

  return formattedDateTime;
};

// Handler to find days, hours, and minutes since datetime
export const handleDatetimeDifference = (originTime, now) => {
//   const now = new Date(); // Current DateTime 
  const createdAtDate = new Date(originTime); // Quote Creation DateTime

  // Time Math Values
  const differenceInMilliseconds = now - createdAtDate; // Calculate the difference in milliseconds
  const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60)); // Convert the difference into total minutes
  const differenceInHours = (Math.floor(Math.floor(differenceInMinutes % (60 * 24)) / 60)); // Calculate remaining hours
  const differenceInDays = Math.floor(differenceInMinutes / (60 * 24)); // Calculate days
  const differenceInRemainingMinutes = Math.floor(differenceInMinutes % 60); // Calculate remaining minutes

  // Value Existence Variables
  const dayExists = differenceInDays > 0;
  const hourExists = differenceInHours > 0;
  const minuteExists = differenceInRemainingMinutes > 0;
  
  if (!dayExists && !hourExists && !minuteExists) {
      return "Created just now";
  }
  
  // Grammar hell below
  let timeString = "Created ";

  if (dayExists) { // If day value exists
      timeString += differenceInDays === 1 ? "1 day" : `${differenceInDays} days`;
  }

  if (hourExists) { // If hour value exists
      const hourString = differenceInHours === 1 ? "1 hour" : `${differenceInHours} hours`;
      
      if (dayExists && minuteExists) { // If day and minute values exists
          timeString += `, ${hourString}`;
      } else if (dayExists && !minuteExists) { // Else if day exists but not minute
          timeString += ` and ${hourString}`;
      } else { // Else if minute exists but not day or only hour exists
          timeString += hourString;
      }
  }

  if (minuteExists) { // If minute value exists
      const minuteString = differenceInRemainingMinutes === 1 ? "1 minute" : `${differenceInRemainingMinutes} minutes`;
      
      if (dayExists && hourExists) {// If day and hour values exists
          timeString += `, and ${minuteString}`
      } else if (dayExists || hourExists) { // Else if day or hour exists
          timeString += ` and ${minuteString}`
      } else { // Else if only minute exists
          timeString += minuteString;
      }
  }

  timeString += " ago";
  
  return timeString;
};