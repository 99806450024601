import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
// import RefreshIcon from '@mui/icons-material/Refresh';
// import CallsEmailsLeaderboard from './CallsEmailsLeaderboard';
// import MarginLoadQuotesLeaderboard from './MarginLoadQuotesLeaderboard';
import { useGetLeaderboardDataQuery } from 'state/api';
import { isWithinBusinessHours } from 'components/helpers/CheckBusinessHours';
import { AddBox, AttachEmail, Attachment, Email, EmojiEvents, LocalPhone, LocalShipping, PhoneForwarded, RequestQuote, SupportAgent } from '@mui/icons-material';

const headers = [
  { text: 'Place', value: '', icon: <EmojiEvents /> },
  { text: 'Broker', value: '', icon: <SupportAgent /> },
  { text: 'FU Calls', value: 'totalCalls', icon: <PhoneForwarded /> },
  { text: 'New Calls', value: 'totalNewCalls', icon: <LocalPhone /> },
  { text: 'Packets', value: 'totalPackets', icon: <Attachment /> },
  { text: 'Quotes', value: 'totalQuotes', icon: <RequestQuote /> },
  { text: 'FU Emails', value: 'totalEmails', icon: <Email /> },
  { text: 'List Emails', value: 'totalListEmails', icon: <AttachEmail /> },
  { text: 'Loads', value: 'totalLoadsBooked', icon: <LocalShipping /> },
  { text: 'Setups', value: 'totalSetups', icon: <AddBox /> }
]

const AgentLeaderboard = ({ toggleLeaderboard }) => {
  const [lastRefreshTime, setLastRefreshTime] = useState(new Date());
  const [priorityStat, setPriorityStat] = useState('totalCalls');

  const { data: agents = [], error, isLoading, refetch } = useGetLeaderboardDataQuery({ priorityStat, limit: 5 }, { staleTime: 0 });
  // TODO: Check if only top 3-5 agents

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isWithinBusinessHours()) {
        refetch();
        setLastRefreshTime(new Date());
      }
    }, 15 * 60 * 1000); // Refresh every 15 minutes

    return () => clearInterval(intervalId);
  }, [refetch]);

  const handleStatChange = (value) => {
    if (priorityStat !== value) {
      setPriorityStat(value)
    }
  };

  const handleRefresh = () => {
    refetch();
    setLastRefreshTime(new Date());
  };

  return (
    <Dialog
      open={true}
      onClose={toggleLeaderboard}
      maxWidth="1"
      fullWidth
    >
      <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        <Box sx={{ textAlign: 'center', fontWeight: 'bold', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {/* <div>
            Leaderboards - {new Date().toLocaleDateString()} - Last Refresh: {lastRefreshTime.toLocaleTimeString()}
          </div> */}
          <div>
            Week Leaderboard as of {new Date().toLocaleDateString()}
          </div>
          {/* <IconButton onClick={handleRefresh}>
            <RefreshIcon sx={{ color: 'success' }} />
          </IconButton> */}
        </Box>
      </DialogTitle>
      <DialogContent>
        {isLoading && <p>Loading...</p>}
        {error && <p>Error: {error.message}</p>}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell align='center' style={{ minWidth: '50px' }} key={index}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {header.icon}
                      {header.value !== '' ? (
                        <Box>
                          <Typography
                            onClick={() => handleStatChange(header.value)}
                            sx={{
                              textDecoration: priorityStat === header.value ? 'underline' : 'none',
                              cursor: priorityStat === header.value ? 'text' : 'pointer',
                              '&:hover': {
                                textDecoration: 'underline',
                              }
                            }}
                          >
                            {header.text}
                          </Typography>
                        </Box>
                      ) : (
                        <Box>
                          <Typography>
                            {header.text}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {/* TODO: Ask if every value needs a column */}
            <TableBody>
              {agents.map((agent, index) => (
                <TableRow key={index}>
                  <TableCell align='center'>
                    <Box
                      color={index === 0 ? 'gold' : index === 1 ? 'silver' : index === 2 ? '#B08D57' : 'inherit'}
                      fontWeight={index === 0 ? 'bold' : index === 1 ? 'bold' : index === 2 ? 600 : 'normal'}
                      fontSize={index === 0 ? '1.3rem' : index === 1 ? '1.1rem' : index === 2 ? '1rem' : '1rem'}
                    >
                      {index === 0 ? '1st' : index === 1 ? '2nd' : index === 2 ? '3rd' : index + 1}
                    </Box>
                  </TableCell>
                  <TableCell align='center'>{agent.name}</TableCell>
                  <TableCell align='center'>{agent.totalCalls || 0}</TableCell>
                  <TableCell align='center'>{agent.totalNewCalls || 0}</TableCell>
                  <TableCell align='center'>{agent.totalPackets || 0}</TableCell>
                  <TableCell align='center'>{agent.totalQuotes || 0}</TableCell>
                  <TableCell align='center'>{agent.totalEmails || 0}</TableCell>
                  <TableCell align='center'>{agent.totalListEmails || 0}</TableCell>
                  <TableCell align='center'>{agent.totalLoadsBooked || 0}</TableCell>
                  <TableCell align='center'>{agent.totalSetups || 0}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <CallsEmailsLeaderboard agents={agents} />
        <MarginLoadQuotesLeaderboard agents={agents} /> */}
        <Box sx={{ marginTop: 'auto', textAlign: 'center' }}>
          <IconButton
            aria-label="close"
            onClick={toggleLeaderboard}
            sx={{ marginTop: '1rem', backgroundColor: 'crimson' }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AgentLeaderboard;
