import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogContent, DialogTitle, Divider, Typography, useTheme } from "@mui/material";
import { highlightStyle, hoverStyle } from "components/helpers/MediaQueries";
import { useState } from "react";
import PoolLeadDetails from "./PoolLeadDetails";

// Edit Menu for Selected Pool Leads
const EditPoolLeadsMenu = ({ categories, allLeads, selectedLeads, setSelectedLeads, open, onClose }) => {
    const theme = useTheme();
    const [leadToDetail, setLeadToDetail] = useState(null);
    const [detailedLeadIndex, setDetailedLeadIndex] = useState(0);

    const handleLeadRemoval = (leadId) => {
        setSelectedLeads(prev => prev.filter((lead) => lead._id !== leadId));
        if (leadToDetail._id === leadId) {
            setLeadToDetail(null);
        };

        if (selectedLeads.length === 1) {
            onClose();
        };
    };

    const handleLeadUpdate = (newLead) => {
        setLeadToDetail(newLead);
        setSelectedLeads(prev => {
            const newLeads = [...prev];
            newLeads[detailedLeadIndex] = newLead;
            return newLeads;
        });
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth='lg'>
            <DialogTitle>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h6">Edit Pool Leads</Typography>
                    <Close sx={hoverStyle} onClick={onClose} />
                </div>
                <Divider sx={{ mb: '15px', }}/>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
                    <Box sx={{ width: '20%', height: '100%', maxHeight: 540, }}>
                        <Box sx={{ border: '1px solid black', borderBottom: '0.5px', borderRadius: '5px', backgroundColor: theme.palette.background.secondary, }}>
                            {selectedLeads.map((lead, index) => (
                                <div key={index} style={{ display: 'flex', alignItems: 'center', width: '100%', }}>
                                    <Close sx={hoverStyle} onClick={() => handleLeadRemoval(lead._id)}/>
                                    <Typography 
                                        style={{
                                            padding: '5px 10px', 
                                            borderRadius: 0,
                                            borderBottom: '1px solid black',
                                            width: '94%'
                                        }}
                                        sx={highlightStyle}
                                        onClick={() => {
                                            setLeadToDetail(lead);
                                            setDetailedLeadIndex(index);
                                        }}
                                    >
                                        {lead.name}
                                    </Typography>
                                </div>
                            ))}
                        </Box>
                        <Button
                            variant="contained"
                            color="error"
                            fullWidth
                            sx={{ mt: '15px', borderRadius: '0'}}
                        >
                            Delete All
                        </Button>
                    </Box>
                    <Box sx={{ width: '70%', }}>
                        {leadToDetail && (
                            <PoolLeadDetails 
                            lead={leadToDetail} 
                            onClose={() => setLeadToDetail(null)} 
                            onEdit={handleLeadUpdate}
                            onAssign={handleLeadRemoval}
                            onDelete={handleLeadRemoval} 
                            categories={categories} 
                            multiple='true' 
                            />
                        )}
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default EditPoolLeadsMenu;