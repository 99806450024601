import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';

const ToolsMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleToolClick = (toolName) => {
    alert(`${toolName} under development`);
    closeMenu();
  };

  return (
    <>
      <Button variant="contained" color="primary" onClick={openMenu}
      sx={{
        mt: { xs: 1, sm: 0 },
        marginBottom: { xs: 1, sm: 0 },
      }}
      >
        Tools
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu} keepMounted>
        <MenuItem onClick={() => handleToolClick('Tool 1')}>Tool 1</MenuItem>
        <MenuItem onClick={() => handleToolClick('Tool 2')}>Tool 2</MenuItem>
        <MenuItem onClick={() => handleToolClick('Tool 3')}>Tool 3</MenuItem>
      </Menu>
    </>
  );
};

export default ToolsMenu;