import { Book, Cancel, Check, Close, Edit, Info } from "@mui/icons-material";
import { Box, FormControl, IconButton, InputAdornment, /* InputLabel, */ OutlinedInput, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useTheme } from "@mui/material";
import { hoverStyle } from "components/helpers/MediaQueries";
import { useEffect, useState } from "react";
import { useGetAllQuotesQuery, useUpdateQuoteMutation } from "state/operationsApi";
import QuoteDetails from "./QuoteDetails";
import { DateFormatter } from "components/helpers/DateTimeHelpers";
import QuoteAcceptedForm from "./forms/QuoteAcceptedForm";
import QuoteRefusedForm from "./forms/QuoteRefusedForm";
import EditQuoteForm from "./forms/EditQuoteForm";
import handleUpdateDifferences from "components/helpers/UpdateFormDifferences";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import QuoteCarrierSubmissionForm from "./forms/QuoteCarrierSubmissionForm";

const QuoteList = ({ }) => {
    const theme = useTheme();
    const userData = useSelector((state) => state.user);

    const [userQuotes, setUserQuotes] = useState([]);

    // Query States
    const [page, setPage] = useState(null);
    const [limit, setLimit] = useState(null);
    const [status, setStatus] = useState(undefined);
    const [emailSubject, setEmailSubject] = useState(undefined);
    const [date, setDate] = useState(undefined);
    const [assignedTo, setAssignedTo] = useState(undefined);
    const [includeArchived, setIncludeArchived] = useState(false);

    const [quoteDetailsOpen, setQuoteDetailsOpen] = useState(false);
    const [quoteToDetail, setQuoteToDetail] = useState(null);
    const [quoteEditOpen, setQuoteEditOpen] = useState(false);
    const [quoteToEdit, setQuoteToEdit] = useState(null);
    const [quoteAcceptedOpen, setQuoteAcceptedOpen] = useState(false);
    const [quoteToAccept, setQuoteToAccept] = useState(null);
    const [quoteRefusedOpen, setQuoteRefusedOpen] = useState(false);
    const [quoteToRefuse, setQuoteToRefuse] = useState(null);
    const [quoteCancelled, setQuoteCancelled] = useState(false);
    const [isBooking, setIsBooking] = useState(false);
    const [quoteToBook, setQuoteToBook] = useState(null);

    const [isEditingTruckPrice, setIsEditingTruckPrice] = useState(false);
    const [newTruckPrice, setNewTruckPrice] = useState(0);
    const [isEditingCustomerQuote, setIsEditingCustomerQuote] = useState(false);
    const [newCustomerQuote, setNewCustomerQuote] = useState(0);


    const { data: quotes, isLoading: quotesLoading, isError: quotesError, refetch: refetchQuotes } = useGetAllQuotesQuery(
        {
            page,
            limit,
            status,
            emailSubject,
            date,
            assignedTo,
            includeArchived
        }
    );

    const [updateQuote] = useUpdateQuoteMutation();

    useEffect(() => {
        if (quotes) {
            setUserQuotes(quotes.quotes.filter((quote) => (quote.status === "quoted" || quote.status === "brokerBooking") && quote.ownerDetails.email === userData.email));
        }
    }, [quotes, refetchQuotes]);

    // Togglers
    const toggleIsBooking = () => setIsBooking(prev => !prev);
    const toggleEditingTruckPrice = (quote) => {
        if (!isEditingTruckPrice) {
            setQuoteToEdit(quote);
            setNewTruckPrice(parseFloat(quote.truckPrice).toFixed(2));
        } else {
            setNewTruckPrice(0);
        }
        setIsEditingTruckPrice(prev => !prev);
    };
    const toggleEditingCustomerQuote = (quote) => {
        if (!isEditingCustomerQuote) {
            setQuoteToEdit(quote);
            setNewCustomerQuote(parseFloat(quote.customerQuote).toFixed(2));
        } else {
            setNewCustomerQuote(0);
        }
        setIsEditingCustomerQuote(prev => !prev);
    };
    const toggleDetailsOpen = () => setQuoteDetailsOpen(prev => !prev);
    const toggleEditOpen = () => setQuoteEditOpen(prev => !prev);
    const toggleQuoteAcceptedOpen = () => setQuoteAcceptedOpen(prev => !prev);
    const toggleQuoteRefusedOpen = () => setQuoteRefusedOpen(prev => !prev);
    const toggleQuoteCancelled = () => setQuoteCancelled(prev => !prev);

    // Handlers
    const handleUpdateQuote = async (quote) => {
        const quoteUpdate = await handleUpdateDifferences(quoteToEdit, quote);

        try {
            await updateQuote({ id: quoteToEdit._id, updates: quoteUpdate })
                .then(() => {
                    enqueueSnackbar(`Quote Updated`);
                    toggleEditOpen();
                })
        } catch (error) {
            console.error("Error updating quote:", error);
        }
    };

    const handleDecimalFormat = (value) => {
        return parseFloat(value).toFixed(2);
    };

    const handlePriceChanges = async (quote, value) => {
        const changes = {};

        if (value === "truckPrice") {
            changes["truckPrice"] = handleDecimalFormat(newTruckPrice);
            changes["margin"] = handleDecimalFormat(quote.customerQuote - newTruckPrice);

            toggleEditingTruckPrice();
        } else if (value === "customerQuote") {
            changes["customerQuote"] = handleDecimalFormat(newCustomerQuote);
            changes["margin"] = handleDecimalFormat(newCustomerQuote - quote.truckPrice);

            toggleEditingCustomerQuote();
        };

        try {
            await updateQuote({ id: quote._id, updates: changes })
                .then(() => {
                    enqueueSnackbar(`Quote Updated`);
                })
        } catch (error) {
            console.error("Error updating quote:", error);
        }
    };

    if (!quotes) return;

    return (
        <Box
            sx={{
                backgroundColor: theme.palette.grey[900],
                borderRadius: '4px',
                padding: theme.spacing(1),
            }}
        >
            <Paper elevation={4} sx={{ p: 2, mt: 2 }}>
                <TableContainer component={Paper}>
                    <Table /* size="small" */>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                        >
                                            Subject
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                        >
                                            Origin
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                        >
                                            Destination
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                        >
                                            Truck Price
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                        >
                                            Customer Quote
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                        >
                                            Date Created
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                        <Typography
                                            variant="h6"
                                            fontWeight="bold"
                                        >
                                            Status
                                        </Typography>
                                    </Box>
                                </TableCell>
                                <TableCell sx={{ p: '6px 0px', }} />
                                <TableCell sx={{ p: '6px 0px', }} />
                                <TableCell sx={{ p: '6px 0px', }} />
                                <TableCell sx={{ p: '6px 0px', }} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!userQuotes || !userQuotes.length ? (
                                <TableRow>
                                    <TableCell>
                                        <Typography>No Quotes To Display</Typography>
                                    </TableCell>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                </TableRow>
                            ) : (
                                userQuotes.map((quote, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Tooltip title="Open Quote Email" placement="top">
                                                <a
                                                    href={quote.gmailLink}
                                                    target="_blank"
                                                    style={{
                                                        textDecoration: 'underline',
                                                        color: 'inherit',
                                                    }}
                                                >
                                                    {quote.emailSubject}
                                                </a>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>
                                            {quote.origin.city || ""}, {quote.origin.state || ""}
                                        </TableCell>
                                        <TableCell>
                                            {quote.destination.city || ""}, {quote.destination.state || ""}
                                        </TableCell>
                                        <TableCell sx={{ width: '200px', height: '40px', }}>
                                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', }}>
                                                {isEditingTruckPrice && (quoteToEdit && quoteToEdit._id === quote._id) ? (
                                                    <FormControl fullWidth>
                                                        <OutlinedInput
                                                            size="small"
                                                            type="number"
                                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <Tooltip title="Cancel" placement="top">
                                                                        <Close
                                                                            sx={hoverStyle}
                                                                            onClick={() => {
                                                                                toggleEditingTruckPrice();
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                </InputAdornment>
                                                            }
                                                            value={newTruckPrice}
                                                            onChange={(e) => {
                                                                setNewTruckPrice(e.target.value);
                                                            }}
                                                            onBlur={() => {
                                                                const newValue = handleDecimalFormat(newTruckPrice);
                                                                setNewTruckPrice(newValue);
                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key === "Enter") {
                                                                    handlePriceChanges(quote, "truckPrice");
                                                                }
                                                            }}
                                                            sx={{
                                                                '& input[type=number]': {
                                                                    'MozAppearance': 'textfield',
                                                                },
                                                                '& input[type=number]::-webkit-outer-spin-button': {
                                                                    'WebkitAppearance': 'none',
                                                                    margin: 0,
                                                                },
                                                                '& input[type=number]::-webkit-inner-spin-button': {
                                                                    'WebkitAppearance': 'none',
                                                                    margin: 0,
                                                                },
                                                            }}
                                                        />
                                                    </FormControl>
                                                ) : (
                                                    <>
                                                        {`$${parseFloat(quote.truckPrice).toFixed(2)}`}
                                                        <Edit
                                                            sx={hoverStyle}
                                                            style={{ fontSize: '16px', marginLeft: '10px', }}
                                                            onClick={() => {
                                                                toggleEditingTruckPrice(quote);
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </TableCell>
                                        <TableCell sx={{ width: '200px', height: '40px', }}>
                                            <div style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%', }}>
                                                {isEditingCustomerQuote && (quoteToEdit && quoteToEdit._id === quote._id) ? (
                                                    <FormControl fullWidth>
                                                        <OutlinedInput
                                                            size="small"
                                                            type="number"
                                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <Tooltip title="Cancel" placement="top">
                                                                        <Close
                                                                            sx={hoverStyle}
                                                                            onClick={() => {
                                                                                toggleEditingCustomerQuote();
                                                                            }}
                                                                        />
                                                                    </Tooltip>
                                                                </InputAdornment>
                                                            }
                                                            value={newCustomerQuote}
                                                            onChange={(e) => {
                                                                setNewCustomerQuote(e.target.value);
                                                            }}
                                                            onBlur={() => {
                                                                const newValue = handleDecimalFormat(newCustomerQuote);
                                                                setNewCustomerQuote(newValue);
                                                            }}
                                                            onKeyDown={(e) => {
                                                                if (e.key === "Enter") {
                                                                    handlePriceChanges(quote, "customerQuote");
                                                                }
                                                            }}
                                                            sx={{
                                                                '& input[type=number]': {
                                                                    'MozAppearance': 'textfield',
                                                                },
                                                                '& input[type=number]::-webkit-outer-spin-button': {
                                                                    'WebkitAppearance': 'none',
                                                                    margin: 0,
                                                                },
                                                                '& input[type=number]::-webkit-inner-spin-button': {
                                                                    'WebkitAppearance': 'none',
                                                                    margin: 0,
                                                                },
                                                            }}
                                                        />
                                                    </FormControl>
                                                ) : (
                                                    <>
                                                        {`$${parseFloat(quote.customerQuote).toFixed(2)}`}
                                                        <Edit
                                                            sx={hoverStyle}
                                                            style={{ fontSize: '16px', marginLeft: '10px', }}
                                                            onClick={() => {
                                                                toggleEditingCustomerQuote(quote);
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            </div>
                                        </TableCell>
                                        <TableCell>{DateFormatter(quote.createdAt)}</TableCell>
                                        <TableCell>{quote.status === "brokerBooking" ? "Booking" : quote.status.replace(/^\w/, (c) => c.toUpperCase())}</TableCell>
                                        <TableCell>
                                            <Info
                                                sx={hoverStyle}
                                                onClick={() => {
                                                    setQuoteToDetail(quote);
                                                    toggleDetailsOpen();
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Edit
                                                sx={hoverStyle}
                                                onClick={() => {
                                                    setQuoteToEdit(quote);
                                                    toggleEditOpen();
                                                }}
                                            />
                                        </TableCell>
                                        {quote.status === "quoted" ? (
                                            <>
                                                <TableCell>
                                                    <Tooltip title="Accepted" placement="top">
                                                        <Check
                                                            sx={hoverStyle}
                                                            onClick={() => {
                                                                setQuoteToAccept(quote);
                                                                toggleQuoteAcceptedOpen();
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip title="Refused" placement="top">
                                                        <Close
                                                            sx={hoverStyle}
                                                            onClick={() => {
                                                                setQuoteToRefuse(quote);
                                                                toggleQuoteRefusedOpen();
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </TableCell>
                                            </>
                                        ) : quote.status === "brokerBooking" ? (
                                            <>
                                                <TableCell>
                                                    <Tooltip title="Booked" placement="top">
                                                        <Book 
                                                            sx={hoverStyle}
                                                            onClick={() => {
                                                                toggleIsBooking();
                                                                setQuoteToBook(quote);
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip title="Cancel" placement="top">
                                                        <Cancel 
                                                            sx={hoverStyle}
                                                            onClick={() => {
                                                                setQuoteToRefuse(quote);
                                                                toggleQuoteCancelled();
                                                                toggleQuoteRefusedOpen();
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </TableCell>
                                            </>
                                        ) : (
                                            <>
                                                <TableCell />
                                                <TableCell />
                                            </>
                                        )}
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            {quoteDetailsOpen && (
                <QuoteDetails
                    quote={quoteToDetail}
                    onClose={toggleDetailsOpen}
                />
            )}
            {quoteEditOpen && (
                <EditQuoteForm
                    quote={quoteToEdit}
                    onClose={toggleEditOpen}
                    onSubmit={handleUpdateQuote}
                />
            )}
            {quoteAcceptedOpen && (
                <QuoteAcceptedForm
                    quote={quoteToAccept}
                    onClose={toggleQuoteAcceptedOpen}
                />
            )}
            {quoteRefusedOpen && (
                <QuoteRefusedForm
                    quote={quoteToRefuse}
                    onClose={() => {
                        toggleQuoteRefusedOpen();
                        setQuoteCancelled(false);
                    }}
                    cancelled={quoteCancelled}
                />
            )}
            {isBooking && 
                <QuoteCarrierSubmissionForm 
                    quote={quoteToBook}
                    onClose={toggleIsBooking}
                />
            }
        </Box>
    )
}

export default QuoteList;