import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { configureStore } from '@reduxjs/toolkit';
//can import without './' because of jsconfig baseurl being src
import globalReducer from "state";
import { Provider } from 'react-redux';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api } from "state/api";
import { managementApi } from 'state/managementApi';
import { salesApi } from 'state/salesApi';
import webSocketReducer from 'store/webSocketSlice';
import userReducer from 'store/userSlice';
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import { dispatchApi } from 'state/dispatchApi';
import { operationsApi } from 'state/operationsApi';
import { clientApi } from 'state/clientApi';

if (process.env.NODE_ENV === 'production') disableReactDevTools();

const store = configureStore({
  reducer: {
    global: globalReducer,
    [api.reducerPath]: api.reducer,
    [managementApi.reducerPath]: managementApi.reducer,
    [salesApi.reducerPath]: salesApi.reducer,
    // [dispatchApi.reducerPath]: dispatchApi.reducer,
    [operationsApi.reducerPath]: operationsApi.reducer,
    [clientApi.reducerPath]: clientApi.reducer,
    user: userReducer,
    webSocket: webSocketReducer,
  },
  middleware: (getDefault) => getDefault()
  .concat(api.middleware)
  .concat(managementApi.middleware)
  .concat(salesApi.middleware)
  .concat(operationsApi.middleware)
  .concat(clientApi.middleware)
});
setupListeners(store.dispatch);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <App />
    </Provider>
);


