import React from 'react';
import { Typography, Grid, useTheme } from '@mui/material';

const SalesInfo = ({ sale }) => {
  const theme = useTheme();

  // const textFieldStyles = {
  //   borderRadius: '4px',
  //   border: `1px solid ${theme.palette.divider}`,
  //   padding: theme.spacing(1),
  //   backgroundColor: theme.palette.background.paper,
  //   width: '100%',
  //   textAlign: 'center',
  // };

  return (
    <>
      <Typography variant="h3" align="center" sx={{ mt: 1 }}>
        Sale Details
      </Typography>
      <Grid container justifyContent="center" sx={{ mt: 1 }}>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="body1" align="center">
            Description: {sale.description}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Typography variant="body1" align="center">
            Date: {sale.date}
          </Typography>
        </Grid>
      </Grid>
      <style>
        {`
        .lead-website-link {
          color: ${theme.palette.primary.contrastText};
          text-decoration: none;
        }
        
        .lead-website-link:hover {
          text-decoration: underline;
        }
        `}
      </style>
    </>
  );
};

export default SalesInfo;
