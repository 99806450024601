// WebSocketHook.js
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setConnected, addData } from '../../store/webSocketSlice';
import { io } from 'socket.io-client';

export const useWebSocket = (componentName) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const socket = io(process.env.REACT_APP_WEBSOCKET_URL, {
      withCredentials: true,
      query: { component: componentName },
    });

    socket.on('connect', () => {
      dispatch(setConnected(true));
    });

    socket.on('FromAPI', (message) => {
      dispatch(addData(JSON.parse(message)));
    });

    socket.on('disconnect', () => {
      dispatch(setConnected(false));
    });

    return () => {
      socket.disconnect();
    };
  }, [dispatch]);

  return null; 
};
