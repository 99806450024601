import React from 'react'

function EmailDetails(email) {
  return (
    <div>
    <h2>{email.subject}</h2>
    <p>From: {email.sender}</p>
    <div dangerouslySetInnerHTML={{ __html: email.content }} />
  </div>
  )
}

export default EmailDetails