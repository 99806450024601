import { Alert, Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material";
import { useFormik } from "formik";
import * as yup from 'yup';
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useUpdatePoolLeadMutation } from "state/managementApi";
import { Close } from "@mui/icons-material";
import { hoverStyle } from "components/helpers/MediaQueries";

const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}(?:\s*(?:#|x.?|ext.?|extension)\s*[0-9]+)?$/im;

const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
    city: yup.string(),
    state: yup.string(),
    country: yup.string(),
    zipCode: yup.string(),
    contactName: yup.string(),
    contactLastName: yup.string(),
    contactNumber: yup.string().matches(
        phoneRegExp,
        'Invalid phone number'
    ),
    contactEmail: yup.string().email('Invalid email address'),
    APName: yup.string(),
    APNumber: yup.string().matches(
        phoneRegExp,
        'Invalid phone number'
    ),
    APEmail: yup.string().email('Invalid email address'),
    creditLimit: yup.number(),
    commodity: yup.string(),
    equipmentType: yup.string(),
    brokerNotes: yup.string(),
    csNotes: yup.string(),
    title: yup.string(),
    website: yup.string(),
});

const EditPoolLead = ({ leadToUpdate, closeForm, categories, onSubmit }) => {
    const theme = useTheme();
    const initialValues = {
        _id: leadToUpdate._id,
        name: leadToUpdate.name || '',
        contactName: leadToUpdate.contactName || '',
        contactLastName: leadToUpdate.contactLastName || '',
        contactNumber: leadToUpdate.contactNumber || '',
        contactEmail: leadToUpdate.contactEmail || '',
        commodity: leadToUpdate.commodity || '',
        website: leadToUpdate.website || '',
        city: leadToUpdate.city || '',
        state: leadToUpdate.state || '',
        country: leadToUpdate.country || '',
        zipCode: leadToUpdate.zipCode || '',
        APName: leadToUpdate.APName || '',
        APNumber: leadToUpdate.APNumber || '',
        APEmail: leadToUpdate.APEmail || '',
        creditLimit: leadToUpdate.creditLimit || '',
        equipmentType: leadToUpdate.equipmentType || '',
        brokerNotes: leadToUpdate.brokerNotes || '',
        title: leadToUpdate.title || '',
        csNotes: leadToUpdate.csNotes || '',
        categories: leadToUpdate.categories || [],
    };
    const [selectedPoolCategories, setSelectedPoolCategories] = useState(leadToUpdate.categories);
    
    const [updatePoolLead] = useUpdatePoolLeadMutation();
    const [alert, setAlert] = useState(null); // State to manage alert

    const { enqueueSnackbar } = useSnackbar();

    const handleAlertClose = () => {
        setAlert(null); // Clear alert
    };

    const handlePoolCategoryCheckbox = (selectedCategoryId) => {
        const isSelected = selectedPoolCategories.includes(selectedCategoryId);
        if (isSelected) {
            setSelectedPoolCategories(selectedPoolCategories.filter((category) => category !== selectedCategoryId));
        } else {
            setSelectedPoolCategories([...selectedPoolCategories, selectedCategoryId]);
        };
    };

    // Handle update form submit
    const handleSubmit = async (values) => {
        try {
            values.categories = selectedPoolCategories;

            await updatePoolLead({ id: values._id, data: values })
                .then((res) => {
                    enqueueSnackbar(`${values.name} Updated`);
                    onSubmit(res.data);
                    closeForm();
                });

        } catch (error) {
            console.error('Error updating lead:', error);
            setAlert({ type: 'error', message: 'An error occurred'});
        };
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => {
            handleSubmit(values)
        },
    });

    return (
        <Dialog open onClose={closeForm} fullWidth maxWidth="xl">
            <DialogTitle sx={{ paddingBottom: '0px', }}>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography variant="h6" align="center" gutterBottom>
                        Edit {leadToUpdate.name}
                    </Typography>
                    <Close sx={hoverStyle} onClick={closeForm} />
                </div>
            </DialogTitle>
            <DialogContent>
                <Container
                    maxWidth="xl"
                    sx={{
                        backgroundColor: theme.palette.primary[900],
                        padding: '40px',
                    }}
                >
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="name"
                                    name="name"
                                    label="Company name *"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                />
                            </Grid>
                            {/* <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="contactName"
                                    name="contactName"
                                    label="Contact First Name"
                                    value={formik.values.contactName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.contactName && Boolean(formik.errors.contactName)}
                                    helperText={formik.touched.contactName && formik.errors.contactName}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="contactLastName"
                                    name="contactLastName"
                                    label="Contact Last Name"
                                    value={formik.values.contactLastName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.contactLastName && Boolean(formik.errors.contactLastName)}
                                    helperText={formik.touched.contactLastName && formik.errors.contactLastName}
                                />
                            </Grid> */}
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="city"
                                    name="city"
                                    label="City *"
                                    value={formik.values.city}
                                    onChange={formik.handleChange}
                                    error={formik.touched.city && Boolean(formik.errors.city)}
                                    helperText={formik.touched.city && formik.errors.city}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="state"
                                    name="state"
                                    label="State *"
                                    value={formik.values.state}
                                    onChange={formik.handleChange}
                                    error={formik.touched.state && Boolean(formik.errors.state)}
                                    helperText={formik.touched.state && formik.errors.state}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="country-label">Country *</InputLabel>
                                    <Select
                                        labelId="country-label"
                                        id="country"
                                        name="country"
                                        value={formik.values.country}
                                        onChange={formik.handleChange}
                                        error={formik.touched.country && Boolean(formik.errors.country)}
                                    >
                                        <MenuItem value="US">US</MenuItem>
                                        <MenuItem value="CAN">CAN</MenuItem>
                                        <MenuItem value="MEX">MEX</MenuItem>
                                        <MenuItem value="Other">Other</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="zipCode"
                                    name="zipCode"
                                    label="Zip Code"
                                    value={formik.values.zipCode}
                                    onChange={formik.handleChange}
                                    error={formik.touched.zipCode && Boolean(formik.errors.zipCode)}
                                    helperText={formik.touched.zipCode && formik.errors.zipCode}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="website"
                                    name="website"
                                    label="Website"
                                    value={formik.values.website}
                                    onChange={formik.handleChange}
                                    error={formik.touched.website && Boolean(formik.errors.website)}
                                    helperText={formik.touched.website && formik.errors.website}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="contactNumber"
                                    name="contactNumber"
                                    label="Contact Number"
                                    value={formik.values.contactNumber}
                                    onChange={formik.handleChange}
                                    error={formik.touched.contactNumber && Boolean(formik.errors.contactNumber)}
                                    helperText={formik.touched.contactNumber && formik.errors.contactNumber}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="contactEmail"
                                    name="contactEmail"
                                    label="Contact Email"
                                    value={formik.values.contactEmail}
                                    onChange={formik.handleChange}
                                    error={formik.touched.contactEmail && Boolean(formik.errors.contactEmail)}
                                    helperText={formik.touched.contactEmail && formik.errors.contactEmail}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="APName"
                                    name="APName"
                                    label="AP Name"
                                    value={formik.values.APName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.APName && Boolean(formik.errors.APName)}
                                    helperText={formik.touched.APName && formik.errors.APName}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="APNumber"
                                    name="APNumber"
                                    label="AP Number"
                                    value={formik.values.APNumber}
                                    onChange={formik.handleChange}
                                    error={formik.touched.APNumber && Boolean(formik.errors.APNumber)}
                                    helperText={formik.touched.APNumber && formik.errors.APNumber}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="APEmail"
                                    name="APEmail"
                                    label="AP Email"
                                    value={formik.values.APEmail}
                                    onChange={formik.handleChange}
                                    error={formik.touched.APEmail && Boolean(formik.errors.APEmail)}
                                    helperText={formik.touched.APEmail && formik.errors.APEmail}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="creditLimit"
                                    name="creditLimit"
                                    label="Credit Limit(no commas)"
                                    value={formik.values.creditLimit}
                                    onChange={formik.handleChange}
                                    error={formik.touched.creditLimit && Boolean(formik.errors.creditLimit)}
                                    helperText={formik.touched.creditLimit && formik.errors.creditLimit}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="commodity"
                                    name="commodity"
                                    label="Commodity"
                                    value={formik.values.commodity}
                                    onChange={formik.handleChange}
                                    error={formik.touched.commodity && Boolean(formik.errors.commodity)}
                                    helperText={formik.touched.commodity && formik.errors.commodity}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="equipmentType"
                                    name="equipmentType"
                                    label="Equipment Type"
                                    value={formik.values.equipmentType}
                                    onChange={formik.handleChange}
                                    error={formik.touched.equipmentType && Boolean(formik.errors.equipmentType)}
                                    helperText={formik.touched.equipmentType && formik.errors.equipmentType}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="csNotes"
                                    name="csNotes"
                                    label="CS Notes"
                                    value={formik.values.csNotes}
                                    onChange={formik.handleChange}
                                    error={formik.touched.csNotes && Boolean(formik.errors.csNotes)}
                                    helperText={formik.touched.csNotes && formik.errors.csNotes}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="title"
                                    name="title"
                                    label="Title"
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                    error={formik.touched.title && Boolean(formik.errors.title)}
                                    helperText={formik.touched.title && formik.errors.title}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    id="brokerNotes"
                                    name="brokerNotes"
                                    label="Broker Notes"
                                    value={formik.values.brokerNotes}
                                    onChange={formik.handleChange}
                                    error={formik.touched.brokerNotes && Boolean(formik.errors.brokerNotes)}
                                    helperText={formik.touched.brokerNotes && formik.errors.brokerNotes}
                                    style={{ marginBottom: '16px' }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormGroup>
                                    <InputLabel>Categories</InputLabel>
                                    {categories.map((category) => (
                                        <FormControlLabel 
                                            key={category.name}
                                            control={
                                                <Checkbox checked={!!selectedPoolCategories.includes(category._id)} onChange={() => handlePoolCategoryCheckbox(category._id)}/>
                                            }
                                            label={category.name}
                                        />
                                    ))}
                                    <FormControlLabel control={<Checkbox checked={selectedPoolCategories.length === 0} disabled/>} label="Default"/>
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </form>
                </Container>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', }}>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={formik.handleSubmit}
                >
                    Update
                </Button>
            </DialogActions>
            <Box textAlign="center">
                {alert && (
                    <Alert severity={alert.type} onClose={handleAlertClose} sx={{ mb: 1 }}>
                        {alert.message}
                    </Alert>
                )}
            </Box>
        </Dialog>
    )
    
}

export default EditPoolLead;