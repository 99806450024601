import SearchBar from "components/searchbar/SearchBar";
import { useState } from "react";
import ManageLeadSearchList from "./ManageLeadSearchList";

const ManageLeadSearch = ({}) => {
    const [searchInput, setSearchInput] = useState("");
    const [search, setSearch] = useState(false);

    const handleSearch = () => {
        setSearch(!search);
    };

    return (
        <>
            <SearchBar searchInput={searchInput} setSearchInput={setSearchInput} handleSearchChange={handleSearch} allLeadsSearch={true} hasSearched={search}/>
            {search && searchInput && (
                <ManageLeadSearchList searchInput={searchInput}/>
            )}
        </>
    )
}

export default ManageLeadSearch;