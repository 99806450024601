import { Box, Button, InputAdornment, TextField, Typography, styled } from "@mui/material";
import { useState } from "react";

const Calculator = ({ isMargin }) => {
    const [value, setValue] = useState(0);
    const [operator, setOperator] = useState("");
    const [firstTerm, setFirstTerm] = useState(0);
    const [secondTerm, setSecondTerm] = useState(0);
    const [calculated, setCalculated] = useState(false);
    const [driverRate, setDriverRate] = useState(0);
    const [customerQuote, setCustomerQuote] = useState(0);
    const [otherPercentage, setOtherPercentage] = useState(false);
    const [percentageInput, setPercentageInput] = useState('');


    const StyledButton = styled(Button)({
        width: 40,
        height: 40,
        margin: '4px',
        borderRadius: 10,
        boxShadow: 'inset 0px -4px 0px #3a4663',
        '&.del': {
            backgroundColor: '#647198'
        },
        '&.reset': {
            width: 110,
            backgroundColor: '#647198'
        },
        '&.equalsTo': {
            width: 110,
            backgroundColor: '#D03F2F'
        }
    })

    const ButtonText = styled(Typography)({
        color: '#ffff'
      });

    const ButtonTemplate = ({ onClick, children, buttonprops, uncommon = true }) => {
        return (
            <StyledButton variant="contained" className={`${buttonprops}`} onClick={onClick}>
                <ButtonText variant="h4" className={uncommon ? ButtonText : ''}><b>{children}</b></ButtonText>
            </StyledButton>
        )
    }

    const handlePerformOperation = (op) => {
        if (calculated) {
            setCalculated(false);
        }
        setOperator(op);
    };

    const handleClick = (num) => {
        if (calculated) {
            if (num !== 'dot') {
                setValue(num);
                setFirstTerm(num);
            } else if (num === 'dot') {
                setValue(0 + '.');
            }
            setCalculated(false);
        } else if (!operator) {
            if (value === 0 && num !== 'dot') {
                setValue(num);
                setFirstTerm(num);
            } else if (num === 'dot') {
                if (value.toString().includes('.')) {
                    return;
                }
                setValue(value + '.');
            } else {
                setValue(`${value}${num}`);
                setFirstTerm(`${value}${num}`);
            }
        } else {
            if (!secondTerm && num !== 'dot') {
                setValue(num);
                setSecondTerm(num);
            } else if (num === 'dot') {
                if (secondTerm.toString().includes('.')) {
                    return;
                } 
                // setFirstTerm(value);
                setSecondTerm(`${secondTerm}` + ".");
                setValue(`${secondTerm}` + ".");
               
            } else {
                setSecondTerm(`${secondTerm}${num}`);
                setValue(`${secondTerm}${num}`);
            }
        }
    }

    const handleResult = () => {
        if (operator) {
            let result;

            switch (operator) {
                case '+':
                    result = parseFloat(firstTerm) + parseFloat(secondTerm);
                    break;
                case '-':
                    result = parseFloat(firstTerm) - parseFloat(secondTerm);
                    break;
                case '*':
                    result = parseFloat(firstTerm) * parseFloat(secondTerm);
                    break;
                case '/':
                    result = parseFloat(firstTerm) / parseFloat(secondTerm);
                    break;
                default:
                    result = 0;
                    break;
            }
            setValue(result);
            setOperator('');
            setFirstTerm(result);
            setSecondTerm(0);
            setCalculated(true);
        }
    };

    const handleReset = () => {
        setValue(0);
        setFirstTerm(0);
        setOperator('');
        setSecondTerm(0);
        setCalculated(false);
    }

    const handlePercentageCalculation = (percentage) => {
        let percent = percentage / 100;
        let rate = parseFloat(driverRate);
        let addition = (rate * percent);
        let result = (rate + parseFloat(addition)).toFixed(2);

        setCustomerQuote(result);
    };

    const val = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return (
        <Box>
            {isMargin ? (
                <>
                    <Box sx={{ marginBottom: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <TextField 
                            variant="standard"
                            size="small"
                            label="Driver Rate"
                            value={driverRate}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                            }}
                            sx={{ width: '70%', marginBottom: '10px' }}
                            onChange={(e) => setDriverRate(e.target.value)}
                        />
                    </Box>
                    <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center', 
                        border: '1px solid grey',
                        borderRadius: '5px'
                    }}>
                        <TextField 
                            variant="outlined"
                            size="small"
                            value={val}
                            disabled
                            sx={{ width: '70%', marginTop: '10px', marginBottom: '15px' }}
                        />
                        <Box>
                            <ButtonTemplate onClick={() => handleClick(7)}>7</ButtonTemplate>
                            <ButtonTemplate onClick={() => handleClick(8)}>8</ButtonTemplate>
                            <ButtonTemplate onClick={() => handleClick(9)}>9</ButtonTemplate>
                            <ButtonTemplate 
                                onClick={() => {
                                    if (value >= 10) {
                                        setValue(value.slice(0, -1))
                                    } else if (value < 10) {
                                        setValue(0);
                                    }
                                }} 
                                buttonprops="del" 
                                uncommon
                            >
                                Del</ButtonTemplate>
                        </Box>
                        <Box>
                            <ButtonTemplate onClick={() => handleClick(4)}>4</ButtonTemplate>
                            <ButtonTemplate onClick={() => handleClick(5)}>5</ButtonTemplate>
                            <ButtonTemplate onClick={() => handleClick(6)}>6</ButtonTemplate>
                            <ButtonTemplate onClick={() => handlePerformOperation('+')}>+</ButtonTemplate>
                        </Box>
                        <Box>
                            <ButtonTemplate onClick={() => handleClick(1)}>1</ButtonTemplate>
                            <ButtonTemplate onClick={() => handleClick(2)}>2</ButtonTemplate>
                            <ButtonTemplate onClick={() => handleClick(3)}>3</ButtonTemplate>
                            <ButtonTemplate onClick={() => handlePerformOperation('-')}>-</ButtonTemplate>
                        </Box>
                        <Box>
                            <ButtonTemplate onClick={() => handleClick('dot')}>.</ButtonTemplate>
                            <ButtonTemplate onClick={() => handleClick(0)}>0</ButtonTemplate>
                            <ButtonTemplate onClick={() => handlePerformOperation('/')}>/</ButtonTemplate>
                            <ButtonTemplate onClick={() => handlePerformOperation('*')}>*</ButtonTemplate>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <ButtonTemplate onClick={handleReset} buttonprops='reset' uncommon>RESET</ButtonTemplate>
                            <ButtonTemplate onClick={handleResult} buttonprops='equalsTo' uncommon>=</ButtonTemplate>
                        </Box>
                    </Box>
                    <Box sx={{ marginBottom: '15px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Box>
                                    <ButtonTemplate onClick={() => handlePercentageCalculation(10)}>10%</ButtonTemplate>
                                    <ButtonTemplate onClick={() => handlePercentageCalculation(20)}>20%</ButtonTemplate>
                                    <ButtonTemplate onClick={() => handlePercentageCalculation(30)}>30%</ButtonTemplate>
                                    <ButtonTemplate onClick={() => setOtherPercentage(!otherPercentage)}>Other</ButtonTemplate>
                                </Box>
                                {otherPercentage && (
                                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                                        <TextField 
                                            variant="standard"
                                            size="small"
                                            value={percentageInput}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                                            }}
                                            onChange={(e) => setPercentageInput(e.target.value)}
                                            sx={{ width: '20%' }}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{padding: '3px 5px', marginLeft: '10px' }}
                                            onClick={() => handlePercentageCalculation(parseFloat(percentageInput))}
                                        >
                                            Calculate
                                        </Button>
                                    </Box>
                                )}
                                <TextField 
                                    variant="standard"
                                    size="small"
                                    label="Customer Quote"
                                    value={customerQuote}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                                    }}
                                    disabled
                                    sx={{ width: '70%', marginTop: '25px' }}
                                />
                    </Box>
                </>
            ) : (
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'center', 
                    border: '1px solid grey',
                    borderRadius: '5px'
                }}>
                    <TextField 
                        variant="outlined"
                        size="small"
                        value={val}
                        disabled
                        sx={{ width: '70%', marginTop: '10px', marginBottom: '15px' }}
                    />
                    <Box>
                        <ButtonTemplate onClick={() => handleClick(7)}>7</ButtonTemplate>
                        <ButtonTemplate onClick={() => handleClick(8)}>8</ButtonTemplate>
                        <ButtonTemplate onClick={() => handleClick(9)}>9</ButtonTemplate>
                        <ButtonTemplate 
                            onClick={() => {
                                if (value >= 10) {
                                    setValue(value.slice(0, -1))
                                } else if (value < 10) {
                                    setValue(0);
                                }
                            }} 
                            buttonprops="del" 
                            uncommon
                        >
                            Del</ButtonTemplate>
                    </Box>
                    <Box>
                        <ButtonTemplate onClick={() => handleClick(4)}>4</ButtonTemplate>
                        <ButtonTemplate onClick={() => handleClick(5)}>5</ButtonTemplate>
                        <ButtonTemplate onClick={() => handleClick(6)}>6</ButtonTemplate>
                        <ButtonTemplate onClick={() => handlePerformOperation('+')}>+</ButtonTemplate>
                    </Box>
                    <Box>
                        <ButtonTemplate onClick={() => handleClick(1)}>1</ButtonTemplate>
                        <ButtonTemplate onClick={() => handleClick(2)}>2</ButtonTemplate>
                        <ButtonTemplate onClick={() => handleClick(3)}>3</ButtonTemplate>
                        <ButtonTemplate onClick={() => handlePerformOperation('-')}>-</ButtonTemplate>
                    </Box>
                    <Box>
                        <ButtonTemplate onClick={() => handleClick('dot')}>.</ButtonTemplate>
                        <ButtonTemplate onClick={() => handleClick(0)}>0</ButtonTemplate>
                        <ButtonTemplate onClick={() => handlePerformOperation('/')}>/</ButtonTemplate>
                        <ButtonTemplate onClick={() => handlePerformOperation('*')}>*</ButtonTemplate>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <ButtonTemplate onClick={handleReset} buttonprops='reset' uncommon>RESET</ButtonTemplate>
                        <ButtonTemplate onClick={handleResult} buttonprops='equalsTo' uncommon>=</ButtonTemplate>
                    </Box>
                </Box>
            )}
        </Box>
    )

}

export default Calculator;